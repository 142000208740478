import { Category } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux'
import CommandPlayer, { simpleInboundAddedRunCommand } from './command-player'

const CategoryAddedCommand = taggedSum('CategoryAddedCommand', {
    Inbound: {
        category: 'Category',
    },
})

const runInboundCommand = (resources, command) =>
    simpleInboundAddedRunCommand(resources, command, Category, ReduxActions.categoryAdded, c => c.category)

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        CategoryAddedCommand,
        CommandPlayer({
            CommandType: CategoryAddedCommand,
            Type: Category,
            collectionPath: projectId => `/projects/${projectId}/categories/`,
            runInboundCommand,
            runOutboundCommand: () => {},
            addCommandToHistory,
            changeType: 'added',
        })
    )
}

export { addCommand, CategoryAddedCommand }
