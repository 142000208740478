import { Participant } from '@range.io/basic-types'
import { pluck, uniq } from '@range.io/functional'
import { pdf } from '@react-pdf/renderer'
import React from 'react'
import { useSelector, useStore } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getImageUrl, URL_SEARCH_ORDER } from '../../components-reusable/hooks/useImageUrl'
import { ReduxSelectors } from '../../redux'
import { getImageUri, makeCancelable } from './helpers'
import MediaReportDoc from './MediaReportDoc'

const useReport = () => {
    const { getState } = useStore()

    const allUploads = useSelector(ReduxSelectors.uploadLookupTable)
    const allCollaborations = useSelector(ReduxSelectors.collaborationsAsObject)
    const allCanvases = useSelector(ReduxSelectors.canvasLookupTable)
    const allTagNames = useSelector(ReduxSelectors.tagNameLookupTable)
    const selectedProject = useSelector(ReduxSelectors.selectedProject)
    const selectedOrganization = useSelector(ReduxSelectors.selectedOrganization)
    const { projectId } = useParams()
    const projectIdentifier = selectedProject.identifier

    const getUserFromParticipants = userId => selectedOrganization.participants[userId]
    const getTagNames = tagIds => tagIds.map(tag => allTagNames[tag].name)

    const prepareReport = selectedUploadIds => {
        const reportPromise = async () => {
            const selectedUploads = selectedUploadIds.map(uploadId => allUploads[uploadId])
            const uploadData = await Promise.all(
                selectedUploads.map(async upload => {
                    const author = getUserFromParticipants(upload.createdBy)
                    const authorAvatarUrl = await getImageUri(author.avatarUrl)

                    const parentCollaboration = allCollaborations[upload.parentId]
                    const state = getState()
                    const geometry = ReduxSelectors.geometryForCollaboration(state, parentCollaboration)
                    const canvas = allCanvases[geometry.canvasId]
                    const imageUrl = await getImageUrl(
                        projectId,
                        upload.id,
                        URL_SEARCH_ORDER.ANNOTATED_THUMBNAIL_1,
                        upload.storageVersion
                    )
                    const comments = ReduxSelectors.commentShapesForUpload(state, upload)

                    const { id, storageVersion } = parentCollaboration
                    const locationSnapshotUrl = await getImageUrl(
                        projectId,
                        id,
                        URL_SEARCH_ORDER.LOCATION,
                        storageVersion
                    )

                    return {
                        canvas,
                        createdAt: upload.createdAt,
                        description: upload.description,
                        comments,
                        fileSize: upload.fileSize,
                        fileType: upload.fileType,
                        imageUrl,
                        locationLabel: canvas.name,
                        locationSnapshotUrl,
                        name: upload.name,
                        parentId: upload.parentId,
                        parentName: parentCollaboration.name,
                        tagNames: getTagNames(uniq(upload.tagIds.concat(parentCollaboration.tags))),
                        userAvatarUrl: authorAvatarUrl,
                        userName: Participant.fullName(author),
                        projectIdentifier,
                        collaborationIdentifier: parentCollaboration.identifier,
                    }
                })
            )
            const tagNamesUsed = uniq(pluck('tagNames', uploadData).flat())
            const getUploadCountForUser = userId => selectedUploads.filter(upload => upload.createdBy === userId).length
            const contributors = await Promise.all(
                uniq(pluck('createdBy', selectedUploads)).map(async userId => {
                    const participant = getUserFromParticipants(userId)
                    const avatarUrl = await getImageUri(participant.avatarUrl)
                    const uploadsCount = getUploadCountForUser(userId)

                    return {
                        avatarUrl,
                        name: Participant.fullName(participant),
                        photoCount: uploadsCount,
                    }
                })
            )

            const logo = await getImageUri(selectedOrganization.logo)

            const data = {
                contributors,
                organizationName: selectedOrganization.name,
                logo,
                projectName: selectedProject.name,
                tagNamesUsed,
                uploadData,
            }

            const doc = <MediaReportDoc {...data} />
            const blob = await pdf(doc).toBlob()
            return blob
        }
        return makeCancelable(reportPromise())
    }

    const openReport = (reportBlob = null) => {
        if (!reportBlob) throw new Error('Requesting openReport on an empty report data blob!')
        const url = URL.createObjectURL(reportBlob)
        window.open(url, '_blank')
    }

    return { prepareReport, openReport }
}

export default useReport
