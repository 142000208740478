/*
 * FilterGroupBaseGroup is the foundational component for all filter groups in the system.
 * A "filter group" is a single "row" of a Filter dialog
 *
 * - Common layout structure
 * - Title/header handling
 * - Collapsible content
 * - Consistent styling
 *
 * This is the base for FilterGroupMultiSelect and FilterGroupSingleSelect, ensuring consistent
 * behavior and appearance across all filter rows
 */

import React from 'react'
import { FlexColumn } from '../../../components-reusable/index.js'
import { Row } from './FilterGroupShared.js'

const FilterGroupBaseGroup = ({
    title,
    checked,
    disabled = false,
    onToggle,
    width = 288,
    css,
    children,
    dataCy,
    fullPadding = false,
}) => (
    <FlexColumn
        css={{
            w: width,
            m: 0,
            gap: 12,
            ...(fullPadding ? { p: 16 } : { pt: 16, pb: 16 }),
            borderTop: '1px solid $neutral07',
            ...css,
        }}
        data-cy={dataCy}
    >
        <Row title={title} checked={checked && !disabled} setChecked={onToggle} disabled={disabled} />
        {checked && children}
    </FlexColumn>
)

export default FilterGroupBaseGroup
