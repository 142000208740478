import { Collaboration } from '@range.io/basic-types'
import { LookupTable } from '@range.io/functional'
import { CollaborationShape } from '../../../react-shapes/collaboration-shape.js'
import memoizeResult from '../../memoize-result.js'
import ReduxSelectors from '../../redux-selectors.js'
import { _field } from '../../selector-primitives.js'
import UiStateSelectors from '../../ui-state/ui-state-selectors.js'
import FeatureSelectors from '../feature-slice/feature-selectors.js'

const collaborationsAsArray = memoizeResult(state => Object.values(state.collaborations))
const collaborationsAsLookupTable = memoizeResult(state =>
    LookupTable(Object.values(state.collaborations), Collaboration)
)
const collaborationsAsObject = _field('collaborations')

/*
 * Return all the Collaborations for the currently-selected Feature
 * @sig _collaborationsForSelectedFeature :: State -> [Collaboration]
 */
const _collaborationsForSelectedFeature = state => {
    const featureId = state.selectedFeatureId
    return collaborationsAsArray(state).filter(c => c.featureId === featureId)
}

/*
 * Return the first Collaboration for the currently-selected Feature
 * @sig firstCollaborationForSelectedFeature :: State -> Collaboration
 */
const firstCollaborationForSelectedFeature = state => {
    const featureId = state.selectedFeatureId

    return featureId ? _collaborationsForSelectedFeature(state)[0] : undefined
}

/*
 * Return the first Collaboration for the given geometryId
 * @sig firstCollaborationForGeometry :: State -> Collaboration
 */
const firstCollaborationForGeometry = (state, geometryId) => {
    const feature = FeatureSelectors.firstFeatureForGeometry(state, geometryId)
    return feature ? collaborationsAsArray(state).find(c => c.featureId === feature.id) : null
}

// return the Collaboration for the upload
const collaborationForUpload = (state, upload) => collaborationsAsArray(state).find(c => upload?.parentId === c.id)

// :: State -> CollaborationShape
const collaborationShapeForSelectedCollaboration = state => {
    const collaboration = UiStateSelectors.selectedCollaboration(state)
    const shapeLookupTable = ReduxSelectors.allShapes(state)
    return CollaborationShape.fromCollaboration(shapeLookupTable)(collaboration)
}

const collaborationWithId = (state, collaborationId) => state.collaborations[collaborationId]

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------
const CollaborationSelectors = {
    collaborationForUpload,
    collaborationsAsObject,
    collaborationsAsLookupTable,
    collaborationsAsArray,
    collaborationShapeForSelectedCollaboration,
    collaborationWithId,
    firstCollaborationForGeometry,
    firstCollaborationForSelectedFeature,
}

export default CollaborationSelectors
