/*
 * FilterGroupForIdentifier is a filter group component for filtering content by identifiers.
 * It provides a multi-select interface for choosing identifiers, with support for:
 *
 * - Selecting multiple identifiers
 * - Project-specific identifier filtering
 * - Custom styling through CSS props
 *
 * The component integrates with the FilterSettings system and uses FilterGroupMultiSelect
 * for its UI implementation, with special handling for project identifiers.
 */

import { FilterSettings } from '@range.io/basic-types'
import React from 'react'
import { Text } from '../../components-reusable/index.js'
import Pill from '../Pill.js'
import FilterGroupMultiSelect from './filter-primitives/FilterGroupMultiSelect.js'
import FilterGroupTextInput from './filter-primitives/FilterGroupTextInput.js'

const FilterGroupForIdentifier = ({ allIdentifiers, projectIdentifier, filterSettings, setFilterSettings, css }) => {
    const updateFilterSettings = f => v => setFilterSettings(f(filterSettings, v))

    const phraseMatches = (identifier, phrase) => {
        const fullIdentifier = `${projectIdentifier}-${identifier}`
        return fullIdentifier.toLowerCase().includes(phrase)
    }

    const renderInput = (inputWrapperRef, showList, setShowList, phraseToFilter, setPhraseToFilter) => (
        <FilterGroupTextInput
            inputRef={inputRef}
            inputWrapperRef={inputWrapperRef}
            showList={showList}
            setShowList={setShowList}
            phraseToFilter={phraseToFilter}
            setPhraseToFilter={setPhraseToFilter}
            iconName="tags"
            dataCy="filter-by-identifier"
        />
    )

    const renderItem = identifier => (
        <Text>
            {projectIdentifier}-{identifier}
        </Text>
    )

    const renderPill = identifier => {
        const onDeselect = () => updateFilterSettings(FilterSettings.unselectIdentifier)(identifier)
        return (
            <Pill key={identifier} onDeselect={onDeselect} tooltipText="Remove ID">
                <Text ellipsed={true} css={{ maxWidth: 140, limitLinesOfTextTo: 1 }}>
                    {projectIdentifier}-{identifier}
                </Text>
            </Pill>
        )
    }

    const inputRef = React.useRef(null)
    const { shouldFilterByIdentifiers, selectedIdentifiers = [] } = filterSettings

    return (
        <FilterGroupMultiSelect
            title="Filter by ID"
            checked={shouldFilterByIdentifiers}
            onToggle={updateFilterSettings(FilterSettings.setShouldFilterByIdentifiers)}
            items={allIdentifiers}
            selectedItems={selectedIdentifiers}
            onItemSelect={updateFilterSettings(FilterSettings.toggleIdentifier)}
            onItemRemove={updateFilterSettings(FilterSettings.unselectIdentifier)}
            filterItem={phraseMatches}
            renderItem={renderItem}
            renderPill={renderPill}
            renderInput={renderInput}
            css={{ w: 'auto', ...css }}
            dataCy="filter-identifier"
            variant="identifiers"
            fullPadding
        />
    )
}

export default FilterGroupForIdentifier
