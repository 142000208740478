import * as CheckboxPrimitive from '@radix-ui/react-checkbox'

import * as Popover from '@radix-ui/react-popover'
import { arrayToLookupTable, pluck } from '@range.io/functional'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import UserRow from '../components-application/UserRow.js'
import { styled } from '../range-theme/index.js'
import { useKeyMaps } from './hooks/index.js'
import { Avatar, FlexColumn, FlexRow, Icon, ScrollArea, TextInput } from './index.js'
import PossiblySuspendedUserName from './PossiblySuspendedUserName.js'

const InputWrapper = styled('div', {
    position: 'relative',

    input: {
        paddingRight: '26px',
        fw: '500',

        '&:hover': {
            cursor: 'pointer',
        },
    },

    svg: {
        position: 'absolute',
        top: '18px',
        right: '14px',
    },
})

const ListWrapper = styled(FlexColumn, {
    mt: '4px',
    background: '$neutral10',
    color: '$neutral05',
    border: '1px solid $neutral07',
    borderRadius: '6px',
    position: 'absolute',
    zIndex: 200,
    boxShadow: '0px 6px 10px #00000010',
    boxSizing: 'border-box',
    maxHeight: '800px',
    variants: {
        variant: {
            default: { width: 450 },
            mediaViewUser: { maxWidth: 250 },
            mediaViewCanvas: { maxWidth: 300 },
            mediaViewTags: { maxWidth: 250, right: 29 },
            assigneeFilter: { width: 'content-fit' },
        },
    },
    defaultVariants: {
        variant: 'default',
    },
})
ListWrapper.toString = () => 'ListWrapper'

const StyledFilteringSelectWrapper = styled('div', {
    zIndex: 999,
    variants: {
        variant: {
            default: { display: 'inline-block', position: 'relative', width: 'inherit' },
            assigneeFilter: { display: 'flex', flexWrap: 'wrap', gap: 8, position: 'relative', width: 'inherit' },
        },
    },
    defaultVariants: {
        variant: 'default',
    },
})

const ListItem = styled('div', {
    padding: '8px',
    display: 'flex',
    borderRadius: '6px',
    alignItems: 'center',
    transitionDuration: '0.4s',
    overflow: 'wrap',
    margin: 8,
    fs: 14,
    '&:hover': {
        backgroundColor: '$primary02',
        cursor: 'pointer',
        color: '$neutral04',

        button: {
            border: '1px solid $neutral07',
        },
    },
})

const ListItemText = styled('span', {
    flex: '1 1 auto',
    ai: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
    all: 'unset',
    width: '12px',
    height: '12px',
    minWidth: '12px',
    background: '$neutral09',
    border: '1px solid $neutral07',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px',
    transitionDuration: '0.4s',
})

const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
    color: '#FFFFFF',
    backgroundColor: '$primary04',
    border: '1px solid $primary04',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    transitionDuration: '0.4s',
})

const Separator = styled('div', {
    width: 'calc(100% - 16px)',
    height: 1,
    backgroundColor: '$neutral07',
    margin: '0 8px',
})

/*
 * The fundamental algorithm for MultiSelect and SingleSelectUser
 * A FilteringSelect is made up of a Text input and a list of possible selections.
 *
 * The text input is used to filter the list of choices in the dropdown by matching each element of the list
 * against the typed value somehow (probably just checking if the typed text is a substring of the list item).
 *
 * The selection list shows each element of the (possibly-filtered) list of selection choices. Clicking on
 * an item of the list has some effect on the list.
 *
 * Hitting "escape" or clicking away from the box will close the select list (leaving only the Text input visible)
 *
 * items            the list of choices being selected among; the type of the items is not known by FilteringSelect
 * selectedItems    the list of selected choices; the type of the items is not known by FilteringSelect
 * inputRenderer    callback to render the Text input box (SingleSelectUser shows an icon and close button in
 *                  addition to the Text input, for instance)
 * itemRenderer     callback to render a row of the dropdown choices (MultiSelect shows a checkbox for each row in
 *                  addition to the name of the item for that row)
 * showList         indicates that the dropdown list SHOULD be shown (the Text input always rendered)
 * setShowList      callback to indicate that the list is no longer shown (for SingleSelectUser, this is
 *                  enough to shut down the selector
 * doesItemMatchPhrase callback to check if a specific item should be displayed, given the current filter text
 *
 */
const FilteringSelect = ({
    items,
    selectedItems,
    inputRenderer,
    itemRenderer,
    pillRenderer,
    showList,
    setShowList,
    doesItemMatchPhrase,
    maxListHeight = '800px',
    variant = 'default',
    'data-cy': dataCy = 'filtering-select',
}) => {
    const [itemsToRender, setItemsToRender] = useState(items)
    const [phraseToFilter, setPhraseToFilter] = useState('')
    const inputRef = useRef()
    const listRef = useRef()

    // check for mousedown anywhere but in the input box and stop showing the list
    useEffect(() => {
        const listener = event => {
            // if we clicked outside of the input or the list then hide the list
            if (
                !inputRef.current.contains(event.target) &&
                (!listRef?.current || !listRef.current.contains(event.target))
            )
                setShowList(false)
        }
        document.addEventListener('mousedown', listener)
        return () => document.removeEventListener('mousedown', listener)
    }, [])

    // check for a press of the escape key and stop showing the list
    useEffect(() => {
        const listener = event => {
            if (event.key === 'Escape') {
                document.activeElement.blur()
                setShowList(false)
            }
        }
        window.addEventListener('keydown', listener)
        return () => window.removeEventListener('keydown', listener)
    }, [])

    // rerender the list if the filter (or items) has changed
    useEffect(() => {
        const newItemsToRender = items.filter(item => doesItemMatchPhrase(item, phraseToFilter))
        setItemsToRender(newItemsToRender)
    }, [phraseToFilter, items])

    const css = variant === 'assigneeFilter' ? { mt: 40, maxHeight: maxListHeight } : { maxHeight: maxListHeight }
    return (
        <StyledFilteringSelectWrapper data-cy={dataCy} variant={variant}>
            {inputRenderer(inputRef, showList, setShowList, phraseToFilter, setPhraseToFilter)}
            {showList && itemsToRender.length > 0 && (
                <ListWrapper data-cy={`${dataCy}-list`} variant={variant} ref={listRef} css={css}>
                    <ScrollArea maxHeight={maxListHeight}>{itemsToRender.map(itemRenderer)}</ScrollArea>
                </ListWrapper>
            )}
            {pillRenderer && selectedItems.map(pillRenderer)}
        </StyledFilteringSelectWrapper>
    )
}

/*
 * The complicated Multi-filtering system used, for instance, on the Media tab that allows the user to
 * filter by date, tags or participants
 *
 * filterItem       callback to check if a specific item should be displayed, given the current filter text
 *                  (passed as doesItemMatchPhrase to FilteringSelect)
 * items            the items to filter
 * selectedItems    a list of the already-selected items
 * onItemClick      callback when an item has been selected
 * placeholder      default for the Text input if there is currently no text
 * renderItem       callback to render an item in the select dropdown
 *
 */
const MultiSelect = ({
    filterItem,
    items,
    maxListHeight = '800px',
    onItemClick,
    placeholder,
    renderInput,
    renderItem,
    renderPill = undefined,
    selectedItems,
    variant = 'default',
}) => {
    const isElementSelected = elementId => selectedItems.findIndex(x => x === elementId) !== -1

    const defaultRenderInput = (inputRef, showList, setShowList, phraseToFilter, setPhraseToFilter) => (
        <InputWrapper ref={inputRef} onClick={() => setShowList(prev => !prev)}>
            <TextInput
                placeholder={placeholder}
                value={phraseToFilter}
                onBlur={setPhraseToFilter}
                onChange={value => {
                    setPhraseToFilter(value)
                    if (!showList) setShowList(true) // ensure list shows up back again when user starts typing
                }}
                noClear
            />
            <Icon iconSize="8px" css={{ color: '$neutral05' }} name="chevronDown2" />
        </InputWrapper>
    )

    const itemRenderer = (item, idx) => (
        <ListItem key={idx} onClick={() => onItemClick(item)}>
            <StyledCheckbox checked={isElementSelected(item)}>
                <StyledIndicator>
                    <Icon name="tickSmall" iconSize="12px" />
                </StyledIndicator>
            </StyledCheckbox>
            <ListItemText>{renderItem(item)}</ListItemText>
        </ListItem>
    )

    const [showList, setShowList] = useState(false)
    const props = {
        doesItemMatchPhrase: filterItem,
        items,
        selectedItems,
        inputRenderer: renderInput || defaultRenderInput,
        itemRenderer,
        pillRenderer: renderPill,
        showList,
        setShowList,
        maxListHeight,
        variant,
    }
    return <FilteringSelect {...props} data-cy="multi-select" />
}

MultiSelect.propTypes = {
    items: PropTypes.array,
    selectedItems: PropTypes.array,
    placeholder: PropTypes.string.isRequired,
    filterItem: PropTypes.func.isRequired,
    renderInput: PropTypes.func,
    renderItem: PropTypes.func.isRequired,
    onItemClick: PropTypes.func.isRequired,
}

const StyledUserName = styled(PossiblySuspendedUserName, {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const noAssigneeShape = {
    isSuspended: false,
    fullName: 'No Assignee',
}

/*
 * A dropdown to select among the given participants using the same UI as FilteringSelect
 * @sig SingleSelectUser :: ({ [ParticipantShape], User, SetUserId, String }) -> ReactElement
 */
const SingleSelectUser = ({
    projectParticipantShapes,
    selectedUser,
    setSelectedUserId,
    maxListHeight = '290px',
    withNoAssigneeOption = false,
    withoutIcon = false,
    css = {},
}) => {
    /* Callback from FilteringSelect for each user to see if that user's name matches the current filter */
    const doesItemMatchPhrase = (userId, phrase) => {
        if (!userId && !phrase.length) return true // allow "no assignee" option to show on the list
        if (!userId) return false
        const participantShape = participantShapeLookupTable[userId]
        return participantShape.fullName.toLowerCase().includes(phrase.toLowerCase())
    }

    /* Callback from FilteringSelect to render the text box that will be used as a filter to shorten the list */
    const inputRenderer = (inputRef, showList, setShowList, filterPhrase, setFilterPhrase) => {
        const iconCss = {
            position: 'absolute',
            left: 9,
            top: 9,
            br: 1000,
            bg: '$neutral10',
            border: '1px solid $neutral07',
            p: 6,
            zIndex: 999,
            boxSizing: 'content-box',
        }
        const inputCss = { h: '48px', w: '192px', maxWidth: '100%', input: { pl: withoutIcon ? 10 : 48 } }
        return (
            <FlexRow
                css={{ w: '217px', maxWidth: '100%', ...css }}
                ref={inputRef}
                onClick={() => setShowList(prev => !prev)}
            >
                {!withoutIcon && <Icon iconSize="16px" name="user" css={iconCss} />}
                <TextInput
                    autoFocus
                    css={inputCss}
                    placeholder="Enter name..."
                    value={filterPhrase}
                    onBlur={setFilterPhrase}
                    onChange={value => {
                        setFilterPhrase(value)
                        if (!showList) setShowList(true) // ensure list shows up back again when user starts typing
                    }}
                    noClear
                />
            </FlexRow>
        )
    }

    /* Callback from FilteringSelect to render an item in the dropdown list from its userId */
    const itemRenderer = userId => {
        const css = { bg: userId === selectedUser?.id ? '$neutral08' : 'transparent' }

        const handleClick = event => {
            event.stopPropagation()
            setSelectedUserId(userId)
        }

        // render "no assignee" item
        if (!userId) {
            return (
                <>
                    <ListItem data-cy="list-item-user" css={css} key={userId} onClick={handleClick}>
                        <FlexRow css={{ alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <Avatar size="24" css={{ mr: '8px' }} participantShape={noAssigneeShape} />
                            <StyledUserName participantShape={noAssigneeShape} />
                        </FlexRow>
                    </ListItem>
                    <Separator />
                </>
            )
        }

        const participantShape = participantShapeLookupTable[userId]

        return (
            <ListItem data-cy="list-item-user" css={css} key={userId} onClick={handleClick}>
                <UserRow participantShape={participantShape} />
            </ListItem>
        )
    }

    // if the list is closed for whatever reason, we're done, which we indicate by setting the previously-assigned user
    const setShowList = showList => {
        if (!showList) setSelectedUserId(selectedUser?.id)
    }

    const activeParticipants = projectParticipantShapes.filter(p => !p.isSuspended)
    const userIds = pluck('id', activeParticipants)
    const participantShapeLookupTable = arrayToLookupTable('id', projectParticipantShapes)
    const props = {
        doesItemMatchPhrase,
        items: withNoAssigneeOption ? [null, ...userIds] : userIds,
        inputRenderer,
        itemRenderer,
        showList: true,
        setShowList,
        maxListHeight,
    }

    useEffect(() => {
        pushKeyMap('SingleSelectUser', { Escape: () => setSelectedUserId(selectedUser?.id) })
        return () => popKeyMap('SingleSelectUser')
    }, [])

    const { pushKeyMap, popKeyMap } = useKeyMaps()

    return <FilteringSelect {...props} />
}

SingleSelectUser.propTypes = {
    projectParticipantShapes: PropTypes.arrayOf(PropTypes.object).isRequired, // [ParticipantShape]
    selectedUser: PropTypes.object, // User
    setSelectedUserId: PropTypes.func.isRequired,
    withoutIcon: PropTypes.bool,
    css: PropTypes.object,
}

/*
 * PopoverContent handles the dropdown list portion of the MultiSelectPopover. It's responsible for:
 *
 * 1. Positioning the list above or below the input based on available space
 * 2. Rendering the filtered items with checkboxes
 * 3. Handling scroll behavior for long lists
 * 4. Managing click-outside behavior
 *
 * The component uses Radix's Popover.Content for positioning, which:
 *
 * - Automatically adjusts position when scrolling/resizing
 * - Handles collision detection with viewport edges
 * - Provides proper ARIA attributes for accessibility
 */
const PopoverContent = ({
    itemsToRender,
    itemRenderer,
    handleClickOutside,
    sideToRender,
    listRef,
    variant,
    maxListHeight,
    maxListWidth,
}) => (
    <Popover.Content
        className="PopoverContent"
        onOpenAutoFocus={e => e.preventDefault()}
        onInteractOutside={handleClickOutside}
        side={sideToRender}
        align="start"
        sideOffset={4}
        collisionPadding={20}
        forceMount
        style={{
            zIndex: 99999,
            height: 'auto',
            maxHeight: `var(--radix-popper-available-height)`,
            pointerEvents: 'all',
        }}
    >
        {itemsToRender.length > 0 && (
            <ListWrapper
                variant={variant}
                ref={listRef}
                css={{
                    position: 'inherit',
                    width: `min(var(--radix-popper-available-width), ${maxListWidth})`,
                    maxHeight: `var(--radix-popper-available-height)`,
                    overflowY: 'auto',
                }}
            >
                <ScrollArea
                    viewportCss={{
                        maxHeight: `min(var(--radix-popper-available-height), ${maxListHeight})`,
                    }}
                >
                    {itemsToRender.map(itemRenderer)}
                </ScrollArea>
            </ListWrapper>
        )}
    </Popover.Content>
)

/*
 * MultiSelectPopover provides a searchable dropdown list with multi-select functionality.
 * It combines a text input for filtering with a popover list of selectable items.
 * The component uses Radix UI's Popover for positioning and accessibility.
 *
 * Note on Popover.Trigger: We intentionally don't use Radix's Popover.Trigger because:
 *
 * 1. We need the popover to open on multiple events (focus, click, and typing)
 * 2. Radix's trigger has built-in toggle behavior that conflicts with our needs
 * 3. The trigger's click handling can interfere with the input's focus behavior
 * 4. We need fine-grained control over when the popover closes
 *
 * Instead, we:
 * 1. Manually control the open state with our own state management
 * 2. Use Popover.Anchor to position the popover correctly
 * 3. Handle our own click/focus/type events through the input component
 * 4. Carefully manage click-outside behavior to prevent unwanted closes
 */
const MultiSelectPopover = ({
    filterItem,
    items,
    maxListHeight = '300px',
    maxListWidth = '280px',
    onItemClick,
    renderInput,
    renderItem,
    renderPill = undefined,
    selectedItems,
    variant = 'default',
}) => {
    // Track filtered items based on search input
    const [itemsToRender, setItemsToRender] = useState(items)
    const [phraseToFilter, setPhraseToFilter] = useState('')
    const inputRef = useRef()
    const listRef = useRef()
    const [showList, setShowList] = useState(false)
    const inputWrapperRef = useRef()

    /*
     * Always shows the list when called. Used by the input component to open
     * the list on focus/click/type. Named to clarify that it only opens, never closes.
     */
    const forceShowList = () => setShowList(true)

    /*
     * Handles the Radix Popover's open state changes.
     * Only handles closing the popover, and prevents closing when clicking within our components.
     * This ensures the popover doesn't close when interacting with the input or list.
     */
    const onOpenChange = v => {
        if (!v) {
            const target = document.activeElement
            const clickedInput = inputWrapperRef.current?.contains(target)
            const clickedList = listRef.current?.contains(target)

            if (clickedInput || clickedList) return
            setShowList(false)
        }
    }

    /*
     * Helper function to check if an item is in the selectedItems array.
     * Used by itemRenderer to determine if an item's checkbox should be checked.
     */
    const isElementSelected = elementId => selectedItems.findIndex(x => x === elementId) !== -1

    /*
     * Updates the filtered list whenever the search phrase or items change.
     * Uses the provided filterItem function to determine which items match the search.
     */
    useEffect(() => {
        const newItemsToRender = items.filter(item => filterItem(item, phraseToFilter))
        setItemsToRender(newItemsToRender)
    }, [phraseToFilter, items])

    /*
     * Renders a single item in the dropdown list.
     * Includes a checkbox to show selection state and uses the provided renderItem
     * function to render the item's content.
     */
    const itemRenderer = (item, idx) => (
        <ListItem key={idx} onClick={() => onItemClick(item)}>
            <StyledCheckbox checked={isElementSelected(item)}>
                <StyledIndicator>
                    <Icon name="tickSmall" iconSize="12px" />
                </StyledIndicator>
            </StyledCheckbox>
            <ListItemText>{renderItem(item)}</ListItemText>
        </ListItem>
    )

    /*
     * Determines whether the popover should appear above or below the input. This is complex because:
     *
     * 1. We need to measure both the input and list heights
     * 2. We need to consider the viewport boundaries
     * 3. The list height can change as items are filtered
     * 4. We want to prevent the list from "flipping" position while typing
     *
     * The function:
     *
     * 1. Gets the input's position relative to the viewport
     * 2. Measures the list's height
     * 3. Compares available space above and below the input
     * 4. Chooses the side with more space
     *
     * It's memoized to prevent recalculation on every render, only updating when:
     *
     * - The input or list elements change
     * - The list visibility changes (which can affect measurements)
     */
    const sideToRender = React.useMemo(() => {
        if (!inputRef?.current) return 'bottom'

        const rect = inputRef.current.getBoundingClientRect()
        if (!listRef?.current) return 'bottom'

        const rectList = listRef.current.getBoundingClientRect()
        return rectList.height < document.body.offsetHeight - rect.bottom ? 'bottom' : 'top'
    }, [inputRef.current, listRef.current, showList])

    /*
     * Handles clicks outside both the input and list.
     * Clears the filter text and closes the list when clicking outside.
     * Used by Radix Popover's onInteractOutside prop.
     */
    const handleClickOutside = event => {
        const target = event.target
        const clickedInput = inputWrapperRef.current?.contains(target)
        const clickedList = listRef.current?.contains(target)

        if (!clickedInput && !clickedList) {
            setPhraseToFilter('')
            setShowList(false)
        }
    }

    return (
        <Popover.Root open={showList} onOpenChange={onOpenChange} modal={false}>
            <FlexRow css={{ flexWrap: 'wrap', gap: '8px' }}>
                <Popover.Anchor asChild>
                    <div ref={inputWrapperRef}>
                        {renderInput(inputRef, showList, forceShowList, phraseToFilter, setPhraseToFilter)}
                    </div>
                </Popover.Anchor>
                {renderPill && selectedItems.map(renderPill)}
            </FlexRow>
            {showList && (
                <Popover.Portal>
                    <PopoverContent
                        itemsToRender={itemsToRender}
                        itemRenderer={itemRenderer}
                        handleClickOutside={handleClickOutside}
                        sideToRender={sideToRender}
                        listRef={listRef}
                        variant={variant}
                        maxListHeight={maxListHeight}
                        maxListWidth={maxListWidth}
                    />
                </Popover.Portal>
            )}
        </Popover.Root>
    )
}

export { MultiSelect, SingleSelectUser, MultiSelectPopover }
