/*
 * FilterGroupForFollower is a filter group component for filtering content by followers.
 * It provides a multi-select interface for choosing followers, with support for:
 *
 * - Selecting multiple followers
 * - Filtering items with no followers
 * - Disabled state when task pins are not shown
 * - Custom styling through CSS props
 *
 * The component integrates with the FilterSettings system and uses FilterGroupMultiSelect for its UI implementation.
 */

import { FilterSettings } from '@range.io/basic-types'
import React from 'react'
import PossiblySuspendedUserName from '../../components-reusable/PossiblySuspendedUserName.js'
import { ParticipantShape } from '../../react-shapes/participant-shape.js'
import Pill from '../Pill.js'
import UserRow from '../UserRow.js'
import FilterGroupMultiSelect from './filter-primitives/FilterGroupMultiSelect.js'
import FilterGroupTextInput from './filter-primitives/FilterGroupTextInput.js'

const FilterGroupForFollower = ({ allParticipantShapes, filterSettings, setFilterSettings, disabled = false, css }) => {
    const updateFilterSettings = f => v => setFilterSettings(f(filterSettings, v))
    const nameMatchesUser = (participantShape, phrase) => ParticipantShape.matchesFullName(participantShape, phrase)

    const renderInput = (inputWrapperRef, showList, setShowList, phraseToFilter, setPhraseToFilter) => (
        <FilterGroupTextInput
            inputRef={inputRef}
            inputWrapperRef={inputWrapperRef}
            showList={showList}
            setShowList={setShowList}
            phraseToFilter={phraseToFilter}
            setPhraseToFilter={setPhraseToFilter}
            iconName="user"
            dataCy="filter-by-follower"
        />
    )

    const renderUserItem = participantShape => <UserRow participantShape={participantShape} />

    const renderPill = participantShape => {
        const onDeselect = () => updateFilterSettings(FilterSettings.unselectFollower)(participantShape)
        return (
            <Pill key={participantShape.id} onDeselect={onDeselect} tooltipText="Remove User">
                <PossiblySuspendedUserName
                    participantShape={participantShape}
                    ellipsed={true}
                    css={{ maxWidth: 140, limitLinesOfTextTo: 1 }}
                />
            </Pill>
        )
    }

    const inputRef = React.useRef(null)
    const { shouldFilterByFollower, followers } = filterSettings

    return (
        <FilterGroupMultiSelect
            title="Filter by Follower"
            checked={shouldFilterByFollower}
            disabled={disabled}
            onToggle={updateFilterSettings(FilterSettings.setShouldFilterByFollower)}
            items={allParticipantShapes}
            selectedItems={followers}
            onItemSelect={updateFilterSettings(FilterSettings.toggleFollower)}
            onItemRemove={updateFilterSettings(FilterSettings.unselectFollower)}
            filterItem={nameMatchesUser}
            renderItem={renderUserItem}
            renderPill={renderPill}
            renderInput={renderInput}
            css={css}
            dataCy="filter-follower"
        />
    )
}

export default FilterGroupForFollower
