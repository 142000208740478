// import { Search } from 'lucide-react'
import React from 'react'
import { styled } from '../../range-theme/index.js'
import { Icon } from '../index.js'

const StyledContainer = styled('div', {
    height: '40px',
    paddingLeft: 8,
    paddingRight: 8,
    background: '$neutral09',
    borderRadius: 6,
    border: '1px solid $neutral07',
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    cursor: 'pointer',
    transition: 'all 0.2s',
    text: '$neutral05',
    color: '$neutral05',

    '&:hover, &:focus-within': {
        borderColor: '$primary04',
    },
})

const StyledInput = styled('input', {
    height: '100%',
    width: '100%',
    background: 'transparent',
    border: 'none',
    fontSize: '$sm',
    color: '$neutral05',
    outline: 'none',
    cursor: 'pointer',

    '&::placeholder': {
        color: '$neutral05',
    },

    '&:focus': {
        color: '$neutral04',
        '&::placeholder': {
            color: '$neutral05',
        },
    },
})

const IconPickerSearchBar = ({ value, onChange }) => (
    <StyledContainer>
        <Icon name="searchFollowers" iconSize="16" />
        <StyledInput type="text" value={value} onChange={e => onChange(e.target.value)} placeholder="Search icons" />
    </StyledContainer>
)

export default IconPickerSearchBar
