import { pick, pluck } from '@range.io/functional'
import { _field } from '../../selector-primitives.js'
import CanvasSourceSelectors from '../canvas-source-slice/canvas-source-selectors.js'
import GeometrySelectors from '../geometry-slice/geometry-selectors.js'

const canvasLookupTable = _field('canvases')

/*
 * Return canvas for given Collaboration
 * @sig canvasForCollaboration :: (State, Collaboration) -> Canvas
 */
const canvasForCollaboration = (state, collaboration) => {
    const geometry = GeometrySelectors.geometryForCollaboration(state, collaboration)
    return state.canvases[geometry.canvasId]
}

/*
 * Return ONLY the canvases that actually have geometries on them
 * @sig canvasesWithGeometries :: State -> [Canvas]
 */
const canvasesWithGeometries = state => {
    const { geometries, canvases } = state

    const canvasIdsUsedByGeometries = pluck('canvasId', Object.values(geometries))
    return Object.values(pick(canvasIdsUsedByGeometries, canvases))
}

/*
 * Sorted array of all canvases: ALL PDF's (sorted by name), followed by all non-PDF's (in no particular order)
 * @sig canvasesAsArray :: State -> [Canvas]
 */
const canvasesAsArray = state => {
    const orderPDFsFirstThenByName = (canvas1, canvas2) => {
        const pdfCanvasSource1 = CanvasSourceSelectors.pdfCanvasSource(state, canvas1)
        const pdfCanvasSource2 = CanvasSourceSelectors.pdfCanvasSource(state, canvas2)

        // one or both are not PDF's
        if (!pdfCanvasSource1 && !pdfCanvasSource2) return 0
        if (!pdfCanvasSource1 && pdfCanvasSource2) return -1
        if (pdfCanvasSource1 && !pdfCanvasSource2) return 1

        // sort PDF's by name (temporary)
        // TODO: Make it use canvas ordering once it's in place
        return pdfCanvasSource1.name.localeCompare(pdfCanvasSource2.name)
    }

    return Object.values(state.canvases).sort(orderPDFsFirstThenByName)
}

/*
 * All Canvases that have a related CanvasSource.Pdf
 * @sig pdfCanvases :: State -> [Canvas]
 */
const pdfCanvases = state => {
    const canvasSourceIds = pluck('canvasId', CanvasSourceSelectors.pdfCanvasSources(state))
    const canvases = pick(canvasSourceIds, state.canvases)
    return Object.values(canvases)
}

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

const CanvasSelectors = {
    canvasesAsArray,
    canvasesWithGeometries,
    canvasForCollaboration,
    canvasLookupTable,
    pdfCanvases,
}

export default CanvasSelectors
