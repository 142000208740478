import PropTypes from 'prop-types'
import React from 'react'
import { ParticipantShapePropTypes } from '../react-shapes/participant-shape.js'
import { StatusShapePropTypes } from '../react-shapes/status-shape.js'
import { TagShapePropTypes } from '../react-shapes/tag-shape.js'
import FilterSelector from './filters/FilterSelector.js'

/*
 * FilterForCanvas select from about a dozen different filters
 * @sig FilterForCanvas = ({ LookupTable, LookupTable, FilterSettings, F) -> React component
 *  F = FilterSettings -> ()
 */
const FilterForCanvas = ({
    allCategories,
    allIdentifiers,
    allParticipantShapes,
    allStatusShapes,
    allTagShapes,
    projectIdentifier,
    filterSettings,
    setFilterSettings,
}) => {
    const groupStyles = {
        assignee: { ml: 16, mr: 16 },
        category: { ml: 16, mr: 16 },
        follower: { ml: 16, mr: 16 },
        overdue: { ml: 16, mr: 16, pl: 0, pr: 0 },
    }

    return (
        <FilterSelector
            allCategories={allCategories}
            allIdentifiers={allIdentifiers}
            allParticipantShapes={allParticipantShapes}
            allStatusShapes={allStatusShapes}
            allTagShapes={allTagShapes}
            filterSettings={filterSettings}
            filterTitle="Canvas Filters"
            groupStyles={groupStyles}
            projectIdentifier={projectIdentifier}
            setFilterSettings={setFilterSettings}
            showCanvasGroup={false}
            showPhotosGroup={true}
            tooltipText="Canvas Filters"
        />
    )
}

FilterForCanvas.propTypes = {
    allParticipantShapes: PropTypes.arrayOf(PropTypes.shape(ParticipantShapePropTypes)).isRequired,
    allStatusShapes: PropTypes.arrayOf(PropTypes.shape(StatusShapePropTypes)).isRequired,
    allTagShapes: PropTypes.arrayOf(PropTypes.shape(TagShapePropTypes)).isRequired,
    filterSettings: PropTypes.object.isRequired,
    setFilterSettings: PropTypes.func.isRequired,
}

export default FilterForCanvas
