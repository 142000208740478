/*
 * FilterGroupForCanvas is a filter group component for filtering content by canvas.
 * It provides a multi-select interface for choosing canvases, with support for:
 *
 * - Selecting multiple canvases
 * - Custom styling through CSS props
 *
 * This component is specifically used in the task list view to filter tasks by their associated canvases.
 * It integrates with the FilterSettings system and uses FilterGroupMultiSelect for its UI implementation.
 */

import { FilterSettings } from '@range.io/basic-types'
import React from 'react'
import { FlexRow } from '../../components-reusable/index.js'
import { CanvasShape } from '../../react-shapes/canvas-shape.js'
import Pill from '../Pill.js'
import FilterGroupMultiSelect from './filter-primitives/FilterGroupMultiSelect.js'
import { StyledText } from './filter-primitives/FilterGroupShared.js'
import FilterGroupTextInput from './filter-primitives/FilterGroupTextInput.js'

const FilterGroupForCanvas = ({ allCanvasShapes, filterSettings, setFilterSettings, disabled = false, css }) => {
    const updateFilterSettings = f => v => setFilterSettings(f(filterSettings, v))
    const phraseMatches = (canvasShape, phrase) => CanvasShape.matchesFullName(canvasShape, phrase)

    const renderInput = (inputWrapperRef, showList, setShowList, phraseToFilter, setPhraseToFilter) => (
        <FilterGroupTextInput
            inputRef={inputRef}
            inputWrapperRef={inputWrapperRef}
            showList={showList}
            setShowList={setShowList}
            phraseToFilter={phraseToFilter}
            setPhraseToFilter={setPhraseToFilter}
            iconName="levels"
            dataCy="filter-by-canvas"
        />
    )

    const renderCanvasItem = canvasShape => (
        <FlexRow css={{ ai: 'center' }}>
            <StyledText>{canvasShape.name}</StyledText>
        </FlexRow>
    )

    const renderPill = canvasShape => {
        const onDeselect = () => updateFilterSettings(FilterSettings.unselectCanvas)(canvasShape)
        return (
            <Pill key={canvasShape.id} onDeselect={onDeselect} tooltipText="Remove Canvas">
                <StyledText css={{ maxWidth: 140, limitLinesOfTextTo: 1 }}>{canvasShape.name}</StyledText>
            </Pill>
        )
    }

    const inputRef = React.useRef(null)
    const { shouldFilterByCanvas, selectedCanvasShapes } = filterSettings

    return (
        <FilterGroupMultiSelect
            title="Filter by Canvas"
            checked={shouldFilterByCanvas}
            disabled={disabled}
            onToggle={updateFilterSettings(FilterSettings.setShouldFilterByCanvas)}
            items={allCanvasShapes}
            selectedItems={selectedCanvasShapes}
            onItemSelect={updateFilterSettings(FilterSettings.toggleCanvas)}
            onItemRemove={updateFilterSettings(FilterSettings.unselectCanvas)}
            filterItem={phraseMatches}
            renderItem={renderCanvasItem}
            renderPill={renderPill}
            renderInput={renderInput}
            css={css}
            dataCy="filter-canvas"
            maxListHeight="426px"
        />
    )
}

export default FilterGroupForCanvas
