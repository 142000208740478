/*
 * @sig categories :: State -> LookupTable<Category>
 */
const categories = state => state.categories

// Collaboration -> Category
// when deleting a category, the collaboration's category may be gone, in which canse use the default category
const categoryForCollaboration = (state, collaboration) =>
    state.categories[collaboration.categoryId] || state.categories.find(c => c.isDefault)

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

const CategorySelectors = {
    categories,
    categoryForCollaboration,
}

export default CategorySelectors
