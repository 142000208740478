import { filterObject, memoizeReduxState, pick } from '@range.io/functional'
import memoizeResult from '../../memoize-result.js'
import { _field } from '../../selector-primitives.js'
import UiStateSelectors from '../../ui-state/ui-state-selectors.js'

const projectLookupTable = _field('projects')
const projectsAsArray = memoizeResult(state => Object.values(state.projects))

/*
 * Return only the projects permitted to the given user; at the moment, this is identical to those permitted to the org
 */
const _userProjectsAsArray = state => {
    const organizationProjects = state.projects

    const user = UiStateSelectors.selectedUser(state)
    if (!user) return []

    const userProjectIds = user.permissions.projects ?? []
    let projects = userProjectIds.map(i => organizationProjects[i])
    projects = projects.filter(p => p) // remove nils
    return projects.sort((a, b) => a.name.localeCompare(b.name))
}

const _userProjectsAsLookupTable = state => {
    const organizationProjects = state.projects

    const user = UiStateSelectors.selectedUser(state)
    if (!user) return {}

    const userProjectIds = user.permissions.projects ?? []
    return pick(userProjectIds, organizationProjects)
}

const userProjectsAsArray = memoizeReduxState(['projects', 'user'], _userProjectsAsArray)

const userProjectsForSelectedOrganizationAsArray = state => {
    const organization = UiStateSelectors.selectedOrganization(state)
    if (!organization) return []

    const organizationProjects = organization.projectIds
    const allProjects = userProjectsAsArray(state)
    return allProjects.filter(p => organizationProjects.includes(p.id))
}

const userProjectsAsLookupTable = memoizeReduxState(['projects'], _userProjectsAsLookupTable)

/*
 * Return all Projects where:
 *
 * - the project is for the currently-selected organization
 * - project.participants includes participant.id
 * - the participant is not suspended
 *
 * @sig currentProjectsForParticipant :: (State, Participant) -> { Id: Project }
 */
const currentProjectsForParticipant = (state, participant) => {
    const projectHasParticipant = project => {
        const projectParticipant = project.participants?.[participant.id]
        return projectParticipant && !projectParticipant.isSuspended && organizationProjects.includes(project.id)
    }

    const organizationProjects = UiStateSelectors.selectedOrganization(state).projectIds
    return filterObject(projectHasParticipant, state.projects)
}

/*
 * Return all the Projects for the currently-selected Organization
 * @sig organizationProjectAsArray :: State => [Project]
 */
const organizationProjectAsArray = state => {
    const organization = UiStateSelectors.selectedOrganization(state)
    if (!organization) return []

    // include only elements of state.projects that match one of the organization.projectIds
    const { projectIds: organizationProjectIds } = organization
    const projects = filterObject(p => organizationProjectIds.includes(p.id), state.projects)
    return Object.values(projects)
}

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

const ProjectSelectors = {
    currentProjectsForParticipant,
    organizationProjectAsArray,
    projectLookupTable,
    projectsAsArray,
    userProjectsAsArray,
    userProjectsAsLookupTable,
    userProjectsForSelectedOrganizationAsArray,
}

export default ProjectSelectors
