/*
 * DrawPolygonMode
 *
 * Draw mode used to draw a polygon
 *
 * Type DrawPolygonModeState:
 *    polygon               : DrawFeature<JsonFeature>
 *    currentVertexPosition : Integer
 */
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import BasicDrawMode from './basic-draw-mode.js'

const MapboxDrawLib = MapboxDraw.lib

const { CommonSelectors, isEventAtCoordinates, createVertex } = MapboxDrawLib

const DrawPolygonMode = { ...BasicDrawMode }

/*
 * @sig onSetup :: {} -> DrawPolygonModeState
 */
DrawPolygonMode.onSetup = function () {
    const polygon = this.newFeature({
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [[]],
        },
        properties: { annotationType: 'polygon' },
    })

    this.addFeature(polygon)
    this.clearSelectedFeatures()
    this.setActionableState({ trash: true })

    return {
        polygon,
        currentVertexPosition: 0,
    }
}

/*
 * @sig clickAnywhere :: (DrawPolygonModeState, Event) -> ()
 */
DrawPolygonMode.clickAnywhere = function (state, e) {
    if (
        state.currentVertexPosition > 0 &&
        isEventAtCoordinates(e, state.polygon.coordinates[0][state.currentVertexPosition - 1])
    ) {
        return this.finishDrawing(state)
    }
    state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat)
    state.currentVertexPosition++
    state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat)
}

/*
 * @sig clickOnVertex :: (DrawPolygonModeState) -> ()
 */
DrawPolygonMode.clickOnVertex = function (state) {
    return this.changeMode('select', { featureId: state.polygon.id })
}

/*
 * @sig onMouseMove :: (DrawPolygonModeState, Event) -> ()
 */
DrawPolygonMode.onMouseMove = function (state, e) {
    state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat)
}

/*
 * @sig onTap :: (DrawPolygonModeState, Event) -> ()
 * @sig onClick :: (DrawPolygonModeState, Event) -> ()
 */
DrawPolygonMode.onTap = DrawPolygonMode.onClick = function (state, e) {
    if (CommonSelectors.isVertex(e)) return this.clickOnVertex(state, e)
    return this.clickAnywhere(state, e)
}

/*
 * @sig onStop :: (DrawPolygonModeState) -> ()
 */
DrawPolygonMode.onStop = function (state) {
    // check to see if we've deleted this feature
    if (this.getFeature(state.polygon.id) === undefined) return

    // remove last added coordinate
    state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`)
    if (state.polygon.isValid()) this.map.fire('draw.create', { features: [state.polygon.toGeoJSON()] })
}

/*
 * @sig toDisplayFeatures (DrawPolygonModeState, GeoJsonFeature, DisplayFunc) -> ()
 * DisplayFunc = GeoJsonFeature -> ()
 */
DrawPolygonMode.toDisplayFeatures = function (state, geojson, display) {
    const isActivePolygon = geojson.properties.id === state.polygon.id
    geojson.properties.active = isActivePolygon ? 'true' : 'false'
    if (!isActivePolygon) return display(geojson)

    // Don't render a polygon until it has two positions
    // (and a 3rd which is just the first repeated)
    if (geojson.geometry.coordinates.length === 0) return

    const coordinateCount = geojson.geometry.coordinates[0].length
    // 2 coordinates after selecting a draw type
    // 3 after creating the first point
    if (coordinateCount < 3) {
        return
    }
    geojson.properties.meta = 'feature'
    display(createVertex(state.polygon.id, geojson.geometry.coordinates[0][0], '0.0', false))
    if (coordinateCount > 3) {
        // Add a start position marker to the map, clicking on this will finish the feature
        // This should only be shown when we're in a valid spot
        const endPos = geojson.geometry.coordinates[0].length - 3
        display(createVertex(state.polygon.id, geojson.geometry.coordinates[0][endPos], `0.${endPos}`, false))
    }
    if (coordinateCount <= 4) {
        // If we've only drawn two positions (plus the closer),
        // make a LineString instead of a Polygon
        const lineCoordinates = [
            [geojson.geometry.coordinates[0][0][0], geojson.geometry.coordinates[0][0][1]],
            [geojson.geometry.coordinates[0][1][0], geojson.geometry.coordinates[0][1][1]],
        ]
        // create an initial vertex so that we can track the first point on mobile devices
        display({
            type: 'Feature',
            properties: geojson.properties,
            geometry: {
                coordinates: lineCoordinates,
                type: 'LineString',
            },
        })
        if (coordinateCount === 3) {
            return
        }
    }
    // render the Polygon
    return display(geojson)
}

/*
 * @sig finishDrawing :: (DrawPolygonModeState) -> ()
 */
DrawPolygonMode.finishDrawing = function (state) {
    this.changeMode('select', { featureId: state.polygon.id })
}

/*
 * @sig cancelDrawingAndDelete :: (DrawPolygonModeState) -> ()
 */
DrawPolygonMode.cancelDrawingAndDelete = function (state) {
    this.deleteFeature([state.polygon.id])
    this.changeMode('idle')
}

export default DrawPolygonMode
