import categoryIcons from '@range.io/functional/src/categories/category-icons.js'
import React, { useMemo, useState } from 'react'
import { styled } from '../../range-theme/index.js'
import IconPickerGrid from './IconPickerGrid.jsx'
import IconPickerSearchBar from './IconPickerSearchBar.jsx'

// convert category-icons data to [{ name: String, icon: SvgText }]
const asWrapper = ([name, svgText]) => ({ name, icon: svgText.trim() })
const iconList = Object.entries(categoryIcons).map(asWrapper)
iconList.sort((a, b) => a.name.localeCompare(b.name))

const StyledContainer = styled('div', {
    width: '410px',
    height: '416px',
    padding: 16,
    background: '$neutral10',
    borderRadius: 10,
    border: '1px solid $neutral07',
    display: 'flex',
    flexDirection: 'column',
})

const IconPicker = ({ selectedIcon, onSelectedIconChange }) => {
    const [search, setSearch] = useState('')

    const filteredIcons = useMemo(() => {
        const searchTerm = search.toLowerCase()
        return iconList.filter(({ name }) => name.toLowerCase().includes(searchTerm))
    }, [search])

    return (
        <StyledContainer>
            <IconPickerSearchBar value={search} onChange={setSearch} />
            <IconPickerGrid icons={filteredIcons} selectedIcon={selectedIcon} onSelectIcon={onSelectedIconChange} />
        </StyledContainer>
    )
}

export default IconPicker
