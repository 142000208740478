import React from 'react'
import { Button, Flex, FlexColumn, Icon, ScrollableList, Text } from '../components-reusable'
import { styled } from '../range-theme/index.js'
import ListViewHeader from './ListViewHeader'
import ListViewTaskRow from './ListViewTaskRow'
import { PageLoadingMedia } from './PageLoading'

const StyledListViewContent = styled(FlexColumn, {
    width: '100%',
    height: '100%',
    background: '$neutral10',
    borderRadius: '6px',
    border: '1px solid $neutral07',
    backgroundCLip: 'border-box',
    overflow: 'hidden',
})

const ListViewEmptyArea = ({ areFiltersApplied, height, onGoToCanvas }) => (
    <FlexColumn css={{ height, alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
        <Flex css={{ position: 'relative' }}>
            <Flex
                css={{
                    width: 14,
                    height: 1,
                    position: 'absolute',
                    filter: 'blur(4px)',
                    background: '$neutral01',
                    mt: 100,
                }}
            />
            <Icon name="pin" css={{ color: '$neutral07', width: 64, mb: 12, position: 'relative' }} />
            <Icon name="convertToTask" css={{ color: '$neutral04', width: 32, position: 'absolute', pt: 17 }} />
        </Flex>
        <Text css={{ color: '$neutral04', fs: '18px', fw: '600', lh: '24px', mb: '8px', mt: 8 }}>
            {areFiltersApplied
                ? `It looks like there aren't any results...🤔`
                : `It looks like there aren't any tasks...🤔`}
        </Text>
        <Text css={{ color: '$neutral05', fs: '14px', fw: '500', lh: '20px', mb: '24px' }}>
            {areFiltersApplied ? `Try adjusting your filters` : `Start adding task pins to the canvas`}
        </Text>
        {areFiltersApplied ? (
            <Flex />
        ) : (
            <Button variant="primary" type="submit" size="lg" onClick={onGoToCanvas}>
                <Icon name="levels" iconSize="16px" />
                <Text>Go to Canvas</Text>
            </Button>
        )}
    </FlexColumn>
)

const ListViewMain = ({
    hasRightsToArchive,
    hasRightsToDelete,
    isProjectDataLoaded,
    items,
    onGoToCanvas,
    onGoToCanvasId,
    onItemSelect,
    showFullContent,
    statusNames,
    areFiltersApplied,
    handleSearchPhraseChanged,
    checkedItems,
    clearCheckedItems,
    taskRowCheckboxDataId,
    handleItemChecked,
    onDeleteItems,
    onArchiveItems,
    allAvailableAssigneesShapes,
    bulkActionSelectedAssignee,
    onBulkActionSelectedAssignee,
    areAllCheckedTasksArchived,
    areAllTasksFollowed,
    isFollowingInProgress,
    bulkActionSelectedStatus,
    onBulkActionSelectedStatus,
    onBulkActionTagsChange,
    onBulkActionDateChange,
    onCheckAllItemsClick,
    onSortClick,
    onBulkFollowButtonClick,
    onBulkActionFollowersChange,
    sortSettings,
    onDateChange,
    onAssigneeChange,
    onExport,
    onStatusChange,
}) => {
    const renderItem = item => {
        const status = statusNames[item.status.id]
        return (
            <ListViewTaskRow
                allAvailableAssigneesShapes={allAvailableAssigneesShapes}
                allStatuses={statusNames}
                handleItemChecked={handleItemChecked}
                isChecked={checkedItems.includes(item.id)}
                item={item}
                key={item.id}
                onAssigneeChange={onAssigneeChange}
                onDateChange={onDateChange}
                onGoToCanvasId={onGoToCanvasId}
                onItemSelect={onItemSelect}
                onStatusChange={onStatusChange}
                showFullContent={showFullContent}
                status={status}
                taskRowCheckboxDataId={taskRowCheckboxDataId}
            />
        )
    }

    const renderContent = () => {
        const scrollAreaHeight = '100%'
        if (!isProjectDataLoaded) return <PageLoadingMedia css={{ height: scrollAreaHeight }} />
        const isViewEmpty = items.length === 0

        if (isViewEmpty)
            return (
                <ListViewEmptyArea
                    onGoToCanvas={onGoToCanvas}
                    height={scrollAreaHeight}
                    areFiltersApplied={areFiltersApplied}
                />
            )

        return <ScrollableList css={{ height: scrollAreaHeight, zIndex: 0 }} data={items} renderDataItem={renderItem} />
    }

    return (
        <StyledListViewContent data-cy="tasks-list-view">
            <ListViewHeader
                handleSearchPhraseChanged={handleSearchPhraseChanged}
                items={items}
                checkedItems={checkedItems}
                clearCheckedItems={clearCheckedItems}
                onDeleteItems={onDeleteItems}
                onArchiveItems={onArchiveItems}
                allAvailableAssigneesShapes={allAvailableAssigneesShapes}
                bulkActionSelectedAssignee={bulkActionSelectedAssignee}
                onBulkActionSelectedAssignee={onBulkActionSelectedAssignee}
                areAllCheckedTasksArchived={areAllCheckedTasksArchived}
                areAllTasksFollowed={areAllTasksFollowed}
                isFollowingInProgress={isFollowingInProgress}
                statusNames={statusNames}
                bulkActionSelectedStatus={bulkActionSelectedStatus}
                onBulkActionSelectedStatus={onBulkActionSelectedStatus}
                onBulkActionTagsChange={onBulkActionTagsChange}
                onBulkActionDateChange={onBulkActionDateChange}
                onBulkFollowButtonClick={onBulkFollowButtonClick}
                onBulkActionFollowersChange={onBulkActionFollowersChange}
                showFullContent={showFullContent}
                onCheckAllItemsClick={onCheckAllItemsClick}
                onSortClick={onSortClick}
                sortSettings={sortSettings}
                hasRightsToDelete={hasRightsToDelete}
                hasRightsToArchive={hasRightsToArchive}
                onExport={onExport}
            />
            {renderContent()}
        </StyledListViewContent>
    )
}

export default ListViewMain
