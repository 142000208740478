import { FilterSettings } from '@range.io/basic-types'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useNavigateWithQuery from '../components-reusable/hooks/useNavigateWithQuery.js'
import {
    Button,
    DropdownMenuWithTooltip,
    Flex,
    FlexColumn,
    FlexRow,
    Icon,
    ScrollArea,
    Text,
    TextInput,
} from '../components-reusable/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { styled } from '../range-theme/index.js'
import { CanvasShapePropTypes } from '../react-shapes/canvas-shape.js'
import { CollaborationShapePropTypes } from '../react-shapes/collaboration-shape.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import { CollaborationCardList } from './CollaborationCard.js'
import FilterForCanvas from './FilterForCanvas.js'
import FiltersActiveBanner from './FiltersActiveBanner.js'
import { possiblyRecenter } from './Map.js'
import NavigatorSortSelector from './NavigatorSortSelector.js'
import { AdminMenuButtonFeedback, AdminMenuButtonSupport } from './pages/SharedComponents.js'

const StyledNavigatorContainer = styled(FlexColumn, {
    background: '$neutral10',
    width: 'var(--gridColumn0Width)',
    minWidth: 'var(--gridColumn0Width)',
    maxWidth: 'var(--gridColumn0Width)',
    borderRight: '1px solid $neutral07',
    zIndex: 1,
})

const StyledSheetIndicator = styled(FlexRow, {
    br: 999,
    backgroundColor: '$primary04',
    minWidth: '8px',
    minHeight: '8px',
    maxHeight: '8px',
    maxWidth: '8px',
})

const StyledTotalPinsRow = styled(FlexRow, {
    height: 32,
    minHeight: 32,
    width: '100%',
    borderBottom: '1px solid $neutral07',
    backgroundColor: '$neutral08',
    paddingLeft: 15,
    paddingRight: 4,
    color: '$neutral04',
    ai: 'center',
    fs: 12,
    justifyContent: 'space-between',

    '& svg': {
        color: '$neutral05',
    },
})

const StyledCanvasName = styled(Text, {
    // width: '100%',
    color: '$neutral04',
    fontSize: '14px',
    fontWeight: '600 !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    textAlign: 'left',
})

const StyledCanvasMenuItemLabel = styled(Text, {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
})

const StyledCanvasButton = styled(Button, {
    height: 32,
    minHeight: 32,
    paddingLeft: '10px',
    fs: '12px',
    justifyContent: 'space-between',
    background: 'none',
})

const StyleCanvasSelectorButton = styled('button', {
    width: 228,
    minWidth: 228,
    maxWidth: 228,
    textDecoration: 'ellipsis',
    boxSizing: 'border-box',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '$neutral09',
    outline: 'none',
    border: '1px solid $neutral07',
    minHeight: 40,
    transitionDuration: '0.4s',
    paddingLeft: 10,
    justifyContent: 'space-between',
    borderRadius: 6,
    cursor: 'pointer',
    gap: 8,
    boxShadow: '0px 0px 0px #5D81FF50',

    '& svg': {
        color: '$neutral04',
    },

    '&:hover': {
        backgroundColor: '$primary02',
        borderColor: '$primary03',
        boxShadow: '6px 6px 2px #8B8F9D20',
    },
})

/*
 * Dropdown menu for selecting a canvas among a list of canvases
 * @sig CanvasSelector :: ([CanvasShape], [CollaborationShape], CanvasShape, (CanvasShape -> void)) -> ReactElement
 * AllCollaborations is needed to know where to put the markers that show which canvases have pins
 */
const CanvasSelector = ({
    allCanvasShapes,
    allCollaborationShapes,
    selectedCanvasShape,
    setSelectedCanvasShape,
    canvasOrder,
}) => {
    const [filteredCanvasShapes, setFilteredCanvasShapes] = useState(allCanvasShapes)
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        const filtered = allCanvasShapes.filter(canvas => canvas.name.toLowerCase().includes(inputValue.toLowerCase()))
        setFilteredCanvasShapes(filtered)
    }, [inputValue])

    const handleCanvasSelect = id => {
        setSelectedCanvasShape(id)
        resetCanvasSearch()
    }

    const resetCanvasSearch = () => {
        setFilteredCanvasShapes(allCanvasShapes)
        setInputValue('')
    }

    const canvasMenuItem = canvasId => {
        const canvasShape = filteredCanvasShapes.get(canvasId)
        if (!canvasShape) return null
        const hasPins = allCollaborationShapes.some(cs => cs.canvasId === canvasShape.id)
        return (
            <DropdownMenuWithTooltip.MenuItem
                key={canvasShape.id}
                onSelect={() => handleCanvasSelect(canvasShape.id)}
                onPointerLeave={event => event.preventDefault()}
                onPointerMove={event => event.preventDefault()}
            >
                <StyledCanvasButton
                    style={{ justifyContent: 'space-between' }}
                    variant="dropdown-menuitem-navigator"
                    data-selected={selectedCanvasShape.id === canvasShape.id}
                >
                    <StyledCanvasMenuItemLabel>{canvasShape.name}</StyledCanvasMenuItemLabel>
                    {hasPins && <StyledSheetIndicator />}
                </StyledCanvasButton>
            </DropdownMenuWithTooltip.MenuItem>
        )
    }

    const boxCss = {
        mt: 5,
        width: '276px',
        boxSizing: 'border-box',
        boxShadow: '0px 6px 10px #00000010',
        marginLeft: 12,
    }
    const css1 = { display: 'flex', fd: 'column', gap: '8px' }
    const selectedCanvasName = selectedCanvasShape.name

    const scrollAreaHeight = '75vh'

    return (
        <DropdownMenuWithTooltip.Root>
            <DropdownMenuWithTooltip.Trigger tooltipText="Change Canvas">
                <StyleCanvasSelectorButton>
                    <Icon iconSize="20px" name="levels" />
                    <StyledCanvasName>{selectedCanvasName}</StyledCanvasName>
                    <Icon iconSize="16px" name="chevronDown" />
                </StyleCanvasSelectorButton>
            </DropdownMenuWithTooltip.Trigger>
            <DropdownMenuWithTooltip.Content css={boxCss} side="bottom" align="middle" sideOffset={-1} loop={true}>
                <DropdownMenuWithTooltip.MenuItem
                    css={{ padding: 0 }}
                    onSelect={e => e.preventDefault()} // prevent the dropdown from collapsing when input is clicked
                    onPointerLeave={event => event.preventDefault()}
                    onPointerMove={event => event.preventDefault()}
                >
                    <TextInput
                        data-cy="canvas-selector-search-input"
                        placeholder="Search for canvas..."
                        onChange={setInputValue}
                        value={inputValue}
                        onKeyDown={e => {
                            if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp' && e.key !== 'Escape') e.stopPropagation() // allow bubbling only for ArrowDown, ArrowUp and Escape
                        }}
                    />
                </DropdownMenuWithTooltip.MenuItem>

                <ScrollArea maxHeight={scrollAreaHeight}>
                    <FlexColumn css={css1} orientation="vertical">
                        {filteredCanvasShapes.length ? (
                            canvasOrder.map(canvasMenuItem)
                        ) : (
                            <StyledCanvasMenuItemLabel css={{ paddingLeft: '10px', color: '$neutral05' }}>
                                No results found
                            </StyledCanvasMenuItemLabel>
                        )}
                    </FlexColumn>
                </ScrollArea>
            </DropdownMenuWithTooltip.Content>
        </DropdownMenuWithTooltip.Root>
    )
}

CanvasSelector.propTypes = {
    allCanvasShapes: PropTypes.arrayOf(PropTypes.shape(CanvasShapePropTypes)).isRequired,
    allCollaborationShapes: PropTypes.arrayOf(PropTypes.shape(CollaborationShapePropTypes)).isRequired,
    selectedCanvasShape: PropTypes.shape(CanvasShapePropTypes).isRequired,
    setSelectedCanvasShape: PropTypes.func.isRequired,
    canvasOrder: PropTypes.arrayOf(PropTypes.string),
}

const FeedbackButton = () => (
    <Flex css={{ p: 12, borderTop: '1px solid $neutral07', height: '64px', gap: 8 }}>
        <AdminMenuButtonFeedback buttonText="Support" variant="button-primary" />
        <AdminMenuButtonSupport buttonText="Tutorials" variant="canvasNav" />
    </Flex>
)

const Navigator = ({
    canvasSelectorProps,
    collaborationCardListProps,
    navigatorFilterSelectorProps,
    navigatorSortSelectorProps,
    resetFilterSettings,
    pinsLimitReached,
}) => {
    const pinCount = () => {
        const { collaborationShapes } = collaborationCardListProps
        if (collaborationShapes.length === 0) return '0 Pins on Canvas'
        if (collaborationShapes.length === 1) return '1 Pin Total'
        return `${collaborationShapes.length} Pins Total`
    }

    const { filterSettings, allStatusShapes, allParticipantShapes, allIdentifiers } = navigatorFilterSelectorProps

    const isSetToDefault = FilterSettings.isSetToDefaults(
        filterSettings,
        allStatusShapes,
        allParticipantShapes,
        allIdentifiers
    )

    return (
        <StyledNavigatorContainer className="canvas-navigator">
            <FlexRow css={{ gap: 8, mt: 12, mb: 12, ml: 12 }}>
                <CanvasSelector {...canvasSelectorProps} />
                <FilterForCanvas {...navigatorFilterSelectorProps} />
            </FlexRow>
            <Flex css={{ height: 1, background: '$neutral07' }} />
            {!isSetToDefault && <FiltersActiveBanner variant="canvas" onResetClick={resetFilterSettings} />}

            <StyledTotalPinsRow>
                <FlexRow css={{ ai: 'center', gap: 4 }}>
                    <Icon name="projectDetails" iconSize="14" />
                    <Text>{pinCount()}</Text>
                </FlexRow>
                <NavigatorSortSelector {...navigatorSortSelectorProps} />
            </StyledTotalPinsRow>
            <CollaborationCardList
                {...collaborationCardListProps}
                isSetToDefault={isSetToDefault}
                pinsLimitReached={pinsLimitReached}
            />
            <FeedbackButton />
        </StyledNavigatorContainer>
    )
}

/*
 * Left panel of CanvasView; smart wrapper for Navigator
 */
const NavigatorWrapper = ({ selectCanvas }) => {
    const navigate = useNavigateWithQuery()

    const selectCollaboration = (e, collaborationShape) => {
        commandHistory.getResources().mapboxDraw.selectFeature(collaborationShape.geometryId)
        const mapboxMap = commandHistory.getResources().mapboxMap
        possiblyRecenter(collaborationShape, mapboxMap)
    }

    const selectUpload = (e, uploadId, parentId) => {
        const collaborationForUpload = canvasCollaborationShapesFiltered.find(c => c.id === parentId)
        // clicking an uploads allows also to navigate through its' siblings in the details view
        const siblingUploadIds = collaborationForUpload.uploads.map(u => u.id)
        navigate(`../media/${uploadId}`, {
            state: {
                safeToNavigateBack: true,
                uploadIds: siblingUploadIds,
                navigatedFrom: 'collaborationWindow',
                navigatedFromUrl: window.location.pathname + window.location.search,
            },
        })
    }

    const setFilterSettings = settings => {
        dispatch(ReduxActions.navigatorFilterSettingsChanged(settings))
        const mapboxMap = commandHistory.getResources().mapboxMap
        mapboxMap.jumpTo({ ...mapboxMap.getCenter() })
    }
    const resetFilterSettings = () =>
        setFilterSettings(FilterSettings.defaultFilterSettings(allStatusShapes, allParticipantShapes))
    const setSortSettings = settings => dispatch(ReduxActions.navigatorSortSettingsChanged(settings))

    // selectors
    const dispatch = useDispatch()
    const commandHistory = useCommandHistory()
    const selectedCanvas = useSelector(ReduxSelectors.selectedCanvas)
    const selectedCollaborationId = useSelector(ReduxSelectors.selectedCollaborationId)
    const selectedTool = useSelector(ReduxSelectors.selectedTool)
    const {
        canvases: allCanvasShapes,
        statuses: allStatusShapes,
        tags: allTagShapes,
        projectParticipants: allParticipantShapes,
        collaborations: collaborationShapes,
    } = useSelector(ReduxSelectors.allShapes)
    const filterSettings = useSelector(ReduxSelectors.navigatorFilterSettings)
    const navigatorSortSettings = useSelector(ReduxSelectors.navigatorSortSettings)
    const filteredCollaborationShapes = useSelector(ReduxSelectors.filteredCollaborations)
    const selectedProject = useSelector(ReduxSelectors.selectedProject)
    const canCreateCollaboration = useSelector(ReduxSelectors.isCreateAllowed('collaboration', null))
    const organization = useSelector(ReduxSelectors.selectedOrganization)

    // if there's no selected canvas, don't render the navigator
    if (!selectedCanvas)
        return <Flex css={{ bg: '$neutral10', w: 'var(--gridColumn0Width)', borderRight: '1px solid $neutral07' }} />

    const canvasCollaborationShapes = collaborationShapes.filter(cs => cs.canvasId === selectedCanvas?.id)
    const canvasCollaborationShapesFiltered = filteredCollaborationShapes.filter(
        cs => cs.canvasId === selectedCanvas?.id
    )
    const selectedCanvasShape =
        selectedCanvas && allCanvasShapes.find(canvasShape => canvasShape.id === selectedCanvas.id)

    const projectIdentifier = selectedProject?.identifier
    const allIdentifiers = canvasCollaborationShapes.flatMap(collaboration => collaboration.identifier)
    const allCategories = useSelector(ReduxSelectors.categories)

    const pinsLimitReached = useMemo(() => {
        if (!organization.limitsAndCounts) return false
        const { collaborationCount, collaborationCountLimit } = organization.limitsAndCounts
        return collaborationCount >= collaborationCountLimit
    }, [organization])

    const canvasSelectorProps = {
        allCollaborationShapes: filteredCollaborationShapes,
        allCanvasShapes,
        selectedCanvasShape,
        setSelectedCanvasShape: selectCanvas,
        canvasOrder: selectedProject.canvasOrder,
    }

    const collaborationCardListProps = {
        selectedCollaborationId,
        canCreateCollaboration,
        collaborationShapes: canvasCollaborationShapesFiltered,
        onImageClicked: selectUpload,
        onTileClicked: selectCollaboration,
        selectedTool,
        projectIdentifier: selectedProject?.identifier,
        allCategories,
    }

    const navigatorFilterSelectorProps = {
        allStatusShapes,
        allTagShapes,
        allParticipantShapes,
        allIdentifiers,
        allCategories,
        projectIdentifier,
        filterSettings,
        setFilterSettings,
    }

    const navigatorSortSelectorProps = { navigatorSortSettings, updateNavigatorSortSettings: setSortSettings }

    return (
        <Navigator
            canvasSelectorProps={canvasSelectorProps}
            collaborationCardListProps={collaborationCardListProps}
            navigatorFilterSelectorProps={navigatorFilterSelectorProps}
            navigatorSortSelectorProps={navigatorSortSelectorProps}
            resetFilterSettings={resetFilterSettings}
            pinsLimitReached={pinsLimitReached}
        />
    )
}

Navigator.displayName = 'Navigator'
Navigator.propTypes = {
    canvasSelectorProps: PropTypes.shape(CanvasSelector.propTypes).isRequired,
    collaborationCardListProps: PropTypes.shape(CollaborationCardList.propTypes).isRequired,
    navigatorFilterSelectorProps: PropTypes.shape(FilterForCanvas.propTypes).isRequired,
    navigatorSortSelectorProps: PropTypes.shape(NavigatorSortSelector.propTypes).isRequired,
}

export { Navigator, NavigatorWrapper }
