/*
 * Defines the top-level CommandHistory singleton (probably via an UndoRedo component)
 * See Readme
 */
import { assoc, dissoc, forEachObject, without } from '@range.io/functional'
import * as Firestore from 'firebase/firestore'
import { ReduxActions } from '../../redux/index.js'
import * as Segment from '../../segment/segment.js'
import { firestore } from '../configure-environment/config-local.js'
import { addCommand as addCanvasChangedCommandSingleton } from './canvas-changed-command.js'
import { addCommand as addCanvasRemovedCommandSingleton } from './canvas-removed-command.js'
import { addCommand as addCanvasSourceChangedCommandSingleton } from './canvas-source-changed-command.js'
import { addCommand as addCategoryAddedCommandSingleton } from './category-added-command.js'
import { addCommand as addCategoryChangedCommandSingleton } from './category-changed-command.js'
import { addCommand as addCategoryRemovedCommandSingleton } from './category-removed-command.js'
import { addCommand as addCollaborationAccessChangedCommandSingleton } from './collaboration-access-changed-command.js'
import { addCommand as addCollaborationAddedCommandSingleton } from './collaboration-added-command.js'
import { addCommand as addCollaborationChangedCommandSingleton } from './collaboration-changed-command.js'
import { addCommand as addCommentAddedCommandSingleton } from './comment-added-command.js'
import { addCommand as addCommentChangedCommandSingleton } from './comment-changed-command.js'
import { addCommand as addCommentRemovedCommandSingleton } from './comment-removed-command.js'
import { addCommand as addDuplicateCollaborationCommandSingleton } from './duplicate-collaboration-command.js'
import { addCommand as addGeometriesAddedCommandSingleton } from './geometries-added-command.js'
import { addCommand as addGeometriesChangedCommandSingleton } from './geometries-changed-command.js'
import { addCommand as addGeometriesRemovedCommandSingleton } from './geometries-removed-command.js'
import {
    addEntireProjectLoadedCommandSingleton,
    addInitialDataLoadedCommandSingleton,
} from './initial-data-loaded-command.js'
import { addCommand as addInvitationAddedCommandSingleton } from './invitation-added-command.js'
import { addInvitationChangedCommandSingleton } from './invitation-changed-command.js'
import { addInvitationRemovedCommandSingleton } from './invitation-removed-command.js'
import { addCommand as addOrganizationAddedCommandSingleton } from './organization-added-command.js'
import { addCommand as addOrganizationChangedCommandSingleton } from './organization-changed-command.js'
import { addOrganizationParticipantAddedCommandSingleton } from './organization-participant-added-command.js'
import { addOrganizationParticipantChangedCommandSingleton } from './organization-participant-changed-command.js'
import { addOrganizationParticipantReInvitedCommandSingleton } from './organization-participant-re-invited-command.js'
import { addOrganizationParticipantRemovedCommandSingleton } from './organization-participant-removed-command.js'
import { addOrganizationParticipantRemovedSelfCommandSingleton } from './organization-participant-removed-self-command.js'
import { addCommand as addPresenceChangedCommandSingleton } from './presence-changed-command.js'
import { addCommand as addProjectAddedCommandSingleton } from './project-added-command.js'
import { addCommand as addProjectCanvasesChangedCommandSingleton } from './project-canvases-updated-command.js'
import { addCommand as addProjectChangedCommandSingleton } from './project-changed-command.js'
import { addProjectParticipantAddedCommandSingleton } from './project-participant-added-command.js'
import { addProjectParticipantRemovedCommandSingleton } from './project-participant-removed-command.js'
import { addCommand as addProjectRemovedCommandSingleton } from './project-removed-command.js'
import { addCommand as addRangeStatusChangedCommandSingleton } from './range-status-changed-command.js'
import { addCommand as addStatusNameAddedCommandSingleton } from './status-name-added-command.js'
import { addCommand as addStatusNameChangedCommandSingleton } from './status-name-changed-command.js'
import { addCommand as addStatusNameRemovedCommandSingleton } from './status-name-removed-command.js'
import { addCommand as addTagNameAddedCommandSingleton } from './tag-name-added-command.js'
import { addCommand as addTagNameChangedCommandSingleton } from './tag-name-changed-command.js'
import { addCommand as addTagNameRemovedCommandSingleton } from './tag-name-removed-command.js'
import { addCommand as addUpdateAddedCommandSingleton } from './update-added-command.js'
import { addCommand as addUploadAddedCommandSingleton } from './upload-added-command.js'
import { addCommand as addUploadChangedCommandSingleton } from './upload-changed-command.js'
import { addCommand as addUploadRemovedCommandSingleton } from './upload-removed-command.js'
import { addCommand as addUserAddedCommandSingleton } from './user-added-command.js'
import { addCommand as addUserChangedCommandSingleton } from './user-changed-command.js'
import { addUserInvitationAddedCommandSingleton } from './user-invitation-added-command.js'
import { addUserInvitationChangedCommandSingleton } from './user-invitation-changed-command.js'

const createCommandHistorySingleton = store => {
    // -----------------------------------------------------------------------------------------------------------------
    // Update the CommandPlayers -- that is, not the commands
    // -----------------------------------------------------------------------------------------------------------------

    const registerCommandPlayer = (Type, player) => (commandPlayers[Type['@@typeName']] = player)

    const resourceChanged = (key, value) => {
        if (resources[key] === value) return // no change

        resources = assoc(key, value, resources)
        forEachObject(commandPlayer => commandPlayer.resourceChanged(key, value), commandPlayers)
    }

    const resourceRemoved = key => {
        resources = dissoc(key, resources)
        forEachObject(commandPlayer => commandPlayer.resourceRemoved(key), commandPlayers)
    }

    // remove ALL the resources EXCEPT the initialResources, which have nothing to do with a specific user
    const userResourcesRemoved = () => {
        const initialResourceKeys = Object.keys(initialResources)
        const resourceKeys = Object.keys(resources)
        const userKeys = without(initialResourceKeys, resourceKeys)

        userKeys.map(resourceRemoved)
    }

    // -----------------------------------------------------------------------------------------------------------------
    // Operate on commands
    // -----------------------------------------------------------------------------------------------------------------

    const _commandPlayerForCommand = command => commandPlayers[command['@@typeName']]

    const runCommand = async command => {
        const result = await _commandPlayerForCommand(command).runCommand(resources, command)
        undoStack.push(command)
        return result
    }

    const undoCommand = async () => {
        const command = undoStack.pop()
        const result = await _commandPlayerForCommand(command).undoCommand(resources, command)
        redoStack.push(command)
        return result
    }

    const redoCommand = async () => {
        const command = redoStack.pop()
        const result = await _commandPlayerForCommand(command).redoCommand(resources, command)
        undoStack.push(command)
        return result
    }

    const runTransaction = async updateFunction => await Firestore.runTransaction(firestore, updateFunction)
    const _firestoreDoc = (projectId, collection, id) =>
        Firestore.doc(firestore, `/projects/${projectId}/${collection}/${id}`)

    const transactionSet = (transaction, projectId, collectionName, id, data) =>
        transaction.set(_firestoreDoc(projectId, collectionName, id), data)

    const transactionUpdate = (transaction, projectId, collectionName, id, changes) =>
        transaction.update(_firestoreDoc(projectId, collectionName, id), changes)

    const transactionDelete = (transaction, projectId, collectionName, id) =>
        transaction.delete(_firestoreDoc(projectId, collectionName, id))

    const undoStack = []
    const redoStack = []
    const commandPlayers = {}

    const { dispatch, getState } = store

    const displayError = e => {
        const description =
            "This doesn't usually happen, but we were unable to save your changes. We apologise for the inconvenience. Please try again."
        const title = 'Oops... something went wrong'
        dispatch(
            ReduxActions.globalModalDataSet({
                title,
                description,
                cancelButton: {
                    label: 'Ok',
                },
            })
        )

        console.error(e)
    }

    const displayDelay = (id, message, severity = 'alert') => {
        return new Promise(resolve => {
            // delay ended; proceed with action
            const onClose = () => {
                dispatch(ReduxActions.toastRemoved(toast))
                resolve(true)
            }

            // user canceled the action
            const onUndo = () => {
                dispatch(ReduxActions.toastRemoved(toast))
                resolve(false)
            }

            const toast = { id, severity, toastLabel: message, onClose, onUndo }
            dispatch(ReduxActions.toastAdded(toast))
        })
    }

    // initialize the resources to have dispatch and getState
    // other resources will be added/modified later from outside, eg. projectId
    const initialResources = {
        dispatch,
        displayError,
        displayDelay,
        getState,
        firestore,
        runTransaction,
        transactionDelete,
        transactionSet,
        transactionUpdate,
        resourceRemoved,
        resourceChanged,
        Segment,
    }

    let resources = initialResources

    // add Command singletons to manage individual tags
    addProjectAddedCommandSingleton(runCommand, registerCommandPlayer)
    addCanvasChangedCommandSingleton(runCommand, registerCommandPlayer)
    addCanvasRemovedCommandSingleton(runCommand, registerCommandPlayer)
    addCanvasSourceChangedCommandSingleton(runCommand, registerCommandPlayer)
    addCollaborationAddedCommandSingleton(runCommand, registerCommandPlayer)
    addCollaborationChangedCommandSingleton(runCommand, registerCommandPlayer)
    addCollaborationAccessChangedCommandSingleton(runCommand, registerCommandPlayer)
    addCommentAddedCommandSingleton(runCommand, registerCommandPlayer)
    addCommentChangedCommandSingleton(runCommand, registerCommandPlayer)
    addCommentRemovedCommandSingleton(runCommand, registerCommandPlayer)
    addGeometriesAddedCommandSingleton(runCommand, registerCommandPlayer)
    addGeometriesChangedCommandSingleton(runCommand, registerCommandPlayer)
    addGeometriesRemovedCommandSingleton(runCommand, registerCommandPlayer)
    addInvitationAddedCommandSingleton(runCommand, registerCommandPlayer)
    addOrganizationAddedCommandSingleton(runCommand, registerCommandPlayer)
    addOrganizationParticipantAddedCommandSingleton(runCommand, registerCommandPlayer)
    addOrganizationParticipantChangedCommandSingleton(runCommand, registerCommandPlayer)
    addOrganizationParticipantRemovedSelfCommandSingleton(runCommand, registerCommandPlayer)
    addOrganizationParticipantRemovedCommandSingleton(runCommand, registerCommandPlayer)
    addOrganizationParticipantReInvitedCommandSingleton(runCommand, registerCommandPlayer)
    addPresenceChangedCommandSingleton(runCommand, registerCommandPlayer)
    addProjectChangedCommandSingleton(runCommand, registerCommandPlayer)
    addProjectParticipantAddedCommandSingleton(runCommand, registerCommandPlayer)
    addProjectParticipantRemovedCommandSingleton(runCommand, registerCommandPlayer)
    addProjectRemovedCommandSingleton(runCommand, registerCommandPlayer)
    addTagNameAddedCommandSingleton(runCommand, registerCommandPlayer)
    addTagNameChangedCommandSingleton(runCommand, registerCommandPlayer)
    addTagNameRemovedCommandSingleton(runCommand, registerCommandPlayer)
    addUpdateAddedCommandSingleton(runCommand, registerCommandPlayer)
    addUploadAddedCommandSingleton(runCommand, registerCommandPlayer)
    addUploadChangedCommandSingleton(runCommand, registerCommandPlayer)
    addUploadRemovedCommandSingleton(runCommand, registerCommandPlayer)
    addUserAddedCommandSingleton(runCommand, registerCommandPlayer)
    addUserChangedCommandSingleton(runCommand, registerCommandPlayer)
    addUserInvitationAddedCommandSingleton(runCommand, registerCommandPlayer)
    addUserInvitationChangedCommandSingleton(runCommand, registerCommandPlayer)
    addInvitationChangedCommandSingleton(runCommand, registerCommandPlayer)
    addInvitationRemovedCommandSingleton(runCommand, registerCommandPlayer)
    addProjectCanvasesChangedCommandSingleton(runCommand, registerCommandPlayer)
    addOrganizationChangedCommandSingleton(runCommand, registerCommandPlayer)
    addInitialDataLoadedCommandSingleton(runCommand, registerCommandPlayer)
    addEntireProjectLoadedCommandSingleton(runCommand, registerCommandPlayer)
    addDuplicateCollaborationCommandSingleton(runCommand, registerCommandPlayer)
    addRangeStatusChangedCommandSingleton(runCommand, registerCommandPlayer)
    addStatusNameAddedCommandSingleton(runCommand, registerCommandPlayer)
    addStatusNameChangedCommandSingleton(runCommand, registerCommandPlayer)
    addStatusNameRemovedCommandSingleton(runCommand, registerCommandPlayer)
    addCategoryAddedCommandSingleton(runCommand, registerCommandPlayer)
    addCategoryChangedCommandSingleton(runCommand, registerCommandPlayer)
    addCategoryRemovedCommandSingleton(runCommand, registerCommandPlayer)

    return {
        resourceChanged,
        resourceRemoved,
        userResourcesRemoved,
        getResources: () => resources, // HACK? need a way to WAIT for mapboxDraw to exist

        runCommand,
        undoCommand,
        redoCommand,
    }
}

let commandHistorySingleton = null
const CommandHistory = store => {
    if (!commandHistorySingleton) {
        commandHistorySingleton = createCommandHistorySingleton(store)
    }
    return commandHistorySingleton
}

export default CommandHistory
