import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box-without-terminal'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { draggable, dropTargetForElements, monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import * as Popover from '@radix-ui/react-popover'
import { Category } from '@range.io/basic-types'
import { assoc, categoryIcons, mergeRight } from '@range.io/functional'
import React, { useEffect, useRef, useState } from 'react'
import { useStore } from 'react-redux'
import invariant from 'tiny-invariant'
import { v4 } from 'uuid'
import IconPicker from '../components-reusable/icon-picker/IconPicker.jsx'
import {
    Button,
    Flex,
    FlexColumn,
    FlexRow,
    Icon,
    IconButtonWithTooltip,
    Text,
    TextInput,
    Tooltip,
} from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import { CategoryIcon } from './CategorySelector.js'

const DragAreaIcon = () => (
    <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2.5" r="2" fill="#424D66" />
        <circle cx="2" cy="9.5" r="2" fill="#424D66" />
        <circle cx="2" cy="16.5" r="2" fill="#424D66" />
        <circle cx="9" cy="2.5" r="2" fill="#424D66" />
        <circle cx="9" cy="9.5" r="2" fill="#424D66" />
        <circle cx="9" cy="16.5" r="2" fill="#424D66" />
    </svg>
)

const StyledLabel = styled(Text, {
    fs: 12,
    fw: 500,
    color: '$neutral05',
})

const CategoryDraggingSection = styled(FlexColumn, {
    padding: 8,
    gap: 8,
    backgroundColor: '$neutral09',
    br: '6px 6px 0 0',
    borderBottom: '1px solid $neutral07',
})

const InputArea = styled('div', {
    border: '1px solid $neutral07',
    br: 6,
    gap: 8,
    backgroundColor: '$neutral10',
})

const CategoryPickerButton = ({ category, onIconChange }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedIcon, _setSelectedIcon] = useState()

    const setSelectedIcon = iconName => {
        onIconChange(iconName)
        _setSelectedIcon(iconName)
        setIsOpen(false)
    }

    return (
        <Popover.Root onOpenChange={setIsOpen} open={isOpen}>
            <Popover.Trigger asChild>
                <Button variant="category" onClick={() => setIsOpen(true)}>
                    <CategoryIcon category={category} color="$neutral04" size={24} />
                </Button>
            </Popover.Trigger>
            {isOpen && (
                <Popover.Content side="bottom" align="start" sideOffset={8} style={{ zIndex: 999, height: 'auto' }}>
                    <IconPicker selectedIcon={selectedIcon} onSelectedIconChange={setSelectedIcon} />
                </Popover.Content>
            )}
        </Popover.Root>
    )
}

const SortableCategory = ({ category, index, onDelete, onNameChange, onIconChange, instanceId }) => {
    const ref = useRef(null)
    const [dragging, setDragging] = useState(false)
    const isFixed = category.isDefault

    const [state, setState] = useState({ type: 'idle' })

    useEffect(() => {
        const el = ref.current
        if (isFixed) return

        invariant(el)
        return combine(
            draggable({
                element: el,
                onDragStart: () => setDragging(true),
                onDrop: () => setDragging(false),
                getInitialData() {
                    return { type: 'item-row', category, index, instanceId }
                },
            }),

            dropTargetForElements({
                element: el,
                canDrop({ source }) {
                    return (
                        source.data.instanceId === instanceId &&
                        source.data.type === 'item-row' &&
                        source.data.item !== category
                    )
                },
                getData({ input, element }) {
                    const data = { category, index }
                    return attachClosestEdge(data, {
                        input,
                        element,
                        allowedEdges: ['top', 'bottom'],
                    })
                },
                onDragEnter(args) {
                    setState({
                        type: 'is-over',
                        closestEdge: extractClosestEdge(args.self.data),
                    })
                },
                onDrag(args) {
                    const closestEdge = extractClosestEdge(args.self.data)

                    // only update react state if the `closestEdge` changes
                    setState(current => {
                        if (current.type !== 'is-over') {
                            return current
                        }
                        if (current.closestEdge === closestEdge) {
                            return current
                        }
                        return {
                            type: 'is-over',
                            closestEdge,
                        }
                    })
                },
                onDragLeave() {
                    setState({ type: 'idle' })
                },
                onDrop() {
                    setState({ type: 'idle' })
                },
            })
        )
    }, [])

    const css = isFixed
        ? {}
        : {
              br: 6,
              p: '8px 12px 8px 8px',
              border: '1px solid $neutral07',
              backgroundColor: '$neutral09',
          }

    return (
        <FlexColumn>
            <FlexRow
                css={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    br: 6,
                    gap: 10,
                    opacity: dragging ? '0.6' : '1',
                    position: 'relative',
                    cursor: 'pointer',
                    ...css,
                }}
                ref={ref}
            >
                {!isFixed && (
                    <Flex css={{ alignItems: 'center' }}>
                        <DragAreaIcon />
                    </Flex>
                )}
                <CategoryPickerButton category={category} onIconChange={icon => onIconChange(category, icon)} />
                <InputArea
                    style={{
                        flex: '1 0 auto',
                        height: '39px',
                        boxSizing: 'border-box',
                        display: 'flex',
                        alignItems: 'center',
                        paddingRight: 8,
                    }}
                >
                    <TextInput
                        value={category.name}
                        placeholder="Category name"
                        onChange={value => onNameChange(category, value)}
                        variant="simple"
                        noClear
                    />
                </InputArea>

                {!isFixed && (
                    <IconButtonWithTooltip
                        variant="iconOnly"
                        onClick={() => onDelete(category)}
                        iconName="trash"
                        tooltipText="Delete category"
                        side="right"
                        size="16px"
                        css={{ padding: '4px 0' }}
                    />
                )}
                {state.type === 'is-over' && state.closestEdge ? <DropIndicator edge={state.closestEdge} /> : null}
            </FlexRow>
        </FlexColumn>
    )
}

function extractIndex(data) {
    const { index } = data
    if (typeof index !== 'number') {
        return null
    }
    return index
}

const CategoryEditorView = ({ categories, onAddNew, onDelete, onNameChange, onIconChange, onReorder }) => {
    const [instanceId] = useState(() => Symbol('instance-id'))

    useEffect(() => {
        return monitorForElements({
            onDrop({ location, source }) {
                const destination = location.current.dropTargets[0]
                if (!destination) {
                    return
                }

                const startIndex = extractIndex(source.data)
                const indexOfTarget = extractIndex(destination.data)
                if (startIndex === null || indexOfTarget === null) {
                    return
                }
                onReorder(startIndex, indexOfTarget)
            },
        })
    }, [instanceId, categories]) // categories are needed here, because it affects the reorder logic in onReorder function

    return (
        <FlexColumn
            css={{
                border: '1px solid $neutral07',
                br: 6,
            }}
        >
            <CategoryDraggingSection>
                <FlexRow
                    style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <StyledLabel>Default category</StyledLabel>
                    <Tooltip
                        tooltipText="A new task pin will have the default category as its category until you change it."
                        side="bottom"
                        align="center"
                    >
                        <div style={{ display: 'flex' }}>
                            <Icon name="helpCenter" css={{ size: '20px', color: '$neutral05' }} />
                        </div>
                    </Tooltip>
                </FlexRow>
                {categories.slice(0, 1).map(category => (
                    <SortableCategory
                        key={category.id}
                        category={category}
                        onNameChange={onNameChange}
                        onDelete={onDelete}
                        onIconChange={onIconChange}
                        instanceId={instanceId}
                    />
                ))}
            </CategoryDraggingSection>
            <FlexColumn style={{ gap: 8, padding: 8 }}>
                {categories.slice(1).map((category, index) => (
                    <SortableCategory
                        key={index + 1}
                        index={index + 1}
                        category={category}
                        onNameChange={onNameChange}
                        onDelete={onDelete}
                        onIconChange={onIconChange}
                        instanceId={instanceId}
                    />
                ))}
                <Button variant="secondary" shortPadding onClick={onAddNew} size="md">
                    <Icon name="addCircled" iconSize="14" />
                    Add Category
                </Button>
            </FlexColumn>
        </FlexColumn>
    )
}

const folderIcon =
    'data:image/svg+xml;base64,CiAgICAgICAgPHN2ZyB3aWR0aD0nNDAnIGhlaWdodD0nNDAnIHZpZXdCb3g9JzAgMCA0MCA0MCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz4KICAgICAgICAgICAgPHBhdGggZD0nTTMxIDI3LjE0MjlDMzEgMjguNzE4OCAyOS43NjY4IDMwIDI4LjI1IDMwSDExLjc1QzEwLjIzMzIgMzAgOSAyOC43MTg4IDkgMjcuMTQyOVYxMi44NTcxQzkgMTEuMjgxMyAxMC4yMzMyIDEwIDExLjc1IDEwSDE3LjI1QzE4LjExMzcgMTAgMTguOTMwMSAxMC40MjQxIDE5LjQ1IDExLjE0MjlMMjAuMjc1IDEyLjI4NTdDMjAuNTMyOCAxMi42NDczIDIwLjk0MSAxMi44NTcxIDIxLjM3NSAxMi44NTcxSDI4LjI1QzI5Ljc2NjggMTIuODU3MSAzMSAxNC4xMzg0IDMxIDE1LjcxNDNWMjcuMTQyOVpNMTguOTY4OCAyNS4zNTcxQzE4Ljk2ODggMjUuOTUwOSAxOS40Mjg1IDI2LjQyODYgMjAgMjYuNDI4NkMyMC41NzE1IDI2LjQyODYgMjEuMDMxMiAyNS45NTA5IDIxLjAzMTIgMjUuMzU3MVYyMi41SDIzLjc4MTJDMjQuMzUyNyAyMi41IDI0LjgxMjUgMjIuMDIyMyAyNC44MTI1IDIxLjQyODZDMjQuODEyNSAyMC44MzQ4IDI0LjM1MjcgMjAuMzU3MSAyMy43ODEyIDIwLjM1NzFIMjEuMDMxMlYxNy41QzIxLjAzMTIgMTYuOTA2MyAyMC41NzE1IDE2LjQyODYgMjAgMTYuNDI4NkMxOS40Mjg1IDE2LjQyODYgMTguOTY4OCAxNi45MDYzIDE4Ljk2ODggMTcuNVYyMC4zNTcxSDE2LjIxODhDMTUuNjQ3MyAyMC4zNTcxIDE1LjE4NzUgMjAuODM0OCAxNS4xODc1IDIxLjQyODZDMTUuMTg3NSAyMi4wMjIzIDE1LjY0NzMgMjIuNSAxNi4yMTg4IDIyLjVIMTguOTY4OFYyNS4zNTcxWicgZmlsbD0nY3VycmVudENvbG9yJy8+CiAgICAgICAgPC9zdmc+CiAgICA='
const CategoryEditor = ({ defaultCategories = [], onCategoriesChange }) => {
    const [categories, setCategories] = useState(defaultCategories)
    const { dispatch, getState } = useStore()

    const NEW_CATEGORY_BLUEPRINT = {
        id: v4(),
        isDefault: false,
        name: '',
        icon: folderIcon,
        order: 1, // this will be overridden
    }

    useEffect(() => {
        onCategoriesChange(categories) // report category change
    }, [categories])

    // find the existing Category with id, and replace it with a new one after applying the changes
    const _onCategoryChange = (id, changes) => {
        const category = Category.from(mergeRight(categories.get(id), changes))
        setCategories(categories.addItemWithId(category)) // will replace category, not add
    }

    const onNameChange = (category, name) => _onCategoryChange(category.id, { name })

    const onIconChange = (category, iconName) => {
        // iconName is a key in categoryIcons, because of the way IconPicker works, but the categoryIcon will be the
        // TEXT of an SVG, so we need to convert it into a base64-encoded datauri for the category's icon
        const svgText = categoryIcons[iconName]
        const base64Encoded = btoa(svgText)
        const icon = `data:image/svg+xml;base64,${base64Encoded}`

        _onCategoryChange(category.id, { icon })
    }

    const _onDelete = category => setCategories(categories.removeItemWithId(category.id))

    const onDelete = category => {
        const allCollaborations = ReduxSelectors.collaborationsAsArray(getState())
        const hasCollaborationsWithThisCategory = allCollaborations.some(c => c.categoryId === category.id)
        if (hasCollaborationsWithThisCategory)
            dispatch(
                ReduxActions.globalModalDataSet({
                    type: 'destructive',
                    title: 'Are you sure you want to delete this category?',
                    description:
                        'You currently have pins using this category. Deleting this category will change all affected pins to the default category.',
                    cancelButton: {
                        label: 'Cancel',
                    },
                    submitButton: {
                        label: 'Delete',
                        onClick: () => _onDelete(category),
                    },
                })
            )
        else _onDelete(category)
    }

    // When changing the order, we also need to change the order field of each item
    const onReorder = (fromIndex, toIndex) => {
        const newCategories = categories.moveElement(fromIndex, toIndex)
        newCategories.forEach((category, i) => Category.from(assoc('order', i, category))) // set new 'order' field
        setCategories(newCategories)
    }

    // We need to add a new item just before the last item
    const onAddNew = () => {
        // create a new Category with its 'order' set to the end of the array
        const newCategory = Category.from(assoc('order', categories.length, NEW_CATEGORY_BLUEPRINT))
        setCategories(categories.addItemWithId(newCategory))
    }

    return (
        <CategoryEditorView
            categories={categories}
            onAddNew={onAddNew}
            onNameChange={onNameChange}
            onIconChange={onIconChange}
            onDelete={onDelete}
            onReorder={onReorder}
        />
    )
}

export default CategoryEditor
