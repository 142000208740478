import {
    Canvas,
    CanvasSource,
    Category,
    Collaboration,
    Comment,
    Feature,
    Geometry,
    Invitation,
    Organization,
    Presence,
    Project,
    StatusName,
    TagName,
    Update,
    Upload,
} from '@range.io/basic-types'
import { assoc, filterObject, mapObject, memoizeReduxState, mergeRight } from '@range.io/functional'
import { generateCollaborationShapes } from './generate-shapes.js'
import memoizeResult from './memoize-result.js'
import CanvasSelectors from './persisted-state/canvas-slice/canvas-selectors.js'
import CanvasSourceSelectors from './persisted-state/canvas-source-slice/canvas-source-selectors.js'
import CategorySelectors from './persisted-state/category-slice/category-selectors.js'
import CollaborationSelectors from './persisted-state/collaboration-slice/collaboration-selectors.js'
import CommentSelectors from './persisted-state/comment-slice/comment-selectors.js'
import GeometrySelectors from './persisted-state/geometry-slice/geometry-selectors.js'
import OrganizationSelectors from './persisted-state/organization-slice/organization-selectors.js'
import ParticipantSelectors from './persisted-state/participant-slice/participant-selectors.js'
import PresenceSelectors from './persisted-state/presence-slice/presence-selectors.js'
import ProjectSelectors from './persisted-state/project-slice/project-selectors.js'
import StatusNameSelectors from './persisted-state/status-name-slice/status-name-selectors.js'
import TagNameSelectors from './persisted-state/tag-name-slice/tag-name-selectors.js'
import UpdateSelectors from './persisted-state/update-slice/update-selectors.js'
import UploadSelectors from './persisted-state/upload-slice/upload-selectors.js'
import { LoggedInStatus } from './redux-actions.js'
import { filteredCollaborations } from './redux-filter-canvas-view.js'
import { filteredMediaView } from './redux-filter-media-view.js'
import { filteredTaskList } from './redux-filter-tasklist-view.js'
import { isCreateAllowed, isDeleteAllowed, isUpdateAllowed, UserRoles } from './redux-selectors-permissions.js'
import UiStateSelectors from './ui-state/ui-state-selectors.js'

const empty = {}

// ---------------------------------------------------------------------------------------------------------------------
// Helpers
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Given an array of TagName ids, return the associated text for each tag
 * @sig namesFromTagNameIds :: (State, [Id]) -> [String]
 */
const namesFromTagNameIds = (state, tagNameIds) => {
    const tagNames = state.tagNames
    return tagNameIds.map(id => tagNames[id].name)
}

// prettier-ignore
const itemWithId = (state, Type, id) => {
    if (Type === Canvas             ) return  state.canvases            [id]
    if (Type === CanvasSource       ) return  state.canvasSources       [id]
    if (Type === CanvasSource.Pdf   ) return  state.canvasSources       [id]
    if (Type === Collaboration      ) return  state.collaborations      [id]
    if (Type === Comment            ) return  state.comments            [id]
    if (Type === Feature            ) return  state.features            [id]
    if (Type === Geometry           ) return  state.geometries          [id]
    if (Type === Invitation         ) return  state.invitations         [id]
    if (Type === Organization       ) return  state.organizations       [id]
    if (Type === Presence           ) return  state.presences           [id]
    if (Type === Project            ) return  state.projects            [id]
    if (Type === StatusName         ) return  state.statusNames         [id]
    if (Type === TagName            ) return  state.tagNames            [id]
    if (Type === Update             ) return  state.updates             [id]
    if (Type === Upload             ) return  state.uploads             [id]
   
    // data is in a LookupTable, not a vanilla object
    if (Type === Category           ) return  state.categories.get(id)
    
    throw new Error("Don't understand Type " + Type)
}

// ---------------------------------------------------------------------------------------------------------------------
// Collaboration Selectors
// ---------------------------------------------------------------------------------------------------------------------

/*
 * Is the currently-selected Collaboration marked isCompleted
 * @sig isSelectedCollaborationCompleted :: State -> Boolean
 */
const isSelectedCollaborationCompleted = state => {
    const collaboration = state.collaborations[state.selectedCollaborationId]
    return collaboration.statusName === Collaboration.completedCollaborationStatus
}

/*
 * Return the Canvas and its first CanvasSource
 * @sig canvasAndFirstSourceForId :: (State, Id) -> { canvas: Canvas, canvasSource: CanvasSource }
 */
const __canvasAndFirstSourceForId = (state, canvasId) => {
    const canvas = state.canvases[canvasId]
    return { canvas, canvasSource: CanvasSourceSelectors.canvasSourcesForCanvas(state, canvas)[0] }
}

// non-partial (and memoized) version of canvasAndFirstSourceForId
const _canvasAndFirstSourceForId = memoizeReduxState(
    ['canvasId', 'canvases', 'canvasSources'],
    __canvasAndFirstSourceForId
)

// partial version of canvasAndFirstSourceForId calls non-partial version
const canvasAndFirstSourceForId = canvasId => state => _canvasAndFirstSourceForId(state, canvasId)

/*
 * Return all the Canvases and related CanvasSources for PDF's
 * @ sig pdfCanvasesAndTheirSources :: State -> [[Canvas], [CanvasSource.Pdf]saf
 */
const pdfCanvasesAndTheirSources = state => [
    CanvasSelectors.pdfCanvases(state),
    CanvasSourceSelectors.pdfCanvasSources(state),
]

/*
 * Return a lookup table where for each Collaboration a single value is assigned - its most recent update date.
 * It takes into account collaboration creation date, all updates to that collaboration (status, due date, assignee)
 * and creation dates for related entities - comments and uploads
 * @sig recentCollaborationUpdateLookup :: State -> {Id: Date}
 */
const recentCollaborationUpdateLookup = state => {
    const updatesLookup = empty
    Object.values(state.collaborations).forEach(c => (updatesLookup[c.id] = [c.createdAt]))

    // we are only interested in entities related to an existing collaboration
    const filterApplicable = e => e.parentType === 'Collaboration' && !!updatesLookup[e.parentId]
    const updatesFiltered = Object.values(state.updates).filter(filterApplicable)
    const uploadsFiltered = Object.values(state.uploads).filter(filterApplicable)
    const commentsFiltered = Object.values(state.comments).filter(filterApplicable)

    const entities = [...updatesFiltered, ...uploadsFiltered, ...commentsFiltered]
    entities.forEach(e => updatesLookup[e.parentId].push(e.createdAt))

    return mapObject(dates => dates.sort((a, b) => b - a)[0], updatesLookup)
}

/*
 * Return the organizationRole for the selected user within the selected organization
 * @sig selectedUsersRoleInSelectedOrganization :: state -> 'Admin'|'Collaborator'|'Guest'|undefined
 *
 */
const selectedUsersRoleInSelectedOrganization = state => {
    if (!state.selectedUserId || !state.selectedOrganizationId) return undefined
    const participants = UiStateSelectors.selectedOrganization(state)?.participants
    if (!participants) return undefined

    const participant = participants[state.selectedUserId]
    return participant.organizationRole
}

/*
 * Check if the org is on a "free" plan
 * isFreePlanOrganization :: (State, uuid) -> Boolean
 */
const isFreePlanOrganization = (state, organizationId) => {
    const organizations = ReduxSelectors.organizationLookupTable(state)
    const organization = organizations[organizationId]
    return organization.plan === 'Free'
}

/*
 * Return the expected for Segment for ANY track event
 */
const paramsForTrackEvent = state => {
    const userId = state.selectedUserId
    const groupId = state.selectedOrganizationId
    const organizationRole = selectedUsersRoleInSelectedOrganization(state)
    const params = { groupId, userId, platform: 'web', organizationRole }

    return groupId && userId && organizationRole && params
}

/*
 * Return the complicated data expected for Segment when reporting on a Collaboration
 */
const paramsForCollaborationTrackEvent = state => {
    const collaboration = UiStateSelectors.selectedCollaboration(state)
    const canvasSource = state.canvasSources[state.selectedCanvasSource]
    const collaborationType = Collaboration.isTask(collaboration) ? 'task' : 'photo'
    const collaborationTitle = collaboration.name || ''
    const canvasType = canvasSource.type
    const paramsForAnyTrackEvent = paramsForTrackEvent(state)

    const valid = paramsForAnyTrackEvent && collaborationType && canvasType
    return valid && mergeRight(paramsForAnyTrackEvent, { collaborationType, canvasType, collaborationTitle })
}

/*
 * Return the data expected for Segment when reporting on a new Project
 */
const paramsForProjectTrackEvent = (state, { name, organizationId, projectType }) => {
    const organization = state.organizations[organizationId]
    const organizationName = organization.name

    const paramsForAnyTrackEvent = paramsForTrackEvent(state)
    return mergeRight(paramsForAnyTrackEvent, { name, projectType, organizationName })
}

/*
 * Return mapPositions, first Canvas and first CanvasSource for the project
 * @sig initialDataForSelectedProject :: State -> { {Id: MapPosition}, Canvas, CanvasSource }
 *  MapPosition = { center: { lng: Number, lat: Number }, zoom: Number }
 */
const initialDataForSelectedProject = state => {
    // pull the center from either the first CanvasSource for the canvas, or (for a PDF) from the project's center
    const mapPositionForCanvasId = (acc, canvasId) => {
        const canvas = state.canvases[canvasId]
        const canvasSource = CanvasSourceSelectors.canvasSourcesForCanvas(state, canvas)[0]
        const center = canvasSource.center ?? project.center // [lng, lat]

        const mapPosition = { center: { lng: center[0], lat: center[1] }, zoom: canvasSource.zoom ?? 19.8 }
        return assoc(canvasId, mapPosition, acc)
    }

    const project = state.projects[state.selectedProjectId]
    const mapPositions = project.canvasOrder.reduce(mapPositionForCanvasId, {})
    const firstCanvasId = project.canvasOrder[0]
    const firstCanvas = state.canvases[firstCanvasId]
    const firstCanvasSource = Object.values(filterObject(cs => cs.canvasId === firstCanvasId, state.canvasSources))[0]
    return { mapPositions, firstCanvas, firstCanvasSource }
}

// return the storageVersion of the Collaboration or Upload with the given entityId
const storageVersionForEntity = entityId => state => {
    const entity = state.collaborations[entityId] || state.uploads[entityId]
    return entity?.storageVersion
}

const invitationsForSelectedOrganization = state =>
    Object.values(state.invitations).filter(i => i.organizationId === state.selectedOrganizationId)

const organizationParticipantsAsArray = state => {
    const participants = Object.values(state?.organizations?.[state?.selectedOrganizationId]?.participants || {})
    const organizationRole = selectedUsersRoleInSelectedOrganization(state)

    // if user is admin he should have access to all organization users
    if (organizationRole === UserRoles.ADMIN || !participants.length) return participants

    const orgProjects = ProjectSelectors.organizationProjectAsArray(state)

    // if user is not an admin he should have access to see:
    // - the admins
    // - themselves
    // - only organization users that are assigned (or were once assigned) to the same projects
    return participants.filter(
        participant =>
            participant.organizationRole === UserRoles.ADMIN ||
            participant.id === state.selectedUserId ||
            orgProjects.some(project => Object.keys(project.participants).includes(participant.id))
    )
}

const isProjectDataLoaded = state => Object.keys(state.canvases).length > 0

const isRangeClosed = state => !!state.rangeStatus?.isClosed

const ReduxSelectors = {
    // canvases
    canvasForCollaboration: CanvasSelectors.canvasForCollaboration,
    canvasLookupTable: CanvasSelectors.canvasLookupTable,
    canvasesAsArray: memoizeResult(CanvasSelectors.canvasesAsArray),
    canvasesWithGeometries: CanvasSelectors.canvasesWithGeometries,
    pdfCanvases: CanvasSelectors.pdfCanvases,

    // canvasSources
    canvasSources: CanvasSourceSelectors.canvasSourceLookupTable,
    canvasSourcesForSelectedCanvas: memoizeResult(CanvasSourceSelectors.canvasSourcesForSelectedCanvas),
    pdfCanvasSource: CanvasSourceSelectors.pdfCanvasSource,
    pdfCanvasSourceForSelectedCanvas: CanvasSourceSelectors.pdfCanvasSourceForSelectedCanvas,
    pdfCanvasSources: CanvasSourceSelectors.pdfCanvasSources,

    // categories
    categories: CategorySelectors.categories,
    categoryForCollaboration: CategorySelectors.categoryForCollaboration,

    // collaborations
    collaborationForUpload: CollaborationSelectors.collaborationForUpload,
    collaborationsAsObject: CollaborationSelectors.collaborationsAsObject,
    collaborationsAsLookupTable: CollaborationSelectors.collaborationsAsLookupTable,
    collaborationShapeForSelectedCollaboration: memoizeResult(
        CollaborationSelectors.collaborationShapeForSelectedCollaboration
    ),
    collaborationWithId: CollaborationSelectors.collaborationWithId,
    collaborationsAsArray: CollaborationSelectors.collaborationsAsArray,
    firstCollaborationForGeometry: CollaborationSelectors.firstCollaborationForGeometry,
    firstCollaborationForSelectedFeature: CollaborationSelectors.firstCollaborationForSelectedFeature,
    recentCollaborationUpdateLookup: memoizeResult(recentCollaborationUpdateLookup),

    // comments
    commentLookupTable: CommentSelectors.commentLookupTable,
    commentShapesForUpload: CommentSelectors.commentShapesForUpload,
    commentsForCollaboration: CommentSelectors.commentsForCollaboration,
    commentsForSelectedCollaboration: CommentSelectors.commentsForSelectedCollaboration,

    // features

    // geometries
    geometries: GeometrySelectors.geometriesAsArray,
    geometriesForSelectedCanvas: GeometrySelectors.geometriesForSelectedCanvas,
    geometryForCollaboration: GeometrySelectors.geometryForCollaboration,

    // participants
    organizationParticipantsAsArray: memoizeResult(organizationParticipantsAsArray),

    organizationParticipantWithId: ParticipantSelectors.organizationParticipantWithId,
    presentProjectParticipants: memoizeResult(ParticipantSelectors.presentProjectParticipants),
    selectedProjectParticipantShapeWithId: ParticipantSelectors.selectedProjectParticipantShapeWithId,
    selectedProjectParticipantShapes: memoizeResult(ParticipantSelectors.selectedProjectParticipantShapes),
    selectedProjectParticipantShapesAsArray: memoizeResult(
        ParticipantSelectors.selectedProjectParticipantShapesAsArray
    ),
    selectedProjectParticipantWithId: ParticipantSelectors.selectedProjectParticipantWithId,
    selectedProjectParticipants: ParticipantSelectors.selectedProjectParticipants,

    // presences
    presences: memoizeResult(PresenceSelectors.presences),

    // organizations
    organizationLookupTable: OrganizationSelectors.organizationLookupTable,
    organizationsAsArray: OrganizationSelectors.organizationsAsArray,

    // projects
    currentProjectsForParticipant: ProjectSelectors.currentProjectsForParticipant,
    organizationProjectAsArray: memoizeResult(ProjectSelectors.organizationProjectAsArray),
    projectLookupTable: ProjectSelectors.projectLookupTable,
    projectsAsArray: ProjectSelectors.projectsAsArray,
    userProjectsAsArray: ProjectSelectors.userProjectsAsArray,
    userProjectsAsLookupTable: ProjectSelectors.userProjectsAsLookupTable,
    userProjectsForSelectedOrganizationAsArray: memoizeResult(
        ProjectSelectors.userProjectsForSelectedOrganizationAsArray
    ),

    // statusNames
    statusNameForCollaboration: StatusNameSelectors.statusNameForCollaboration,
    statusNameForProjectId: StatusNameSelectors.statusNameForProjectId,
    statusNames: StatusNameSelectors.statusNameLookupTable,
    statusNamesAsArray: StatusNameSelectors.statusNamesAsArray,
    statusOfSelectedCollaboration: StatusNameSelectors.statusOfSelectedCollaboration,

    // tagNames
    enrichedTagNames: memoizeResult(TagNameSelectors.enrichedTagNames),
    tagNameForName: TagNameSelectors.tagNameForName,
    tagNameLookupTable: TagNameSelectors.tagNameLookupTable,

    // updates
    updatesForCollaboration: UpdateSelectors.updatesForCollaboration,
    updatesForSelectedCollaboration: UpdateSelectors.updatesForSelectedCollaboration,
    updatesLookupTable: UpdateSelectors.updatesLookupTable,

    // uploads
    correlatedUploads: UploadSelectors.correlatedUploads,
    uploadLookupTable: UploadSelectors.uploadLookupTable,
    uploadsAsArray: UploadSelectors.uploadsAsArray,
    uploadsForCollaboration: UploadSelectors.uploadsForCollaboration,

    // UiStateSelectors
    allProjectMetrics: UiStateSelectors.allProjectMetrics,
    backgroundMapLoadingState: UiStateSelectors.backgroundMapLoadingState,
    currentlyLoadingProject: UiStateSelectors.currentlyLoadingProject,
    cursor: UiStateSelectors.cursor,
    drawingMode: UiStateSelectors.drawingMode,
    globalModalData: UiStateSelectors.globalModalData,
    isLoadingInitialData: UiStateSelectors.isLoadingInitialData,
    knockUserToken: UiStateSelectors.knockUserToken,
    mapPosition: UiStateSelectors.mapPosition,
    mapPositionsHistory: UiStateSelectors.mapPositionsHistory,
    mediaViewFilterSettings: UiStateSelectors.mediaViewFilterSettings,
    navigatorFilterSettings: UiStateSelectors.navigatorFilterSettings,
    navigatorSortSettings: UiStateSelectors.navigatorSortSettings,
    notificationPreferences: UiStateSelectors.notificationPreferences,
    pdfLoadingState: UiStateSelectors.pdfLoadingState,
    selectedCanvas: UiStateSelectors.selectedCanvas,
    selectedCanvasId: UiStateSelectors.selectedCanvasId,
    selectedCanvasSource: UiStateSelectors.selectedCanvasSource,
    selectedCollaboration: UiStateSelectors.selectedCollaboration,
    selectedCollaborationId: UiStateSelectors.selectedCollaborationId,
    selectedFeature: UiStateSelectors.selectedFeature,
    selectedGeometries: UiStateSelectors.selectedGeometries,
    selectedGeometryIds: UiStateSelectors.selectedGeometryIds,
    selectedMediaUploadsIds: UiStateSelectors.selectedMediaUploadsIds,
    selectedOrganization: UiStateSelectors.selectedOrganization,
    selectedOrganizationId: UiStateSelectors.selectedOrganizationId,
    selectedProject: UiStateSelectors.selectedProject,
    selectedProjectId: UiStateSelectors.selectedProjectId,
    selectedTool: UiStateSelectors.selectedTool,
    selectedUser: UiStateSelectors.selectedUser,
    selectedUserId: UiStateSelectors.selectedUserId,
    shouldFocusCollabWindow: UiStateSelectors.shouldFocusCollabWindow,
    showAccountCreatedToast: UiStateSelectors.showAccountCreatedToast,
    showCanvasSnippetMode: UiStateSelectors.showCanvasSnippetMode,
    showTimelineDetails: UiStateSelectors.showTimelineDetails,
    taskListFilterSettings: UiStateSelectors.taskListFilterSettings,
    toastLookupTable: UiStateSelectors.toastLookupTable,

    // OTHER ------------------------------------------------

    invitations: memoizeResult(invitationsForSelectedOrganization),
    canvasAndFirstSourceForId,
    pdfCanvasesAndTheirSources,
    isSelectedCollaborationCompleted,

    selectedUsersRoleInSelectedOrganization,
    isFreePlanOrganization,

    initialDataForSelectedProject,
    isProjectDataLoaded,

    textsFromTagNameIds: namesFromTagNameIds,

    // filters
    filteredCollaborations,
    filteredTaskList,
    filteredMediaView,

    // permissions
    isCreateAllowed,
    isDeleteAllowed,
    isUpdateAllowed,

    // segment
    paramsForCollaborationTrackEvent,
    paramsForProjectTrackEvent,
    paramsForTrackEvent,

    // loggedInStatus
    isAwaitingEmailVerification: state => state.loggedInStatus === LoggedInStatus.awaitingEmailVerification,
    isLoggedIn: state => state.loggedInStatus === LoggedInStatus.loggedIn,
    isLoggedOut: state => state.loggedInStatus === LoggedInStatus.loggedOut,
    isLoginStatusKnown: state => state.loggedInStatus !== LoggedInStatus.unknown,
    loggedInStatus: state => state.loggedInStatus,

    // generic
    itemWithId,

    allShapes: state => generateCollaborationShapes(state),

    // miscellaneous
    storageVersionForEntity,
    isRangeClosed,
}

export default ReduxSelectors
