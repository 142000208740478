import { Category } from '@range.io/basic-types'
import { assoc, LookupTable } from '@range.io/functional'

const byOrder = (a, b) => a.order - b.order

/*
 * Also used for updates
 * @sig categoryAdded = (state, { payload: Category }) -> State
 */
const categoryAdded = (state, { payload: category }) =>
    state.categories.hasItemEqualTo(category)
        ? state
        : assoc('categories', state.categories.addItemWithId(category, byOrder), state)

/*
 * @sig categoryRemoved = (state, { payload: Category }) -> State
 */
const categoryRemoved = (state, { payload: category }) =>
    assoc('categories', state.categories.removeItemWithId(category.id, byOrder), state)

/*
 * Replace ALL the categories
 * @sig categoriesChanged = (State, { payload: [Category] }) -> State
 */
const categoriesChanged = (state, { payload: categories }) => {
    categories = [...categories].sort(byOrder)
    return assoc('categories', LookupTable(categories, Category), state)
}

export { categoryAdded, categoryRemoved, categoriesChanged }
