import { Category } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux'
import CommandPlayer, { simpleInboundRemovedRunCommand } from './command-player'

const CategoryRemovedCommand = taggedSum('CategoryRemovedCommand', {
    Inbound: {
        item: 'Category',
    },
})

const runInboundCommand = (resources, command) =>
    simpleInboundRemovedRunCommand(resources, command, Category, ReduxActions.categoryRemoved)

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        CategoryRemovedCommand,
        CommandPlayer({
            CommandType: CategoryRemovedCommand,
            Type: Category,
            collectionPath: projectId => `/projects/${projectId}/categories/`,
            runInboundCommand,
            runOutboundCommand: () => {},
            addCommandToHistory,
            changeType: 'removed',
        })
    )
}

export { addCommand, CategoryRemovedCommand }
