import StringTypes from '@range.io/basic-types/src/string-types.js'
import { tagged } from '@range.io/functional'
import PropTypes from 'prop-types'
import { CommentShapePropTypes } from './comment-shape.js'
import { ParticipantShapePropTypes } from './participant-shape.js'
import { StatusShapePropTypes } from './status-shape.js'
import { TagShapePropTypes } from './tag-shape.js'
import { UpdateShapePropTypes } from './update-shape.js'
import { UploadShapePropTypes } from './upload-shape.js'

// prettier-ignore
const CollaborationShape = tagged('CollaborationShape', {
    id             : StringTypes.Id,
    parentId       : StringTypes.OptionalId, // "optional" allows Collaboration arriving before its parents
    geometryId     : StringTypes.OptionalId, // "optional" allows Collaboration arriving before its parents
    canvasId       : StringTypes.OptionalId, // "optional" allows Collaboration arriving before its parents
    identifier     : 'String?',
    categoryId     : StringTypes.OptionalId,
    
    createdBy      : 'ParticipantShape',
    comments       : '[CommentShape]',
    createdAt      : 'Object', // Date
    followers      : '[String]',
    hasNotesPending: 'Boolean',
    isArchived     : 'Boolean',
    isTask         : 'Boolean',
    lastUpdateDate : 'Object',
    name           : 'String',
    tags           : '[TagShape]',
    updates        : '[UpdateShape]',
    uploads        : '[UploadShape]',
    
    // task only
    assignee       : 'ParticipantShape?',
    description    : 'String?',
    dueDate        : 'Object?',
    isCompleted    : 'Boolean?',
    status         : 'StatusShape?',
})

const arrayOfPropTypes = type => PropTypes.arrayOf(PropTypes.shape(type))

// prettier-ignore
const CollaborationShapePropTypes = {
    id             : PropTypes.string.isRequired,
    parentId       : PropTypes.string, // "optional" allows Collaboration arriving before its parents
    geometryId     : PropTypes.string, // "optional" allows Collaboration arriving before its parents
    canvasId       : PropTypes.string, // "optional" allows Collaboration arriving before its parents
    identifier     : PropTypes.string, // "optional"
    categoryId     : PropTypes.string,
    
    createdBy      : PropTypes.shape(ParticipantShapePropTypes).isRequired,
    comments       : arrayOfPropTypes(CommentShapePropTypes).isRequired,
    createdAt      : PropTypes.object.isRequired,
    followers      : PropTypes.arrayOf(PropTypes.string.isRequired),
    hasNotesPending: PropTypes.bool.isRequired,
    isArchived     : PropTypes.bool.isRequired,
    isTask         : PropTypes.bool.isRequired,
    lastUpdateDate : PropTypes.object.isRequired,
    name           : PropTypes.string.isRequired,
    tags           : arrayOfPropTypes(TagShapePropTypes).isRequired,
    updates        : arrayOfPropTypes(UpdateShapePropTypes).isRequired,
    uploads        : arrayOfPropTypes(UploadShapePropTypes).isRequired,
    
    // task only
    assignee       : PropTypes.shape(ParticipantShapePropTypes),
    description    : PropTypes.string,
    dueDate        : PropTypes.object,
    isCompleted    : PropTypes.bool,
    status         : PropTypes.shape(StatusShapePropTypes),
}

CollaborationShape.fromCollaboration = shapeLookupTable => collaboration => {
    const { canvases, features, geometries, statuses, tags, projectParticipants, comments, updates, uploads } =
        shapeLookupTable
    const { createdAt, featureId, createdBy, assignee, tags: tagNames, dueDate, identifier, followers } = collaboration
    const statusShape = statuses.get(collaboration.statusName)

    const ourUpdates = updates.filter(u => u.parentId === collaboration.id)
    const ourComments = comments.filter(c => c.parentId === collaboration.id)
    const ourUploads = uploads.filter(u => u.parentId === collaboration.id)
    const dates = [
        createdAt,
        ...ourUpdates.map(u => u.createdAt),
        ...ourComments.map(c => c.createdAt),
        ...ourUploads.map(u => u.createdAt),
    ]
    const lastUpdateDate = new Date(Math.max(...dates)) // max uses/returns timestamps

    const feature = features.get(featureId)
    const geometry = geometries.get(feature?.parentId) || {}

    const hasNotesPending = ourComments.some(c => c.isPending)

    const shape = CollaborationShape.from({
        id: collaboration.id,
        parentId: featureId,
        geometryId: geometry.id,
        canvasId: geometry.parentId,
        identifier,
        categoryId: collaboration.categoryId, // NOTE: NOT a Shape!

        createdBy: projectParticipants.get(createdBy),
        canvas: canvases.get(geometry.parentId),
        comments: ourComments,
        createdAt,
        followers,
        hasNotesPending,
        isArchived: !!geometry.isArchived,
        isTask: !!statusShape,
        lastUpdateDate,
        name: collaboration.name,
        tags: tagNames.map(t => tags.get(t)),
        updates: ourUpdates,
        uploads: ourUploads,

        // task only
        assignee: projectParticipants.get(assignee),
        description: collaboration.description,
        dueDate: dueDate ? new Date(dueDate) : undefined,
        isCompleted: statusShape?.isCompleted,
        status: statusShape,
    })

    shape.parent = () => feature
    shape.geometry = () => geometry
    return shape
}

export { CollaborationShape, CollaborationShapePropTypes }
