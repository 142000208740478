/*
 * FilterGroupForCategory is a filter group component for filtering content by categories.
 * It provides a multi-select interface for choosing categories, with support for:
 *
 * - Selecting multiple categories
 * - Filtering items with no category
 * - Disabled state when task pins are not shown
 * - Custom styling through CSS props
 *
 * The component integrates with the FilterSettings system and uses FilterGroupMultiSelect for its UI implementation.
 */

import { FilterSettings } from '@range.io/basic-types'
import React from 'react'
import { FlexRow, Icon } from '../../components-reusable/index.js'
import { styled } from '../../range-theme/index.js'
import { CategoryIcon } from '../CategorySelector.js'
import Pill from '../Pill.js'
import FilterGroupMultiSelect from './filter-primitives/FilterGroupMultiSelect.js'
import { StyledText } from './filter-primitives/FilterGroupShared.js'
import FilterGroupTextInput from './filter-primitives/FilterGroupTextInput.js'

const StyledCategoryInputIcon = styled(Icon, {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    bottom: 0,
    left: 10,
    margin: 'auto',
    color: '$neutral05',
    transitionDuration: '0.4s',
    size: '16px',

    '&:active': { color: '$neutral05' },
})

const FilterGroupForCategory = ({ allCategories, filterSettings, setFilterSettings, disabled = false, css }) => {
    const updateFilterSettings = f => v => setFilterSettings(f(filterSettings, v))
    const phraseMatchesCategory = (category, phrase) => category.name.toLowerCase().includes(phrase.toLowerCase())

    const renderInput = (inputWrapperRef, showList, setShowList, phraseToFilter, setPhraseToFilter) => (
        <FilterGroupTextInput
            inputRef={inputRef}
            inputWrapperRef={inputWrapperRef}
            showList={showList}
            setShowList={setShowList}
            phraseToFilter={phraseToFilter}
            setPhraseToFilter={setPhraseToFilter}
            iconName="levels"
            IconComponent={StyledCategoryInputIcon}
            dataCy="filter-by-category"
        />
    )

    const renderCategoryItem = category => (
        <FlexRow css={{ ai: 'center' }}>
            <CategoryIcon category={category} />
            <StyledText>{category.name}</StyledText>
        </FlexRow>
    )

    const renderPill = category => {
        const onDeselect = () => updateFilterSettings(FilterSettings.unselectCategory)(category)
        return (
            <Pill key={category.id} onDeselect={onDeselect} tooltipText="Remove Category">
                <StyledText css={{ maxWidth: 140, limitLinesOfTextTo: 1 }}>{category.name}</StyledText>
            </Pill>
        )
    }

    const inputRef = React.useRef(null)
    const { shouldFilterByCategory, categories: selectedCategories } = filterSettings

    return (
        <FilterGroupMultiSelect
            title="Filter by Category"
            checked={shouldFilterByCategory}
            disabled={disabled}
            onToggle={updateFilterSettings(FilterSettings.setShouldFilterByCategory)}
            items={allCategories}
            selectedItems={selectedCategories}
            onItemSelect={updateFilterSettings(FilterSettings.toggleCategory)}
            onItemRemove={updateFilterSettings(FilterSettings.unselectCategory)}
            filterItem={phraseMatchesCategory}
            renderItem={renderCategoryItem}
            renderPill={renderPill}
            renderInput={renderInput}
            css={css}
            dataCy="filter-category"
            maxListHeight="426px"
        />
    )
}

export default FilterGroupForCategory
