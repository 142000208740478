/*
 * FilterGroupForDateUpdated is a filter group component for filtering content by last update date.
 * It provides an interface for filtering items based on when they were last updated,
 * integrating with the FilterSettings system and using FilterGroupSingleSelect for its UI.
 */

import { FilterSettings } from '@range.io/basic-types'
import React from 'react'
import FilterGroupSingleSelect from './filter-primitives/FilterGroupSingleSelect.js'

const FilterGroupForDateUpdated = ({ filterSettings, setFilterSettings }) => {
    const updateFilterSettings = f => v => setFilterSettings(f(filterSettings, v))

    const { shouldFilterByDateUpdated, dateUpdatedDayRange } = filterSettings

    return (
        <FilterGroupSingleSelect
            title="Show pins updated in the..."
            checked={shouldFilterByDateUpdated}
            onToggle={updateFilterSettings(FilterSettings.setShouldFilterByDateUpdated)}
            options={FilterSettings.dateRangeOptions}
            selectedValue={dateUpdatedDayRange}
            onOptionSelect={updateFilterSettings(FilterSettings.setDateUpdatedDayRange)}
            dataCy="filter-date-updated"
        />
    )
}

export default FilterGroupForDateUpdated
