import { without } from '@range.io/functional'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, FlexColumn, FlexRow, Text } from '../components-reusable/index.js'
import { ProjectChangedCommand } from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import StatusEditor from './StatusEditor.js'

const StyledOuterContainer = styled(FlexColumn, {
    height: '100vh',
    width: '100%',
    fontSize: 14,
    ai: 'center',
    justifyContent: 'start-center',
    transitionDuration: '0.4s',
    background: '$neutral10',

    position: 'absolute',
    zIndex: 100,
    overflow: 'auto',
    paddingTop: 80,
    paddingBottom: 80,
})

const StyledFlexColumn = styled(FlexColumn, {
    width: '80%',
    maxWidth: '50%',
    justifyContent: 'start',
})

const StyledTopBar = styled(FlexRow, {
    width: '100%',
    marginBottom: '24px',
})

const StyledPrimaryLabel = styled(Box, {
    width: 'auto',
    color: '$neutral04',
    fs: 32,
    fw: '700',
})

const StyledDescriptionLabel = styled(Text, {
    width: 'auto',
    color: '$neutral05',
    fs: 16,
    justifyContent: 'start',
    whiteSpace: 'wrap',
    pt: 16,
})

const StatusManager = () => {
    const navigate = useNavigate()
    const { workspaceId, projectId } = useParams()
    const { runCommand } = useCommandHistory()

    const currentProject = useSelector(ReduxSelectors.selectedProject)
    const allStatusNames = useSelector(ReduxSelectors.statusNames)

    const [initialStatuses, setInitialStatuses] = useState()
    const [statuses, setStatuses] = useState(initialStatuses)

    useEffect(() => {
        const statuses = Object.values(allStatusNames).sort((a, b) => a.order - b.order)
        setInitialStatuses(statuses)
    }, [allStatusNames])

    const handleClose = () => {
        const validStatuses = statuses.filter(status => status.name.length) // remove empty statuses
        validStatuses.forEach((s, i) => (s.order = i)) // renumber the order

        const initialStatusesIds = initialStatuses.map(status => status.id) // the ones we started editing
        const newStatusesIds = validStatuses.map(status => status.id) // the ones we try to save now
        const defaultStatusId = initialStatuses[0].id

        const deletedStatusIds = without(newStatusesIds, initialStatusesIds) // check if any of the statuses was deleted
        const deletedStatusesObject = deletedStatusIds?.reduce((acc, id) => ({ ...acc, [id]: defaultStatusId }), {})

        // save statuses
        runCommand(
            ProjectChangedCommand.Outbound(currentProject.id, {
                statusNames: validStatuses,
                deletedStatusNames: deletedStatusesObject,
            })
        )

        if (window.history.state?.usr?.safeToNavigateBack) navigate(-1)
        else navigate(`/${workspaceId}/${projectId}`)
    }

    const handleStatusesChanged = newStatuses => {
        setStatuses(newStatuses)
    }

    return (
        <StyledOuterContainer>
            <StyledFlexColumn>
                <StyledTopBar>
                    <FlexColumn css={{ width: 'inherit' }}>
                        <Button onClick={handleClose} css={{ width: '70px', mb: '24px' }} variant="primary" size="lg">
                            <Text>Close</Text>
                        </Button>
                        <StyledDescriptionLabel css={{ fw: '600' }}>{currentProject.name}</StyledDescriptionLabel>
                        <FlexRow css={{ ai: 'center', gap: 8, width: '100%', pt: 4 }}>
                            <StyledPrimaryLabel>Task Status Manager</StyledPrimaryLabel>
                        </FlexRow>
                        <StyledDescriptionLabel>
                            Pin statuses represent the state of a task pin. Manage your existing project statuses below.
                        </StyledDescriptionLabel>
                    </FlexColumn>
                </StyledTopBar>
                {initialStatuses && (
                    <StatusEditor defaultStatuses={initialStatuses} onStatusesChange={handleStatusesChanged} />
                )}
            </StyledFlexColumn>
        </StyledOuterContainer>
    )
}

export default StatusManager
