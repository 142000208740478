import React from 'react'
import { styled } from '../../range-theme/index.js'

const IconPickerIcon = ({ svgIcon }) => (
    <span
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '32px', width: '32px' }}
        dangerouslySetInnerHTML={{ __html: svgIcon }}
    />
)

const Button = styled('button', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 999,
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 'auto',
    margin: 0,
    transition: 'all 0.2s',
    color: '$neutral05',

    '& svg': {
        width: 32,
        height: 32,
        transition: 'transform 0.2s',
    },

    '&:hover svg': {
        transform: 'scale(1.1)',
    },

    variants: {
        selected: {
            true: {
                background: '$primary04',
                color: '$white',
                '&:hover': {
                    background: '$primary04',
                },
            },
            false: {
                '&:hover': {
                    background: '$neutral07',
                    color: '$neutral04',
                },
            },
        },
    },
})

const IconPickerButton = ({ svgIcon, isSelected = false, onClick }) => (
    <Button selected={isSelected} onClick={onClick}>
        <IconPickerIcon svgIcon={svgIcon} />
    </Button>
)

export default IconPickerButton
