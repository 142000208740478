import memoizeResult from '../../memoize-result.js'
import { _field } from '../../selector-primitives.js'

const updatesAsArray = memoizeResult(state => Object.values(state.updates))
const updatesLookupTable = _field('updates')

// Return ALL the items as an array
const updatesForCollaboration = (state, id) => updatesAsArray(state).filter(u => u.parentId === id)

/*
 * All the Updates for the selected Collaboration
 * @sig updatesForSelectedCollaboration :: State -> [Update]
 */
const updatesForSelectedCollaboration = state => {
    const { selectedCollaborationId } = state

    // no Collaboration selected, implies there are no selected Updates either
    return selectedCollaborationId ? updatesForCollaboration(state, selectedCollaborationId) : []
}

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

const UpdateSelectors = {
    updatesAsArray,
    updatesForCollaboration,
    updatesForSelectedCollaboration,
    updatesLookupTable,
}

export default UpdateSelectors
