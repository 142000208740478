import { Presence } from '@range.io/basic-types'
import memoizeResult from '../../memoize-result.js'

const presencesAsArray = memoizeResult(state => Object.values(state.presences))

/*
 * Return all users who are "present" -- meaning their lastPresenceTimestamp was recent AND they're not "me"
 * @sig presences :: State -> [User]
 */
const presences = state => {
    const me = state.selectedUserId
    return presencesAsArray(state).filter(u => {
        const isNotMe = u.id !== me
        const movedRecently = u.lastPresenceTimestamp + Presence.userIsNoLongerPresentAfter > Date.now()
        return isNotMe && movedRecently
    })
}

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

const PresenceSelectors = {
    presences,
    presencesAsArray,
}

export default PresenceSelectors
