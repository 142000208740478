import { CanvasSource } from '@range.io/basic-types'
import { _field } from '../../selector-primitives.js'

const canvasSourceLookupTable = _field('canvasSources')

/*
 * Note: Assuming a maximum of 1 PDF canvasSource per canvas
 * @sig pdfCanvasSource :: (State, Canvas) -> CanvasSource.Pdf|undefined
 */
const pdfCanvasSource = (state, canvas) =>
    Object.values(state.canvasSources).find(cs => cs.canvasId === canvas.id && cs.type === 'pdf')

/*
 * Note: Assuming a maximum of 1 PDF canvasSource per canvas
 * @sig pdfCanvasSourceForSelectedCanvas :: (State) -> CanvasSource.Pdf|undefined
 */
const pdfCanvasSourceForSelectedCanvas = state => {
    const canvas = state.canvases[state.selectedCanvas]
    return canvas ? pdfCanvasSource(state, canvas) : undefined
}

/*
 * Return the CanvasSources for the given Canvas; for a Pdf there should be 1, but it can be > 1 for other types
 * @sig canvasSourcesForCanvas :: (State, Canvas) -> [CanvasSource]
 */
const canvasSourcesForCanvas = (state, canvas) =>
    Object.values(state.canvasSources).filter(cs => cs.canvasId === canvas.id)

/*
 * Return the CanvasSources for the selected Canvas; for a Pdf there should be 1, but it can be > 1 for other types
 */
const canvasSourcesForSelectedCanvas = state =>
    Object.values(state.canvasSources).filter(cs => cs.canvasId === state.selectedCanvas)

/*
 * All CanvasSource.Pdf's sorted by name
 *
 * @sig _pdfCanvasSources :: State -> [CanvasSource.Pdf]
 */
const pdfCanvasSources = state => {
    const canvasSources = Object.values(state.canvasSources)
    const pdfCanvasSources = canvasSources.filter(cs => CanvasSource.Pdf.is(cs))
    return pdfCanvasSources.sort((a, b) => a.name.localeCompare(b.name))
}

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

const CanvasSourceSelectors = {
    canvasSourceLookupTable,
    canvasSourcesForCanvas,
    canvasSourcesForSelectedCanvas,
    pdfCanvasSource,
    pdfCanvasSourceForSelectedCanvas,
    pdfCanvasSources,
}

export default CanvasSourceSelectors
