import memoizeResult from '../../memoize-result.js'

const featuresAsArray = memoizeResult(state => Object.values(state.features))

/*
 * Return the Feature with the given Geometry's id
 * @sig firstFeatureForGeometry :: (State, Id) -> Feature|undefined
 */
const firstFeatureForGeometry = (state, geometryId) => featuresAsArray(state).find(f => f.geometryId === geometryId)

const FeatureSelectors = {
    featuresAsArray,
    firstFeatureForGeometry,
}

export default FeatureSelectors
