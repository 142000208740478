import StringTypes from '@range.io/basic-types/src/string-types.js'
import { tagged } from '@range.io/functional'
import PropTypes from 'prop-types'
import { CanvasSourceShapePropTypes } from './canvas-source-shape.js'

// prettier-ignore
const CanvasShape = tagged('CanvasShape', {
    id              : StringTypes.Id,
    canvasSources   : '[CanvasSourceShape]',
    name            : 'String',
})

// (stricter requirements for LookupTable require exact type information)
// prettier-ignore
const CanvasShapeWithSingleCanvasSource = tagged('CanvasShapeWithSingleCanvasSource', {
    id              : StringTypes.Id,
    canvasSource    : 'CanvasSourceShape',
    name            : 'String',
    justCreated     : 'Boolean?',
})

const arrayOfPropTypes = type => PropTypes.arrayOf(PropTypes.shape(type))

// prettier-ignore
const CanvasShapePropTypes = {
    id              : PropTypes.string.isRequired,
    canvasSources   : arrayOfPropTypes(CanvasSourceShapePropTypes),
    name            : PropTypes.string.isRequired,
}

CanvasShape.fromCanvas = shapeLookupTable => canvas => {
    const isCanvasSourceForThisCanvas = cs => cs.parentId === canvas.id

    const { canvasSources } = shapeLookupTable
    const canvasSourcesForCanvas = canvasSources.filter(isCanvasSourceForThisCanvas)
    const shape = CanvasShape.from({
        id: canvas.id,
        canvasSources: canvasSourcesForCanvas,
        name: canvas.name,
    })

    return shape
}

CanvasShape.matchesFullName = (canvasShape, substring) =>
    canvasShape.name.toLowerCase().includes(substring.toLowerCase())

export { CanvasShape, CanvasShapeWithSingleCanvasSource, CanvasShapePropTypes }
