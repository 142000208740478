import memoizeResult from '../../memoize-result.js'
import { _field } from '../../selector-primitives.js'
import UiStateSelectors from '../../ui-state/ui-state-selectors.js'

const statusNameLookupTable = _field('statusNames')
const statusNamesAsArray = memoizeResult(state => Object.values(state.statusNames))

const statusNameForProjectId = (state, projectId) => {
    // TODO: get status names for project
}

/*
 * Return the StatusName of the Collaboration
 * @sig statusNameForCollaboration :: (State, Collaboration) -> StatusName|undefined
 */
const statusNameForCollaboration = (state, collaboration) =>
    collaboration ? state.statusNames[collaboration.statusName] : undefined

/*
 * Return the StatusName of the currently-selected Collaboration
 * @sig statusOfSelectedCollaboration :: State -> StatusName
 */
const statusOfSelectedCollaboration = state =>
    statusNameForCollaboration(state, UiStateSelectors.selectedCollaboration(state))

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

const StatusNameSelectors = {
    statusNameForCollaboration,
    statusNameForProjectId,
    statusNameLookupTable,
    statusNamesAsArray,
    statusOfSelectedCollaboration,
}

export default StatusNameSelectors
