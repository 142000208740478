/*
 * Define filter settings for the Navigator panel on the main left panel
 * There are about a dozen different filter settings the user can set
 * There is also a "default" value for all the settings used to control:
 *
 * - the "reset" button
 * - the filter button
 * - the filter banner
 *
 * There are a slew of functions to update the settings of the form: (oldSettings, newSetting) => newSettings
 */

import { equals, LookupTable, omit, tagged, without } from '@range.io/functional'
import { CanvasShape } from 'range-editor/src/react-shapes/canvas-shape.js'
import { ParticipantShape } from 'range-editor/src/react-shapes/participant-shape.js'
import Category from './category.js'

const FilterSettings = tagged('FilterSettings', {
    allUsers: '[ParticipantShape]', // LookupTable actually, not array
    assignees: '[ParticipantShape]', // LookupTable actually, not array
    categories: '[Category]', // LookupTable
    dateCreatedDayRange: 'Number?',
    dateUpdatedDayRange: 'Number?',
    followers: '[ParticipantShape]', // LookupTable actually, not array
    matchUnassigned: 'Boolean',
    matchUntagged: 'Boolean',
    selectedCanvasShapes: '[CanvasShape]',
    selectedIdentifiers: '[String]',
    shouldFilterByAssignee: 'Boolean',
    shouldFilterByCanvas: 'Boolean',
    shouldFilterByCategory: 'Boolean',
    shouldFilterByDateCreated: 'Boolean',
    shouldFilterByDateUpdated: 'Boolean',
    shouldFilterByFollower: 'Boolean',
    shouldFilterByIdentifiers: 'Boolean',
    shouldFilterByTags: 'Boolean',
    showArchivedPins: 'Boolean',
    showOnlyCreatedByMe: 'Boolean',
    showOnlyOverdueTasks: 'Boolean',
    showPhotoPins: 'Boolean',
    showStatuses: '[StatusShape]',
    showTaskPins: 'Boolean',
    tags: '[TagShape]',
})

// can't be usefully set until the project data is loaded
const defaults = (statusShapes, participantShapes) => ({
    allUsers: LookupTable(participantShapes, ParticipantShape),
    assignees: LookupTable([], ParticipantShape),
    categories: LookupTable([], Category),
    dateCreatedDayRange: undefined,
    dateUpdatedDayRange: undefined,
    followers: LookupTable([], ParticipantShape),
    matchUnassigned: false,
    matchUntagged: false,
    selectedCanvasShapes: LookupTable([], CanvasShape),
    selectedIdentifiers: [],
    shouldFilterByAssignee: false,
    shouldFilterByCanvas: false,
    shouldFilterByCategory: false,
    shouldFilterByDateCreated: false,
    shouldFilterByDateUpdated: false,
    shouldFilterByFollower: false,
    shouldFilterByIdentifiers: false,
    shouldFilterByTags: false,
    showArchivedPins: false,
    showOnlyCreatedByMe: false,
    showOnlyOverdueTasks: false,
    showPhotoPins: true,
    showStatuses: statusShapes,
    showTaskPins: true,
    tags: [],
})

// Abbreviation
const from = FilterSettings.from

// defaults
const defaultFilterSettings = (statusShapes, participantShapes) => from(defaults(statusShapes, participantShapes))

/*
 * Are the current filter settings the same as the default filter settings?
 * As a special case, if shouldFilterByTags, shouldFilterByAssignee or shouldFilterByDateCreated ARE true, but no tags or assignees
 * have been selected yet, consider this a match.
 */
const isSetToDefaults = (filterSettings, statusShapes, participantShapes) => {
    // We only want to compare applied statuses ids
    const statusesToIdsMap = statuses => statuses?.map(status => status.id).sort()

    const keysToOmit = [
        'shouldFilterByTags',
        'shouldFilterByAssignee',
        'shouldFilterByCategory',
        'shouldFilterByCanvas',
        'shouldFilterByDateCreated',
        'shouldFilterByDateUpdated',
        'shouldFilterByIdentifiers',
        'allUsers',
        'shouldFilterByFollower',
    ]

    let currentSettings = omit(keysToOmit, filterSettings)
    currentSettings = {
        ...currentSettings,
        showStatuses: statusesToIdsMap(currentSettings.showStatuses),
    }

    let defaultSettings = omit(keysToOmit, defaultFilterSettings(statusShapes, participantShapes))
    defaultSettings = {
        ...defaultSettings,
        showStatuses: statusesToIdsMap(defaultSettings.showStatuses),
    }

    return equals(currentSettings, defaultSettings)
}

// simple toggles
const setDateCreatedDayRange = (nfs, b) => from({ ...nfs, dateCreatedDayRange: b })
const setDateUpdatedDayRange = (nfs, b) => from({ ...nfs, dateUpdatedDayRange: b })
const setShouldFilterByAssignee = (nfs, b) => from({ ...nfs, shouldFilterByAssignee: b })
const setShouldFilterByCanvas = (nfs, b) => from({ ...nfs, shouldFilterByCanvas: b })
const setShouldFilterByCategory = (nfs, b) => from({ ...nfs, shouldFilterByCategory: b })
const setShouldFilterByDateCreated = (nfs, b) => from({ ...nfs, shouldFilterByDateCreated: b })
const setShouldFilterByDateUpdated = (nfs, b) => from({ ...nfs, shouldFilterByDateUpdated: b })
const setShouldFilterByFollower = (nfs, b) => from({ ...nfs, shouldFilterByFollower: b })
const setShouldFilterByIdentifiers = (nfs, b) => from({ ...nfs, shouldFilterByIdentifiers: b })
const setShouldFilterByTags = (nfs, b) => from({ ...nfs, shouldFilterByTags: b })
const setShowArchivedPins = (nfs, b) => from({ ...nfs, showArchivedPins: b })
const setShowOnlyCreatedByMe = (nfs, b) => from({ ...nfs, showOnlyCreatedByMe: b })
const setShowOnlyOverdueTasks = (nfs, b) => from({ ...nfs, showOnlyOverdueTasks: b })
const setShowPhotoPins = (nfs, b) => from({ ...nfs, showPhotoPins: b })
const setShowTaskPins = (nfs, b) => from({ ...nfs, showTaskPins: b })

// ---------------------------------------------------------------------------------------------------------------------
// Handle canvas toggles
// ---------------------------------------------------------------------------------------------------------------------
const setSelectedCanvasShapes = (nfs, b) => from({ ...nfs, selectedCanvasShapes: b })

// The user turned the checkbox OFF for the given canvasShape; remove it from the selected canvases
const unselectCanvas = (nfs, canvasShape) =>
    from({ ...nfs, selectedCanvasShapes: nfs.selectedCanvasShapes.removeItem(canvasShape) })

// toggle the specific canvas
const toggleCanvas = (nfs, canvasShape) => {
    const canvases = nfs.selectedCanvasShapes.toggleItem(canvasShape)

    return from({ ...nfs, selectedCanvasShapes: canvases })
}

// ---------------------------------------------------------------------------------------------------------------------
// Handle status toggles
// ---------------------------------------------------------------------------------------------------------------------

// This is tricky only because the Statuses and nominally part of the project data, and not predefined
const setStatus = (nfs, statusShape, checked) => {
    const newStatuses = checked ? nfs.showStatuses.concat(statusShape) : without(statusShape, nfs.showStatuses)
    return from({ ...nfs, showStatuses: newStatuses })
}

// ---------------------------------------------------------------------------------------------------------------------
// Handle assignee filters
// ---------------------------------------------------------------------------------------------------------------------

// The singleton "unassigned tasks" ParticipantShape
const unassignedTasksShape = ParticipantShape(
    '4db05060-896f-4481-ae74-11190f85700b',
    '',
    null,
    'Unassigned Tasks',
    false
)

const dateRangeOptions = [
    { name: 'Last day', dayValue: 1 },
    { name: 'Last 5 days', dayValue: 5 },
    { name: 'Last 7 days', dayValue: 7 },
    { name: 'Last 14 days', dayValue: 14 },
    { name: 'Last 30 days', dayValue: 30 },
]

// The user turned the checkbox OFF for the given participantShape; remove it from the assignees and reset matchUnassigned
const unselectAssignee = (nfs, participantShape) =>
    from({ ...nfs, assignees: nfs.assignees.removeItem(participantShape), matchUnassigned: false })

/*
 * The user toggled the checkbox for the given participantShape. There are 3 cases
 *
 *                                  side effect
 * - unassigned went on             turn OFF all "real" assignees; set matchUnassigned
 * - unassigned went off            turn OFF "unassigned"; reset matchUnassigned
 * - some other assignee changed    toggle assignee; reset matchUnassigned
 */
const toggleAssignee = (nfs, participantShape) => {
    // set assignees to ["unassigned"]; set matchUnassigned
    const turnOnUnassigned = () =>
        from({ ...nfs, assignees: LookupTable([participantShape], ParticipantShape), matchUnassigned: true })

    // set assignees to [] and reset matchUnassigned
    const turnOffUnassigned = () =>
        from({ ...nfs, assignees: LookupTable([], ParticipantShape), matchUnassigned: false })

    // toggle the specific assignee; turn OFF "unassigned"; reset matchUnassigned
    const toggleAssignee = () => {
        let assignees = nfs.assignees.toggleItem(participantShape)
        assignees = assignees.removeItem(unassignedTasksShape)

        return from({ ...nfs, assignees, matchUnassigned: false })
    }

    if (participantShape === unassignedTasksShape && !nfs.matchUnassigned) return turnOnUnassigned()
    if (participantShape === unassignedTasksShape && nfs.matchUnassigned) return turnOffUnassigned()
    return toggleAssignee()
}

const unselectCategory = (nfs, category) => from({ ...nfs, categories: nfs.categories.removeItem(category) })
const toggleCategory = (nfs, category) => from({ ...nfs, categories: nfs.categories.toggleItem(category) })

// ---------------------------------------------------------------------------------------------------------------------
// Handle identifier toggles
// ---------------------------------------------------------------------------------------------------------------------
const toggleIdentifier = (nfs, identifier) => {
    const identifiers = nfs.selectedIdentifiers
    const newIdentifiers =
        identifiers.indexOf(identifier) === -1 ? identifiers.concat(identifier) : without(identifier, identifiers)

    return from({ ...nfs, selectedIdentifiers: newIdentifiers })
}

const unselectIdentifier = (nfs, identifier) => {
    const newIdentifiers = without(identifier, nfs.selectedIdentifiers)

    return from({ ...nfs, selectedIdentifiers: newIdentifiers })
}

// ---------------------------------------------------------------------------------------------------------------------
// Handle follower filters
// ---------------------------------------------------------------------------------------------------------------------

const unselectFollower = (nfs, participantShape) =>
    from({ ...nfs, followers: nfs.followers.removeItem(participantShape) })

const toggleFollower = (nfs, participantShape) => {
    const newFollowers = nfs.followers.toggleItem(participantShape)
    return from({ ...nfs, followers: newFollowers })
}

// ---------------------------------------------------------------------------------------------------------------------
// Handle tag toggles
// ---------------------------------------------------------------------------------------------------------------------

const toggleMatchUntagged = nfs => from({ ...nfs, tags: [], matchUntagged: !nfs.matchUntagged })
const setTagShapes = (nfs, tags) => from({ ...nfs, tags, matchUntagged: false })

// ---------------------------------------------------------------------------------------------------------------------
// public API
// ---------------------------------------------------------------------------------------------------------------------

FilterSettings.dateRangeOptions = dateRangeOptions
FilterSettings.defaultFilterSettings = defaultFilterSettings
FilterSettings.isSetToDefaults = isSetToDefaults
FilterSettings.setDateCreatedDayRange = setDateCreatedDayRange
FilterSettings.setDateUpdatedDayRange = setDateUpdatedDayRange
FilterSettings.setSelectedCanvasShapes = setSelectedCanvasShapes
FilterSettings.setShouldFilterByAssignee = setShouldFilterByAssignee
FilterSettings.setShouldFilterByCanvas = setShouldFilterByCanvas
FilterSettings.setShouldFilterByCategory = setShouldFilterByCategory
FilterSettings.setShouldFilterByDateCreated = setShouldFilterByDateCreated
FilterSettings.setShouldFilterByDateUpdated = setShouldFilterByDateUpdated
FilterSettings.setShouldFilterByFollower = setShouldFilterByFollower
FilterSettings.setShouldFilterByIdentifiers = setShouldFilterByIdentifiers
FilterSettings.setShouldFilterByTags = setShouldFilterByTags
FilterSettings.setShowArchivedPins = setShowArchivedPins
FilterSettings.setShowOnlyCreatedByMe = setShowOnlyCreatedByMe
FilterSettings.setShowOnlyOverdueTasks = setShowOnlyOverdueTasks
FilterSettings.setShowPhotoPins = setShowPhotoPins
FilterSettings.setShowTaskPins = setShowTaskPins
FilterSettings.setStatus = setStatus
FilterSettings.setTagShapes = setTagShapes
FilterSettings.toggleAssignee = toggleAssignee
FilterSettings.toggleCanvas = toggleCanvas
FilterSettings.toggleCategory = toggleCategory
FilterSettings.toggleFollower = toggleFollower
FilterSettings.toggleIdentifier = toggleIdentifier
FilterSettings.toggleMatchUntagged = toggleMatchUntagged
FilterSettings.unassignedTasksShape = unassignedTasksShape
FilterSettings.unselectAssignee = unselectAssignee
FilterSettings.unselectCanvas = unselectCanvas
FilterSettings.unselectCategory = unselectCategory
FilterSettings.unselectFollower = unselectFollower
FilterSettings.unselectIdentifier = unselectIdentifier

export default FilterSettings
