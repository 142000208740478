import { Category } from '@range.io/basic-types'
import { taggedSum } from '@range.io/functional'
import { ReduxActions } from '../../redux'
import CommandPlayer, { simpleInboundChangedRunCommand } from './command-player'

const CategoryChangedCommand = taggedSum('CategoryChangedCommand', {
    Inbound: {
        category: 'Category',
    },
})

const runInboundCommand = (resources, command) =>
    simpleInboundChangedRunCommand(resources, command, Category, ReduxActions.categoryAdded, c => c.category)

const addCommand = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        CategoryChangedCommand,
        CommandPlayer({
            CommandType: CategoryChangedCommand,
            Type: Category,
            collectionPath: projectId => `/projects/${projectId}/categories/`,
            runInboundCommand,
            runOutboundCommand: () => {},
            addCommandToHistory,
            changeType: 'modified',
        })
    )
}

export { addCommand, CategoryChangedCommand }
