/*
 * The independent (not connected to outside state, or 'dumb') main component for the Tags component.
 * Displays list of selected tags, allows there adding & removing with an input component with a scrollable list
 */

import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { byFieldStringComparator } from '@range.io/functional'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, FlexColumn, FlexRow, Icon, ScrollArea } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import TagList from './TagList.js'
import TagPill from './TagPill.js'
import * as Popover from '@radix-ui/react-popover'
import TagInput from './TagInput.js'

const StyledTagsDisplay = styled(FlexRow, {
    width: '100%',
    flexWrap: 'wrap',
    gap: '8px',
    position: 'relative',
    zIndex: 500,
    fw: 500,

    variants: {
        variant: {
            standard: { pb: 12 },
            tagFilter: { pb: 0 },
        },
    },
    defaultVariants: {
        variant: 'standard',
    },
})

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
    all: 'unset',
    minWidth: '12px',
    width: '12px',
    height: '12px',
    background: '$neutral09',
    border: '1px solid $neutral07',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px',
})

const StyledCheckboxIndicator = styled(CheckboxPrimitive.Indicator, {
    color: '#FFFFFF',
    backgroundColor: '$primary04',
    border: '1px solid $primary04',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
})

const StyledListItem = styled(FlexRow, {
    alignItems: 'center',
    color: '$neutral05',
    padding: '8px',
    margin: '8px 8px 8px 8px',
    fontSize: '14px',
    lineHeight: '21px',
    width: 'calc(100% - 16px)',
    userSelect: 'none',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '$primary02',
        color: '$neutral04',
        borderRadius: '6px',
        [`& ${StyledCheckbox}`]: {
            border: '1px solid $primary04',
        },
    },

    '&:first-child': {
        mt: 8,
    },

    '&:last-child': {
        mb: 8,
    },

    variants: {
        highlighted: {
            true: {
                backgroundColor: '$neutral08',
                color: '$neutral04',
                borderRadius: '6px',
                [`& ${StyledCheckbox}`]: {
                    border: '1px solid $primary04',
                },
            },
        },
        inactive: {
            true: {
                pointerEvents: 'none',
            },
        },
    },
})
const ListWrapper = styled(FlexColumn, {
    mt: '4px',
    background: '$neutral10',
    color: '$neutral05',
    border: '1px solid $neutral07',
    borderRadius: '6px',
    position: 'absolute',
    zIndex: 200,
    boxShadow: '0px 6px 10px #00000010',
    boxSizing: 'border-box',
    maxHeight: '800px',
    variants: {
        variant: {
            default: { width: 450 },
            mediaViewUser: { maxWidth: 250 },
            mediaViewCanvas: { maxWidth: 300 },
            mediaViewTags: { maxWidth: 250, right: 29 },
            assigneeFilter: { width: 'content-fit' },
        },
    },
    defaultVariants: {
        variant: 'default',
    },
})

// "Project Tag Manager" list item
const ProjectTagManagerButton = ({ onTagManagerClick }) => (
    <>
        <Box
            css={{
                width: '100%',
                borderBottom: '1px solid $neutral07',
            }}
        />
        <StyledListItem css={{ mt: 8 }} onClick={onTagManagerClick}>
            <Icon name="tags" iconSize="14px" css={{ marginRight: '8px' }} />
            Tag Manager
        </StyledListItem>
    </>
)

// "untagged pins" list item
const UntaggedItem = ({ matchUntagged, setMatchUntagged, untaggedOptionText }) => (
    <Flex css={{ borderBottom: '1px solid $neutral07' }}>
        <StyledListItem onClick={setMatchUntagged}>
            <StyledCheckbox checked={matchUntagged} onClick={setMatchUntagged}>
                <StyledCheckboxIndicator>
                    <Icon name="tickSmall" iconSize="12px" />
                </StyledCheckboxIndicator>
            </StyledCheckbox>
            {untaggedOptionText}
        </StyledListItem>
    </Flex>
)

/*
 * Display a tag selector; there are two variants: 'standard' and 'tagFilter' which control managing untagged pins
 * and whether you can create or manage tags
 */
const TagsDisplay = ({
    variant = 'standard',
    selectedTagIds,
    inputPlaceholder,
    onItemSelectionChange,
    onNewTagAdd,
    onTagManagerClick,
    matchUntagged,
    setMatchUntagged = () => {},
    untaggedOptionText = 'untagged',
    maxListHeight = '325px',
    tags,
}) => {
    // depend on variant
    const addRowForMatchUntagged = variant === 'tagFilter'
    const allowEditing = variant !== 'tagFilter'
    const showTagCounts = variant !== 'tagFilter'

    const [phraseToFilter, setPhraseToFilter] = useState('')
    const [showList, setShowList] = useState(false)
    const [filteredTags, setFilteredTags] = useState([])
    const inputRef = useRef()
    const listRef = useRef()
    const sideRef = useRef(null)
    const inputWrapperRef = useRef()

    const handleInputKeyPress = e => {
        // Ensure input gets cleared after Enter press
        if (e.key === 'Enter') clearInput()
    }

    useEffect(() => {
        updateFiltered(phraseToFilter)
    }, [phraseToFilter, tags])
    // -----------------------------------------------------------------------------------------------------------------
    // update data
    // -----------------------------------------------------------------------------------------------------------------

    // "filtered" is the list of tags which contain the value in the text input field
    const updateFiltered = (inputValue = null) => {
        const newFiltered =
            inputValue?.length > 0
                ? Object.values(tags).filter(tag => tag.name.toLowerCase().includes(inputValue.toLowerCase()))
                : Object.values(tags)
        const filteredAndSorted = newFiltered.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        setFilteredTags(filteredAndSorted)
    }

    // -----------------------------------------------------------------------------------------------------------------
    // tag pills (displaying selected tags)
    // -----------------------------------------------------------------------------------------------------------------

    const renderTagPill = ([tagId, tagName]) => (
        <TagPill onBtnClick={() => handleItemClicked(tagId, false)} key={`tag-pill-${tagId}`}>
            {tagName}
        </TagPill>
    )

    const renderUntaggedPill = () => (
        <TagPill onBtnClick={setMatchUntagged} key={`tag-pill-untagged-pins`}>
            {untaggedOptionText}
        </TagPill>
    )

    // (alphabetically)
    const renderTagPills = () => {
        // [Id] -> [[Id, String]]
        const selectedTagNames = selectedTagIds.map(tagId => [tagId, tags[tagId]?.name]).filter(([, name]) => !!name)

        // sort by index 1 (which is the name)
        const sorted = selectedTagNames.sort(byFieldStringComparator(1, false))
        return matchUntagged ? [renderUntaggedPill()] : sorted.map(renderTagPill)
    }

    // -----------------------------------------------------------------------------------------------------------------
    // text input
    // -----------------------------------------------------------------------------------------------------------------

    /*
     * Clears the input and makes sure list gets back to default state
     */
    const clearInput = () => {
        setPhraseToFilter('')
    }

    // -----------------------------------------------------------------------------------------------------------------
    // dropdown tag selector list
    // -----------------------------------------------------------------------------------------------------------------

    /*
     * We distinguish two types of clicks on a tag list item - this one handles clicking an item itself. It selects it and focuses out the component.
     */
    const handleItemClicked = (tagId, shouldFocusInput = true) => {
        onItemSelectionChange(tagId)
        clearInput()
        if (shouldFocusInput) inputRef.current?.focus()
    }

    /*
     * This one is the second type of a click on an item - when a user clicks just the checkbox we want to allow 'bulk' selection, so we don't hide the list.
     * We also need to make sure the input is focused back on item click, and we need to stop click event from propagating up to the list item
     */
    const handleCheckboxClicked = (e, tagId) => {
        e.stopPropagation()
        onItemSelectionChange(tagId)
        inputRef.current.focus()
    }

    const handleCreateTagClicked = () => {
        if (phraseToFilter.length > 0) {
            onNewTagAdd(phraseToFilter)
            setPhraseToFilter('')
            inputRef.current.focus()
        }
    }

    const CreateTagItem = ({ inputValue, ...rest }) => (
        <StyledListItem onClick={handleCreateTagClicked} {...rest}>
            <Icon name="addCircled" iconSize="14px" css={{ marginRight: '8px' }} />
            {`Create tag "${inputValue}"`}
        </StyledListItem>
    )

    // Given an enriched Tag object generate its label consisting of its name and formatted count of uses
    const tagNameWithCount = tag => {
        if (tag.count === 0) return `${tag.name} (0)`
        if (tag.count > 99) return `${tag.name} (+99)`
        return `${tag.name} (${tag.count})`
    }

    // show a checkbox and label for the tag
    const renderListItem = (tag, idx) => {
        const onClick = e => handleCheckboxClicked(e, tag.id)

        const checked = selectedTagIds.findIndex(tagId => tagId === tag.id) !== -1
        return (
            <StyledListItem key={`tag-item-${idx}`} onClick={() => handleItemClicked(tag.id)}>
                <StyledCheckbox checked={checked} onClick={onClick}>
                    <StyledCheckboxIndicator>
                        <Icon name="tickSmall" iconSize="12px" />
                    </StyledCheckboxIndicator>
                </StyledCheckbox>
                {showTagCounts ? tagNameWithCount(tag) : tag.name}
            </StyledListItem>
        )
    }

    /*
     * Renders the items for the tag list.
     * List consists of items built from tags based on input value and two potential special items -
     * to create a new tag or an informative item to say that this tag is already assigned to this entity.
     * This list doesn't include the item that links to Tag Manager.
     */
    const renderListItems = () => {
        let listItems = filteredTags.map(renderListItem)

        // check if current input value is already a name for a tag
        const possibleTag = Object.values(tags).find(t => t.name === phraseToFilter)
        const nameAlreadyExists = !!possibleTag

        // if the currently put in name doesn't exist then prepend an item to allow creation of the tag with that name

        const shouldAllowCreateTagItem =
            allowEditing && phraseToFilter && phraseToFilter.length > 0 && !nameAlreadyExists

        // shouldAllowCreateTagItem and addRowForMatchUntagged are probably never BOTH true...
        if (listItems.length === 0 && !shouldAllowCreateTagItem) {
            return (
                <Flex
                    css={{
                        fs: 14,
                        alignItems: 'center',
                        justifyContent: 'start',
                        color: '$neutral05',
                        p: 16,
                    }}
                >
                    Start typing to add a new tag...
                </Flex>
            )
        }
        if (shouldAllowCreateTagItem) {
            listItems = [<CreateTagItem inputValue={phraseToFilter} key="tag-item-create-tag" />, ...listItems]
        }
        if (addRowForMatchUntagged)
            listItems = [
                <UntaggedItem
                    matchUntagged={matchUntagged}
                    setMatchUntagged={setMatchUntagged}
                    key="tag-item-untaggged"
                    untaggedOptionText={untaggedOptionText}
                />,
                ...listItems,
            ]

        return listItems
    }

    /*
     * If the list has only one item and the user pressed enter we need to check if the only option is to create a new tag, and then create it if we can
     */
    const handleNoItemSelectedSubmit = () => {
        const possibleTag = Object.values(tags).find(t => t.name === phraseToFilter)
        if (!possibleTag) handleCreateTagClicked()
    }

    // Detect the rendered list height and set the position once,
    // so it doesn't flip when the list changes its height,
    // e.g. after filtering.
    const sideToRender = React.useMemo(() => {
        if (!inputRef?.current || !listRef?.current) return sideRef.current // Fallback to last known value, can be null

        const rect = inputRef.current.getBoundingClientRect()

        // if full list height is higher than bottom available space, swap to top
        const rectList = listRef.current.getBoundingClientRect()

        const side = rectList.height < document.body.offsetHeight - rect.bottom ? 'bottom' : 'top'
        sideRef.current = side
        return side
    }, [inputRef.current, listRef.current])

    const handleClickOutside = event => {
        // Clear only when outside click was not on trigger
        if (!inputWrapperRef?.current || !inputWrapperRef.current.contains(event.target)) {
            setPhraseToFilter('')
        }
    }

    return (
        <StyledTagsDisplay variant={variant} data-cy="tags-display">
            <Popover.Root onOpenChange={setShowList} open={showList}>
                <FlexRow css={{ flexWrap: 'wrap', gap: '8px' }}>
                    <Popover.Trigger asChild>
                        <div ref={inputWrapperRef}>
                            <TagInput
                                onKeyPress={handleInputKeyPress}
                                inputRef={inputRef}
                                showList={showList}
                                setShowList={setShowList}
                                phraseToFilter={phraseToFilter}
                                setPhraseToFilter={setPhraseToFilter}
                                inputPlaceholder={inputPlaceholder}
                            />
                        </div>
                    </Popover.Trigger>
                    {renderTagPills()}
                </FlexRow>
                <Popover.Portal>
                    {showList && (
                        <Popover.Content
                            className="PopoverContent"
                            onOpenAutoFocus={e => e.preventDefault()} // keep focus on the input
                            onInteractOutside={handleClickOutside}
                            side={sideToRender || 'bottom'}
                            align="start"
                            sideOffset={4}
                            collisionPadding={20}
                            style={{
                                opacity: sideToRender ? '1' : '0',
                                zIndex: 9999,
                                position: 'relative',
                                height: 'auto',
                                maxHeight: `var(--radix-popper-available-height)`,
                                pointerEvents: 'all',
                            }}
                        >
                            <ListWrapper
                                variant={variant}
                                ref={listRef}
                                css={{
                                    position: 'inherit',
                                    width: '280px',
                                    maxHeight: `var(--radix-popper-available-height)`,
                                    overflowY: 'auto',
                                }}
                            >
                                <ScrollArea
                                    viewportCss={{
                                        maxHeight: `min(var(--radix-popper-available-height), ${maxListHeight})`,
                                    }}
                                >
                                    <TagList onNoItemSelectedSubmit={handleNoItemSelectedSubmit}>
                                        {renderListItems()}
                                    </TagList>
                                    {allowEditing && <ProjectTagManagerButton onTagManagerClick={onTagManagerClick} />}
                                </ScrollArea>
                            </ListWrapper>
                        </Popover.Content>
                    )}
                </Popover.Portal>
            </Popover.Root>
        </StyledTagsDisplay>
    )
}

// The data from EITHER ReduxSelectors.enrichedTagNames OR from a TagShape would match this interface
TagsDisplay.tagDataPropTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
}

TagsDisplay.propTypes = {
    variant: PropTypes.oneOf(['standard', 'tagFilter']), // defaults to 'standard'
    tags: PropTypes.objectOf(PropTypes.shape(TagsDisplay.tagDataPropTypes)).isRequired,
    selectedTagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    inputPlaceholder: PropTypes.string, // default 'Add Tags'

    onItemSelectionChange: PropTypes.func.isRequired,

    // standard only
    onNewTagAdd: PropTypes.func,
    onTagManagerClick: PropTypes.func,

    // tagFilter only
    matchUntagged: PropTypes.bool, // true if the "Untagged" row is selected
    setMatchUntagged: PropTypes.func, // user clicked the "Untagged" row
    untaggedOptionText: PropTypes.string,
}

export default TagsDisplay
