/*
 * FilterSelector is a unified dropdown menu component for filtering content in both canvas and task list views.
 * The top-level component for filters
 *
 * - Date created/updated
 * - Photo/Task pins
 * - Status
 * - Assignees
 * - Categories
 * - Followers
 * - Canvas (optional)
 * - Tags
 * - Identifiers
 *
 * The component is designed to be flexible, supporting both canvas-specific and task-specific use cases
 * through configuration props like showCanvasGroup, variant, and groupStyles.
 */

import { FilterSettings } from '@range.io/basic-types'
import PropTypes from 'prop-types'
import React from 'react'
import { DropdownMenuWithTooltip, Icon, ScrollArea, Text } from '../../components-reusable/index.js'
import { CanvasShapePropTypes } from '../../react-shapes/canvas-shape.js'
import { ParticipantShapePropTypes } from '../../react-shapes/participant-shape.js'
import { StatusShapePropTypes } from '../../react-shapes/status-shape.js'
import { TagShapePropTypes } from '../../react-shapes/tag-shape.js'
import {
    OneRowGroup,
    StyleCanvasSelectorButton,
    StyledDropdownMenu,
    StyledFilterIndicator,
    TitleGroup,
} from './filter-primitives/FilterGroupShared.js'
import FilterGroupForAssignee from './FilterGroupForAssignee.js'
import FilterGroupForCanvas from './FilterGroupForCanvas.js'
import FilterGroupForCategory from './FilterGroupForCategory.js'
import FilterGroupForDateCreated from './FilterGroupForDateCreated.js'
import FilterGroupForDateUpdated from './FilterGroupForDateUpdated.js'
import FilterGroupForFollower from './FilterGroupForFollower.js'
import FilterGroupForIdentifier from './FilterGroupForIdentifier.js'
import FilterGroupForStatus from './FilterGroupForStatus.js'
import FilterGroupForTag from './FilterGroupForTag.js'

const DropdownFilter = ({
    tooltipText,
    children,
    isSetToDefault,
    alignment = 'start',
    disabled = false,
    variant,
    showFilterText = false,
    dataCy,
}) => {
    const css = { p: 0, mt: 4 }
    return (
        <DropdownMenuWithTooltip.Root disabled={disabled}>
            <DropdownMenuWithTooltip.Trigger tooltipText={tooltipText}>
                <StyleCanvasSelectorButton variant={variant} disabled={disabled} data-cy={dataCy}>
                    {!isSetToDefault && <StyledFilterIndicator variant={variant} />}
                    <Icon iconSize="16px" css={{ minWidth: 16 }} name="filter" />
                    {showFilterText && <Text>Filters</Text>}
                </StyleCanvasSelectorButton>
            </DropdownMenuWithTooltip.Trigger>
            <DropdownMenuWithTooltip.Content css={css} side="bottom" align={alignment} sideOffset={0}>
                {children}
            </DropdownMenuWithTooltip.Content>
        </DropdownMenuWithTooltip.Root>
    )
}

/*
 * Dropdown menu allowing the user to specify filters
 * @sig DropdownMenu :: {Boolean, {k:v}, {k:v}, {k:v}, FilterSettings, Update} -> ReactElement
 *  Update = FilterSettings -> ()
 */
const DropdownMenu = ({
    allCanvasShapes,
    allCategories,
    allIdentifiers,
    allParticipantShapes,
    allStatusShapes,
    allTagShapes,
    isSetToDefault,
    projectIdentifier,
    filterTitle,
    showCanvasGroup = false,
    showPhotosGroup,
    groupStyles = {},

    filterSettings,
    setFilterSettings,
}) => {
    const update = func => args => setFilterSettings(func(filterSettings, args))
    const onReset = () => setFilterSettings(FilterSettings.defaultFilterSettings(allStatusShapes, allParticipantShapes))

    const {
        selectedCanvasShapes,
        selectedCategories,
        showArchivedPins,
        showOnlyCreatedByMe,
        showOnlyOverdueTasks,
        showPhotoPins,
        showTaskPins,
        tags: selectedTagShapes,
    } = filterSettings
    const { setShowArchivedPins, setShowOnlyCreatedByMe, setShowOnlyOverdueTasks, setShowPhotoPins, setShowTaskPins } =
        FilterSettings

    return (
        <StyledDropdownMenu data-cy="filters-dropdown">
            <TitleGroup showReset={!isSetToDefault} onReset={onReset} filterTitle={filterTitle} />
            <ScrollArea maxHeight={'calc(100vh - 220px)'}>
                <FilterGroupForDateCreated filterSettings={filterSettings} setFilterSettings={setFilterSettings} />
                <FilterGroupForDateUpdated filterSettings={filterSettings} setFilterSettings={setFilterSettings} />
                {showPhotosGroup && (
                    <>
                        <OneRowGroup
                            title="Show Photo Pins"
                            checked={showPhotoPins}
                            setChecked={update(setShowPhotoPins)}
                        />
                        <OneRowGroup
                            title="Show Task Pins"
                            checked={showTaskPins}
                            setChecked={update(setShowTaskPins)}
                        />
                    </>
                )}
                <FilterGroupForStatus
                    allStatusShapes={allStatusShapes}
                    disabled={showTaskPins !== undefined && !showTaskPins}
                    filterSettings={filterSettings}
                    setFilterSettings={setFilterSettings}
                    css={groupStyles.status}
                    data-cy="filter-by-status"
                />
                <FilterGroupForAssignee
                    allParticipantShapes={allParticipantShapes}
                    filterSettings={filterSettings}
                    setFilterSettings={setFilterSettings}
                    disabled={showTaskPins !== undefined && !showTaskPins}
                    css={groupStyles.assignee}
                />
                <FilterGroupForCategory
                    allCategories={allCategories}
                    selectedCategories={selectedCategories}
                    filterSettings={filterSettings}
                    setFilterSettings={setFilterSettings}
                    disabled={showTaskPins !== undefined && !showTaskPins}
                    css={groupStyles.category}
                />
                <FilterGroupForFollower
                    allParticipantShapes={allParticipantShapes}
                    filterSettings={filterSettings}
                    setFilterSettings={setFilterSettings}
                    disabled={showTaskPins !== undefined && !showTaskPins}
                    css={groupStyles.follower}
                />
                {showCanvasGroup && (
                    <FilterGroupForCanvas
                        allCanvasShapes={allCanvasShapes}
                        selectedCanvasShapes={selectedCanvasShapes}
                        filterSettings={filterSettings}
                        setFilterSettings={setFilterSettings}
                        css={groupStyles.canvas}
                    />
                )}
                <FilterGroupForTag
                    allTagShapes={allTagShapes}
                    filterSettings={filterSettings}
                    selectedTagShapes={selectedTagShapes}
                    setFilterSettings={setFilterSettings}
                    untaggedOptionText={showTaskPins !== undefined ? 'untagged pins' : 'untagged tasks'}
                />
                <FilterGroupForIdentifier
                    allIdentifiers={allIdentifiers}
                    projectIdentifier={projectIdentifier}
                    filterSettings={filterSettings}
                    setFilterSettings={setFilterSettings}
                />
                <OneRowGroup
                    title={showTaskPins !== undefined ? "Only show Pins I've created" : "Only show Tasks I've created"}
                    checked={showOnlyCreatedByMe}
                    setChecked={update(setShowOnlyCreatedByMe)}
                    data-cy="filter-show-created-by-me"
                />
                <OneRowGroup
                    disabled={showTaskPins !== undefined && !showTaskPins}
                    title="Only show overdue Tasks"
                    checked={showOnlyOverdueTasks}
                    setChecked={update(setShowOnlyOverdueTasks)}
                    css={groupStyles.overdue}
                    data-cy="filter-show-overdue-tasks"
                />
                <OneRowGroup
                    title={showTaskPins !== undefined ? 'Show Archived Pins' : 'Show Archived Tasks'}
                    checked={showArchivedPins}
                    setChecked={update(setShowArchivedPins)}
                    data-cy="filter-show-archived"
                />
            </ScrollArea>
        </StyledDropdownMenu>
    )
}

/*
 * FilterSelector select from about a dozen different filters
 * @sig FilterSelector = ({ LookupTable, LookupTable, FilterSettings, F) -> React component
 *  F = FilterSettings -> ()
 */
const FilterSelector = ({
    allCanvasShapes,
    allCategories,
    allIdentifiers,
    allParticipantShapes,
    allStatusShapes,
    allTagShapes,
    isDataLoaded = true,
    projectIdentifier,
    variant,
    tooltipText,
    filterTitle,
    showCanvasGroup = false,
    showPhotosGroup = false,
    showFilterText = false,
    alignment = 'start',
    groupStyles = {},
    dataCy,

    filterSettings,
    setFilterSettings,
}) => {
    const isSetToDefault =
        !isDataLoaded || FilterSettings.isSetToDefaults(filterSettings, allStatusShapes, allParticipantShapes)

    return (
        <DropdownFilter
            tooltipText={tooltipText}
            isSetToDefault={isSetToDefault}
            disabled={!isDataLoaded}
            alignment={alignment}
            variant={variant}
            showFilterText={showFilterText}
            dataCy={dataCy}
        >
            <DropdownMenu
                allCanvasShapes={allCanvasShapes}
                allCategories={allCategories}
                allIdentifiers={allIdentifiers}
                allParticipantShapes={allParticipantShapes}
                allStatusShapes={allStatusShapes}
                allTagShapes={allTagShapes}
                isSetToDefault={isSetToDefault}
                projectIdentifier={projectIdentifier}
                filterTitle={filterTitle}
                showCanvasGroup={showCanvasGroup}
                showPhotosGroup={showPhotosGroup}
                groupStyles={groupStyles}
                filterSettings={filterSettings}
                setFilterSettings={setFilterSettings}
            />
        </DropdownFilter>
    )
}

const filterSettingsPropTypes = {
    matchUnassigned: PropTypes.bool,
    matchUntagged: PropTypes.bool,
    shouldFilterByAssignee: PropTypes.bool,
    shouldFilterByCanvas: PropTypes.bool,
    shouldFilterByTags: PropTypes.bool,
    showArchivedPins: PropTypes.bool.isRequired,
    showOnlyCreatedByMe: PropTypes.bool.isRequired,
    showOnlyOverdueTasks: PropTypes.bool.isRequired,
    showPhotoPins: PropTypes.bool,
    showTaskPins: PropTypes.bool,

    allUsers: PropTypes.arrayOf(PropTypes.shape(ParticipantShapePropTypes)),
    assignees: PropTypes.arrayOf(PropTypes.shape(ParticipantShapePropTypes)),
    selectedCanvasShapes: PropTypes.arrayOf(PropTypes.shape(CanvasShapePropTypes)),
    showStatuses: PropTypes.arrayOf(PropTypes.shape(StatusShapePropTypes)),
    tags: PropTypes.arrayOf(PropTypes.shape(TagShapePropTypes)),
}

FilterSelector.propTypes = {
    allParticipantShapes: PropTypes.arrayOf(PropTypes.shape(ParticipantShapePropTypes)).isRequired,
    allStatusShapes: PropTypes.arrayOf(PropTypes.shape(StatusShapePropTypes)).isRequired,
    allTagShapes: PropTypes.arrayOf(PropTypes.shape(TagShapePropTypes)).isRequired,
    filterSettings: PropTypes.shape(filterSettingsPropTypes).isRequired,
    setFilterSettings: PropTypes.func.isRequired,

    // Optional props
    allCanvasShapes: PropTypes.arrayOf(PropTypes.shape(CanvasShapePropTypes)),
    allCategories: PropTypes.array,
    allIdentifiers: PropTypes.array,
    isDataLoaded: PropTypes.bool,
    projectIdentifier: PropTypes.string,
    variant: PropTypes.string,
    tooltipText: PropTypes.string.isRequired,
    filterTitle: PropTypes.string.isRequired,
    showCanvasGroup: PropTypes.bool,
    showFilterText: PropTypes.bool,
    alignment: PropTypes.string,
    groupStyles: PropTypes.object,
    dataCy: PropTypes.string,
}

export default FilterSelector
