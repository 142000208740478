/*
 * SharedComponents provides a collection of common UI components used across the filter system.
 *
 * - Styled containers and layout components
 * - Common filter UI elements (buttons, indicators, etc.)
 * - Title and header components
 * - Row and group components
 *
 * These components ensure consistent styling and behavior across all filter-related
 * components while following the application's design system.
 */

import React from 'react'
import { Button, FlexColumn, FlexRow, Text } from '../../../components-reusable/index.js'
import Switch from '../../../components-reusable/Switch.js'
import { styled } from '../../../range-theme/index.js'

const StyleCanvasSelectorButton = styled('button', {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$neutral09',
    outline: 'none',
    border: '1px solid $neutral07',
    minHeight: 40,
    transitionDuration: '0.4s',
    color: '$neutral04',

    borderRadius: 6,
    position: 'relative',
    minWidth: 40,
    cursor: 'pointer',
    boxShadow: '0px 0px 0px #5D81FF50',

    '&:hover': {
        backgroundColor: '$primary02',
        borderColor: '$primary03',
        boxShadow: '6px 6px 2px #8B8F9D20',
        color: '$neutral04',
    },

    '&[disabled]': {
        cursor: 'not-allowed',
        pointerEvents: 'none',
        opacity: 0.5,
    },

    variants: {
        variant: {
            taskListFilterButton: {
                gap: 10,
                pl: 16,
                pr: 16,
                fs: 14,
                color: '$neutral05',
            },
        },
    },
})

const StyledFilterIndicator = styled(FlexRow, {
    br: 999,
    backgroundColor: '$primary04',
    minWidth: '8px',
    minHeight: '8px',
    maxHeight: '8px',
    maxWidth: '8px',
    top: 10,
    right: 8,
    position: 'absolute',

    variants: {
        variant: {
            taskListFilterButton: { left: 12 },
        },
    },
})

const StyledTitleGroup = styled(FlexRow, {
    w: 320,
    h: 56,
    m: 0,
    gap: 12,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
    background: '$neutral10',
    ai: 'center',
    br: '5px 5px 0px 0px',
})

const StyledDropdownMenu = styled(FlexColumn, {
    w: 320,
})

const Row = ({ title, checked, setChecked, disabled = false }) => (
    <FlexRow css={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Text css={{ color: disabled ? '$neutral06' : '$neutral04', fs: 14, fw: 500 }}>{title}</Text>
        <Switch variant="filter" checked={checked} onCheckedChange={setChecked} disabled={disabled} />
    </FlexRow>
)

const OneRowGroup = ({ title, checked, setChecked, disabled = false, css, 'data-cy': dataCy }) => {
    const elementCss = { w: 'auto', m: 0, gap: 12, p: 16, borderTop: '1px solid $neutral07', ...css }
    return (
        <FlexColumn css={elementCss} data-cy={dataCy}>
            <Row title={title} checked={checked} setChecked={setChecked} disabled={disabled} />
        </FlexColumn>
    )
}

const TitleGroup = ({ showReset, onReset, filterTitle }) => (
    <StyledTitleGroup>
        <Text css={{ color: '$neutral04', fs: 16, fw: 700, mr: 'auto' }}>{filterTitle}</Text>

        {showReset && (
            <Button variant="secondary" size="md" onClick={onReset}>
                Reset
            </Button>
        )}
    </StyledTitleGroup>
)

const StyledText = styled(Text, {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

export {
    OneRowGroup,
    Row,
    StyleCanvasSelectorButton,
    StyledDropdownMenu,
    StyledFilterIndicator,
    StyledText,
    StyledTitleGroup,
    TitleGroup,
}
