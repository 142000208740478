/*
 * Filter the CollaborationShapes that meet the navigatorFilterSettings. This is ALL the CollaborationShapes
 * that meet the filter criteria, not just the ones on the selected canvas.
 * @sig _filteredCollaborations :: State -> [CollaborationShape]
 * memoized as filteredCollaborations below
 */
import { arrayToLookupTable, memoizeReduxState } from '@range.io/functional'
import ReduxSelectors from './redux-selectors.js'

const _isDateInRange = (queryDate, dateRange) =>
    queryDate.getTime() >= dateRange[0].getTime() && queryDate.getTime() <= dateRange[1].getTime()

/*
 * Filter the MediaView items that meet the mediaViewFilterSettings. This is ALL the CollaborationShapes
 * that meet the filter criteria.
 * @sig _filteredMediaView :: State -> [CollaborationShape]
 * memoized as filteredMediaView below
 */
const _filteredMediaView = state => {
    const allCanvases = ReduxSelectors.canvasLookupTable(state)
    const allUploads = Object.values(ReduxSelectors.uploadLookupTable(state))
    const allCollaborations = ReduxSelectors.collaborationsAsObject(state)

    const mediaViewFilterSettings = state.mediaViewFilterSettings

    const { dateRange, canvasIds, tagIds, participantIds } = mediaViewFilterSettings

    const _getUploadCanvas = upload => {
        const parentCollaboration = allCollaborations[upload.parentId]
        const geometry = ReduxSelectors.geometryForCollaboration(state, parentCollaboration)
        return allCanvases[geometry.canvasId]
    }

    const filtered = allUploads.filter(upload => {
        const filteredByDate = !dateRange.length || _isDateInRange(upload.createdAt, dateRange)

        const filteredByCanvas = !canvasIds.length || canvasIds.includes(_getUploadCanvas(upload).id)

        const tagsCombined = [...upload.tagIds, ...allCollaborations[upload?.parentId]?.tags]

        const filteredByTags = !tagIds.length || tagIds.some(tagId => tagsCombined.includes(tagId))

        const filteredByAssignee = !participantIds.length || participantIds.includes(upload.createdBy)

        return filteredByDate && filteredByCanvas && filteredByTags && filteredByAssignee
    })

    return arrayToLookupTable('id', filtered)
}

/*
 * Memoize the _filteredMediaView function, recomputing only if any of the collections or filters has changed
 */
const filteredMediaView = memoizeReduxState(
    [
        'mediaViewFilterSettings',

        'canvases',
        'collaborations',
        'uploads', // we need this so when upload image URLs get updated this also gets refreshed
    ],
    _filteredMediaView
)

export { filteredMediaView }
