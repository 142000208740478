/*
 * FilterGroupForTag is a filter group component that allows users to filter content by tags.
 * It provides a multi-select interface for choosing tags, with support for:
 *
 * - Selecting multiple tags
 * - Filtering untagged items
 * - Custom untagged text based on context (pins vs tasks)
 *
 * The component integrates with the FilterSettings system and uses FilterGroupMultiSelect for its UI implementation.
 */

import { FilterSettings } from '@range.io/basic-types'
import { arrayToLookupTable, pluck, without } from '@range.io/functional'
import LookupTable from '@range.io/functional/src/lookup-table.js'
import React from 'react'
import { FlexRow } from '../../components-reusable/index.js'
import { TagShape } from '../../react-shapes/tag-shape.js'
import TagsDisplay from '../TagsDisplay.js'
import FilterGroupBaseGroup from './filter-primitives/FilterGroupBaseGroup.js'

const TagDetails = ({
    filter,
    allTagShapes,
    selectedTagShapes,
    filterSettings,
    setFilterSettings,
    untaggedOptionText,
}) => {
    const { matchUntagged } = filterSettings
    const selectedTagIds = pluck('id', selectedTagShapes)
    const allTagDatas = arrayToLookupTable('id', allTagShapes)
    const update = func => v => setFilterSettings(func(filterSettings, v))

    const handleTagFilterClick = tagIdClicked => {
        const clickedTagShape = allTagShapes.get(tagIdClicked)

        selectedTagShapes = selectedTagShapes.includes(clickedTagShape)
            ? without(clickedTagShape, selectedTagShapes)
            : [...selectedTagShapes, clickedTagShape]
        const lookupTable = LookupTable(
            selectedTagShapes.sort((a, b) => a.name.localeCompare(b.name)),
            TagShape
        )
        update(filter.setTagShapes)(lookupTable)
    }

    return (
        <FlexRow css={{ gap: 8, flexWrap: 'wrap', width: 290 }}>
            <TagsDisplay
                variant="tagFilter"
                tags={allTagDatas}
                selectedTagIds={selectedTagIds}
                onItemSelectionChange={handleTagFilterClick}
                matchUntagged={matchUntagged}
                setMatchUntagged={update(filter.toggleMatchUntagged)}
                untaggedOptionText={untaggedOptionText}
            />
        </FlexRow>
    )
}

const FilterGroupForTag = ({
    allTagShapes,
    selectedTagShapes,
    untaggedOptionText,
    filterSettings,
    setFilterSettings,
}) => {
    const updateFilterSettings = f => v => setFilterSettings(f(filterSettings, v))
    const { shouldFilterByTags } = filterSettings

    return (
        <FilterGroupBaseGroup
            title="Filter by Tags"
            checked={shouldFilterByTags}
            onToggle={updateFilterSettings(FilterSettings.setShouldFilterByTags)}
            width={320}
            dataCy="filter-tag-group"
            fullPadding
        >
            <TagDetails
                filter={FilterSettings}
                allTagShapes={allTagShapes}
                selectedTagShapes={selectedTagShapes}
                filterSettings={filterSettings}
                setFilterSettings={setFilterSettings}
                untaggedOptionText={untaggedOptionText}
            />
        </FilterGroupBaseGroup>
    )
}

export default FilterGroupForTag
