import { pluck } from '@range.io/functional'
import { differenceInCalendarDays } from 'date-fns'

// ---------------------------------------------------------------------------------------------------------------------
// Helpers
// ---------------------------------------------------------------------------------------------------------------------

const uniq = items => [...new Set(items)] // convert to Set and back to Array

// @sig filterByDayRange :: Number -> Date -> Boolean
const filterByDayRange = maxDayDifference => date => {
    const daysDiff = differenceInCalendarDays(new Date(), date)
    return daysDiff <= maxDayDifference
}

// ---------------------------------------------------------------------------------------------------------------------
// Filters
// ---------------------------------------------------------------------------------------------------------------------

// Does this Collaboration have ANY of the selected assignees?
// @sig filterByAssignee :: FilterSettings -> CollaborationShape -> Boolean
const filterByAssignee = filterSettings => collaborationShape => {
    if (!filterSettings.shouldFilterByAssignee) return true
    if (filterSettings.matchUnassigned) return collaborationShape.assignee === undefined // matchUnassigned inverts the logic
    if (filterSettings.assignees.length === 0) return true // the user hasn't yet selected any assignees to filter on: all pass
    return filterSettings.assignees.some(a => a.id === collaborationShape.assignee?.id) // has ANY required assignee
}

// Does this Collaboration have ANY of the selected categories?
// @sig filterByCategory :: FilterSettings -> CollaborationShape -> Boolean
const filterByCategory = filterSettings => collaborationShape =>
    !filterSettings.shouldFilterByCategory || filterSettings.categories.length === 0
        ? true
        : filterSettings.categories[collaborationShape.categoryId] // collaboration includes any of the selected categories

// Does this Collaboration have ALL selected tags?
// @sig filterByTags :: FilterSettings -> CollaborationShape -> Boolean
const filterByTags = filterSettings => collaborationShape => {
    if (!filterSettings.shouldFilterByTags) return true

    const uploadTags = collaborationShape.uploads.flatMap(u => u.tags)
    const collaborationPlusUploadTags = uniq(collaborationShape.tags.concat(uploadTags))

    if (filterSettings.matchUntagged) return collaborationPlusUploadTags.length === 0 // matchUntagged inverts the logic
    if (filterSettings.tags.length === 0) return true // the user hasn't yet selected any tags to filter on: all pass

    // use the tag ids, not the tags to compare to avoid issues with out-of-date TagShapes
    // collaboration or some upload has ANY of required tags
    const collaborationPlusUploadTagIds = new Set(pluck('id', collaborationPlusUploadTags))
    const userSelectedTagIds = new Set(pluck('id', filterSettings.tags))
    return userSelectedTagIds.intersection(collaborationPlusUploadTagIds).size > 0
}

// either it's not a task, or it matches one of the statuses selected by the user
// @sig filterStatuses :: FilterSettings -> CollaborationShape -> Boolean
const filterStatuses = filterSettings => collaborationShape =>
    filterSettings.showStatuses
        ? !collaborationShape.status || filterSettings.showStatuses.some(s => s.id === collaborationShape.status.id)
        : true

// Does this Collaboration is followed by ANY of the selected followers?
// @sig filterByFollower :: FilterSettings -> CollaborationShape -> Boolean
const filterByFollower = filterSettings => collaborationShape => {
    if (!filterSettings.shouldFilterByFollower) return true
    if (filterSettings.followers.length === 0) return true // the user hasn't yet selected any followers to filter on: all pass

    return filterSettings.followers.some(a => collaborationShape.followers.includes(a.id)) // has ANY required follower
}

// @sig isCreatedByMe :: String -> CollaborationShape -> Boolean
const isCreatedByMe = selectedUserId => collaborationShape => collaborationShape.createdBy.id === selectedUserId

// @sig isArchived :: FilterSettings -> CollaborationShape -> Boolean
const isArchived = filterSettings => collaborationShape =>
    filterSettings.showArchivedPins || !collaborationShape.isArchived

// @sig isOverdue :: CollaborationShape -> Boolean
const isOverdue = collaborationShape => {
    if (!collaborationShape.dueDate) return false

    const now = new Date().toISOString()
    return new Date(collaborationShape.dueDate).toISOString() < now // show only tasks that are after due date
}

// @sig matchesAnyIdentifier :: [String] -> String -> Boolean
const matchesAnyIdentifier = selectedIdentifiers => identifier =>
    selectedIdentifiers.some(selectedIdentifier => selectedIdentifier === identifier)

// @sig filterByCreatedDate :: FilterSettings -> CollaborationShape -> Boolean
const filterByCreatedDate = filterSettings => collaborationShape =>
    filterSettings.shouldFilterByDateCreated && filterSettings.dateCreatedDayRange
        ? filterByDayRange(filterSettings.dateCreatedDayRange)(collaborationShape.createdAt)
        : true

// @sig filterByUpdateDate :: FilterSettings -> CollaborationShape -> Boolean
const filterByUpdateDate = filterSettings => collaborationShape =>
    filterSettings.shouldFilterByDateUpdated && filterSettings.dateUpdatedDayRange
        ? filterByDayRange(filterSettings.dateUpdatedDayRange)(collaborationShape.lastUpdateDate)
        : true

// @sig filterByIdentifier :: FilterSettings -> CollaborationShape -> Boolean
const filterByIdentifier = filterSettings => collaborationShape =>
    filterSettings.shouldFilterByIdentifiers && filterSettings.selectedIdentifiers.length
        ? matchesAnyIdentifier(filterSettings.selectedIdentifiers)(collaborationShape.identifier)
        : true

// @sig filterByCreatedByMe :: FilterSettings -> String -> CollaborationShape -> Boolean
const filterByCreatedByMe = (filterSettings, selectedUserId) => collaborationShape =>
    filterSettings.showOnlyCreatedByMe ? isCreatedByMe(selectedUserId)(collaborationShape) : true

// @sig filterByOverdue :: FilterSettings -> CollaborationShape -> Boolean
const filterByOverdue = filterSettings => collaborationShape =>
    filterSettings.showOnlyOverdueTasks ? isOverdue(collaborationShape) : true

/*
 * Apply common filtering logic to a list of collaboration shapes based on filter settings
 * @sig filterCollaborations :: ([CollaborationShape], FilterSettings, String) -> [CollaborationShape]
 */
const filterCollaborations = (collaborationShapes, filterSettings, selectedUserId) => {
    let result = collaborationShapes

    result = result.filter(isArchived(filterSettings))
    result = result.filter(filterByAssignee(filterSettings))
    result = result.filter(filterByCategory(filterSettings))
    result = result.filter(filterByCreatedDate(filterSettings))
    result = result.filter(filterByUpdateDate(filterSettings))
    result = result.filter(filterByFollower(filterSettings))
    result = result.filter(filterByIdentifier(filterSettings))
    result = result.filter(filterByTags(filterSettings))
    result = result.filter(filterByCreatedByMe(filterSettings, selectedUserId))
    result = result.filter(filterByOverdue(filterSettings))
    result = result.filter(filterStatuses(filterSettings))

    return result
}

export default filterCollaborations
