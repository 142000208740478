import * as ScrollArea from '@radix-ui/react-scroll-area'
import React from 'react'
import { styled } from '../../range-theme/index.js'
import IconPickerButton from './IconPickerButton.jsx'

const StyledScrollRoot = styled(ScrollArea.Root, {
    width: '100%',
    height: 'calc(100% - 40px)',
    overflow: 'hidden',
})

const StyledScrollViewport = styled(ScrollArea.Viewport, {
    width: '100%',
    height: '100%',
    '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
        display: 'none',
    },
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    msOverflowStyle: 'none',
})

const StyledGrid = styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gap: 4,
    paddingTop: 8,
    paddingBottom: 4,
    alignItems: 'center',
    justifyItems: 'center',
})

const StyledScrollbar = styled(ScrollArea.Scrollbar, {
    display: 'none',
})

const StyledThumb = styled(ScrollArea.Thumb, {
    display: 'none',
})

const IconPickerGrid = ({ icons, selectedIcon, onSelectIcon }) => (
    <StyledScrollRoot>
        <StyledScrollViewport>
            <StyledGrid>
                {icons.map(({ name, icon }) => (
                    <IconPickerButton
                        key={name}
                        svgIcon={icon}
                        isSelected={name === selectedIcon}
                        onClick={() => onSelectIcon(name)}
                    />
                ))}
            </StyledGrid>
        </StyledScrollViewport>
        <StyledScrollbar orientation="vertical">
            <StyledThumb />
        </StyledScrollbar>
    </StyledScrollRoot>
)

export default IconPickerGrid
