/*
 * See Readme
 */

import { Participant } from '@range.io/basic-types'
import StringTypes from '@range.io/basic-types/src/string-types.js'
import { pluck, taggedSum } from '@range.io/functional'
import { ReduxActions, ReduxSelectors } from '../../redux/index.js'
import { UserRoles } from '../../redux/redux-selectors-permissions.js'
import { existsDocFromFirestore } from '../firebase-facade.js'
import CommandPlayer from './command-player.js'
import { changeOrganizationRole } from './https-calls.js'

// ---------------------------------------------------------------------------------------------------------------------
// ParticipantChangedCommand
// ---------------------------------------------------------------------------------------------------------------------
const OrganizationParticipantChangedCommand = taggedSum('OrganizationParticipantChangedCommand', {
    Inbound: { participant: 'Participant' },
    Outbound: { organizationId: StringTypes.Id, participant: 'Participant', projectIds: StringTypes.OptionalIds },
})

// ---------------------------------------------------------------------------------------------------------------------
// Handle commands
// ---------------------------------------------------------------------------------------------------------------------

/*
 * A ParticipantChangedCommand.Inbound has arrived from Firestore; send it to redux
 * (since the WHOLE document is returned, we want to add and not change the participant)
 */
const runInboundCommand = async (resources, command) => {
    const { dispatch, userId, getState, organizationId } = resources

    const newParticipant = command.participant
    const participantId = newParticipant.id
    const oldParticipant = ReduxSelectors.organizationParticipantWithId(getState(), participantId)
    const changeIsForCurrentUser = participantId === userId
    const roleHasChanged = oldParticipant.organizationRole !== newParticipant.organizationRole
    const currentUsersRoleHasChanged = changeIsForCurrentUser && roleHasChanged

    // navigate is not available here, since we're in a command
    if (currentUsersRoleHasChanged) {
        dispatch(
            ReduxActions.globalModalDataSet({
                title: `Your role changed to ${newParticipant.organizationRole}`,
                description: `Your project access permissions may have changed. We'll return you to the Projects page momentarily.`,
                cancelButton: {
                    label: 'Ok',
                    onClick: () => (window.location.href = `/${organizationId}/projects`),
                },
            })
        )
    }

    dispatch(ReduxActions.organizationParticipantChanged(newParticipant))

    /*
     * If the participant was modified, we might have to update the participant data stored in Redux to keep
     * the project count correct. If they were downgraded to guest they may now be suspended from some projects,
     * or if they were upgraded to Admin, they will no longer be suspended from any project
     *
     * To find out, we check (for each project) if the user is now in the participants subcollection and
     * update their data in redux
     */
    if (roleHasChanged) {
        const participantExistsInProject = id => existsDocFromFirestore(`projects/${id}/participants/${participantId}`)
        const suspendedParticipantExistsInProject = id =>
            existsDocFromFirestore(`projects/${id}/suspendedParticipants/${participantId}`)

        const projects = ReduxSelectors.organizationProjectAsArray(getState())
        const projectIds = pluck('id', projects)

        const participantExists = await Promise.all(projectIds.map(participantExistsInProject))
        const suspendedParticipantExists = await Promise.all(projectIds.map(suspendedParticipantExistsInProject))

        const unsuspended = projectIds.filter((_, index) => participantExists[index])
        const suspended = projectIds.filter((_, index) => suspendedParticipantExists[index])

        dispatch(ReduxActions.updateParticipantProjects({ participant: newParticipant, unsuspended, suspended }))
    }
}

/*
 * Change the Organization.participant's organizationRole
 */
const runOutboundCommand = async (resources, command) => {
    const { dispatch, displayError } = resources
    const { organizationId, participant, projectIds } = command

    try {
        await changeOrganizationRole(organizationId, participant.id, participant.organizationRole, projectIds)

        if (command.participant.organizationRole === UserRoles.ADMIN)
            dispatch(ReduxActions.addParticipantToEachProject(participant))
    } catch (e) {
        displayError(e)
    }
}

const addOrganizationParticipantChangedCommandSingleton = (addCommandToHistory, registerCommandPlayer) => {
    registerCommandPlayer(
        OrganizationParticipantChangedCommand,
        CommandPlayer({
            CommandType: OrganizationParticipantChangedCommand,
            Type: Participant,
            collectionPath: organizationId => `/organizations/${organizationId}/participants/`,
            runInboundCommand,
            runOutboundCommand,
            addCommandToHistory,
            changeType: 'modified',
            resourceKey: 'organizationId',
        })
    )
}

export { OrganizationParticipantChangedCommand, addOrganizationParticipantChangedCommandSingleton }
