/*
 * IdleMode
 *
 * Map mode to handle state where no geometry is selected; default state of the Draw
 *
 * Type IdleModeState : {}
 */

import MapboxDraw from '@mapbox/mapbox-gl-draw'
import * as F from '@range.io/functional'
import { initHoverState } from './hoverStateHandler.js'

const MapboxDrawLib = MapboxDraw.lib

const { CommonSelectors } = MapboxDrawLib

const IdleMode = { ...MapboxDraw.modes.simple_select }

/*
 * Called just before entering idle mode
 * make sure all previously selected geometries get deselected
 * @sig onSetup :: {} -> IdleModeState
 */
IdleMode.onSetup = function () {
    this.deselect(F.pluck('id', this.getSelected()))
    return { hoverState: initHoverState({ doRender: id => this.doRender(id), getFeature: id => this.getFeature(id) }) }
}

/*
 * @sig onClick :: (IdleModeState, Event) -> ()
 * @sig onTap :: (IdleModeState, Event) -> ()
 */
IdleMode.onClick = IdleMode.onTap = function (state, e) {
    if (CommonSelectors.isFeature(e)) return this.clickOnFeature(state, e)
}

/*
 * @sig clickOnFeature :: (IdleModeState, Event) -> ()
 */
IdleMode.clickOnFeature = function (state, e) {
    const featureId = e.featureTarget.properties.user_parentFeatureId || e.featureTarget.properties.id
    this.changeMode('select', { featureId })
}

/*
 * @sig onMouseMove :: (IdleModeState, Event) -> ()
 */
IdleMode.onMouseMove = function (state, e) {
    state.hoverState.handleMouseMove(e)
}

/*
 * @sig toDisplayFeatures (IdleModeState, GeoJsonFeature, DisplayFunc) -> ()
 * DisplayFunc = GeoJsonFeature -> ()
 */
IdleMode.toDisplayFeatures = function (state, geojson, display) {
    geojson.properties.user_hover = state.hoverState.isGeoJsonHovered(geojson)
    display(geojson)
}

export default IdleMode
