import React from 'react'
import PropTypes from 'prop-types'

import { FlexRow, Tooltip } from '../components-reusable/index.js'
import CollaborationWindowBadge from './CollaborationWindowBadge'

const StatusDot = ({ isArchived, isCompleted, isTask, statusColor, statusLabel, count, size = 20, category }) => {
    const badgeProps = { isArchived, isCompleted, isTask, size, count, color: statusColor, category }
    if (!statusLabel) return <CollaborationWindowBadge {...badgeProps} />

    return (
        <Tooltip tooltipText={statusLabel} side="bottom" align="center">
            <FlexRow
                css={{
                    width: size,
                    height: size,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                data-cy="status-dot"
            >
                <CollaborationWindowBadge {...badgeProps} />
            </FlexRow>
        </Tooltip>
    )
}

StatusDot.propTypes = {
    isArchived: PropTypes.bool,
    isCompleted: PropTypes.bool,
    isTask: PropTypes.bool,
    hasNotesPending: PropTypes.bool, // assumed false is missing
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    statusColor: PropTypes.string,
    statusLabel: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default StatusDot
