/*
 * Category
 */
import { pick, tagged } from '@range.io/functional'
import StringTypes from '../string-types.js'

// ---------------------------------------------------------------------------------------------------------------------
// Definitions
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const Category = tagged('Category', {
    id       : StringTypes.Id,
    
    name     : 'String',
    icon     : 'String', // Data URI
    order    : 'Number',
    isDefault: 'Boolean',  // is this the default Category
})

// ---------------------------------------------------------------------------------------------------------------------
// Serialization
// ---------------------------------------------------------------------------------------------------------------------
Category.fromFirebase = Category.from
Category.toFirebase = category => pick(['id', 'name', 'icon', 'isDefault', 'order'], category)

export default Category
