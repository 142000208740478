/*
 * FilterGroupForAssignee is a filter group component for selecting multiple assignees.
 *
 * - Selecting multiple assignees
 * - Filtering unassigned items
 * - Disabled state when task pins are not shown
 * - Custom styling through CSS props
 *
 * The component integrates with the FilterSettings system and uses FilterGroupMultiSelect
 * for its UI implementation, with special handling for unassigned items.
 */

import { FilterSettings } from '@range.io/basic-types'
import React from 'react'
import { Flex, FlexRow, Icon } from '../../components-reusable/index.js'
import PossiblySuspendedUserName from '../../components-reusable/PossiblySuspendedUserName.js'
import { ParticipantShape } from '../../react-shapes/participant-shape.js'
import Pill from '../Pill.js'
import UserRow from '../UserRow.js'
import FilterGroupMultiSelect from './filter-primitives/FilterGroupMultiSelect.js'
import FilterGroupTextInput from './filter-primitives/FilterGroupTextInput.js'

const FilterGroupForAssignee = ({ allParticipantShapes, filterSettings, setFilterSettings, disabled = false, css }) => {
    const updateFilterSettings = f => v => setFilterSettings(f(filterSettings, v))
    const phraseMatches = (participantShape, phrase) => ParticipantShape.matchesFullName(participantShape, phrase)

    const UnassignedUserIcon = () => (
        <Flex
            css={{
                br: 999,
                b: '1px solid $neutral07',
                mr: 8,
                width: 26,
                height: 26,
                minWidth: 26,
                minHeight: 26,
                ai: 'center',
                background: '$neutral08',
            }}
        >
            <Icon iconSize="18" name="unassignedUser" />
        </Flex>
    )

    const renderInput = (inputWrapperRef, showList, setShowList, phraseToFilter, setPhraseToFilter) => (
        <FilterGroupTextInput
            inputRef={inputRef}
            inputWrapperRef={inputWrapperRef}
            showList={showList}
            setShowList={setShowList}
            phraseToFilter={phraseToFilter}
            setPhraseToFilter={setPhraseToFilter}
            iconName="user"
            dataCy="filter-by-assignee"
        />
    )

    const renderUnassigned = participantShape => (
        <FlexRow css={{ ai: 'center' }}>
            <UnassignedUserIcon />
            <PossiblySuspendedUserName participantShape={participantShape} />
        </FlexRow>
    )

    const renderUser = participantShape => <UserRow participantShape={participantShape} />

    const renderUserItem = participantShape =>
        participantShape === FilterSettings.unassignedTasksShape
            ? renderUnassigned(participantShape)
            : renderUser(participantShape)

    const renderPill = participantShape => {
        const onDeselect = () => updateFilterSettings(FilterSettings.unselectAssignee)(participantShape)
        return (
            <Pill key={participantShape.id} onDeselect={onDeselect} tooltipText="Remove User">
                <PossiblySuspendedUserName
                    participantShape={participantShape}
                    ellipsed={true}
                    css={{ maxWidth: 140, limitLinesOfTextTo: 1 }}
                />
            </Pill>
        )
    }

    const inputRef = React.useRef(null)
    const { shouldFilterByAssignee, assignees } = filterSettings
    const participantsPlusUnassigned = [FilterSettings.unassignedTasksShape, ...allParticipantShapes]

    return (
        <FilterGroupMultiSelect
            title="Filter by Assignee"
            checked={shouldFilterByAssignee}
            disabled={disabled}
            onToggle={updateFilterSettings(FilterSettings.setShouldFilterByAssignee)}
            items={participantsPlusUnassigned}
            selectedItems={assignees}
            onItemSelect={updateFilterSettings(FilterSettings.toggleAssignee)}
            onItemRemove={updateFilterSettings(FilterSettings.unselectAssignee)}
            filterItem={phraseMatches}
            renderItem={renderUserItem}
            renderPill={renderPill}
            renderInput={renderInput}
            css={css}
            dataCy="filter-assignee"
        />
    )
}

export default FilterGroupForAssignee
