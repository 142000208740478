import { KnockProvider } from '@knocklabs/react'
import { TooltipProvider } from '@radix-ui/react-tooltip'
import { REGEX } from '@range.io/basic-types/src/string-types.js'
import { assoc } from '@range.io/functional'
import { logEvent, setUserId } from 'firebase/analytics'
import * as FirebaseApp from 'firebase/app'
import { applyActionCode, getIdToken, getIdTokenResult } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useDispatch, useSelector, useStore } from 'react-redux'
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    useNavigate,
    useParams,
} from 'react-router-dom'
import useLocalStorage from '../components-reusable/hooks/useLocalStorage.js'
import { Box, Toast, ToastList } from '../components-reusable/index.js'
import { attemptSignOut } from '../firebase/authentication-helpers.js'
import * as Commands2 from '../firebase/commands-2/index.js'
import { EntireProjectLoadedCommand, InitialDataLoadedCommand } from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { app, KNOCK_PUBLIC_API_KEY } from '../firebase/configure-environment/config-local.js'
import { analytics, auth } from '../firebase/configure-environment/config.js'
import envFromWindowLocation from '../firebase/configure-environment/env-from-window-location.js'
import { updateKnockToken } from '../knock/knock.js'
import RangeUITheme from '../range-theme/range-ui-theme.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import { LoggedInStatus } from '../redux/redux-actions.js'
import * as Segment from '../segment/segment.js'
import AdminViewAccountSettings from './AdminViewAccountSettings.js'
import AdminViewMembers from './AdminViewMembers.js'
import AdminViewOrganizationIsDisabled from './AdminViewOrganizationIsDisabled.js'
import AdminViewProjects from './AdminViewProjects.js'
import AdminViewWorkspaceSettings from './AdminViewWorkspaceSettings.js'
import './App.css'
import BillingError from './BillingError.js'
import CanvasView from './CanvasView.js'
import CategoryManager from './CategoryManager.js'
import GlobalModal from './GlobalModal.js'
import ListView from './ListView.js'
import LoginPage from './LoginPage.js'
import MaintenanceMode from './MaintenanceMode.js'
import MediaDetailsView from './MediaDetailsView.js'
import MediaView from './MediaView.js'
import NotFound from './NotFound.js'
import PageLoading from './PageLoading.js'
import EditProjectPage from './pages/EditProjectPage.js'
import NewProjectWizard from './pages/NewProjectWizard.js'
import ProjectCanvasSources from './ProjectCanvasSources.js'
import { RegistrationAcceptInvitations } from './RegistrationAcceptInvitations.js'
import { CreateAccount } from './RegistrationCreateAcccount.js'
import { InvitedToOrgWrongAccount, VerifyEmailModal } from './RegistrationModals.js'
import { NewOrganization } from './RegistrationNewOrganization.js'
import { NewPassword, ResetPassword, ResetPasswordConfirmation } from './RegistrationResetPassword.js'
import { SignUp } from './RegistrationSignUp.js'
import StatusManager from './StatusManager.js'
import TagManager from './TagManager.js'
import URLController, { checkUrlForCookies } from './URLController.js'

// ---------------------------------------------------------------------------------------------------------------------
// App
// ---------------------------------------------------------------------------------------------------------------------

const retrieveClaims = async currentUser => {
    // Force the idToken to refresh; otherwise there are times when the claims will not properly reflect the state
    // on the server. In particular, emailVerified was not being properly updated with updating
    await getIdToken(currentUser, /* forceRefresh */ true)
    const token = await getIdTokenResult(currentUser)
    return token.claims
}

// For development users render error message if something goes wrong.
// For other users render NotFound screen
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        const { env } = envFromWindowLocation()
        this.state = { error: null, errorInfo: null, env }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo })
    }

    renderError() {
        const lines = this.state.errorInfo.componentStack.split(' at ')
        return (
            <Box css={{ margin: '60px' }}>
                <h2>Something went wrong.</h2>
                {this.state.error && this.state.error.toString()}
                <br />
                <br />
                {lines.map((l, i) => (
                    <div key={i}>at {l}</div>
                ))}
            </Box>
        )
    }

    clearError() {
        this.setState({ error: null, errorInfo: null }) // we need to ensure errors are cleared
    }

    render() {
        if (this.state.errorInfo) {
            if (this.state.env === 'development') return this.renderError() // render the error if we are on the development environment

            // on other envs render the NotFound page
            return <NotFound beforeNavigate={this.clearError.bind(this)} />
        }

        return this.props.children
    }
}

// Possibly show a modal dialog box from data in Redux
const PossibleGlobalModal = ({ children }) => {
    const data = useSelector(ReduxSelectors.globalModalData)

    return (
        <>
            {data && Object.keys(data).length > 0 && <GlobalModal {...data} />}
            {children}
        </>
    )
}

const App = () => {
    const userLoggedOut = () => {
        userResourcesRemoved()
        analytics && logEvent(analytics, 'logout')
        dispatch(ReduxActions.userLogOut())

        Segment.reset()
    }

    const userLoggedIn = async () => {
        try {
            dispatch(ReduxActions.userLogIn())
            const claims = await retrieveClaims(auth.currentUser)
            const userId = claims.userId
            resourceChanged('userId', userId)
            const { user } = await runCommand(InitialDataLoadedCommand.Outbound(userId))
            analytics && logEvent(analytics, 'login', { method: 'email' })
            analytics && setUserId(analytics, userId)

            if (!userNeedsToCreateAccount(user)) await Segment.sendIdentify(user)
        } catch (e) {
            // invalid-refresh-token means Firebase thinks the user has logged out, but Redux doesn't
            if (e.code === 'auth/invalid-refresh-token') dispatch(ReduxActions.userLogOut())
            else console.error(e)
        }
    }

    const userAwaitingEmailVerification = () => {
        dispatch(ReduxActions.userAwaitingEmailVerification())
    }

    const { runCommand } = useCommandHistory()
    const [user, loading] = useAuthState(auth)
    const { resourceChanged, userResourcesRemoved } = useCommandHistory()
    const [colorTheme] = useLocalStorage('rangeTheme', 'system')
    const selectedCanvasId = useSelector(ReduxSelectors.selectedCanvasId)
    const selectedProjectId = useSelector(ReduxSelectors.selectedProjectId)
    const [projectToCanvas, setProjectToCanvas] = useLocalStorage('projectToCanvas', {})

    useEffect(() => {
        if (loading) return undefined
        if (user?.emailVerified) userLoggedIn()
        else if (user) userAwaitingEmailVerification()
        else userLoggedOut()
    }, [user, loading])

    useEffect(() => {
        RangeUITheme.changeColorMode(colorTheme)
        checkUrlForCookies()
    }, [])

    // stop Firebase from using an old "app" on reload
    useEffect(() => {
        const unloadCallback = async () => await FirebaseApp.deleteApp(app)
        window.addEventListener('beforeunload', unloadCallback)
        return () => window.removeEventListener('beforeunload', unloadCallback)
    }, [])

    // store the current canvas for this project in local storage whenever it changes
    useEffect(() => {
        if (!selectedCanvasId) return
        setProjectToCanvas(assoc(selectedProjectId, selectedCanvasId, projectToCanvas))
    }, [selectedCanvasId])

    const store = useStore()
    const { dispatch } = store

    const isLoginStatusKnown = useSelector(ReduxSelectors.isLoginStatusKnown)
    if (!isLoginStatusKnown) return <PageLoading />

    return <RouterProvider router={router} />
}

const uuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

const RootRoute = () => {
    /*
     * A tricky business: the pathname MAY still include the organization for the just-logged-out user
     * in which case we DON'T want to use it as "redirectedFrom"
     */
    const hasObsoleteOrganizationInPathname = () => {
        const pathname = window.location.pathname
        const pathSegments = pathname.split('/')
        const possibleOrgId = pathSegments[1]
        const organization = organizations[possibleOrgId]

        if (!possibleOrgId) return false // no segment 1 at all
        if (!possibleOrgId.match(uuid)) return false // segment 1 is not a uuid
        return !organization // there is no organization for the current user that matches the id
    }

    // get a new token from our server and stow it
    const _updateKnockUserToken = async () => {
        const token = await updateKnockToken()
        if (token) dispatch(ReduxActions.knockUserTokenChanged(token))
        return token
    }

    const dispatch = useDispatch()
    const toastLookupTable = useSelector(ReduxSelectors.toastLookupTable)
    const isAllowed = useSelector(ReduxSelectors.isLoggedIn)
    const organizations = useSelector(ReduxSelectors.organizationLookupTable)
    const userToken = useSelector(ReduxSelectors.knockUserToken)
    const userId = useSelector(ReduxSelectors.selectedUserId)
    const { resourceChanged } = useCommandHistory()
    const isRangeClosed = useSelector(ReduxSelectors.isRangeClosed)

    useEffect(() => {
        // TODO: this could be handled better, without requiring this artificial call
        resourceChanged('RangeStatus', {}) // required to attach Firebase listeners for RangeStatus changes
    }, [])

    // get an initial Knock token (to be updated periodically via onUserTokenExpiring below)
    useEffect(() => {
        const p = async () => await _updateKnockUserToken()
        p()
    }, [isRangeClosed])

    if (!isAllowed) {
        const pathname = window.location.pathname
        const redirectedFrom = hasObsoleteOrganizationInPathname() ? '/' : pathname + window.location.search

        return <Navigate to={'/login'} replace={true} state={{ redirectedFrom }} />
    }
    return (
        <ErrorBoundary>
            {isRangeClosed ? (
                <MaintenanceMode />
            ) : (
                <KnockProvider
                    apiKey={KNOCK_PUBLIC_API_KEY}
                    userId={userId}
                    userToken={userToken}
                    onUserTokenExpiring={_updateKnockUserToken}
                >
                    <PossibleGlobalModal>
                        <TooltipProvider>
                            <Toast.Provider>
                                <URLController />
                                <Outlet />
                                <ToastList toastLookupTable={toastLookupTable} />
                                <Toast.Viewport />
                            </Toast.Provider>
                        </TooltipProvider>
                    </PossibleGlobalModal>
                </KnockProvider>
            )}
        </ErrorBoundary>
    )
}

// We set the user's firstName to be their email when we created their Range User, and they need to fix that
const userNeedsToCreateAccount = user => user.firstName === user.email

// show the list of workspaces
const WorkspaceRootRoute = () => {
    const allOrganizations = useSelector(ReduxSelectors.organizationsAsArray)
    const user = useSelector(ReduxSelectors.selectedUser)
    const [lastSelectedOrganizationId] = useLocalStorage('lastSelectedOrganizationId')

    // haven't finished loadUserRelatedDataIntoRedux yet...
    if (!user) return <PageLoading />

    if (userNeedsToCreateAccount(user)) return <CreateAccount user={user} />
    if (user.invitations) return <Navigate to={'/acceptInvitations'} replace={true} />
    if (allOrganizations.length === 0) return <NewOrganization includeLogOut={true} />
    // try to select the recently selected organization instead of the first one from the list
    if (lastSelectedOrganizationId && !!allOrganizations.find(o => o.id === lastSelectedOrganizationId)) {
        return <Navigate to={`/${lastSelectedOrganizationId}`} replace={true} />
    }
    return <Navigate to={`/${allOrganizations[0].id}`} replace={true} />
}

// show a specific workspace
const WorkspaceRoute = () => {
    const { workspaceId } = useParams()
    const { resourceChanged } = useCommandHistory()
    const organizations = useSelector(ReduxSelectors.organizationLookupTable)
    const user = useSelector(ReduxSelectors.selectedUser)
    const navigate = useNavigate()
    const { dispatch, getState } = useStore()
    const [, setLastSelectedOrganizationId] = useLocalStorage('lastSelectedOrganizationId')

    const selectedOrganization = useSelector(ReduxSelectors.selectedOrganization)
    const userHasOrganizations = user => Boolean(user.permissions.organizations?.length)

    useEffect(() => {
        if (!organizations || Object.keys(organizations).length === 0) return // organizations not loaded yet; wait
        if (selectedOrganization === workspaceId) return // no change
        if (!organizations[workspaceId]) return navigate('/') // no such organization for this user; abort

        const shouldShowAccountCreatedToast = ReduxSelectors.showAccountCreatedToast(getState())
        if (shouldShowAccountCreatedToast) {
            dispatch(
                ReduxActions.toastAdded({
                    id: workspaceId,
                    toastLabel: 'Your Range account has been successfully created',
                    severity: 'success',
                    showUndo: false,
                })
            )
            dispatch(ReduxActions.setShowAccountCreatedToast(false))
        }

        // set the current organization
        resourceChanged('organizationId', workspaceId)
        setLastSelectedOrganizationId(workspaceId)
        Commands2.selectedOrganizationChanged(workspaceId)
    }, [workspaceId, organizations])

    useEffect(() => {
        // If user state has changed and he has already registered,
        // check if he actually can access any organization (i.e. he was suspended),
        // and if not then navigate to root.
        if (user && !userNeedsToCreateAccount(user) && !userHasOrganizations(user)) {
            return navigate('/')
        }
    }, [user])

    if (selectedOrganization?.isDisabled) return <AdminViewOrganizationIsDisabled />
    if (selectedOrganization?.billingError?.gracePeriodEndsTimestamp < new Date())
        return <AdminViewOrganizationIsDisabled type="billingError" />
    if (selectedOrganization) return <Outlet />
    return <PageLoading />
}

const OrganizationLevelRoute = () => {
    return (
        <>
            <BillingError />
            <Outlet />
        </>
    )
}

/*
 * Show a Project; might reroute back to Project page if necessary
 *
 * Note for the future:
 *
 * We WANT to loadEntireProject when a Project is mounted and dispatch projectUnloaded when it is unmounted.
 * This would simplify the logic that is currently scattered here and in AdminViewProjects.
 *
 * Unfortunately, starting in React 18, in strict mode -- and in development mode only -- each component is
 * mounted, unmounted and mounted again
 *
 * We could "fix" this by implementing useEffectOnce: https://blog.ag-grid.com/avoiding-react-18-double-mount/
 */
const ProjectRoute = () => {
    const projectChanged = async newProject => {
        resourceRemoved('projectId')
        store.dispatch(ReduxActions.selectedProjectChanged(projectId))
        await runCommand(EntireProjectLoadedCommand.Outbound(newProject))
        resourceChanged('projectId', projectId)
    }

    /*
     * The User may need to be evicted from the Project they currently have onscreen if that project has been deleted
     * (or the User has lost permission to view it).
     *
     * AdminViewProjects will call projectUnloaded if a change to the User has
     * resulted in their losing permission for this Project. That will trigger a rerender, but because
     * there is no longer a current project, this function will navigate to (`/${workspaceId}/projects`)
     *
     * a. if the project hasn't changed there's nothing to do
     * b. if the project changed (the user probably selected a project on the projects page), load the new project
     * c. if there is NO project (eg. user's permission was revoked, or it was deleted), reroute to 'projects'
     */
    const p = () => {
        const possiblySwitchProjects = async () => {
            // there is no user yet (we just loaded the page) or the project hasn't changed,
            const selectedProject = ReduxSelectors.selectedProject(store.getState())

            // selectedUserId also means that the userData has been loaded, therefore we wait for it being valid before
            // deciding where to route
            if (!selectedUserId || selectedProject?.id === projectId) return

            const newProject = userProjects[projectId]
            if (newProject) await projectChanged(newProject)
            else navigate(`/${workspaceId}/projects`) // no project; reroute
        }

        // we need to use this syntax because possiblySwitchProjects is async, and React complains otherwise
        possiblySwitchProjects()
    }

    const navigate = useNavigate()
    const { runCommand } = useCommandHistory()
    const { resourceChanged, resourceRemoved } = useCommandHistory()
    const { projectId, workspaceId } = useParams()
    const selectedUserId = useSelector(ReduxSelectors.selectedUserId)
    const store = useStore()
    const userProjects = useSelector(ReduxSelectors.userProjectsAsLookupTable)
    const isProjectLoaded = useSelector(ReduxSelectors.isProjectDataLoaded)

    useEffect(p, [projectId, userProjects])

    return isProjectLoaded ? <Outlet /> : <PageLoading />
}

// Navigate to the selected project if there is one
const ProjectRootRoute = () => {
    const selectedProject = useSelector(ReduxSelectors.selectedProject)
    const allCanvases = useSelector(ReduxSelectors.canvasesAsArray)
    const pdfCanvasSources = useSelector(ReduxSelectors.pdfCanvasSources)
    const [projectToCanvas] = useLocalStorage('projectToCanvas', {})

    const getFirstCanvasToShow = () => {
        // firstly we pick the most recently canvas if this project was viewed previously
        if (projectToCanvas && projectToCanvas[selectedProject.id]) return projectToCanvas[selectedProject.id]
        // secondly we pick first canvas in canvasOrder that's a PDF
        if (pdfCanvasSources.length > 0) {
            const firstPdfCanvasInOrder = selectedProject.canvasOrder.find(
                canvasId => !!pdfCanvasSources.find(cs => cs.canvasId === canvasId)
            )
            return firstPdfCanvasInOrder
        }
        // we default to the very first canvas if previous checks fail
        return allCanvases[0].id
    }

    if (!selectedProject || allCanvases.length === 0) return null // not finished loading

    // use the canvas stored for this project in localStorage, if there is one, or just pick the first canvas
    const canvasId = getFirstCanvasToShow()
    return <Navigate to={canvasId} replace={true} />
}

// Show the map view (as opposed to one of the Admin views)
const CanvasRoute = () => {
    const { canvasId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const selectedCanvas = useSelector(ReduxSelectors.selectedCanvas)
    const project = useSelector(ReduxSelectors.selectedProject)

    // in case you are on a canvas that doesn't exist you have to go to safety - the first available canvas
    const navigateToSafety = () => {
        navigate(`../${project.canvasOrder[0]}`)
        dispatch(
            ReduxActions.globalModalDataSet({
                title: 'PDF Canvas no longer exists.',
                description:
                    'The PDF canvas you are trying to access has been removed since you last accessed the project',
                cancelButton: {
                    label: 'Ok',
                },
            })
        )
    }

    useEffect(() => {
        if (!canvasId) return
        if (selectedCanvas && selectedCanvas.id === canvasId) return
        if (!project || !project.canvasOrder) return

        if (project.canvasOrder.includes(canvasId)) {
            Commands2.selectionChanged({ canvasId, context: 'selectedCanvasChanged' })
        } else {
            navigateToSafety()
        }
    }, [canvasId, selectedCanvas, project])

    project && Segment.sendPage('project canvas', canvasId, { projectType: project.projectType })

    return <CanvasView />
}

/*
 * Convert window.location.search to an object
 * @sig parseUrlParams = () -> {k:v}
 */
const parseUrlParams = search => {
    const reducer = (acc, pair) => {
        const [key, value] = pair.split('=') // Split the key=value pair into a key and a value
        return assoc(decodeURIComponent(key), decodeURIComponent(value), acc)
    }

    return search
        .substring(1) // Remove the '?'
        .split('&') // Split the query string into key=value pairs
        .reduce(reducer, {})
}

// prevent processing the same actionCode twice
/*
 * Call applyActionCode (once) and return a Promise
 * @sig String -> Promise void|Error
 */
const isActionCodeProcessed = {}
const applyFirebaseActionCode = actionCode =>
    new Promise((resolve, reject) => {
        if (isActionCodeProcessed[actionCode]) return resolve(true)
        isActionCodeProcessed[actionCode] = true

        applyActionCode(auth, actionCode).then(resolve).catch(reject)
    })

/*
 * We told Firebase (in the Firebase console) that actions should be sent to range.io/actioncode for processing
 * We're handling that path here.
 *
 * The URL will receive parameters for mode, oobCode, lang and possibly, continueUrl)
 *
 * So far the only mode we handle is verifyEmail, which we want to do exactly once, so we track handling it
 * with isActionCodeProcessed (because if we use React mechanisms, it's not clear how to guarantee "only-once")
 *
 * The component itself always returns null, and is useful only for its side effect of verifying the code
 * Assuming the verification works, we navigate to '/'
 */
const FirebaseRedirect = () => {
    const handleVerifyEmail = async () => {
        await applyFirebaseActionCode(actionCode)
        await auth.currentUser.reload() // update the emailVerified

        // the nuclear option: couldn't figure out how to get App to rerender AFTER emailVerified is true
        // navigate('/') didn't work
        window.location.href = '/'
    }

    const navigate = useNavigate()
    const { mode, oobCode: actionCode /* continueUrl, lang */ } = parseUrlParams(window.location.search)

    useEffect(() => {
        if (mode === 'verifyEmail' && actionCode) handleVerifyEmail()
        if (mode === 'resetPassword' && actionCode) navigate(`/newPassword?actionCode=${actionCode}`)
        else {
            console.error(`Don't understand action: `, mode, actionCode)
            navigate('/')
        }
    }, [mode, actionCode, navigate])

    // always just side effect
    return null
}

/*
 * The child of this will be shown only if the user is allowed to create the project; other we reroute to the parent
 * For instance /<org-id>/createProject takes you back to /<org-id>/
 *
 * Note: given that the links that take you to /<org-id>/createProject should ALSO have been removed if
 * you're not allowed to update a project, the only way to get to /<org-id>/createProject
 * in the first place should be by hand-editing the URL to try to get there
 */
const CreateProjectIfAllowed = ({ children }) => {
    const isCreateAllowed = useSelector(ReduxSelectors.isCreateAllowed('project', null))
    return isCreateAllowed ? children : <Navigate to=".." replace />
}

/*
 * The child of this will be shown only if the user is allowed to update the project; other we reroute to the parent
 * For instance /<org-id>/<project-id>/details takes you back to /<org-id>/<project-id>/
 *
 * Note: given that the links that take you to /<org-id>/<project-id>/details should ALSO have been removed if
 * you're not allowed to update a project, the only way to get to /<org-id>/<project-id>/details
 * in the first place should be by hand-editing the URL to try to get there
 */
const UpdateProjectIfAllowed = ({ children }) => {
    const isUpdateAllowed = useSelector(ReduxSelectors.isUpdateAllowed('project'))
    return isUpdateAllowed ? children : <Navigate to=".." replace />
}

/*
 * The child of this will be shown only if the user is allowed to update the organization; other we reroute to the parent
 * For instance /<org-id>/settings takes you back to /<org-id>/
 *
 * Note: given that the links that take you to /<org-id>/settings should ALSO have been removed if
 * you're not allowed to update an organization, the only way to get to /<org-id>/settings
 * in the first place should be by hand-editing the URL to try to get there
 */
const UpdateOrganizationIfAllowed = ({ children }) => {
    const isUpdateAllowed = useSelector(ReduxSelectors.isUpdateAllowed('organization'))
    return isUpdateAllowed ? children : <Navigate to=".." replace />
}

/*
 * We emailed the user a link to /invitation/:invitationId so that we can give them feedback about their invitation.
 * In particular, they might not actually have the invitation they think they have, probably
 * because they have multiple Range accounts, and the invitation is for a different one than they're signed in as
 *
 * This is SEPARATE from AcceptInvitations, because AcceptInvitations shows you invitations you definitely
 * HAVE for the logged-in user, not whatever random Organization id you happened to paste in the browser
 */
const ValidateInvitation = () => {
    const onLogOut = () => navigate('/logout')
    const onGotoRoot = () => navigate('/')

    const { invitationId } = useParams()
    const user = useSelector(ReduxSelectors.selectedUser)
    const loggedInStatus = useSelector(ReduxSelectors.loggedInStatus)
    const navigate = useNavigate()
    const [noSuchInvitation, setNoSuchInvitation] = useState()

    useEffect(() => {
        if (!invitationId.match(REGEX.uuid)) return navigate('/') // malformed invitation
        if (loggedInStatus === LoggedInStatus.unknown) return // too early to know what to do
        if (loggedInStatus !== LoggedInStatus.loggedIn) return navigate('/') // can't accept if you're not logged in
        if (!user) return // too early to know what to do

        const validInvitation = user.invitations?.find(i => i.id === invitationId)
        return validInvitation ? navigate('/acceptInvitations') : setNoSuchInvitation(true)
    }, [user, invitationId])

    return noSuchInvitation ? (
        <InvitedToOrgWrongAccount loggedInUserEmail={user.email} onLogOut={onLogOut} onGotoDashboard={onGotoRoot} />
    ) : null
}

/*
 * Log the user out and go to '/'
 */
const LogoutPage = () => {
    const navigate = useNavigate()
    const user = useSelector(ReduxSelectors.selectedUser)

    useEffect(() => {
        const f = async () => {
            try {
                await attemptSignOut()
                navigate('/')
            } catch (e) {
                console.error('Error logging out from firebase', e)
            }
        }

        // log out as soon as we have a user
        if (user) f()
    }, [user])

    return null
}

// main browser router configuration
// We need to use createBrowserRouter to enable of use for React Router data APIs (like useMatches)
const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<RootRoute />}>
                <Route index element={<Navigate to="workspaces" replace={true} />} />
                <Route path="workspaces" element={<WorkspaceRootRoute />} />
                <Route path=":workspaceId" element={<WorkspaceRoute />}>
                    <Route element={<OrganizationLevelRoute />}>
                        <Route index element={<Navigate to="projects" replace={true} />} />
                        <Route path="projects" element={<AdminViewProjects />} />
                        <Route path="members" element={<AdminViewMembers />} />
                        <Route
                            path="settings"
                            element={
                                <UpdateOrganizationIfAllowed>
                                    <AdminViewWorkspaceSettings />
                                </UpdateOrganizationIfAllowed>
                            }
                        />
                        <Route path="account" element={<AdminViewAccountSettings />} />
                        <Route
                            path="createProject"
                            element={
                                <CreateProjectIfAllowed>
                                    <NewProjectWizard.Live />
                                </CreateProjectIfAllowed>
                            }
                        />
                    </Route>
                    <Route path=":projectId" element={<ProjectRoute />}>
                        <Route index element={<ProjectRootRoute />} />
                        <Route path="media">
                            <Route index element={<MediaView />} />
                            <Route path=":uploadId" element={<MediaDetailsView />} />
                        </Route>
                        <Route
                            path="details"
                            element={
                                <UpdateProjectIfAllowed>
                                    <EditProjectPage.Live />
                                </UpdateProjectIfAllowed>
                            }
                        />
                        <Route
                            path="sources"
                            element={
                                <UpdateProjectIfAllowed>
                                    <ProjectCanvasSources />
                                </UpdateProjectIfAllowed>
                            }
                        />
                        <Route path="tags" element={<TagManager />} />
                        <Route
                            path="statuses"
                            element={
                                <UpdateProjectIfAllowed>
                                    <StatusManager />
                                </UpdateProjectIfAllowed>
                            }
                        />
                        <Route
                            path="categories"
                            element={
                                <UpdateProjectIfAllowed>
                                    <CategoryManager />
                                </UpdateProjectIfAllowed>
                            }
                        />
                        <Route
                            path="list"
                            element={<ListView />}
                            handle={{ acceptedURLParams: ['selectedCollaborationId'] }}
                        />
                        <Route
                            path=":canvasId"
                            element={<CanvasRoute />}
                            handle={{ acceptedURLParams: ['mapPosition', 'selectedCollaborationId'] }}
                        />
                    </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/verifyEmail" element={<VerifyEmailModal />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/resetPasswordConfirmation" element={<ResetPasswordConfirmation />} />
            <Route path="/newPassword" element={<NewPassword />} />
            <Route path="/newOrganization" element={<NewOrganization includeLogOut={false} />} />

            <Route path="/invitation/:invitationId" element={<ValidateInvitation />} />
            <Route path="/acceptInvitations" element={<RegistrationAcceptInvitations />} />
            <Route path="/actioncode" element={<FirebaseRedirect />} />
        </>
    )
)

export default App
