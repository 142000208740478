import { byFieldStringComparator, memoizeReduxState } from '@range.io/functional'
import filterCollaborations from './redux-filter-common.js'
import ReduxSelectors from './redux-selectors.js'

const _filteredCollaborations = state => {
    const isTask = cs => cs.isTask
    const isnotTask = cs => !cs.isTask

    const sort = collaborationShapes => {
        if (sortOrder === 'recent') return collaborationShapes.sort((a, b) => b.lastUpdateDate - a.lastUpdateDate)
        if (sortOrder === 'alphabetical') return collaborationShapes.sort(byFieldStringComparator('name', false, true))

        throw new Error("Don't understand sortOrder")
    }

    const { canvases, navigatorFilterSettings, navigatorSortSettings, selectedCanvas, selectedUserId } = state
    const { showPhotoPins, showTaskPins } = navigatorFilterSettings
    const { sortOrder } = navigatorSortSettings

    const selectedCanvasShape = canvases[selectedCanvas]
    if (!selectedCanvasShape) return [] // too early

    const allShapes = ReduxSelectors.allShapes(state)
    let { collaborations: collaborationShapes } = allShapes

    // Apply photo/task pin filters specific to canvas view
    if (!showPhotoPins) collaborationShapes = collaborationShapes.filter(isTask)
    if (!showTaskPins) collaborationShapes = collaborationShapes.filter(isnotTask)

    // Apply common filters
    collaborationShapes = filterCollaborations(collaborationShapes, navigatorFilterSettings, selectedUserId)

    return sort(collaborationShapes)
}

/*
 * Memoize the _filteredCollaborations function, recomputing only if any of the collections or filters has changed
 */
const filteredCollaborations = memoizeReduxState(
    [
        'navigatorFilterSettings', // the whole point...
        'navigatorSortSettings', // the whole point...

        'canvases',
        'collaborations',
        'geometries',
        'selectedCanvas',
        'uploads', // we need this so when upload image URLs get updated this also gets refreshed
        'statusNames',
    ],
    _filteredCollaborations
)
export { filteredCollaborations }
