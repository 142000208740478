import ReduxSelectors from '../../redux-selectors.js'
import { _field } from '../../selector-primitives.js'

const tagNameLookupTable = _field('tagNames')

/*
 * Return the TagName with the given text or undefined
 * @sig :: tagNameForName :: (State, String) -> TagName|undefined
 */
const tagNameForName = (state, name) => {
    const tagNames = Object.values(state.tagNames)
    return tagNames.find(t => t.name === name)
}

/*
 * Return all TagNames enriched with their count of usage across the app
 * @sig :: tagDataPropTypes :: (State) => {...TagName, Number}
 */
const enrichedTagNames = state => {
    const allTagNames = ReduxSelectors.tagNameLookupTable(state)
    const allUploads = ReduxSelectors.uploadLookupTable(state)
    const allCollaborations = ReduxSelectors.collaborationsAsObject(state)
    const counts = {}
    Object.keys(allTagNames).forEach(tagId => (counts[tagId] = 0))
    Object.values(allUploads).forEach(upload => {
        upload.tagIds.forEach(tagId => (counts[tagId] += 1))
    })
    Object.values(allCollaborations).forEach(collaboration => {
        collaboration.tags.forEach(tagId => (counts[tagId] += 1))
    })
    const newAllTagNames = {}
    Object.values(allTagNames).forEach(tag => {
        newAllTagNames[tag.id] = { ...tag }
        newAllTagNames[tag.id].count = counts[tag.id]
    })
    return newAllTagNames
}

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

const TagNameSelectors = {
    enrichedTagNames,
    tagNameForName,
    tagNameLookupTable,
}

export default TagNameSelectors
