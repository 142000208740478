/*
 * The Toolbar of drawing tools that appears at the top-left of the map
 */
import * as Popover from '@radix-ui/react-popover'
import React, { useEffect, useState } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useNavigateToCollaboration } from '../components-reusable/hooks/useNavigateToCollaboration.js'
import useRecentCollaborations from '../components-reusable/hooks/useRecentCollaborations.js'
import { FlexColumn, FlexRow, ScrollArea, Text } from '../components-reusable/index.js'
import { styled } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'
import CollaborationWindowBadge from './CollaborationWindowBadge.js'

const CollaborationItemStyled = styled(FlexRow, {
    width: '100%',
    gap: 8,
    p: '6px 8px',
    br: 6,
    cursor: 'pointer',
    alignItems: 'center',

    '&:hover': {
        background: '$neutral08',
    },
})

const CollaborationItem = ({ collaboration, projectIdentifier, onClick }) => {
    const { getState } = useStore()

    const { identifier, isArchived, isOverdue, name, statusName, uploads } = collaboration

    const isTask = Boolean(statusName)
    const status = statusName && ReduxSelectors.statusNameForCollaboration(getState(), collaboration)

    return (
        <CollaborationItemStyled onClick={onClick}>
            <div
                style={{
                    flexShrink: '0',
                    display: 'flex',
                }}
            >
                {isTask ? (
                    <CollaborationWindowBadge
                        size={24}
                        isTask={true}
                        color={status.color}
                        isCompleted={status.isCompleted}
                        isOverdue={isOverdue}
                        isArchived={isArchived}
                    />
                ) : (
                    <CollaborationWindowBadge size={24} isArchived={isArchived} count={uploads?.length || 0} />
                )}
            </div>

            <Text
                css={{
                    fs: 14,
                    fw: 500,
                    color: '$neutral05',
                    flexShrink: '0',
                }}
            >
                ID: {projectIdentifier}-{identifier}
            </Text>
            <Text
                css={{
                    fs: 14,
                    fw: 500,
                    color: '$neutral04',
                    flex: '1 1 auto',
                }}
                ellipsed
            >
                {name || 'Untitled Pin'}
            </Text>
        </CollaborationItemStyled>
    )
}

/*
 * Popover with trigger that displays recent collaborations rows.
 */
const RecentCollaborationsPopover = ({ children }) => {
    const { getState } = useStore()
    const allCollaborations = useSelector(ReduxSelectors.collaborationsAsObject)
    const projectIdentifier = ReduxSelectors.selectedProject(getState())?.identifier

    const [showList, setShowList] = useState(false)
    const [recentCollaborations, setRecentCollaborations] = useState([])
    const { getRecentCollaborations } = useRecentCollaborations()
    const { navigateToCollaboration } = useNavigateToCollaboration()
    const MAX_DISPLAYED_COLLABORATIONS = 10

    useEffect(() => {
        // Recalculate recentCollaborations only when we need to display them
        if (showList) {
            const recentlyViewedCollaborationsIds = getRecentCollaborations()

            if (!recentlyViewedCollaborationsIds) setRecentCollaborations([])
            const collaborations = recentlyViewedCollaborationsIds
                .slice(0, MAX_DISPLAYED_COLLABORATIONS) // limit number of results
                .reduce((filteredArr, id) => {
                    if (allCollaborations[id]) {
                        filteredArr.push(allCollaborations[id])
                    }
                    return filteredArr
                }, [])

            setRecentCollaborations(collaborations)
        }
    }, [showList, allCollaborations])

    const handleCollaborationClick = collaboration => navigateToCollaboration(collaboration.id)

    return (
        <Popover.Root onOpenChange={setShowList} open={showList}>
            <Popover.Trigger asChild>
                <div>{children}</div>
            </Popover.Trigger>
            <Popover.Portal>
                {showList && (
                    <Popover.Content
                        onOpenAutoFocus={e => e.preventDefault()} // keep focus on the input
                        side="bottom"
                        align="start"
                        sideOffset={8}
                        style={{
                            zIndex: 2,
                            position: 'relative',
                            height: 'auto',
                            maxHeight: `var(--radix-popper-available-height)`,
                            width: 440,
                        }}
                    >
                        <ScrollArea
                            viewportCss={{
                                maxHeight: `var(--radix-popper-available-height)`,
                            }}
                        >
                            <FlexColumn
                                css={{
                                    background: '$neutral10',
                                    padding: 8,
                                    gap: 8,
                                    br: 6,
                                    border: '1px solid $neutral07',
                                }}
                            >
                                <Text
                                    css={{
                                        fs: 12,
                                        fw: 700,
                                        p: '6px 8px 0px 8px',
                                        pb: '0px',
                                        color: '$neutral05',
                                    }}
                                >
                                    Recently Viewed Project Pins
                                </Text>
                                {recentCollaborations.length ? (
                                    recentCollaborations.map(collaboration => (
                                        <CollaborationItem
                                            key={collaboration.id}
                                            collaboration={collaboration}
                                            projectIdentifier={projectIdentifier}
                                            onClick={() => handleCollaborationClick(collaboration)}
                                        />
                                    ))
                                ) : (
                                    <Text
                                        css={{
                                            p: '6px 8px',
                                            fs: 14,
                                            fw: 500,
                                            color: '$neutral05',
                                        }}
                                    >
                                        Once you view a pin, it will appear here.
                                    </Text>
                                )}
                            </FlexColumn>
                        </ScrollArea>
                    </Popover.Content>
                )}
            </Popover.Portal>
        </Popover.Root>
    )
}

export default RecentCollaborationsPopover
