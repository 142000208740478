// State -> { [Id]: Participant }
import { mapObject } from '@range.io/functional'
import { ParticipantShape } from '../../../react-shapes/participant-shape.js'
import UiStateSelectors from '../../ui-state/ui-state-selectors.js'
import PresenceSelectors from '../presence-slice/presence-selectors.js'

const empty = {}

const organizationParticipants = state => UiStateSelectors.selectedOrganization(state)?.participants || {}

/*
 * Return the user with the specified id
 * @sig organizationParticipantWithId :: (State, String) -> User|Participant|undefined
 */
const organizationParticipantWithId = (state, userId) => organizationParticipants(state)[userId]

/*
 * The project.participants for the selected Project
 */
const selectedProjectParticipants = state => {
    const project = UiStateSelectors.selectedProject(state)
    return project?.participants || empty
}

/*
 * Return all the participants of the selected project as an object
 * @sig selectedProjectParticipantShapes :: State -> { [Id]: ParticipantShape }
 */
const selectedProjectParticipantShapes = state => {
    const project = UiStateSelectors.selectedProject(state)
    if (!project) return {}

    return mapObject(ParticipantShape.fromParticipant(), project.participants) // <-- extra () is actually correct...
}

/*
 * Return the participantShape of the selected project with the given id
 * @sig selectedProjectParticipantShapeWithId :: (State, Id) -> ParticipantShape
 */
const selectedProjectParticipantShapeWithId = (state, id) => selectedProjectParticipantShapes(state)?.[id]

/*
 * Return all the participants of the selected project as an array
 * @sig selectedProjectParticipantShapesAsArray :: State -> [ParticipantShape]
 */
const selectedProjectParticipantShapesAsArray = state => Object.values(selectedProjectParticipantShapes(state))

const selectedProjectParticipantWithId = (state, id) => selectedProjectParticipants(state)[id]

/*
 * Return all the Users who have recent Presences
 * @sig presentProjectParticipants :: State -> [Participant]
 */
const presentProjectParticipants = state => {
    const reducer = (acc, p) => acc.concat(_users[p.id])

    const _presences = PresenceSelectors.presences(state)
    const _users = selectedProjectParticipants(state)
    return _presences.reduce(reducer, [])
}

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

const ParticipantSelectors = {
    organizationParticipantWithId,
    presentProjectParticipants,
    selectedProjectParticipants,
    selectedProjectParticipantShapes,
    selectedProjectParticipantShapesAsArray,
    selectedProjectParticipantShapeWithId,
    selectedProjectParticipantWithId,
}

export default ParticipantSelectors
