import { pick } from '@range.io/functional'

const defaultPosition = { center: { lng: 0, lat: 0 }, zoom: 21 }

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

// prettier-ignore
const UiStateSelectors = {
    allProjectMetrics         : state => state.projectMetrics,
    backgroundMapLoadingState : state => state.backgroundMapLoadingState,
    currentlyLoadingProject   : state => state.currentlyLoadingProject,
    cursor                    : state => state.cursor,
    drawingMode               : state => state.drawingMode,
    globalModalData           : state => state.globalModalData,
    isLoadingInitialData      : state => state.isLoadingInitialData,
    knockUserToken            : state => state.knockUserToken,
    mapPosition               : state => state.mapPositionsHistory[state.selectedCanvas] ?? defaultPosition,
    mapPositionsHistory       : state => state.mapPositionsHistory,
    mediaViewFilterSettings   : state => state.mediaViewFilterSettings,
    navigatorFilterSettings   : state => state.navigatorFilterSettings,
    navigatorSortSettings     : state => state.navigatorSortSettings,
    notificationPreferences   : state => state.notificationPreferences,
    pdfLoadingState           : state => state.pdfLoadingState,
    selectedTool              : state => state.selectedTool,
    selectedCanvas            : state => state.canvases[state.selectedCanvas],
    selectedCanvasId          : state => state.selectedCanvas,
    selectedCanvasSource      : state => state.canvasSources[state.selectedCanvasSource],
    selectedCollaboration     : state => state.collaborations[state.selectedCollaborationId],
    selectedCollaborationId   : state => state.selectedCollaborationId,
    selectedFeature           : state => state.features[state.selectedFeatureId],
    selectedGeometries        : state => Object.values(pick(state.selectedGeometryIds, state.geometries)),
    selectedGeometryIds       : state => state.selectedGeometryIds,
    selectedMediaUploadsIds   : state => state.selectedMediaUploadsIds,
    selectedOrganization      : state => state.organizations[state.selectedOrganizationId],
    selectedOrganizationId    : state => state.selectedOrganizationId,
    selectedProject           : state => state.projects[state.selectedProjectId],
    selectedProjectId         : state => state.selectedProjectId,
    selectedUser              : state => state.selectedUserId && state.user,
    selectedUserId            : state => state.selectedUserId,
    shouldFocusCollabWindow   : state => state.shouldFocusCollabWindow,
    showAccountCreatedToast   : state => state.showAccountCreatedToast,
    showCanvasSnippetMode     : state => state.showCanvasSnippetMode,
    showTimelineDetails       : state => state.showTimelineDetails,
    taskListFilterSettings    : state => state.taskListFilterSettings,
    toastLookupTable          : state => state.toasts,
}

export default UiStateSelectors
