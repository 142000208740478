import { applyMiddleware, compose, createStore as reduxCreateStore } from 'redux'
import thunk from 'redux-thunk'
import { addResource } from '../firebase/commands-2/resources.js'
import logStateMiddleware from './log-state-middleware.js'
import { reduxReducer } from './redux-actions.js'

const createStore = () => {
    const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

    const middlewares = [thunk.default ? thunk.default : thunk]
    if (typeof window !== 'undefined' && window.location.host.match(/localhost|127.0.0.1/)) {
        const message = `    Redux logging will track changes to keys you set; eg. window.reduxKeysToWatch = ['selectedUserId', 'organizations']`
        console.log(message)
        middlewares.push(logStateMiddleware)
    }
    const store = reduxCreateStore(reduxReducer, composeEnhancers(applyMiddleware(...middlewares)))
    addResource('dispatch', store.dispatch)
    addResource('getState', store.getState)
    return store
}
export default createStore
