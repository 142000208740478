import PropTypes from 'prop-types'
import React from 'react'
import { FlexRow, Icon } from '../components-reusable/index.js'
import { CategoryIcon } from './CategorySelector.js'

const FlagSuperscriptIcon = () => (
    <FlexRow
        css={{
            width: '20px',
            height: '20px',
            background: '$orange03',
            ai: 'center',
            justifyContent: 'center',
            border: '1px solid $orange04',
            br: 1000,
            position: 'absolute',
            left: '20px',
            top: -8,
        }}
    >
        <Icon name="flag" css={{ width: '9px', color: 'white' }} />
    </FlexRow>
)

const CalendarSuperscriptIcon = () => (
    <FlexRow
        css={{
            w: 20,
            h: 20,
            bg: '$red03',
            ai: 'center',
            justifyContent: 'center',
            border: '1px solid $orange04',
            br: 1000,
            position: 'absolute',
            left: '20px',
            top: -8,
        }}
    >
        <Icon name="calendar" css={{ mt: -1, width: '14px', color: 'white' }} />
    </FlexRow>
)

const Badge = ({ color, size, css = {} }) => (
    <FlexRow
        css={{
            ai: 'center',
            justifyContent: 'center',
            transitionDuration: '0.4s',
            fs: '14px',
            fw: 600,
            br: 1000,
            backgroundColor: color,
            width: size,
            height: size,
            color: 'white',
            ...css,
        }}
    />
)

const CountLabel = ({ count, size }) => (
    <FlexRow
        css={{
            position: 'absolute',
            top: 0,
            left: 0,
            ai: 'center',
            justifyContent: 'center',
            transitionDuration: '0.4s',
            fs: '14px',
            fw: 600,
            br: 1000,
            color: 'white',
            width: size,
            height: size,
        }}
    >
        {count}
    </FlexRow>
)

const CollaborationWindowBadge = ({
    size = 32,
    isArchived,
    isCompleted,
    hasNotesPending,
    count,
    isTask = false,
    isOverdue,
    color,
    category,
}) => {
    const getTargetColor = () => {
        if (isArchived) return '$archived'
        if (!isTask) return '$pink03'
        return color
    }

    const getExtraBadgeProps = () => {
        if (!isTask) return { border: '1px solid $pink04' }
    }

    const renderContent = () => {
        if (category && !category.isDefault) {
            return (
                <div style={{ position: 'absolute', top: 0, left: 0 }}>
                    <CategoryIcon category={category} size={size} />
                </div>
            )
        }
        if (isTask)
            return <Icon css={{ color, position: 'absolute', top: 0, left: 0 }} name="badge" iconSize={size + 'px'} />
        return <CountLabel count={count} size={size} />
    }

    const renderOverlay = () => {
        if (isOverdue && !isCompleted) return <CalendarSuperscriptIcon />
        if (hasNotesPending) return <FlagSuperscriptIcon />
    }

    return (
        <FlexRow
            css={{ width: size, height: size, maxWidth: size, maxHeight: size, position: 'relative', color: ' white' }}
        >
            <Badge size={size} color={getTargetColor()} css={getExtraBadgeProps()} />
            {renderContent()}
            {renderOverlay()}
        </FlexRow>
    )
}

CollaborationWindowBadge.propTypes = {
    isArchived: PropTypes.bool, // assumed false if missing
    isTask: PropTypes.bool,
    isOverdue: PropTypes.bool,
    hasNotesPending: PropTypes.bool, // assumed false is missing
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    category: PropTypes.object,
}

export default CollaborationWindowBadge
