import * as Popover from '@radix-ui/react-popover'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Checkbox, DatePicker, FlexRow, Icon, SingleSelectUser, Text, Tooltip } from '../components-reusable'
import { useOnClickOutside } from '../components-reusable/hooks/index.js'
import { CollaborationChangedCommand } from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { RangeUITheme, styled } from '../range-theme/index.js'
import { ReduxSelectors, useSelectorWith } from '../redux/index.js'
import { CategorySelector } from './CategorySelector.js'
import Identifier from './Identifier.js'
import ListViewRowAssignee from './ListViewRowAssignee'
import {
    AdditionalInformation,
    CanvasSheetPill,
    CreatedOnLabel,
    DueDatePill,
    LastUpdatedLabel,
} from './ListViewTaskRowElements'
import StatusDot from './StatusDot.js'
import { StatusItem } from './StatusSelector.js'
import TagsPill from './TagsPill'

// ---------------------------------------------------------------------------------------------------------------------
// Styled
// ---------------------------------------------------------------------------------------------------------------------

const StyledListRow = styled(FlexRow, {
    alignItems: 'center',
    minHeight: '48px',
    background: '$neutral10',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderBottom: '1px solid $neutral07',
    zIndex: 2,
    transitionDuration: '0.4s',
    '&:hover': {
        background: '$primary02',
        cursor: 'pointer',
    },
})

const StyledCollaborationNameContainer = styled(FlexRow, {
    alignItems: 'center',
    justifyContent: 'start',
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 12,
    width: 350,
    minWidth: 350,
})

const StyledCollaborationName = styled(Text, {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    color: '$neutral04',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const StyledCheckbox = styled(Checkbox, {
    marginRight: '12px',
})

const SecondaryText = styled(Text, {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    color: '$neutral05',
})

const StyledPopoverContent = styled(Popover.Content, {
    color: '$neutral04',
    backgroundColor: '$neutral09',
    outline: 'none',
    padding: '8px',
    borderRadius: '6px',
    border: '1px solid $neutral07',
    boxShadow: `${RangeUITheme.shadows.lg}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '8px',
    zIndex: 2,
    height: 'auto',
    maxHeight: `var(--radix-popper-available-height)`,
})

const StyledStatusTrigger = styled('div', {
    display: 'inline-flex',
})

// ---------------------------------------------------------------------------------------------------------------------
// Helpers
// ---------------------------------------------------------------------------------------------------------------------

const RowCell = React.forwardRef(({ cellWidth, children, css }, ref) => (
    <FlexRow ref={ref} css={{ ...css, width: cellWidth, minWidth: cellWidth, maxWidth: cellWidth }}>
        {children}
    </FlexRow>
))

/*
 * Render an optional icon and text in a cell
 */
const FallbackCell = ({ iconName, text, onClick = () => {}, dataCy }) => {
    const stopAndClick = e => {
        e.stopPropagation()
        onClick(e)
    }

    return (
        <FlexRow data-cy={dataCy} onClick={stopAndClick} css={{ alignItems: 'center' }}>
            {iconName && <Icon name={iconName} iconSize="12px" css={{ marginRight: '8px' }} shape="circle" />}
            <SecondaryText>{text}</SecondaryText>
        </FlexRow>
    )
}

// ---------------------------------------------------------------------------------------------------------------------
// Status Dot (circular icon for status)
// ---------------------------------------------------------------------------------------------------------------------

const StatusDotCell = ({ isArchived, isTask, selectedStatus, allStatuses, onStatusChange }) => {
    const stopPropagation = e => e.stopPropagation() // do not trigger whole row click

    const changeStatus = e => {
        e.stopPropagation() // do not trigger whole row click
        onStatusChange(status.id)
    }

    const renderStatus = status => (
        <div key={status.id} onClick={changeStatus}>
            <StatusItem selectedStatus={selectedStatus} status={status} />
        </div>
    )

    const { isCompleted, label, color } = selectedStatus

    return (
        <Popover.Root modal={false}>
            <Tooltip tooltipText="Update Status" side="bottom">
                <Popover.Trigger asChild>
                    <StyledStatusTrigger onClick={stopPropagation}>
                        <StatusDot
                            isArchived={isArchived}
                            statusIsCompleted={isCompleted}
                            isTask={isTask}
                            statusColor={color}
                            statusLabel={label}
                        />
                    </StyledStatusTrigger>
                </Popover.Trigger>
            </Tooltip>
            <Popover.Portal>
                <StyledPopoverContent side="bottom" align="start" sideOffset={4} collisionPadding={4}>
                    {Object.values(allStatuses).map(renderStatus)}
                </StyledPopoverContent>
            </Popover.Portal>
        </Popover.Root>
    )
}

// ---------------------------------------------------------------------------------------------------------------------
// Category
// ---------------------------------------------------------------------------------------------------------------------

const CategoryRowCell = ({ collaborationId }) => {
    const setSelectedCategory = category =>
        runCommand(CollaborationChangedCommand.Outbound(collaboration.id, { categoryId: category.id }, selectedUserId))

    const { runCommand } = useCommandHistory()
    const collaboration = useSelectorWith(ReduxSelectors.collaborationWithId, collaborationId)
    const selectedUserId = useSelector(ReduxSelectors.selectedUserId)
    const selectedCategory = useSelectorWith(ReduxSelectors.categoryForCollaboration, collaboration)

    return (
        <RowCell css={{ mr: 8 }} cellWidth={172}>
            <CategorySelector {...{ selectedCategory, setSelectedCategory }} />
        </RowCell>
    )
}

// ---------------------------------------------------------------------------------------------------------------------
// Due Date
// ---------------------------------------------------------------------------------------------------------------------

const DueDateRowCell = ({ item, isCompleted, onDateChange }) => {
    const toggleDatePicker = e => {
        e.stopPropagation()
        setShowDatePicker(val => !val)
    }

    const changeDate = dueDate => {
        onDateChange({ itemId: id, dueDate })
        setShowDatePicker(false)
    }

    const renderDueDatePill = () => (
        <DueDatePill dueDate={dueDate} isCompleted={isCompleted} isArchived={isArchived} onClick={toggleDatePicker} />
    )

    const renderFallback = () => (
        <FallbackCell dataCy="list-view-due-date" iconName="calendar" text="Add Due Date" onClick={toggleDatePicker} />
    )

    const { id, dueDate, isArchived } = item
    const dueDateRow = React.useRef()
    const [showDatePicker, setShowDatePicker] = React.useState()

    useOnClickOutside(dueDateRow, () => setShowDatePicker(false))

    return (
        <RowCell cellWidth="140px" ref={dueDateRow}>
            {dueDate ? renderDueDatePill() : renderFallback()}
            {showDatePicker && (
                <Box css={{ position: 'absolute', mt: '28px', zIndex: 200 }}>
                    <DatePicker onChange={changeDate} value={dueDate} />
                </Box>
            )}
        </RowCell>
    )
}

// ---------------------------------------------------------------------------------------------------------------------
// Identifier
// ---------------------------------------------------------------------------------------------------------------------

const IdentifierRowCell = ({ item }) => (
    <RowCell cellWidth="100px">
        {item.identifier && (
            <Identifier
                projectIdentifier={item.projectIdentifier}
                collaborationIdentifier={item.identifier}
                variant="medium"
                noPrefix
                css={{ fontWeight: '400' }}
            />
        )}
    </RowCell>
)

// ---------------------------------------------------------------------------------------------------------------------
// Assignee
// ---------------------------------------------------------------------------------------------------------------------

const AssigneeCell = ({ item, allAvailableAssigneesShapes, onAssigneeChange }) => {
    const toggleShowAssignee = e => {
        e.stopPropagation()
        setShowAssigneeDropdown(val => !val)
    }

    const setAssignee = userId => {
        onAssigneeChange({ userId, collaborationId: id })
        setShowAssigneeDropdown(false)
    }

    const renderAssigneePicker = () => (
        <SingleSelectUser
            withoutIcon
            withNoAssigneeOption
            css={{ height: '40px' }}
            projectParticipantShapes={allAvailableAssigneesShapes}
            selectedUser={assignee}
            setSelectedUserId={setAssignee}
        />
    )

    const renderCurrentAssignee = () => (
        <ListViewRowAssignee participantShape={assignee} isOnline={assigneeIsOnline} onClick={toggleShowAssignee} />
    )

    const renderFallbackTest = () => (
        <FallbackCell dataCy="list-view-assignee" iconName="user" text="Add Assignee" onClick={toggleShowAssignee} />
    )

    const renderAssigneeCell = () => {
        if (showAssigneeDropdown) return renderAssigneePicker()
        if (assignee) return renderCurrentAssignee()
        return renderFallbackTest()
    }

    const assigneeCell = React.useRef()
    const [showAssigneeDropdown, setShowAssigneeDropdown] = React.useState()
    useOnClickOutside(assigneeCell, () => setShowAssigneeDropdown(false))

    const { id, assignee, assigneeIsOnline } = item

    return (
        <RowCell cellWidth="180px" ref={assigneeCell}>
            {renderAssigneeCell()}
        </RowCell>
    )
}

// ---------------------------------------------------------------------------------------------------------------------
// Extra content (if there's room)
// ---------------------------------------------------------------------------------------------------------------------

const RemainingContentCells = ({ item }) => (
    <>
        <RowCell cellWidth="60px">
            <TagsPill.Simple tagNames={item.tagNames} />
        </RowCell>
        <RowCell cellWidth="38px">
            <AdditionalInformation geometry={item.geometry()} canvasId={item.canvasId} />
        </RowCell>
        <RowCell cellWidth="100px">
            <CreatedOnLabel createdOn={item.createdAt} />
        </RowCell>
    </>
)

// ---------------------------------------------------------------------------------------------------------------------
// Main
// ---------------------------------------------------------------------------------------------------------------------

const ListViewTaskRow = ({
    allAvailableAssigneesShapes, // [ParticipantShape]
    allStatuses, // { [Id]: StatusName }
    isChecked,
    item, // Collaboration + additional information
    showFullContent,
    status, // StatusName
    taskRowCheckboxDataId,

    handleItemChecked,
    onAssigneeChange,
    onDateChange,
    onGoToCanvasId,
    onItemSelect,
    onStatusChange,
}) => {
    const handleStatusChange = statusId => onStatusChange(id, statusId)

    const { id, isArchived, mostRecentUpdateDate, name, sheetLabel } = item

    const isNameEmpty = name.length === 0
    return (
        <StyledListRow data-cy="list-view-task-row" className="list-view__task-row" onClick={() => onItemSelect(id)}>
            <StyledCheckbox
                size="md"
                defaultChecked={true}
                checked={isChecked}
                onClick={e => e.stopPropagation()} // do not bubble, so the row is not selected
                onCheckedChange={() => handleItemChecked(id)}
                data-id={taskRowCheckboxDataId}
            />
            <StatusDotCell
                isArchived={isArchived}
                selectedStatus={status}
                allStatuses={allStatuses}
                isTask={true}
                onStatusChange={handleStatusChange}
            />
            <StyledCollaborationNameContainer>
                <StyledCollaborationName empty={isNameEmpty.toString()} data-cy="list-view-task-name">
                    {isNameEmpty ? 'Untitled task' : name}
                </StyledCollaborationName>
                <LastUpdatedLabel mostRecentUpdateDate={mostRecentUpdateDate} />
            </StyledCollaborationNameContainer>
            <IdentifierRowCell item={item} />
            <AssigneeCell
                item={item}
                allAvailableAssigneesShapes={allAvailableAssigneesShapes}
                onAssigneeChange={onAssigneeChange}
            />
            <CategoryRowCell collaborationId={item.id} />
            <DueDateRowCell item={item} isCompleted={status.isCompleted} onDateChange={onDateChange} />
            <RowCell css={{ mr: 8 }} cellWidth="242px">
                <CanvasSheetPill sheetLabel={sheetLabel} onClick={() => onGoToCanvasId(id)} />
            </RowCell>
            {showFullContent && <RemainingContentCells item={item} />}
        </StyledListRow>
    )
}

ListViewTaskRow.propTypes = {
    allAvailableAssigneesShapes: PropTypes.array.isRequired,
    allStatuses: PropTypes.object.isRequired,
    isChecked: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired, // Collaboration + additional information
    showFullContent: PropTypes.bool.isRequired,
    status: PropTypes.object, // StatusName
    taskRowCheckboxDataId: PropTypes.string,

    handleItemChecked: PropTypes.func.isRequired,
    onAssigneeChange: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
    onGoToCanvasId: PropTypes.func.isRequired,
    onItemSelect: PropTypes.func.isRequired,
    onStatusChange: PropTypes.func.isRequired,
}

export default ListViewTaskRow
