/*
 * FilterGroupForDateCreated is a filter group component for filtering content by (relative) creation date.
 * It provides an interface for filtering items based on when they were created,
 * integrating with the FilterSettings system and using FilterGroupSingleSelect for its UI.
 */

import { FilterSettings } from '@range.io/basic-types'
import React from 'react'
import FilterGroupSingleSelect from './filter-primitives/FilterGroupSingleSelect.js'

const FilterGroupForDateCreated = ({ filterSettings, setFilterSettings }) => {
    const updateFilterSettings = f => v => setFilterSettings(f(filterSettings, v))

    const { shouldFilterByDateCreated, dateCreatedDayRange } = filterSettings

    return (
        <FilterGroupSingleSelect
            title="Show pins created in the..."
            checked={shouldFilterByDateCreated}
            onToggle={updateFilterSettings(FilterSettings.setShouldFilterByDateCreated)}
            options={FilterSettings.dateRangeOptions}
            selectedValue={dateCreatedDayRange}
            onOptionSelect={updateFilterSettings(FilterSettings.setDateCreatedDayRange)}
            dataCy="filter-date-created"
        />
    )
}

export default FilterGroupForDateCreated
