import StringTypes from '@range.io/basic-types/src/string-types.js'
import { tagged } from '@range.io/functional'
import PropTypes from 'prop-types'

// prettier-ignore
const CanvasSourceShape = tagged('CanvasSourceShape', {
    id              : StringTypes.Id,
    parentId        : StringTypes.Id,
    
    name            : 'String',
    validFrom       : 'Object?', // date
    validTo         : 'Object?', // date
    type            : 'String',
    
    // PDF
    pdfUrl          : StringTypes.OptionalUrl, // result of calling Firebase Storage getDownloadUrl
    userDefinedScale: 'Number?',
   
    // Map
    center          : '[Number]?', //  Lng and Lat both Number
    styleUrl        : 'String?',
    zoom            : 'Number?',
   
    // DroneDeploy
    tilesetUrl      : 'String?',
})

// prettier-ignore
const CanvasSourceShapePropTypes = {
    id              : PropTypes.string.isRequired,
    parentId        : PropTypes.string.isRequired,
    
    name            : PropTypes.string.isRequired,
    validFrom       : PropTypes.object, // Date
    validTo         : PropTypes.object, // Date
    
    // PDF
    pdfUrl          : PropTypes.string,
    userDefinedScale: PropTypes.number,
    
    // Map
    center          : PropTypes.arrayOf(PropTypes.number),
    styleUrl        : PropTypes.string,
    zoom            : PropTypes.number ,
    
    // DroneDeploy
    tilesetUrl      : PropTypes.string,
}

CanvasSourceShape.fromCanvasSource = shapeLookupTable => canvasSource => {
    const shape = CanvasSourceShape.from({
        id: canvasSource.id,
        parentId: canvasSource.canvasId,

        name: canvasSource.name,
        validFrom: canvasSource.validFrom,
        validTo: canvasSource.validTo,
        type: canvasSource.type,

        // PDF
        pdfUrl: canvasSource.pdfUrl,
        userDefinedScale: canvasSource.userDefinedScale,

        // Map
        center: canvasSource.center,
        styleUrl: canvasSource.styleUrl,
        zoom: canvasSource.zoom,

        // DroneDeploy
        tilesetUrl: canvasSource.tilesetUrl,
    })
    return shape
}

CanvasSourceShape.prototype.toJSON = function () {
    return {
        id: this.id,
        name: this.name,
        type: this.type,
    }
}

export { CanvasSourceShape, CanvasSourceShapePropTypes }
