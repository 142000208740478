import { filterValues, memoizeReduxState } from '@range.io/functional'
import { generateCollaborationShapes } from '../../generate-shapes.js'
import memoizeResult from '../../memoize-result.js'
import { _field } from '../../selector-primitives.js'

const commentLookupTable = _field('comments')
const commentsAsArray = memoizeResult(state => Object.values(state.comments))

/*
 * All the comments for the selected Collaboration
 * @sig selectCommentsForCollaboration :: (State, Collaboration) -> [Comment]
 */
const commentsForSelectedCollaboration = state => {
    const { selectedCollaborationId } = state
    return filterValues(comment => comment.parentId === selectedCollaborationId, state.comments)
}

/*
 * Return all comments for the given Collaboration
 * @sig commentsForCollaboration :: (State, Collaboration) -> [Comment]
 */
const commentsForCollaboration = (state, collaboration) =>
    collaboration ? filterValues(comment => comment.parentId === collaboration.id, commentsAsArray(state)) : []

/*
 * Return all commentShapes for the given Upload
 * @sig commentShapesForUpload :: (State, Upload) -> [CommentShape]
 */
const _commentShapesForUpload = (state, upload) => {
    if (!upload) return []
    const allCommentShapes = generateCollaborationShapes(state).comments
    return allCommentShapes.filter(c => c.parentId === upload.id)
}

const commentShapesForUpload = memoizeReduxState(['uploads', 'collaborations', 'comments'], _commentShapesForUpload)

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

const CommentSelectors = {
    commentLookupTable,
    commentsForCollaboration,
    commentsForSelectedCollaboration,
    commentShapesForUpload,
}

export default CommentSelectors
