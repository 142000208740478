import { memoizeReduxState } from '@range.io/functional'
import memoizeResult from '../../memoize-result.js'
import { filteredCollaborations } from '../../redux-filter-canvas-view.js'

const geometriesAsArray = memoizeResult(state => Object.values(state.geometries))

/*
 * Return ONLY the geometries that meet the user's filter criteria (on the current canvas)
 * @sig _geometriesForSelectedCanvas :: State -> [Geometry]
 *
 */
const _geometriesForSelectedCanvas = state => {
    let collaborationShapes = filteredCollaborations(state)
    collaborationShapes = collaborationShapes.filter(cs => cs.canvasId === state.selectedCanvas)
    const geometryIds = collaborationShapes.map(c => c.geometry().id)
    return geometryIds.map(id => state.geometries[id])
}

/*
 * It's expensive to call _geometriesForSelectedCanvas, and it changes "rarely" and each call with the same
 * inputs will return a *different* array filled with the same Geometries, so memoize!
 *
 * Note/hack:
 * 'collaborations' is in this list because when a Geometry is added, the collaboration will possibly
 * have arrived earlier, in which case the CollaborationShape's canvas will not be around yet, because
 * the geometry has the canvas. We could fix this a couple of ways, including duplicating the canvas id in Firestore.
 * Because the CollaborationShape has no canvas, it is, by definition, not on the "current" canvas and never appears.
 * However, as it happens, we will definitely get storageVersion updates for a new collaboration AFTER we
 * have gotten the Collaboration's Geometry (and therefore its canvas) when the location snapshot arrives,
 * at which time we also have a canvas for the Collaboration, and it will appear.
 * DroneDeploy maps will not trigger updates, because we currently don't create locationSnapshots for these
 */
const geometriesForSelectedCanvas = memoizeReduxState(
    ['selectedCanvas', 'navigatorFilterSettings', 'geometries', 'collaborations'],
    _geometriesForSelectedCanvas
)

/*
 * Return geometry for the given Collaboration
 * @sig geometryForCollaboration :: (State, Collaboration) -> Geometry
 */
const geometryForCollaboration = (state, collaboration) => {
    const featureLookup = state.features
    const geometryLookup = state.geometries
    const feature = featureLookup[collaboration.featureId]
    return geometryLookup[feature.geometryId]
}

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

const GeometrySelectors = {
    geometriesAsArray,
    geometriesForSelectedCanvas,
    geometryForCollaboration,
}

export default GeometrySelectors
