/*
 * FilterGroupSingleSelect is a base component for creating filter groups with single-select options.
 * The options are rendered as horizontally arranged "pills". It extends FilterGroupBaseGroup and provides:
 *
 * - A list of mutually exclusive options
 * - Radio-button style selection
 * - Consistent styling and layout
 * - Support for custom option rendering
 *
 * This component is used primarily for date-based filters (FilterGroupForDateCreated, FilterGroupForDateUpdated)
 * where only one option can be selected at a time.
 */

import React from 'react'
import { Button, Flex, Text } from '../../../components-reusable/index.js'
import { styled } from '../../../range-theme/index.js'
import FilterGroupBaseGroup from './FilterGroupBaseGroup.js'

const StyledOptionButton = styled(Button, {
    padding: '6px 10px',
    color: '$neutral05',
    fontSize: '13px',
    border: '1px solid $neutral07',
    borderRadius: '100px',
    width: 'fit-content',
    userSelect: 'none',
    flexWrap: 'nowrap',
    alignItems: 'center',
    background: '$neutral10',
    fw: 500,
    maxHeight: 32,
    cursor: 'pointer',
    transitionDuration: '0.2s',

    '&:hover': {
        $$hoverBackgroundColor: 'var(--colors-primary03)',
    },

    variants: {
        selected: {
            true: {
                background: '$primary03',
                color: '$neutral04',
                fw: 400,
            },
        },
    },
})

const FilterGroupSingleSelect = ({
    title,
    checked,
    disabled = false,
    onToggle,
    options,
    selectedValue,
    onOptionSelect,
    width = 320,
    dataCy,
    css,
}) => (
    <FilterGroupBaseGroup
        title={title}
        checked={checked}
        disabled={disabled}
        onToggle={onToggle}
        width={width}
        dataCy={dataCy}
        css={css}
        fullPadding
    >
        <Flex wrap="wrap" align="start" justify="start" style={{ gap: '6px' }}>
            {options.map(({ name, dayValue }) => (
                <StyledOptionButton
                    key={dayValue}
                    onClick={() => onOptionSelect(dayValue)}
                    selected={selectedValue === dayValue}
                >
                    <Text ellipsed={true} css={{ maxWidth: 140, limitLinesOfTextTo: 1 }}>
                        {name}
                    </Text>
                </StyledOptionButton>
            ))}
        </Flex>
    </FilterGroupBaseGroup>
)

export default FilterGroupSingleSelect
