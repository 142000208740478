/*
 * Single-select dropdown for Categories, using the current category as the trigger button
 */
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import Icon from '../components-reusable/Icon.js'
import { Button, Text } from '../components-reusable/index.js'
import SingleSelectWithFilter, { StyledButton, StyledItemLabel } from '../components-reusable/SingleSelectWithFilter.js'
import { RangeUITheme } from '../range-theme/index.js'
import { ReduxSelectors } from '../redux/index.js'

/*
 * Render the icon for a Category, possibly changing its size and color
 * Hack: category is actually the TEXT of an SVG that we convert to a span with an inner <svg> component
 *
 * @sig CategoryIcon :: ({ category: Category, size = Number, color = String }) -> ReactComponent
 */
const CategoryIcon = ({ category, size = 20, color = 'currentColor' }) => {
    // replace the width and height embedded in the SVG text from category.icon with the passed-in size
    // width='40' height='50' -> width="20" height="20"
    const svgContent = atob(category.icon.split(',')[1])
    const htmlText = svgContent
        .replace(/width=['"][^'"]*['"]/, `width="${size}"`)
        .replace(/height=['"][^'"]*['"]/, `height="${size}"`)

    // HACK: can't use stitches because we're using 'style'
    if (color[0] === '$') {
        const colorName = color.slice(1, 2).toUpperCase() + color.slice(2) // $neutral04 -> Neutral04
        color = RangeUITheme[colorName]
    }

    // embed our now-modified SVG text into a span, also setting the style of the span
    return (
        <span
            dangerouslySetInnerHTML={{ __html: htmlText }}
            style={{ width: size, height: size, color, display: 'inline-block' }}
        />
    )
}

const renderItem = (isSelected, category) => (
    <StyledButton
        style={{ justifyContent: 'flex-start' }}
        variant="dropdown-menuitem-navigator"
        data-selected={isSelected}
    >
        <CategoryIcon category={category} />
        <StyledItemLabel css={{ paddingLeft: 6 }}>{category.name}</StyledItemLabel>
    </StyledButton>
)

/*
 * Single-select dropdown for categories, including the selected category as the trigger
 * @sig CategorySelector :: { categories: LookupTable, selectedCategory: Category, setSelectedCategory: Func } -> React
 */
const CategorySelector = ({ selectedCategory, setSelectedCategory }) => {
    const renderTrigger = category => (
        <StyledButton
            variant="categories"
            data-selected={false}
            style={{ backgroundColor: '$neutral03', marginRight: 'auto' }}
        >
            <CategoryIcon category={category} />
            <StyledItemLabel>{category.name}</StyledItemLabel>
            <Icon iconSize="16px" name="chevronDown" />
        </StyledButton>
    )

    const _setSelectedItem = id => setSelectedCategory(categories.get(id))

    const categories = useSelector(ReduxSelectors.categories)

    return (
        <SingleSelectWithFilter
            items={categories}
            selectedItem={selectedCategory}
            placeholder={'Search for a category'}
            tooltipText={'Select a category'}
            setSelectedItem={_setSelectedItem}
            renderItem={renderItem}
            renderTrigger={renderTrigger}
        />
    )
}

/*
 * Category selector whose trigger matches the style of the bulk-editing toolbar
 * @sig BulkCategorySelector :: { setSelectedCategory: Func } -> React
 */
const BulkCategorySelector = ({ setSelectedCategory }) => {
    const _setSelectedItem = id => setSelectedCategory(categories.get(id))

    const renderTrigger = item => (
        <Button variant="secondary" size="lg">
            <Icon name="unassigned" iconSize="16px" />
            <Text>Category</Text>
        </Button>
    )

    const categories = useSelector(ReduxSelectors.categories)

    return (
        <SingleSelectWithFilter
            items={categories}
            placeholder={'Search for a category'}
            tooltipText={'Select a category'}
            setSelectedItem={_setSelectedItem}
            renderItem={renderItem}
            renderTrigger={renderTrigger}
        />
    )
}

CategorySelector.propTypes = {
    selectedCategory: PropTypes.object.isRequired, // Category
    setSelectedCategory: PropTypes.func.isRequired,
}

BulkCategorySelector.propTypes = {
    setSelectedCategory: PropTypes.func.isRequired,
}

export { CategoryIcon, CategorySelector, BulkCategorySelector }
