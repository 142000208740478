const categoryIcons = {
    airconditioning: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M20 8C20.8297 8 21.5 8.67031 21.5 9.5V10.9109L22.2031 10.2078C22.6437 9.76719
                 23.3562 9.76719 23.7922 10.2078C24.2281 10.6484 24.2328 11.3609 23.7922 11.7969L21.4953 14.0938V17.3891L24.3734
                 15.7109L25.2031 12.6125C25.3625 12.0125 25.9812 11.6562 26.5812 11.8156C27.1812 11.975 27.5375 12.5938 27.3781
                 13.1938L27.1344 14.0984L28.2406 13.4516C28.9578 13.0344 29.8766 13.2781 30.2937 13.9906C30.7109 14.7031 30.4719 15.6266
                 29.7547 16.0438L28.5687 16.7375L29.5859 17.0094C30.1859 17.1687 30.5422 17.7875 30.3828 18.3875C30.2234 18.9875 29.6047
                 19.3438 29.0047 19.1844L25.8312 18.3359L22.9766 20L25.8312 21.6641L29.0047 20.8156C29.6047 20.6562 30.2234 21.0125
                 30.3828 21.6125C30.5422 22.2125 30.1859 22.8312 29.5859 22.9906L28.5687 23.2625L29.7547 23.9562C30.4719 24.3734 30.7109
                 25.2922 30.2937 26.0094C29.8766 26.7266 28.9578 26.9656 28.2406 26.5484L27.1344 25.9016L27.3781 26.8063C27.5375 27.4062
                 27.1812 28.025 26.5812 28.1844C25.9812 28.3438 25.3625 27.9875 25.2031 27.3875L24.3734 24.2891L21.5
                 22.6109V25.9062L23.7969 28.2031C24.2375 28.6437 24.2375 29.3562 23.7969 29.7922C23.3562 30.2281 22.6437 30.2328 22.2078
                 29.7922L21.5047 29.0891V30.5C21.5047 31.3297 20.8344 32 20.0047 32C19.175 32 18.5047 31.3297 18.5047
                 30.5V29.0891L17.8016 29.7922C17.3609 30.2328 16.6484 30.2328 16.2125 29.7922C15.7766 29.3516 15.7719 28.6391 16.2125
                 28.2031L18.5094 25.9062V22.6109L15.6312 24.2891L14.8016 27.3875C14.6422 27.9875 14.0234 28.3438 13.4234 28.1844C12.8234
                 28.025 12.4672 27.4062 12.6266 26.8063L12.8703 25.9016L11.7547 26.5438C11.0375 26.9609 10.1187 26.7172 9.70156
                 26.0047C9.28437 25.2922 9.52812 24.3687 10.2406 23.9516L11.4266 23.2578L10.4094 22.9859C9.80937 22.8266 9.45312 22.2078
                 9.61249 21.6078C9.77187 21.0078 10.3906 20.6516 10.9906 20.8109L14.1641 21.6594L17.0234 20L14.1687 18.3359L10.9953
                 19.1844C10.3953 19.3438 9.77656 18.9875 9.61718 18.3875C9.45781 17.7875 9.81406 17.1687 10.4141 17.0094L11.4312
                 16.7375L10.2453 16.0438C9.52812 15.6266 9.28906 14.7078 9.70624 13.9953C10.1234 13.2828 11.0422 13.0391 11.7594
                 13.4563L12.8656 14.1031L12.6219 13.1984C12.4625 12.5984 12.8187 11.9797 13.4187 11.8203C14.0187 11.6609 14.6375 12.0172
                 14.7969 12.6172L15.6266 15.7156L18.5 17.3891V14.0938L16.2031 11.7969C15.7625 11.3563 15.7625 10.6437 16.2031
                 10.2078C16.6437 9.77187 17.3562 9.76719 17.7922 10.2078L18.4953 10.9109L18.5 9.5C18.5 8.67031 19.1703 8 20 8Z" />
        </svg>
    `,

    concrete: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M18.3911 12.2947L19.6096 14.4943C19.736 14.7234 19.9797 14.87 20.2369
                 14.87H22.6424C22.9042 14.87 23.1434 14.728 23.2697 14.4943L24.4883 12.2947C24.6101 12.0702 24.6101 11.7998 24.4883
                 11.5753L23.2697 9.37575C23.1434 9.14664 22.8997 9 22.6424 9H20.2369C19.9752 9 19.736 9.14205 19.6096 9.37575L18.3911
                 11.5753C18.2692 11.7998 18.2692 12.0702 18.3911 12.2947ZM10.0825 11.5249C8.94515 10.3701 7 11.1904 7 12.8217V27.3341C7
                 29.3595 8.61569 31 10.6105 31H24.9035C26.5102 31 27.318 29.025 26.1807 27.8702L10.0825 11.5249ZM29.7551 26.0327C29.9762
                 26.1564 30.2425 26.1564 30.4636 26.0327L32.6299 24.7955C32.8556 24.6672 33 24.4197 33 24.1585V21.7161C33 21.4503
                 32.8601 21.2075 32.6299 21.0792L30.4636 19.8419C30.2425 19.7182 29.9762 19.7182 29.7551 19.8419L27.5888 21.0792C27.3631
                 21.2075 27.2187 21.4549 27.2187 21.7161V24.1585C27.2187 24.4243 27.3586 24.6672 27.5888 24.7955L29.7551
                 26.0327ZM15.6652 10.4709C15.6652 10.082 15.513 9.70907 15.2422 9.43407C14.9713 9.15907 14.604 9.00458 14.221
                 9.00458C13.8379 9.00458 13.4706 9.15907 13.1998 9.43407C12.9289 9.70907 12.7768 10.082 12.7768 10.4709C12.7768 10.8598
                 12.9289 11.2328 13.1998 11.5078C13.4706 11.7828 13.8379 11.9373 14.221 11.9373C14.604 11.9373 14.9713 11.7828 15.2422
                 11.5078C15.513 11.2328 15.6652 10.8598 15.6652 10.4709ZM24.3303 20.7355C24.7133 20.7355 25.0807 20.581 25.3515
                 20.306C25.6224 20.031 25.7745 19.658 25.7745 19.2691C25.7745 18.8802 25.6224 18.5072 25.3515 18.2322C25.0807 17.9572
                 24.7133 17.8027 24.3303 17.8027C23.9473 17.8027 23.58 17.9572 23.3091 18.2322C23.0383 18.5072 22.8861 18.8802 22.8861
                 19.2691C22.8861 19.658 23.0383 20.031 23.3091 20.306C23.58 20.581 23.9473 20.7355 24.3303 20.7355Z" />
        </svg>
    `,

    doors: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M11.6667 11.75C11.6667 10.2332 12.9123 9 14.4444 9H25.5556C27.0877 9 28.3333
                 10.2332 28.3333 11.75V28.25H31.1111C31.8793 28.25 32.5 28.8645 32.5 29.625C32.5 30.3855 31.8793 31 31.1111
                 31H26.25H13.75H8.88889C8.12066 31 7.5 30.3855 7.5 29.625C7.5 28.8645 8.12066 28.25 8.88889 28.25H11.6667V11.75ZM24.1667
                 21.375C24.535 21.375 24.8883 21.2301 25.1488 20.9723C25.4092 20.7144 25.5556 20.3647 25.5556 20C25.5556 19.6353 25.4092
                 19.2856 25.1488 19.0277C24.8883 18.7699 24.535 18.625 24.1667 18.625C23.7983 18.625 23.445 18.7699 23.1846
                 19.0277C22.9241 19.2856 22.7778 19.6353 22.7778 20C22.7778 20.3647 22.9241 20.7144 23.1846 20.9723C23.445 21.2301
                 23.7983 21.375 24.1667 21.375Z" />
        </svg>
    `,

    earthworks: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M10.7047 17.0469L11.1453 17.8156C11.5344 18.4953 12.1719 18.9969 12.9266
                 19.2125L15.6406 19.9859C16.4469 20.2156 17 20.9516 17 21.7906V23.6609C17 24.1766 17.2906 24.6453 17.75 24.875C18.2094
                 25.1047 18.5 25.5734 18.5 26.0891V27.9172C18.5 28.6484 19.1984 29.1781 19.9016 28.9766C20.6562 28.7609 21.2422 28.1563
                 21.4344 27.3922L21.5656 26.8672C21.7625 26.075 22.2781 25.3953 22.9859 24.9922L23.3656 24.7766C24.0687 24.3781 24.5
                 23.6281 24.5 22.8219V22.4328C24.5 21.8375 24.2609 21.2656 23.8391 20.8438L23.6562 20.6609C23.2344 20.2391 22.6625 20
                 22.0672 20H20.0469C19.5266 20 19.0109 19.8641 18.5562 19.6063L16.9391 18.6828C16.7375 18.5656 16.5828 18.3781 16.5078
                 18.1578C16.3578 17.7078 16.5594 17.2203 16.9859 17.0094L17.2625 16.8687C17.5719 16.7141 17.9328 16.6859 18.2609
                 16.7984L19.3484 17.1594C19.7328 17.2859 20.1547 17.1406 20.375 16.8078C20.5953 16.4797 20.5719 16.0438 20.3187
                 15.7391L19.6812 14.975C19.2125 14.4125 19.2172 13.5922 19.6953 13.0391L20.4313 12.1812C20.8438 11.6984 20.9094 11.0094
                 20.5953 10.4609L20.4828 10.2641C20.3187 10.2547 20.1594 10.25 19.9953 10.25C15.6453 10.25 11.9563 13.1047 10.7047
                 17.0469ZM29.75 20C29.75 18.275 29.3 16.6531 28.5125 15.2422L27.3125 15.725C26.5766 16.0203 26.1969 16.8406 26.4453
                 17.5906L27.2375 19.9672C27.4016 20.4547 27.8 20.825 28.2969 20.9469L29.6609 21.2891C29.7172 20.8672 29.7453 20.4359
                 29.7453 20H29.75ZM8 20C8 16.8174 9.26428 13.7652 11.5147 11.5147C13.7652 9.26428 16.8174 8 20 8C23.1826 8 26.2348
                 9.26428 28.4853 11.5147C30.7357 13.7652 32 16.8174 32 20C32 23.1826 30.7357 26.2348 28.4853 28.4853C26.2348 30.7357
                 23.1826 32 20 32C16.8174 32 13.7652 30.7357 11.5147 28.4853C9.26428 26.2348 8 23.1826 8 20Z" />
        </svg>
    `,

    electrical: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M11 19.9977L12.3359 9.31224C12.4297 8.56239 13.0672 8 13.8266
                 8H21.7297C22.4328 8 23 8.56708 23 9.27006C23 9.42004 22.9719 9.57469 22.9203 9.71529L20.75 15.4985H27.2797C28.2266
                 15.4985 29 16.2671 29 17.2185C29 17.5653 28.8969 17.9028 28.7 18.1886L19.6906 31.3579C19.4141 31.761 18.9594 32 18.4766
                 32H18.3406C17.6047 32 17.0047 31.4001 17.0047 30.6643C17.0047 30.5565 17.0187 30.4487 17.0469 30.3409L19.25
                 21.4974H12.5C11.6703 21.4974 11 20.8272 11 19.9977Z" />
        </svg>
    `,

    exterior: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M8 9.5C8 8.67031 8.67031 8 9.5 8H30.5C31.3297 8 32 8.67031 32 9.5C32 10.3297
                 31.3297 11 30.5 11V29C31.3297 29 32 29.6703 32 30.5C32 31.3297 31.3297 32 30.5 32H22.25V29.75C22.25 28.5078 21.2422
                 27.5 20 27.5C18.7578 27.5 17.75 28.5078 17.75 29.75V32H9.5C8.67031 32 8 31.3297 8 30.5C8 29.6703 8.67031 29 9.5
                 29V11C8.67031 11 8 10.3297 8 9.5ZM12.5 13.25V14.75C12.5 15.1625 12.8375 15.5 13.25 15.5H14.75C15.1625 15.5 15.5 15.1625
                 15.5 14.75V13.25C15.5 12.8375 15.1625 12.5 14.75 12.5H13.25C12.8375 12.5 12.5 12.8375 12.5 13.25ZM19.25 12.5C18.8375
                 12.5 18.5 12.8375 18.5 13.25V14.75C18.5 15.1625 18.8375 15.5 19.25 15.5H20.75C21.1625 15.5 21.5 15.1625 21.5
                 14.75V13.25C21.5 12.8375 21.1625 12.5 20.75 12.5H19.25ZM24.5 13.25V14.75C24.5 15.1625 24.8375 15.5 25.25
                 15.5H26.75C27.1625 15.5 27.5 15.1625 27.5 14.75V13.25C27.5 12.8375 27.1625 12.5 26.75 12.5H25.25C24.8375 12.5 24.5
                 12.8375 24.5 13.25ZM13.25 17C12.8375 17 12.5 17.3375 12.5 17.75V19.25C12.5 19.6625 12.8375 20 13.25 20H14.75C15.1625 20
                 15.5 19.6625 15.5 19.25V17.75C15.5 17.3375 15.1625 17 14.75 17H13.25ZM18.5 17.75V19.25C18.5 19.6625 18.8375 20 19.25
                 20H20.75C21.1625 20 21.5 19.6625 21.5 19.25V17.75C21.5 17.3375 21.1625 17 20.75 17H19.25C18.8375 17 18.5 17.3375 18.5
                 17.75ZM25.25 17C24.8375 17 24.5 17.3375 24.5 17.75V19.25C24.5 19.6625 24.8375 20 25.25 20H26.75C27.1625 20 27.5 19.6625
                 27.5 19.25V17.75C27.5 17.3375 27.1625 17 26.75 17H25.25ZM23.375 26C23.9984 26 24.5141 25.4891 24.3594 24.8844C23.8625
                 22.9391 22.1 21.5 20 21.5C17.9 21.5 16.1328 22.9391 15.6406 24.8844C15.4859 25.4844 16.0063 26 16.625 26H23.375Z" />
        </svg>
    `,

    firesafety: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M31.4515 8.34333C31.7984 8.62457 32 9.05111 32 9.50109V14.0009C32 14.4508
                 31.7984 14.8774 31.4515 15.1586C31.1045 15.4399 30.6451 15.557 30.2044 15.468L22.7032 13.9681C22.1312 13.8556 21.6858
                 13.4243 21.5452 12.8759H18.4978V14.5071C20.27 15.3508 21.4983 17.1554 21.4983 19.2506V26.0003H10.9966V19.2506C10.9966
                 17.1554 12.2249 15.3508 13.9971 14.5071V13.1572C12.3 13.6774 10.9029 14.8868 10.1387 16.4617C9.86676 17.0195 9.19165
                 17.2538 8.63374 16.982C8.07584 16.7101 7.84143 16.0351 8.11335 15.4774C9.24791 13.1478 11.4045 11.4041 13.9971
                 10.8323V9.50109C13.9971 8.67144 14.6675 8.00116 15.4973 8.00116H16.9976C17.8274 8.00116 18.4978 8.67144 18.4978
                 9.50109V10.626H21.5452C21.6858 10.0776 22.1312 9.64639 22.7032 9.52921L30.2044 8.02928C30.6451 7.94022 31.0999 8.05741
                 31.4515 8.33864V8.34333ZM21.4983 27.5002V29.0001C21.4983 30.6548 20.1528 32 18.4978 32H13.9971C12.3422 32 10.9966
                 30.6548 10.9966 29.0001V27.5002H21.4983ZM16.2475 12.5009C16.4464 12.5009 16.6372 12.4219 16.7779 12.2813C16.9186
                 12.1406 16.9976 11.9499 16.9976 11.751C16.9976 11.5521 16.9186 11.3613 16.7779 11.2207C16.6372 11.08 16.4464 11.001
                 16.2475 11.001C16.0485 11.001 15.8577 11.08 15.7171 11.2207C15.5764 11.3613 15.4973 11.5521 15.4973 11.751C15.4973
                 11.9499 15.5764 12.1406 15.7171 12.2813C15.8577 12.4219 16.0485 12.5009 16.2475 12.5009Z" />
        </svg>
    `,

    flooring: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M14.4286 13.25C14.4286 12.0069 13.3897 11 12.1071 11H9.32143C8.03884 11 7
                 12.0069 7 13.25V15.95C7 17.1931 8.03884 18.2 9.32143 18.2H12.1071C13.3897 18.2 14.4286 17.1931 14.4286
                 15.95V13.25ZM14.4286 24.05C14.4286 22.8069 13.3897 21.8 12.1071 21.8H9.32143C8.03884 21.8 7 22.8069 7 24.05V26.75C7
                 27.9931 8.03884 29 9.32143 29H12.1071C13.3897 29 14.4286 27.9931 14.4286 26.75V24.05ZM16.2857 13.25V15.95C16.2857
                 17.1931 17.3246 18.2 18.6071 18.2H21.3929C22.6754 18.2 23.7143 17.1931 23.7143 15.95V13.25C23.7143 12.0069 22.6754 11
                 21.3929 11H18.6071C17.3246 11 16.2857 12.0069 16.2857 13.25ZM23.7143 24.05C23.7143 22.8069 22.6754 21.8 21.3929
                 21.8H18.6071C17.3246 21.8 16.2857 22.8069 16.2857 24.05V26.75C16.2857 27.9931 17.3246 29 18.6071 29H21.3929C22.6754 29
                 23.7143 27.9931 23.7143 26.75V24.05ZM25.5714 13.25V15.95C25.5714 17.1931 26.6103 18.2 27.8929 18.2H30.6786C31.9612 18.2
                 33 17.1931 33 15.95V13.25C33 12.0069 31.9612 11 30.6786 11H27.8929C26.6103 11 25.5714 12.0069 25.5714 13.25ZM33
                 24.05C33 22.8069 31.9612 21.8 30.6786 21.8H27.8929C26.6103 21.8 25.5714 22.8069 25.5714 24.05V26.75C25.5714 27.9931
                 26.6103 29 27.8929 29H30.6786C31.9612 29 33 27.9931 33 26.75V24.05Z" />
        </svg>
    `,

    foundation: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M19.3469 9.2056C19.7551 9.45482 20 9.89742 20 10.3744V13.4682H23.8242C23.9789
                 12.8752 24.516 12.4369 25.1562 12.4369H28.9375C30.0762 12.4369 31 13.3608 31 14.4995C31 15.6382 30.0762 16.5621 28.9375
                 16.5621H25.1562C24.516 16.5621 23.9789 16.1238 23.8242 15.5308H20V18.6246C20 19.1016 19.7551 19.5442 19.3469
                 19.7934C18.9387 20.0426 18.4359 20.0684 18.0105 19.8536L9.76055 15.7284C9.29219 15.4964 9 15.0194 9 14.4995C9 13.9796
                 9.29219 13.5026 9.76055 13.2706L18.0105 9.14544C18.4359 8.93059 18.943 8.95637 19.3469 9.2056ZM21.375 19.9997C21.375
                 19.2391 21.9895 18.6246 22.75 18.6246H29.625C30.3855 18.6246 31 19.2391 31 19.9997V22.7498C31 23.5103 30.3855 24.1248
                 29.625 24.1248H22.75C21.9895 24.1248 21.375 23.5103 21.375 22.7498V19.9997ZM10.375 25.4998H14.5C15.2605 25.4998 15.875
                 26.1143 15.875 26.8749V29.625C15.875 30.3855 15.2605 31 14.5 31H10.375C9.61445 31 9 30.3855 9 29.625V26.8749C9 26.1143
                 9.61445 25.4998 10.375 25.4998ZM18.625 25.4998H29.625C30.3855 25.4998 31 26.1143 31 26.8749V29.625C31 30.3855 30.3855
                 31 29.625 31H18.625C17.8645 31 17.25 30.3855 17.25 29.625V26.8749C17.25 26.1143 17.8645 25.4998 18.625 25.4998Z" />
        </svg>
    `,

    framing: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M13.5 8.625C13.5 7.72617 12.7738 7 11.875 7C10.9762 7 10.25 7.72617 10.25
                 8.625V10.25H8.625C7.72617 10.25 7 10.9762 7 11.875C7 12.7738 7.72617 13.5 8.625 13.5H10.25V26.5C10.25 28.2926 11.7074
                 29.75 13.5 29.75H24.875V26.5H13.5V8.625ZM26.5 31.375C26.5 32.2738 27.2262 33 28.125 33C29.0238 33 29.75 32.2738 29.75
                 31.375V29.75H31.375C32.2738 29.75 33 29.0238 33 28.125C33 27.2262 32.2738 26.5 31.375 26.5H29.75V13.5C29.75 11.7074
                 28.2926 10.25 26.5 10.25H15.125V13.5H26.5V31.375Z" />
        </svg>
    `,

    landscaping: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M20.8125 12.146C16.8211 12.146 13.4441 14.6746 12.2965 18.1606C14.0027 17.326
                 15.9273 16.8595 17.9688 16.8595H22.4375C22.8844 16.8595 23.25 17.213 23.25 17.6451C23.25 18.0772 22.8844 18.4307
                 22.4375 18.4307H21.625H17.9688C17.1258 18.4307 16.3082 18.524 15.516 18.6958C14.2008 18.9855 12.982 19.501 11.8902
                 20.2032C8.94492 22.1033 7 25.3487 7 29.036V29.8216C7 30.4746 7.54336 31 8.21875 31C8.89414 31 9.4375 30.4746 9.4375
                 29.8216V29.036C9.4375 26.6449 10.4887 24.4944 12.1695 22.9871C13.175 26.694 16.6637 29.4288 20.8125
                 29.4288H20.8633C27.5715 29.3945 33 23.0018 33 15.1214C33 13.0298 32.6191 11.0413 31.9285 9.24919C31.7965 8.91041
                 31.2836 8.92514 31.1059 9.24428C30.1512 10.9726 28.2621 12.146 26.0938 12.146H20.8125Z" />
        </svg>
    `,

    metalworks: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M19.5544 14.1063C19.8934 13.8041 20.0271 13.3292 19.8504 12.9071C19.7406
                 12.6529 19.6212 12.4035 19.4876 12.1636L19.3395 11.9046C19.1963 11.6647 19.0388 11.4297 18.8716 11.2043C18.5995 10.8397
                 18.122 10.7198 17.6923 10.8637L16.3459 11.3098C15.835 10.8877 15.2478 10.5423 14.6175 10.3073L14.3263 8.91619C14.2356
                 8.47009 13.8918 8.11512 13.443 8.06236C13.1279 8.01919 12.808 8 12.4833 8H12.4499C12.1252 8 11.8053 8.01919 11.4902
                 8.05756C11.0414 8.11033 10.6977 8.47009 10.6069 8.91139L10.3157 10.3073C9.68068 10.5471 9.09819 10.8877 8.58731
                 11.3098L7.23612 10.8685C6.80641 10.7246 6.32896 10.8445 6.05681 11.2091C5.8897 11.4345 5.73215 11.6695 5.58413
                 11.9094L5.4409 12.1636C5.30721 12.4035 5.18785 12.6529 5.07803 12.9119C4.90138 13.3292 5.03506 13.8041 5.37406
                 14.1111L6.434 15.0609C6.38148 15.3823 6.35283 15.718 6.35283 16.0586C6.35283 16.3992 6.38148 16.735 6.434
                 17.0611L5.37406 18.0109C5.03506 18.3131 4.90138 18.788 5.07803 19.2101C5.18785 19.4643 5.30721 19.7138 5.4409
                 19.9584L5.58413 20.2078C5.72737 20.4525 5.88493 20.6827 6.05681 20.9082C6.32896 21.2727 6.80641 21.3926 7.23612
                 21.2487L8.58254 20.8026C9.09341 21.2248 9.68068 21.5701 10.3109 21.8052L10.6022 23.201C10.6929 23.6471 11.0366 24.0021
                 11.4855 24.0549C11.8053 24.0932 12.13 24.1124 12.4595 24.1124C12.7889 24.1124 13.1136 24.0932 13.4335 24.0549C13.8823
                 24.0021 14.226 23.6423 14.3167 23.201L14.608 21.8052C15.243 21.5653 15.8255 21.2248 16.3364 20.8026L17.6828
                 21.2487C18.1125 21.3926 18.5899 21.2727 18.8621 20.9082C19.0292 20.6827 19.1868 20.4525 19.33 20.2078L19.478
                 19.9488C19.6117 19.709 19.7311 19.4595 19.8409 19.2053C20.0175 18.788 19.8838 18.3131 19.5449 18.0061L18.4849
                 17.0563C18.5374 16.7302 18.5661 16.3944 18.5661 16.0538C18.5661 15.7132 18.5374 15.3775 18.4849 15.0513L19.5449
                 14.1015L19.5544 14.1063ZM10.1725 16.0586C10.1725 15.448 10.4139 14.8623 10.8437 14.4305C11.2735 13.9987 11.8564 13.7562
                 12.4642 13.7562C13.072 13.7562 13.655 13.9987 14.0848 14.4305C14.5145 14.8623 14.756 15.448 14.756 16.0586C14.756
                 16.6693 14.5145 17.2549 14.0848 17.6867C13.655 18.1185 13.072 18.3611 12.4642 18.3611C11.8564 18.3611 11.2735 18.1185
                 10.8437 17.6867C10.4139 17.2549 10.1725 16.6693 10.1725 16.0586ZM28.922 31.6242C29.2228 31.9648 29.6955 32.0991 30.1157
                 31.9216C30.3687 31.8113 30.617 31.6914 30.8557 31.557L31.1135 31.4083C31.3523 31.2644 31.5862 31.1061 31.8106
                 30.9383C32.1735 30.6648 32.2928 30.1852 32.1496 29.7535L31.7056 28.4008C32.1257 27.8875 32.4695 27.2975 32.7035
                 26.6643L34.0928 26.3717C34.5369 26.2806 34.8902 25.9352 34.9427 25.4843C34.9809 25.1629 35 24.8367 35 24.5058C35
                 24.1748 34.9809 23.8486 34.9427 23.5272C34.8902 23.0763 34.5321 22.7309 34.0928 22.6398L32.7035 22.3424C32.4647 21.7044
                 32.1257 21.1192 31.7056 20.606L32.1496 19.2533C32.2928 18.8216 32.1735 18.3419 31.8106 18.0685C31.5862 17.9006 31.3523
                 17.7423 31.1135 17.5936L30.8605 17.4497C30.6218 17.3154 30.3735 17.1954 30.1157 17.0851C29.7003 16.9076 29.2276 17.042
                 28.922 17.3825L27.9767 18.4474C27.652 18.3946 27.3178 18.3659 26.9788 18.3659C26.6398 18.3659 26.3056 18.3946 25.9809
                 18.4474L25.0356 17.3825C24.7348 17.042 24.2621 16.9076 23.8419 17.0851C23.5889 17.1954 23.3406 17.3154 23.0971
                 17.4497L22.8488 17.5936C22.6053 17.7375 22.3761 17.8958 22.1517 18.0685C21.7889 18.3419 21.6695 18.8216 21.8128
                 19.2533L22.2568 20.606C21.8366 21.1192 21.4929 21.7092 21.2589 22.3424L19.8695 22.6302C19.4255 22.7214 19.0722 23.0667
                 19.0197 23.5176C18.9815 23.839 18.9624 24.1652 18.9624 24.4962C18.9624 24.8271 18.9815 25.1533 19.0197 25.4747C19.0722
                 25.9256 19.4303 26.271 19.8695 26.3621L21.2589 26.6547C21.4976 27.2927 21.8366 27.8779 22.2568 28.3912L21.8128
                 29.7439C21.6695 30.1756 21.7889 30.6552 22.1517 30.9287C22.3761 31.0966 22.6053 31.2548 22.8488 31.3987L23.1067
                 31.5475C23.3454 31.6818 23.5937 31.8017 23.8467 31.912C24.2621 32.0895 24.7348 31.9552 25.0403 31.6146L25.9857
                 30.5497C26.3104 30.6025 26.6446 30.6313 26.9836 30.6313C27.3226 30.6313 27.6568 30.6025 27.9814 30.5497L28.9268
                 31.6146L28.922 31.6242ZM26.9788 22.1985C27.2798 22.1985 27.5778 22.2581 27.8558 22.3738C28.1339 22.4895 28.3865 22.6591
                 28.5993 22.8729C28.8121 23.0867 28.9809 23.3405 29.0961 23.6199C29.2113 23.8992 29.2706 24.1986 29.2706 24.501C29.2706
                 24.8033 29.2113 25.1027 29.0961 25.3821C28.9809 25.6614 28.8121 25.9152 28.5993 26.129C28.3865 26.3429 28.1339 26.5125
                 27.8558 26.6282C27.5778 26.7439 27.2798 26.8034 26.9788 26.8034C26.6778 26.8034 26.3798 26.7439 26.1018 26.6282C25.8237
                 26.5125 25.5711 26.3429 25.3583 26.129C25.1455 25.9152 24.9766 25.6614 24.8615 25.3821C24.7463 25.1027 24.687 24.8033
                 24.687 24.501C24.687 24.1986 24.7463 23.8992 24.8615 23.6199C24.9766 23.3405 25.1455 23.0867 25.3583 22.8729C25.5711
                 22.6591 25.8237 22.4895 26.1018 22.3738C26.3798 22.2581 26.6778 22.1985 26.9788 22.1985Z" />
        </svg>
    `,

    painting: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M8 11C8 9.34531 9.34531 8 11 8H24.5C26.1547 8 27.5 9.34531 27.5 11V14C27.5
                 15.6547 26.1547 17 24.5 17H11C9.34531 17 8 15.6547 8 14V11ZM15.5 24.5C15.5 23.6703 16.1703 23 17 23V22.25C17 20.1781
                 18.6781 18.5 20.75 18.5H27.5C28.3297 18.5 29 17.8297 29 17V15.5V11.2578C30.7484 11.8766 32 13.5406 32 15.5V17C32
                 19.4844 29.9844 21.5 27.5 21.5H20.75C20.3375 21.5 20 21.8375 20 22.25V23C20.8297 23 21.5 23.6703 21.5 24.5V30.5C21.5
                 31.3297 20.8297 32 20 32H17C16.1703 32 15.5 31.3297 15.5 30.5V24.5Z" />
        </svg>
    `,

    plumbing: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M20 32C15.0312 32 11 27.9688 11 23C11 18.725 17.1031 10.7047 18.8094
                 8.54844C19.0906 8.19687 19.5078 8 19.9578 8H20.0422C20.4922 8 20.9094 8.19687 21.1906 8.54844C22.8969 10.7047 29 18.725
                 29 23C29 27.9688 24.9688 32 20 32ZM15.5 23.75C15.5 23.3375 15.1625 23 14.75 23C14.3375 23 14 23.3375 14 23.75C14
                 26.6516 16.3484 29 19.25 29C19.6625 29 20 28.6625 20 28.25C20 27.8375 19.6625 27.5 19.25 27.5C17.1781 27.5 15.5 25.8219
                 15.5 23.75Z" />
        </svg>
    `,

    roofing: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M31.9937 19.9764C31.9937 20.7497 31.3685 21.3554 30.66 21.3554H29.3263L29.3555
                 28.2376C29.3555 28.3536 29.3472 28.4696 29.3347 28.5856V29.2816C29.3347 30.231 28.5887 31 27.6676 31H27.0007C26.9549 31
                 26.9091 31 26.8632 30.9957C26.8049 31 26.7465 31 26.6882 31L25.3336 30.9957H24.3334C23.4123 30.9957 22.6663 30.2267
                 22.6663 29.2773V28.2462V25.4968C22.6663 24.7364 22.0703 24.122 21.3326 24.122H18.6653C17.9276 24.122 17.3316 24.7364
                 17.3316 25.4968V28.2462V29.2773C17.3316 30.2267 16.5856 30.9957 15.6645 30.9957H14.6642H13.3347C13.2722 30.9957 13.2097
                 30.9914 13.1472 30.9871C13.0972 30.9914 13.0471 30.9957 12.9971 30.9957H12.3303C11.4092 30.9957 10.6632 30.2267 10.6632
                 29.2773V24.4657C10.6632 24.4271 10.6632 24.3841 10.6674 24.3454V21.3511H9.33368C8.58348 21.3511 8 20.7497 8 19.9721C8
                 19.5854 8.12503 19.2417 8.41677 18.941L19.0987 9.34368C19.3904 9.04296 19.7239 9 20.0156 9C20.3073 9 20.6408 9.08592
                 20.8908 9.30072L31.5353 18.9453C31.8687 19.246 32.0354 19.5897 31.9937 19.9764Z" />
        </svg>
    `,

    sheetrock: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M10 12.8571C10 11.2813 11.2813 10 12.8571 10H27.1429C28.7188 10 30 11.2813 30
                 12.8571V27.1429C30 28.7188 28.7188 30 27.1429 30H12.8571C11.2813 30 10 28.7188 10 27.1429V12.8571Z" />
        </svg>
    `,

    unassigned: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M31 27.1429C31 28.7188 29.7668 30 28.25 30H11.75C10.2332 30 9 28.7188 9
                 27.1429V12.8571C9 11.2813 10.2332 10 11.75 10H17.25C18.1137 10 18.9301 10.4241 19.45 11.1429L20.275 12.2857C20.5328
                 12.6473 20.941 12.8571 21.375 12.8571H28.25C29.7668 12.8571 31 14.1384 31 15.7143V27.1429ZM18.9688 25.3571C18.9688
                 25.9509 19.4285 26.4286 20 26.4286C20.5715 26.4286 21.0312 25.9509 21.0312 25.3571V22.5H23.7812C24.3527 22.5 24.8125
                 22.0223 24.8125 21.4286C24.8125 20.8348 24.3527 20.3571 23.7812 20.3571H21.0312V17.5C21.0312 16.9063 20.5715 16.4286 20
                 16.4286C19.4285 16.4286 18.9688 16.9063 18.9688 17.5V20.3571H16.2188C15.6473 20.3571 15.1875 20.8348 15.1875
                 21.4286C15.1875 22.0223 15.6473 22.5 16.2188 22.5H18.9688V25.3571Z" />
        </svg>
    `,

    windows: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M10.4 13.4997H18.8V13.8824C18.8 15.4057 17.9113 16.7727 16.5538 17.3351C14.285
                 18.2842 12.8 20.5691 12.8 23.1156V25.998H10.4V13.4997ZM21.2 13.4997H29.6V25.998H27.0613L26.9225 25.8223L24.1025
                 22.2993C23.7613 21.8736 23.255 21.6236 22.7188 21.6236H20.0525L18.515 19.1591C18.5038 19.1396 18.4925 19.12 18.4775
                 19.1044C20.1538 17.9717 21.1963 16.0189 21.1963 13.8863V13.5036L21.2 13.4997ZM23.9375 25.998H22.22L23.8213
                 28.4977H30.2C31.1938 28.4977 32 27.658 32 26.6229V12.8748C32 11.8397 31.1938 11 30.2 11H9.8C8.80625 11 8 11.8397 8
                 12.8748V26.6229C8 27.658 8.80625 28.4977 9.8 28.4977H19.5575L20.8025 30.4428C21.17 31.0169 21.9163 31.1731 22.4675
                 30.7904C23.0188 30.4076 23.1688 29.6304 22.8013 29.0562L19.6438 24.1233H22.4413L23.9413 25.998H23.9375ZM17.9563
                 25.998H15.2V23.1156C15.2 22.7172 15.26 22.3305 15.3763 21.9634L17.9563 25.998ZM15.2 15.9993C15.2 15.5021 15.0104
                 15.0253 14.6728 14.6737C14.3352 14.3221 13.8774 14.1246 13.4 14.1246C12.9226 14.1246 12.4648 14.3221 12.1272
                 14.6737C11.7896 15.0253 11.6 15.5021 11.6 15.9993C11.6 16.4966 11.7896 16.9734 12.1272 17.325C12.4648 17.6766 12.9226
                 17.8741 13.4 17.8741C13.8774 17.8741 14.3352 17.6766 14.6728 17.325C15.0104 16.9734 15.2 16.4966 15.2 15.9993Z" />
        </svg>
    `,

    id: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M10.6667 11C9.19583 11 8 12.1531 8 13.5714V26.4286C8 27.8469 9.19583 29
                 10.6667 29H29.3333C30.8042 29 32 27.8469 32 26.4286V13.5714C32 12.1531 30.8042 11 29.3333 11H10.6667ZM14
                 21.2857H16.6667C18.5083 21.2857 20 22.7241 20 24.5C20 24.8536 19.7 25.1429 19.3333 25.1429H11.3333C10.9667 25.1429
                 10.6667 24.8536 10.6667 24.5C10.6667 22.7241 12.1583 21.2857 14 21.2857ZM12.6667 17.4286C12.6667 16.7466 12.9476
                 16.0925 13.4477 15.6103C13.9478 15.1281 14.6261 14.8571 15.3333 14.8571C16.0406 14.8571 16.7189 15.1281 17.219
                 15.6103C17.719 16.0925 18 16.7466 18 17.4286C18 18.1106 17.719 18.7646 17.219 19.2468C16.7189 19.7291 16.0406 20
                 15.3333 20C14.6261 20 13.9478 19.7291 13.4477 19.2468C12.9476 18.7646 12.6667 18.1106 12.6667 17.4286ZM23.3333
                 16.1429H28.6667C29.0333 16.1429 29.3333 16.4321 29.3333 16.7857C29.3333 17.1393 29.0333 17.4286 28.6667
                 17.4286H23.3333C22.9667 17.4286 22.6667 17.1393 22.6667 16.7857C22.6667 16.4321 22.9667 16.1429 23.3333
                 16.1429ZM23.3333 18.7143H28.6667C29.0333 18.7143 29.3333 19.0036 29.3333 19.3571C29.3333 19.7107 29.0333 20 28.6667
                 20H23.3333C22.9667 20 22.6667 19.7107 22.6667 19.3571C22.6667 19.0036 22.9667 18.7143 23.3333 18.7143ZM23.3333
                 21.2857H28.6667C29.0333 21.2857 29.3333 21.575 29.3333 21.9286C29.3333 22.2821 29.0333 22.5714 28.6667
                 22.5714H23.3333C22.9667 22.5714 22.6667 22.2821 22.6667 21.9286C22.6667 21.575 22.9667 21.2857 23.3333 21.2857Z" />
        </svg>
    `,
    cog: `
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M31.9335 15.4618C32.0926 15.9037 31.9583 16.3964 31.6152 16.7113L29.4617
                 18.7124C29.5164 19.134 29.5463 19.5657 29.5463 20.0025C29.5463 20.4393 29.5164 20.8711 29.4617 21.2926L31.6152
                 23.2938C31.9583 23.6087 32.0926 24.1014 31.9335 24.5433C31.7146 25.1477 31.4511 25.7267 31.1477 26.2854L30.9139
                 26.6968C30.5857 27.2555 30.2177 27.7837 29.8148 28.2815C29.5214 28.6472 29.034 28.7691 28.5964 28.6269L25.8262
                 27.7279C25.1598 28.251 24.4238 28.6878 23.638 29.018L23.0163 31.9181C22.9169 32.3803 22.5687 32.746 22.1112
                 32.8222C21.4249 32.9391 20.7186 33 19.9975 33C19.2764 33 18.5702 32.9391 17.8839 32.8222C17.4263 32.746 17.0782 32.3803
                 16.9787 31.9181L16.357 29.018C15.5713 28.6878 14.8352 28.251 14.1688 27.7279L11.4036 28.632C10.966 28.7742 10.4786
                 28.6472 10.1852 28.2866C9.78232 27.7888 9.4143 27.2606 9.08606 26.7019L8.85231 26.2905C8.54894 25.7318 8.28536 25.1528
                 8.06653 24.5483C7.90738 24.1065 8.04166 23.6138 8.38482 23.2989L10.5383 21.2977C10.4836 20.8711 10.4537 20.4393 10.4537
                 20.0025C10.4537 19.5657 10.4836 19.134 10.5383 18.7124L8.38482 16.7113C8.04166 16.3964 7.90738 15.9037 8.06653
                 15.4618C8.28536 14.8574 8.54894 14.2784 8.85231 13.7197L9.08606 13.3083C9.4143 12.7496 9.78232 12.2213 10.1852
                 11.7236C10.4786 11.3579 10.966 11.236 11.4036 11.3782L14.1738 12.2772C14.8402 11.7541 15.5762 11.3172 16.362
                 10.9871L16.9837 8.08693C17.0832 7.62473 17.4313 7.25903 17.8888 7.18285C18.5751 7.06095 19.2814 7 20.0025 7C20.7236 7
                 21.4298 7.06095 22.1161 7.17777C22.5737 7.25396 22.9218 7.61965 23.0213 8.08185L23.643 10.982C24.4287 11.3122 25.1648
                 11.749 25.8312 12.2721L28.6013 11.3731C29.039 11.2309 29.5264 11.3579 29.8198 11.7185C30.2226 12.2163 30.5907 12.7445
                 30.9189 13.3032L31.1527 13.7146C31.456 14.2733 31.7196 14.8523 31.9384 15.4567L31.9335 15.4618ZM20.0025 24.0658C21.0577
                 24.0658 22.0697 23.6377 22.8158 22.8757C23.562 22.1137 23.9811 21.0802 23.9811 20.0025C23.9811 18.9249 23.562 17.8914
                 22.8158 17.1294C22.0697 16.3673 21.0577 15.9392 20.0025 15.9392C18.9473 15.9392 17.9353 16.3673 17.1892 17.1294C16.443
                 17.8914 16.0238 18.9249 16.0238 20.0025C16.0238 21.0802 16.443 22.1137 17.1892 22.8757C17.9353 23.6377 18.9473 24.0658
                 20.0025 24.0658Z" />
            </svg>
    `,
    sun: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M24.9446 8.05798C25.1789 8.1564 25.3477 8.36731 25.3945 8.61571L26.3272
                 13.6728L31.3843 14.6008C31.6327 14.6476 31.8436 14.8164 31.942 15.0507C32.0404 15.2851 32.0123 15.5522 31.867
                 15.7631L28.9471 20L31.867 24.2322C32.0123 24.4431 32.0404 24.7103 31.942 24.9446C31.8436 25.1789 31.6327 25.3477
                 31.3843 25.3945L26.3272 26.3272L25.3945 31.3843C25.3477 31.6327 25.1789 31.8436 24.9446 31.942C24.7103 32.0404 24.4431
                 32.0123 24.2322 31.867L20 28.9471L15.7678 31.867C15.5569 32.0123 15.2897 32.0404 15.0554 31.942C14.8211 31.8436 14.6523
                 31.6327 14.6055 31.3843L13.6728 26.3272L8.61571 25.3945C8.36731 25.3477 8.1564 25.1789 8.05798 24.9446C7.95955 24.7103
                 7.98767 24.4431 8.13297 24.2322L11.0529 20L8.13297 15.7678C7.98767 15.5569 7.95955 15.2897 8.05798 15.0554C8.1564
                 14.8211 8.36731 14.6523 8.61571 14.6055L13.6728 13.6728L14.6055 8.61571C14.6523 8.36731 14.8211 8.1564 15.0554
                 8.05798C15.2897 7.95955 15.5569 7.98767 15.7678 8.13297L20 11.0529L24.2322 8.13297C24.4431 7.98767 24.7103 7.95955
                 24.9446 8.05798ZM15.5007 20C15.5007 18.8067 15.9747 17.6623 16.8185 16.8185C17.6623 15.9747 18.8067 15.5007 20
                 15.5007C21.1933 15.5007 22.3377 15.9747 23.1815 16.8185C24.0253 17.6623 24.4993 18.8067 24.4993 20C24.4993 21.1933
                 24.0253 22.3377 23.1815 23.1815C22.3377 24.0253 21.1933 24.4993 20 24.4993C18.8067 24.4993 17.6623 24.0253 16.8185
                 23.1815C15.9747 22.3377 15.5007 21.1933 15.5007 20ZM25.9991 20C25.9991 18.4089 25.3671 16.883 24.242 15.758C23.117
                 14.6329 21.5911 14.0009 20 14.0009C18.4089 14.0009 16.883 14.6329 15.758 15.758C14.6329 16.883 14.0009 18.4089 14.0009
                 20C14.0009 21.5911 14.6329 23.117 15.758 24.242C16.883 25.3671 18.4089 25.9991 20 25.9991C21.5911 25.9991 23.117
                 25.3671 24.242 24.242C25.3671 23.117 25.9991 21.5911 25.9991 20Z" />
        </svg>
    `,
    bell: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M20.499 8C19.6693 8 18.999 8.67031 18.999 9.5V10.4C15.5772 11.0938 12.9991
                 14.1219 12.9991 17.75V18.6313C12.9991 20.8344 12.1881 22.9625 10.7257 24.6125L10.3788 25.0016C9.98504 25.4422 9.89129
                 26.075 10.1304 26.6141C10.3694 27.1531 10.9085 27.5 11.4991 27.5H29.4988C30.0895 27.5 30.6238 27.1531 30.8676
                 26.6141C31.1113 26.075 31.0129 25.4422 30.6191 25.0016L30.2723 24.6125C28.8098 22.9625 27.9989 20.8391 27.9989
                 18.6313V17.75C27.9989 14.1219 25.4208 11.0938 21.9989 10.4V9.5C21.9989 8.67031 21.3286 8 20.499 8ZM22.6224
                 31.1234C23.1849 30.5609 23.4989 29.7969 23.4989 29H20.499H17.499C17.499 29.7969 17.8131 30.5609 18.3756 31.1234C18.938
                 31.6859 19.7021 32 20.499 32C21.2958 32 22.0599 31.6859 22.6224 31.1234Z" />
        </svg>
    `,
    data: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M30.5 11.75V14C30.5 16.0719 25.7984 17.75 20 17.75C14.2016 17.75 9.5 16.0719
                 9.5 14V11.75C9.5 9.67812 14.2016 8 20 8C25.7984 8 30.5 9.67812 30.5 11.75ZM27.9313 18.0641C28.9063 17.7172 29.8016
                 17.2719 30.5 16.7234V21.5C30.5 23.5719 25.7984 25.25 20 25.25C14.2016 25.25 9.5 23.5719 9.5 21.5V16.7234C10.1984
                 17.2766 11.0938 17.7172 12.0687 18.0641C14.1734 18.8141 16.9766 19.25 20 19.25C23.0234 19.25 25.8266 18.8141 27.9313
                 18.0641ZM9.5 24.2234C10.1984 24.7766 11.0938 25.2172 12.0687 25.5641C14.1734 26.3141 16.9766 26.75 20 26.75C23.0234
                 26.75 25.8266 26.3141 27.9313 25.5641C28.9063 25.2172 29.8016 24.7719 30.5 24.2234V28.25C30.5 30.3219 25.7984 32 20
                 32C14.2016 32 9.5 30.3219 9.5 28.25V24.2234Z" />
        </svg>
    `,
    mail: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M10.25 11C9.00781 11 8 12.0078 8 13.25C8 13.9578 8.33281 14.6234 8.9
                 15.05L19.1 22.7C19.6344 23.0984 20.3656 23.0984 20.9 22.7L31.1 15.05C31.6672 14.6234 32 13.9578 32 13.25C32 12.0078
                 30.9922 11 29.75 11H10.25ZM8 16.25V26C8 27.6547 9.34531 29 11 29H29C30.6547 29 32 27.6547 32 26V16.25L21.8 23.9C20.7312
                 24.7016 19.2688 24.7016 18.2 23.9L8 16.25Z" />
        </svg>
    `,
    male: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M17.5979 9.4375C17.5979 8.79103 17.8507 8.17105 18.3007 7.71393C18.7507
                 7.25681 19.3611 7 19.9975 7C20.6339 7 21.2443 7.25681 21.6943 7.71393C22.1443 8.17105 22.3971 8.79103 22.3971
                 9.4375C22.3971 10.084 22.1443 10.704 21.6943 11.1611C21.2443 11.6182 20.6339 11.875 19.9975 11.875C19.3611 11.875
                 18.7507 11.6182 18.3007 11.1611C17.8507 10.704 17.5979 10.084 17.5979 9.4375ZM19.5976 24.875V31.375C19.5976 32.2738
                 18.8827 33 17.9978 33C17.113 33 16.3981 32.2738 16.3981 31.375V20.0457L14.9683 22.4629C14.5134 23.2297 13.5286 23.4785
                 12.7737 23.0164C12.0188 22.5543 11.7738 21.5539 12.2288 20.7871L15.1433 15.8613C16.0132 14.3938 17.5729 13.4949 19.2576
                 13.4949H20.7424C22.4271 13.4949 23.9868 14.3938 24.8567 15.8613L27.7712 20.7871C28.2262 21.5539 27.9812 22.5543 27.2263
                 23.0164C26.4714 23.4785 25.4866 23.2297 25.0317 22.4629L23.5969 20.0457V31.375C23.5969 32.2738 22.882 33 21.9972
                 33C21.1123 33 20.3974 32.2738 20.3974 31.375V24.875H19.5976Z" />
        </svg>
    `,
    tags: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M24.1687 10.317L30.1582 16.0891C32.6139 18.4551 32.6139 22.2586 30.1582
                 24.6246L24.9092 29.6825C24.4733 30.1021 23.761 30.1066 23.3204 29.6914C22.8799 29.2762 22.8752 28.5977 23.3111
                 28.178L28.5554 23.1202C30.1441 21.589 30.1441 19.1292 28.5554 17.598L22.5706 11.8258C22.1347 11.4062 22.1394 10.7277
                 22.58 10.3125C23.0205 9.89732 23.7329 9.90179 24.1687 10.3214V10.317ZM8 18.8167V12.1428C8 10.9598 9.00762 10 10.2496
                 10H17.256C18.0527 10 18.8166 10.2991 19.379 10.8348L27.2525 18.3346C28.4241 19.4506 28.4241 21.2586 27.2525
                 22.3746L20.9959 28.3343C19.8243 29.4503 17.9262 29.4503 16.7545 28.3343L8.88108 20.8345C8.314 20.2988 8 19.5756 8
                 18.8167ZM14.7487 14.9999C14.7487 14.621 14.5907 14.2576 14.3094 13.9897C14.0282 13.7218 13.6467 13.5713 13.249
                 13.5713C12.8512 13.5713 12.4698 13.7218 12.1885 13.9897C11.9073 14.2576 11.7493 14.621 11.7493 14.9999C11.7493 15.3787
                 11.9073 15.7421 12.1885 16.01C12.4698 16.2779 12.8512 16.4284 13.249 16.4284C13.6467 16.4284 14.0282 16.2779 14.3094
                 16.01C14.5907 15.7421 14.7487 15.3787 14.7487 14.9999Z" />
        </svg>
    `,
    tree: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M19.342 7.29961L12.0446 15.6023C11.8531 15.8156 11.75 16.1 11.75 16.3895C11.75
                 17.0395 12.2558 17.5625 12.8844 17.5625H14.1071L10.5027 21.2898C10.2964 21.5031 10.1786 21.7977 10.1786 22.1023C10.1786
                 22.7371 10.6746 23.25 11.2884 23.25H12.9286L9.26518 27.7949C9.0933 28.0082 9 28.2773 9 28.5566C9 29.2168 9.51562 29.75
                 10.154 29.75H18.4286V31.375C18.4286 32.2738 19.1308 33 20 33C20.8692 33 21.5714 32.2738 21.5714
                 31.375V29.75H29.846C30.4844 29.75 31 29.2168 31 28.5566C31 28.2773 30.9067 28.0082 30.7348 27.7949L27.0714
                 23.25H28.7116C29.3254 23.25 29.8214 22.7371 29.8214 22.1023C29.8214 21.7977 29.7036 21.5031 29.4973 21.2898L25.8929
                 17.5625H27.1156C27.7393 17.5625 28.25 17.0395 28.25 16.3895C28.25 16.1 28.1469 15.8156 27.9554 15.6023L20.658
                 7.29961C20.4911 7.10664 20.2504 7 20 7C19.7496 7 19.5089 7.10664 19.342 7.29961Z" />
        </svg>
    `,
    wind: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M21.5 9.5C21.5 10.3297 22.1703 11 23 11H24.5C25.3297 11 26 11.6703 26 12.5C26
                 13.3297 25.3297 14 24.5 14H9.5C8.67031 14 8 14.6703 8 15.5C8 16.3297 8.67031 17 9.5 17H24.5C26.9844 17 29 14.9844 29
                 12.5C29 10.0156 26.9844 8 24.5 8H23C22.1703 8 21.5 8.67031 21.5 9.5ZM24.5 26C24.5 26.8297 25.1703 27.5 26
                 27.5H27.5C29.9844 27.5 32 25.4844 32 23C32 20.5156 29.9844 18.5 27.5 18.5H9.5C8.67031 18.5 8 19.1703 8 20C8 20.8297
                 8.67031 21.5 9.5 21.5H27.5C28.3297 21.5 29 22.1703 29 23C29 23.8297 28.3297 24.5 27.5 24.5H26C25.1703 24.5 24.5 25.1703
                 24.5 26ZM14 32H15.5C17.9844 32 20 29.9844 20 27.5C20 25.0156 17.9844 23 15.5 23H9.5C8.67031 23 8 23.6703 8 24.5C8
                 25.3297 8.67031 26 9.5 26H15.5C16.3297 26 17 26.6703 17 27.5C17 28.3297 16.3297 29 15.5 29H14C13.1703 29 12.5 29.6703
                 12.5 30.5C12.5 31.3297 13.1703 32 14 32Z" />
        </svg>
    `,
    cloud: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M8 23.2143C8 26.4085 10.4188 29 13.4 29H27.2C29.8513 29 32 26.6978 32
                 23.8571C32 21.3701 30.35 19.2929 28.16 18.8188C28.3137 18.3888 28.4 17.9187 28.4 17.4286C28.4 15.2991 26.7875 13.5714
                 24.8 13.5714C24.0613 13.5714 23.3713 13.8125 22.8013 14.2223C21.7625 12.2937 19.8238 11 17.6 11C14.285 11 11.6 13.8768
                 11.6 17.4286C11.6 17.5371 11.6038 17.6455 11.6075 17.754C9.5075 18.5455 8 20.6911 8 23.2143Z" />
        </svg>
    `,

    globe: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M24.5 20C24.5 21.0406 24.4438 22.0438 24.3453 23H15.6594C15.5562 22.0438
                 15.5047 21.0406 15.5047 20C15.5047 18.9594 15.5609 17.9562 15.6594 17H24.3453C24.4484 17.9562 24.5 18.9594 24.5
                 20ZM25.85 17H31.6203C31.8687 17.9609 32 18.9641 32 20C32 21.0359 31.8687 22.0391 31.6203 23H25.85C25.9484 22.0344 26
                 21.0312 26 20C26 18.9688 25.9484 17.9656 25.85 17ZM31.1281 15.5H25.6578C25.1891 12.5047 24.2609 9.99688 23.0656
                 8.39375C26.7359 9.36406 29.7219 12.0266 31.1234 15.5H31.1281ZM24.1391 15.5H15.8609C16.1469 13.7937 16.5875 12.2844
                 17.1266 11.0609C17.6187 9.95469 18.1672 9.15313 18.6969 8.64688C19.2219 8.15 19.6578 8 20 8C20.3422 8 20.7781 8.15
                 21.3031 8.64688C21.8328 9.15313 22.3812 9.95469 22.8734 11.0609C23.4172 12.2797 23.8531 13.7891 24.1391 15.5ZM14.3422
                 15.5H8.87188C10.2781 12.0266 13.2594 9.36406 16.9344 8.39375C15.7391 9.99688 14.8109 12.5047 14.3422 15.5ZM8.37969
                 17H14.15C14.0516 17.9656 14 18.9688 14 20C14 21.0312 14.0516 22.0344 14.15 23H8.37969C8.13125 22.0391 8 21.0359 8 20C8
                 18.9641 8.13125 17.9609 8.37969 17ZM17.1266 28.9344C16.5828 27.7156 16.1469 26.2063 15.8609 24.5H24.1391C23.8531
                 26.2063 23.4125 27.7156 22.8734 28.9344C22.3812 30.0406 21.8328 30.8422 21.3031 31.3484C20.7781 31.85 20.3422 32 20
                 32C19.6578 32 19.2219 31.85 18.6969 31.3531C18.1672 30.8469 17.6187 30.0453 17.1266 28.9391V28.9344ZM14.3422
                 24.5C14.8109 27.4953 15.7391 30.0031 16.9344 31.6063C13.2594 30.6359 10.2781 27.9734 8.87188 24.5H14.3422ZM31.1281
                 24.5C29.7219 27.9734 26.7406 30.6359 23.0703 31.6063C24.2656 30.0031 25.1891 27.4953 25.6625 24.5H31.1328H31.1281Z" />
        </svg>

    `,
    ruler: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M16.2861 31.3323C15.3957 32.2226 13.9482 32.2226 13.0531 31.3323L8.66779
                 26.9432C7.7774 26.053 7.7774 24.6058 8.66779 23.7108L11.0818 21.2972L13.3673 23.5823C13.6625 23.8774 14.1482 23.8774
                 14.4434 23.5823C14.7386 23.2871 14.7386 22.8015 14.4434 22.5064L12.1579 20.2214L14.1292 18.2505L16.4146 20.5356C16.7099
                 20.8307 17.1955 20.8307 17.4907 20.5356C17.7859 20.2404 17.7859 19.7548 17.4907 19.4597L15.2052 17.1747L17.1765
                 15.2038L19.462 17.4888C19.7572 17.784 20.2428 17.784 20.538 17.4888C20.8333 17.1937 20.8333 16.7081 20.538
                 16.413L18.2526 14.1279L20.2238 12.1571L22.5093 14.4421C22.8045 14.7373 23.2902 14.7373 23.5854 14.4421C23.8806 14.147
                 23.8806 13.6614 23.5854 13.3663L21.2999 11.0812L23.7139 8.66766C24.6043 7.77745 26.0518 7.77745 26.9469 8.66766L31.3322
                 13.0521C32.2226 13.9423 32.2226 15.3895 31.3322 16.2844L16.2861 31.3323Z" />
        </svg>
    `,
    truck: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M8.1 9C6.94062 9 6 9.92383 6 11.0625V24.8125C6 25.9512 6.94062 26.875 8.1
                 26.875H8.8C8.8 29.1523 10.6812 31 13 31C15.3187 31 17.2 29.1523 17.2 26.875H22.8C22.8 29.1523 24.6812 31 27 31C29.3187
                 31 31.2 29.1523 31.2 26.875H32.6C33.3744 26.875 34 26.2605 34 25.5C34 24.7395 33.3744 24.125 32.6
                 24.125V21.375V20V19.1965C32.6 18.466 32.3069 17.7656 31.7819 17.25L28.4 13.9285C27.875 13.4129 27.1619 13.125 26.4181
                 13.125H24.2V11.0625C24.2 9.92383 23.2594 9 22.1 9H8.1ZM24.2 15.875H26.4181L29.8 19.1965V20H24.2V15.875ZM10.9
                 26.875C10.9 26.328 11.1212 25.8034 11.5151 25.4166C11.9089 25.0298 12.443 24.8125 13 24.8125C13.557 24.8125 14.0911
                 25.0298 14.4849 25.4166C14.8788 25.8034 15.1 26.328 15.1 26.875C15.1 27.422 14.8788 27.9466 14.4849 28.3334C14.0911
                 28.7202 13.557 28.9375 13 28.9375C12.443 28.9375 11.9089 28.7202 11.5151 28.3334C11.1212 27.9466 10.9 27.422 10.9
                 26.875ZM27 24.8125C27.557 24.8125 28.125 25.1705 28.125 25.5C28.125 25.8295 27.557 26.1875 27 26.1875C26.443 26.1875
                 25.875 25.8295 25.875 25.5C25.875 25.1705 26.443 24.8125 27 24.8125Z" />
        </svg>
    `,
    users: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M12.3 9C13.2283 9 14.1185 9.36216 14.7749 10.0068C15.4313 10.6515 15.8 11.5258
                 15.8 12.4375C15.8 13.3492 15.4313 14.2235 14.7749 14.8682C14.1185 15.5128 13.2283 15.875 12.3 15.875C11.3717 15.875
                 10.4815 15.5128 9.82513 14.8682C9.16875 14.2235 8.8 13.3492 8.8 12.4375C8.8 11.5258 9.16875 10.6515 9.82513
                 10.0068C10.4815 9.36216 11.3717 9 12.3 9ZM28.4 9C29.3283 9 30.2185 9.36216 30.8749 10.0068C31.5313 10.6515 31.9 11.5258
                 31.9 12.4375C31.9 13.3492 31.5313 14.2235 30.8749 14.8682C30.2185 15.5128 29.3283 15.875 28.4 15.875C27.4717 15.875
                 26.5815 15.5128 25.9251 14.8682C25.2687 14.2235 24.9 13.3492 24.9 12.4375C24.9 11.5258 25.2687 10.6515 25.9251
                 10.0068C26.5815 9.36216 27.4717 9 28.4 9ZM6 21.8348C6 19.3039 8.09125 17.25 10.6681 17.25H12.5362C13.2319 17.25 13.8925
                 17.4004 14.4875 17.6668C14.4306 17.9762 14.4044 18.2984 14.4044 18.625C14.4044 20.2664 15.1394 21.7402 16.2987
                 22.75C16.29 22.75 16.2812 22.75 16.2681 22.75H6.93187C6.42 22.75 6 22.3375 6 21.8348ZM23.7319 22.75C23.7231 22.75
                 23.7144 22.75 23.7012 22.75C24.865 21.7402 25.5956 20.2664 25.5956 18.625C25.5956 18.2984 25.565 17.9805 25.5125
                 17.6668C26.1075 17.3961 26.7681 17.25 27.4637 17.25H29.3319C31.9087 17.25 34 19.3039 34 21.8348C34 22.3418 33.58 22.75
                 33.0681 22.75H23.7362H23.7319ZM15.8 18.625C15.8 17.531 16.2425 16.4818 17.0302 15.7082C17.8178 14.9346 18.8861 14.5 20
                 14.5C21.1139 14.5 22.1822 14.9346 22.9698 15.7082C23.7575 16.4818 24.2 17.531 24.2 18.625C24.2 19.719 23.7575 20.7682
                 22.9698 21.5418C22.1822 22.3154 21.1139 22.75 20 22.75C18.8861 22.75 17.8178 22.3154 17.0302 21.5418C16.2425 20.7682
                 15.8 19.719 15.8 18.625ZM11.6 29.8527C11.6 26.6902 14.2119 24.125 17.4319 24.125H22.5637C25.7881 24.125 28.4 26.6902
                 28.4 29.8527C28.4 30.4844 27.8794 31 27.2319 31H12.7638C12.1206 31 11.5956 30.4887 11.5956 29.8527H11.6Z" />
        </svg>

    `,
    banned: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M25.2125 27.3359L12.6641 14.7875C11.6141 16.2547 11 18.0547 11 20C11 24.9688
                 15.0312 29 20 29C21.9453 29 23.7453 28.3859 25.2125 27.3359ZM27.3359 25.2125C28.3859 23.7453 29 21.9453 29 20C29
                 15.0312 24.9688 11 20 11C18.0547 11 16.2547 11.6141 14.7875 12.6641L27.3359 25.2125ZM8 20C8 16.8174 9.26428 13.7652
                 11.5147 11.5147C13.7652 9.26428 16.8174 8 20 8C23.1826 8 26.2348 9.26428 28.4853 11.5147C30.7357 13.7652 32 16.8174 32
                 20C32 23.1826 30.7357 26.2348 28.4853 28.4853C26.2348 30.7357 23.1826 32 20 32C16.8174 32 13.7652 30.7357 11.5147
                 28.4853C9.26428 26.2348 8 23.1826 8 20Z" />
        </svg>
    `,
    bucket: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M13.7143 15.125V15.5H11.3571V15.125C11.3571 11.1922 14.7013 8 18.8214
                 8H21.1786C25.2987 8 28.6429 11.1922 28.6429 15.125V15.5H26.2857V15.125C26.2857 12.4344 23.9973 10.25 21.1786
                 10.25H18.8214C16.0027 10.25 13.7143 12.4344 13.7143 15.125ZM9 18.5C9 17.6703 9.70223 17 10.5714 17H29.4286C30.2978 17
                 31 17.6703 31 18.5C31 19.3297 30.2978 20 29.4286 20H29.1781L28.0781 29.9844C27.9504 31.1281 26.9388 32 25.7357
                 32H14.2643C13.0562 32 12.0446 31.1328 11.9219 29.9844L10.8219 20H10.5714C9.70223 20 9 19.3297 9 18.5Z" />
        </svg>
    `,
    camera: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M14.9891 11.0375L14.5016 12.5H11C9.34531 12.5 8 13.8453 8 15.5V27.5C8 29.1547
                 9.34531 30.5 11 30.5H29C30.6547 30.5 32 29.1547 32 27.5V15.5C32 13.8453 30.6547 12.5 29 12.5H25.4984L25.0109
                 11.0375C24.7062 10.1188 23.8484 9.5 22.8781 9.5H17.1219C16.1516 9.5 15.2938 10.1188 14.9891 11.0375ZM20 17C21.1935 17
                 22.3381 17.4741 23.182 18.318C24.0259 19.1619 24.5 20.3065 24.5 21.5C24.5 22.6935 24.0259 23.8381 23.182 24.682C22.3381
                 25.5259 21.1935 26 20 26C18.8065 26 17.6619 25.5259 16.818 24.682C15.9741 23.8381 15.5 22.6935 15.5 21.5C15.5 20.3065
                 15.9741 19.1619 16.818 18.318C17.6619 17.4741 18.8065 17 20 17Z" />
        </svg>
    `,
    female: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M20 7C20.6366 7 21.2472 7.25681 21.6973 7.71393C22.1475 8.17105 22.4004
                 8.79103 22.4004 9.4375C22.4004 10.084 22.1475 10.704 21.6973 11.1611C21.2472 11.6182 20.6366 11.875 20 11.875C19.3634
                 11.875 18.7528 11.6182 18.3027 11.1611C17.8525 10.704 17.5996 10.084 17.5996 9.4375C17.5996 8.79103 17.8525 8.17105
                 18.3027 7.71393C18.7528 7.25681 19.3634 7 20 7ZM16.3995 26.5H15.5093C14.9643 26.5 14.5792 25.9566 14.7492
                 25.4285L16.6645 19.5988L14.9693 22.4629C14.5142 23.2297 13.529 23.4785 12.7739 23.0164C12.0188 22.5543 11.7738 21.5539
                 12.2288 20.7871L14.9092 16.2574C15.9244 14.5461 17.7447 13.5 19.71 13.5H20.29C22.2553 13.5 24.0756 14.5461 25.0908
                 16.2574L27.7712 20.7871C28.2262 21.5539 27.9812 22.5543 27.2261 23.0164C26.471 23.4785 25.4858 23.2297 25.0307
                 22.4629L23.3355 19.6039L25.2508 25.4285C25.4258 25.9566 25.0357 26.5 24.4907 26.5H23.6005V31.375C23.6005 32.2738
                 22.8854 33 22.0003 33C21.1152 33 20.4001 32.2738 20.4001 31.375V26.5H19.5999V31.375C19.5999 32.2738 18.8848 33 17.9997
                 33C17.1146 33 16.3995 32.2738 16.3995 31.375V26.5Z" />
        </svg>

    `,
    folder: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M11.6961 18.7063L8 24.8134V13.5714C8 12.1531 9.19591 11 10.6668
                 11H15.563C16.2714 11 16.9506 11.2692 17.4506 11.7513L18.5549 12.8161C19.0549 13.2982 19.7341 13.5674 20.4425
                 13.5674L25.3345 13.5714C26.8054 13.5714 28.0013 14.7246 28.0013 16.1429V17.4286H14.0004C13.0503 17.4286 12.1753 17.9147
                 11.6961 18.7063ZM12.8461 19.3531C13.0878 18.9554 13.5254 18.7143 14.0004 18.7143H30.6681C31.1473 18.7143 31.5849
                 18.9594 31.8224 19.3612C32.0599 19.7629 32.0599 20.2531 31.8182 20.6509L27.1512 28.3652C26.9137 28.7589 26.4762 29
                 26.0012 29H9.33342C8.85422 29 8.41669 28.7549 8.17918 28.3531C7.94166 27.9513 7.94166 27.4612 8.18335 27.0634L12.8503
                 19.3491L12.8461 19.3531Z" />
        </svg>
    `,
    layers: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M18.8984 8.2426C19.5969 7.91913 20.4031 7.91913 21.1016 8.2426L31.3484
                 12.9774C31.7469 13.1603 32 13.5587 32 13.9994C32 14.4401 31.7469 14.8386 31.3484 15.0214L21.1016 19.7562C20.4031
                 20.0797 19.5969 20.0797 18.8984 19.7562L8.65156 15.0214C8.25313 14.8339 8 14.4354 8 13.9994C8 13.5634 8.25313 13.1603
                 8.65156 12.9774L18.8984 8.2426ZM28.8547 17.8248L31.3484 18.978C31.7469 19.1609 32 19.5593 32 20C32 20.4407 31.7469
                 20.8391 31.3484 21.022L21.1016 25.7568C20.4031 26.0803 19.5969 26.0803 18.8984 25.7568L8.65156 21.022C8.25313 20.8345 8
                 20.436 8 20C8 19.564 8.25313 19.1609 8.65156 18.978L11.1453 17.8248L18.2703 21.1157C19.3672 21.622 20.6328 21.622
                 21.7297 21.1157L28.8547 17.8248ZM21.7297 27.1163L28.8547 23.8254L31.3484 24.9786C31.7469 25.1614 32 25.5599 32
                 26.0006C32 26.4413 31.7469 26.8397 31.3484 27.0226L21.1016 31.7574C20.4031 32.0809 19.5969 32.0809 18.8984
                 31.7574L8.65156 27.0226C8.25313 26.835 8 26.4366 8 26.0006C8 25.5646 8.25313 25.1614 8.65156 24.9786L11.1453
                 23.8254L18.2703 27.1163C19.3672 27.6226 20.6328 27.6226 21.7297 27.1163Z" />
        </svg>
    `,
    phone1: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M21.125 8.00106C27.1297 8.00106 32 12.8712 32 18.8756C32 19.499 31.4984
                 20.0005 30.875 20.0005C30.2516 20.0005 29.75 19.499 29.75 18.8756C29.75 14.1133 25.8875 10.251 21.125 10.251C20.5016
                 10.251 20 9.74942 20 9.12601C20 8.5026 20.5016 8.00106 21.125 8.00106ZM21.5 17.0007C21.8978 17.0007 22.2794 17.1587
                 22.5607 17.44C22.842 17.7213 23 18.1028 23 18.5006C23 18.8984 22.842 19.2799 22.5607 19.5612C22.2794 19.8425 21.8978
                 20.0005 21.5 20.0005C21.1022 20.0005 20.7206 19.8425 20.4393 19.5612C20.158 19.2799 20 18.8984 20 18.5006C20 18.1028
                 20.158 17.7213 20.4393 17.44C20.7206 17.1587 21.1022 17.0007 21.5 17.0007ZM20 13.6258C20 13.0024 20.5016 12.5009 21.125
                 12.5009C24.6453 12.5009 27.5 15.3554 27.5 18.8756C27.5 19.499 26.9984 20.0005 26.375 20.0005C25.7516 20.0005 25.25
                 19.499 25.25 18.8756C25.25 16.5976 23.4031 14.7508 21.125 14.7508C20.5016 14.7508 20 14.2492 20 13.6258ZM13.5078
                 8.06668C14.4172 7.81825 15.3687 8.28229 15.7297 9.15413L17.6047 13.6539C17.9234 14.418 17.7031 15.3039 17.0609
                 15.8242L14.75 17.7178C16.3109 21.0177 18.9828 23.6894 22.2828 25.2503L24.1719 22.9395C24.6969 22.2973 25.5781 22.077
                 26.3422 22.3957L30.8422 24.2707C31.7141 24.6316 32.1781 25.5831 31.9297 26.4924L30.8047 30.6173C30.5844 31.4328 29.8438
                 32 29 32C17.4031 32 8 22.5973 8 11.0009C8 10.1572 8.56719 9.41662 9.38281 9.19163L13.5078 8.06668Z" />
        </svg>

    `,
    phone2: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M15.7293 9.15413C15.3684 8.28229 14.4169 7.81825 13.5076 8.06668L9.38275
                 9.19163C8.56716 9.41662 8 10.1572 8 11.0009C8 22.5973 17.4027 32 28.9991 32C29.8428 32 30.5834 31.4328 30.8084
                 30.6173L31.9333 26.4924C32.1817 25.5831 31.7177 24.6316 30.8459 24.2707L26.3461 22.3957C25.582 22.077 24.6961 22.2973
                 24.1758 22.9395L22.2822 25.2503C18.9823 23.6894 16.3106 21.0177 14.7497 17.7178L17.0605 15.8288C17.7027 15.3039 17.923
                 14.4226 17.6043 13.6586L15.7293 9.15882V9.15413Z" />
        </svg>
    `,
    shield: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M20 8C20.2108 8 20.4217 8.04713 20.6142 8.13668L29.2446 11.9026C30.2529
                 12.3409 31.0046 13.3637 31 14.5986C30.9771 19.2742 29.1071 27.8288 21.21 31.7172C20.4446 32.0943 19.5554 32.0943 18.79
                 31.7172C10.8929 27.8288 9.02294 19.2742 9.00002 14.5986C8.99544 13.3637 9.7471 12.3409 10.7554 11.9026L19.3904
                 8.13668C19.5783 8.04713 19.7892 8 20 8Z" />
        </svg>
    `,
    tools1: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M30.0222 8.87997L31.1202 9.97817C32.2933 11.1515 32.2933 13.0522 31.1202
                 14.2255L28.4738 16.8724L23.1246 11.5269L25.7711 8.87997C26.9441 7.70668 28.8445 7.70668 30.0175
                 8.87997H30.0222ZM10.1084 24.5504L22.0642 12.5876L27.4133 17.9378L15.4528 29.8959C15.1384 30.2104 14.7443 30.4403
                 14.3173 30.5623L9.43737 31.9562C9.04322 32.0689 8.62092 31.9609 8.33 31.6699C8.03908 31.379 7.93116 30.9566 8.04377
                 30.5623L9.43737 25.6815C9.55937 25.2497 9.78929 24.8602 10.1037 24.5457L10.1084 24.5504ZM19.704 12.8269L12.8533
                 19.6789L8.7523 15.5724C7.87485 14.6948 7.87485 13.2681 8.7523 12.3858L12.4169 8.72509C13.2944 7.84747 14.7208 7.84747
                 15.603 8.72509L16.532 9.65434C16.518 9.66842 16.4992 9.6825 16.4851 9.69658L13.4821 12.7002C13.1912 12.9912 13.1912
                 13.4699 13.4821 13.7608C13.773 14.0518 14.2516 14.0518 14.5425 13.7608L17.5456 10.7572C17.5596 10.7431 17.5737 10.7244
                 17.5878 10.7103L19.704 12.8269ZM27.174 20.2984L29.2902 22.415C29.2762 22.4291 29.2574 22.4432 29.2433 22.4573L26.2403
                 25.4609C25.9494 25.7519 25.9494 26.2306 26.2403 26.5215C26.5312 26.8125 27.0098 26.8125 27.3007 26.5215L30.3038
                 23.5179C30.3178 23.5038 30.3319 23.4851 30.346 23.471L31.275 24.4049C32.1525 25.2825 32.1525 26.7093 31.275
                 27.5916L27.6104 31.2522C26.733 32.1299 25.3065 32.1299 24.4244 31.2522L20.3234 27.1504L27.174 20.2984Z" />
        </svg>

    `,
    tools2: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M11.6855 8.23493C11.2402 7.88807 10.6074 7.93025 10.2043 8.32868L8.32944
                 10.2036C7.93102 10.6021 7.88883 11.2349 8.231 11.6849L11.9808 16.5597C12.1917 16.8363 12.5245 17.0004 12.8714
                 17.0004H15.4072L20.5163 22.1096C19.8272 23.469 20.0475 25.1752 21.1865 26.3095L26.4362 31.5594C27.0222 32.1453 27.9737
                 32.1453 28.5596 31.5594L31.5594 28.5595C32.1453 27.9735 32.1453 27.022 31.5594 26.4361L26.3097 21.1862C25.1754 20.0518
                 23.4692 19.8268 22.1099 20.5159L17.0008 15.4066V12.8708C17.0008 12.5192 16.8368 12.1911 16.5602 11.9802L11.6855
                 8.23493ZM8.93409 26.5673C8.33881 27.1626 8.00133 27.9735 8.00133 28.8173C8.00133 30.575 9.42625 32 11.184 32C12.0277 32
                 12.8386 31.6625 13.4338 31.0672L18.9554 25.5455C18.5898 24.5658 18.5336 23.5018 18.7867 22.494L15.8946 19.6019L8.93409
                 26.5673ZM32 14.7504C32 14.2582 31.9484 13.7801 31.85 13.3208C31.7375 12.7958 31.0954 12.6598 30.7157 13.0395L27.7206
                 16.0347C27.5799 16.1754 27.3878 16.2551 27.1909 16.2551L24.5004 16.2504C24.0879 16.2504 23.7505 15.9129 23.7505
                 15.5004V12.8098C23.7505 12.613 23.8301 12.4208 23.9708 12.2802L26.9659 9.28491C27.3456 8.90523 27.2096 8.26306 26.6847
                 8.15056C26.2206 8.05212 25.7425 8.00056 25.2504 8.00056C21.524 8.00056 18.5007 11.0239 18.5007 14.7504V14.7879L22.499
                 18.7862C24.1864 18.3597 26.0519 18.8097 27.3737 20.1315L28.1096 20.8674C30.4063 19.7893 32 17.455 32 14.7504ZM10.6262
                 28.2501C10.6262 27.9517 10.7447 27.6656 10.9557 27.4546C11.1666 27.2436 11.4528 27.1251 11.7511 27.1251C12.0495 27.1251
                 12.3356 27.2436 12.5466 27.4546C12.7575 27.6656 12.8761 27.9517 12.8761 28.2501C12.8761 28.5485 12.7575 28.8346 12.5466
                 29.0456C12.3356 29.2565 12.0495 29.3751 11.7511 29.3751C11.4528 29.3751 11.1666 29.2565 10.9557 29.0456C10.7447 28.8346
                 10.6262 28.5485 10.6262 28.2501Z" />
        </svg>

    `,
    compass: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M20 32C23.1826 32 26.2348 30.7357 28.4853 28.4853C30.7357 26.2348 32 23.1826
                 32 20C32 16.8174 30.7357 13.7652 28.4853 11.5147C26.2348 9.26428 23.1826 8 20 8C16.8174 8 13.7652 9.26428 11.5147
                 11.5147C9.26428 13.7652 8 16.8174 8 20C8 23.1826 9.26428 26.2348 11.5147 28.4853C13.7652 30.7357 16.8174 32 20
                 32ZM22.3766 23.2391L15.6125 25.8406C14.7031 26.1922 13.8078 25.2969 14.1594 24.3875L16.7609 17.6234C16.9156 17.225
                 17.225 16.9156 17.6234 16.7609L24.3875 14.1594C25.2969 13.8078 26.1922 14.7031 25.8406 15.6125L23.2391 22.3766C23.0891
                 22.775 22.775 23.0844 22.3766 23.2391ZM21.5 20C21.5 19.6022 21.342 19.2206 21.0607 18.9393C20.7794 18.658 20.3978 18.5
                 20 18.5C19.6022 18.5 19.2206 18.658 18.9393 18.9393C18.658 19.2206 18.5 19.6022 18.5 20C18.5 20.3978 18.658 20.7794
                 18.9393 21.0607C19.2206 21.342 19.6022 21.5 20 21.5C20.3978 21.5 20.7794 21.342 21.0607 21.0607C21.342 20.7794 21.5
                 20.3978 21.5 20Z" />
        </svg>
    `,
    concrete2: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M29.8692 17.0899C30.0074 17.2817 30.218 17.4091 30.4527 17.4428C30.4948
                 17.4489 30.5372 17.452 30.5797 17.452C30.7704 17.452 30.9556 17.389 31.1063 17.273L31.6523 16.8569C32.0346 16.5603
                 32.1132 16.0164 31.8303 15.6249L25.813 7.36233C25.6748 7.1705 25.4642 7.04312 25.2295 7.00937C24.9974 6.97497 24.7614
                 7.03615 24.5759 7.17899L24.0299 7.59509C23.6476 7.89165 23.569 8.43557 23.8521 8.82706L29.8692 17.0899ZM24.5621
                 8.28663L25.1038 7.87336L31.1212 16.1362C31.1273 16.1451 31.1271 16.1571 31.1203 16.1658L30.5808 16.5817C30.5808 16.5817
                 30.5793 16.5801 30.5786 16.579L24.5613 8.31603C24.5551 8.3071 24.5553 8.29534 24.5621 8.28663Z" />
            <path fill="currentColor" d="M21.3672 26.3116C21.6823 26.2604 21.9678 26.0971 22.1706 25.8519L29.0821
                 17.494L28.8485 17.1733L28.88 17.1796L23.5304 9.87069L13.0636 13.2132C12.7551 13.3116 12.4941 13.52 12.3308
                 13.7985L8.39761 20.4997C8.13684 20.9452 8.16334 21.5017 8.46548 21.9206L12.1841 27.0658C12.4291 27.4077 12.8254 27.6106
                 13.2475 27.6102C13.3196 27.6102 13.3914 27.6043 13.4623 27.5928L13.6088 27.569V22.2416C13.6099 21.2801 14.3935 20.5008
                 15.3604 20.4997H16.2362C17.2031 20.5008 17.9867 21.2801 17.9878 22.2416V26.859L21.3672 26.3116Z" />
            <path fill="currentColor" d="M8.4379 34H21.9293C22.1712 34 22.3672 33.8051 22.3672 33.5645C22.3672 33.3239
                 22.1712 33.129 21.9293 33.129H17.1122V22.242C17.1118 21.7613 16.7198 21.3715 16.2364 21.3711H15.3606C14.8772 21.3715
                 14.4853 21.7613 14.4848 22.242V33.129H8.4379C8.19596 33.129 8 33.3239 8 33.5645C8 33.8051 8.19596 34 8.4379 34ZM15.3604
                 22.242H16.2362V33.129H15.3604V22.242Z" />
        </svg>
    `,
    drain: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M20 32C13.3725 32 8 26.6275 8 20C8 13.3725 13.3725 8 20 8C26.6275 8 32 13.3725
                 32 20C32 26.6275 26.6275 32 20 32ZM20 28.4706C24.6782 28.4706 28.4706 24.6782 28.4706 20C28.4706 15.3218 24.6782
                 11.5294 20 11.5294C15.3218 11.5294 11.5294 15.3218 11.5294 20C11.5294 24.6782 15.3218 28.4706 20 28.4706ZM23.1765
                 26.3053C22.4208 26.6868 21.5872 26.9364 20.7059 27.0239V12.9761C21.5872 13.0636 22.4208 13.3132 23.1765
                 13.6947V26.3053ZM24.5882 14.6356C26.1006 15.9302 27.0588 17.8534 27.0588 20C27.0588 22.1466 26.1006 24.0698 24.5882
                 25.3644V14.6356ZM16.8235 13.6947C17.5792 13.3132 18.4128 13.0636 19.2941 12.9761V27.0239C18.4128 26.9364 17.5792
                 26.6868 16.8235 26.3053V13.6947ZM12.9412 20C12.9412 17.8531 13.8994 15.9302 15.4118 14.6356V25.3644C13.8994 24.0698
                 12.9412 22.1466 12.9412 20Z" />
        </svg>

    `,
    fence: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M31.6813 11.5223L29.4993 9.32192C29.0737 8.89269 28.3825 8.89269 27.9565
                 9.32192L25.7746 11.5223C25.5704 11.7281 25.4549 12.0087 25.4549 12.2994V15.5992H23.273V12.2994C23.273 12.0075 23.1575
                 11.7281 22.9534 11.5223L20.7714 9.32192C20.3457 8.89269 19.6546 8.89269 19.2286 9.32192L17.0466 11.5223C16.8425 11.7281
                 16.727 12.0087 16.727 12.2994V15.5992H14.5451V12.2994C14.5451 12.0075 14.4296 11.7281 14.2254 11.5223L12.0441
                 9.32192C11.6184 8.89269 10.9272 8.89269 10.5013 9.32192L8.3196 11.5223C8.11549 11.7281 8 12.0087 8 12.2994V29.9006C8
                 30.5079 8.48797 31 9.09025 31H13.4539C14.0574 31 14.5442 30.5079 14.5442 29.9006V26.6008H16.7261V29.9006C16.7261
                 30.5079 17.2141 31 17.8164 31H22.1801C22.7835 31 23.2703 30.5079 23.2703 29.9006V26.6008H25.4523V29.9006C25.4523
                 30.5079 25.9403 31 26.5425 31H30.9062C31.5097 31 31.9965 30.5079 31.9965 29.9006L32 12.3006C32 12.0087 31.8845 11.7293
                 31.6804 11.5235V11.5223H31.6813ZM14.5468 24.4004V17.7996H16.7288V24.4004H14.5468ZM23.2744
                 24.4004V17.7996H25.4564V24.4004H23.2744Z" />
        </svg>
    `,
    firehydrant: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M13.7778 14.4C13.3484 14.4 13 14.7584 13 15.2C13 15.6416 13.3484 16 13.7778
                 16H16.1111V14.4H13.7778Z" />
            <path fill="currentColor" d="M26.2222 16C26.6516 16 27 15.6416 27 15.2C27 14.7584 26.6516 14.4 26.2222
                 14.4H23.8889V16H26.2222Z" />
            <path fill="currentColor" d="M27 20.8C27 19.0328 25.6086 17.6008 23.8904 17.6H23.8889L23.8889
                 16H16.1111V17.6C14.393 17.6 13 19.0328 13 20.8C13 22.5672 14.393 24 16.1111 24V30.4H14.5556C14.1262 30.4 13.7778
                 30.7584 13.7778 31.2C13.7778 31.6416 14.1262 32 14.5556 32H25.4444C25.8738 32 26.2222 31.6416 26.2222 31.2C26.2222
                 30.7584 25.8738 30.4 25.4444 30.4H23.8889V24C25.607 24 26.9992 22.5688 27 20.8016V20.8Z" />
            <path fill="currentColor" d="M23.8889 13.6C23.8889 11.6992 22.5884 10.06 20.7778 9.68V8.8C20.7778 8.3584
                 20.4293 8 20 8C19.5707 8 19.2222 8.3584 19.2222 8.8V9.68C17.4116 10.06 16.1111 11.6992 16.1111 13.6V14.4H23.8889V13.6Z" />
        </svg>
    `,
    foundation2: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M6 23.6364H14.4V28H6V23.6364Z" />
            <path fill="currentColor" d="M15.8 23.6364H24.2V28H15.8V23.6364Z" />
            <path fill="currentColor" d="M10.9 17.8182H19.3V22.1818H10.9V17.8182Z" />
            <path fill="currentColor" d="M20.7 17.8182H29.1V22.1818H20.7V17.8182Z" />
            <path fill="currentColor" d="M15.8 12H24.2V16.3636H15.8V12Z" />
            <path fill="currentColor" d="M25.6 23.6364H34V28H25.6V23.6364Z" />
        </svg>
    `,
    framing2: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M31.8733 16.6595L30.4491 15.1889C30.3579 15.0947 30.2317 15.046 30.104
                 15.0603L29.7523 15.0934C29.4861 15.117 29.2205 15.024 29.0259 14.8334C28.8312 14.6432 28.7253 14.3753 28.7352
                 14.0985L28.7797 12.8559C28.784 12.745 28.7424 12.6359 28.6664 12.5574L26.5544 10.3773C26.4077 10.2253 26.152 10.2258
                 26.0048 10.3773C25.8269 10.5609 25.5365 10.5615 25.3578 10.3773L25.228 10.2429C24.1397 9.11869 22.6933 8.5 21.1546
                 8.5C19.6159 8.5 18.169 9.11869 17.0807 10.2424L16.5631 10.7768C16.4927 10.8498 16.4538 10.9461 16.4538 11.0491C16.4538
                 11.1521 16.4927 11.2487 16.5631 11.3214C16.7039 11.4668 16.9492 11.4671 17.0906 11.3214C18.2666 10.1066 20.1487 10.1066
                 21.3058 11.3011C21.857 11.8705 22.2165 12.3895 22.3733 12.8438C22.5551 13.3692 22.4711 13.8328 22.1306 14.1844C22.1125
                 14.2032 22.1517 14.3381 22.2802 14.4703L24.5474 16.8118C24.6234 16.89 24.7296 16.9337 24.8357 16.9288L26.0397
                 16.8845C26.0506 16.8839 26.0618 16.8839 26.0728 16.8839C26.328 16.8839 26.5733 16.9924 26.7493 17.1843C26.9333 17.3848
                 27.0253 17.6573 27.0016 17.9324L26.9706 18.2956C26.9589 18.428 27.0045 18.5574 27.0949 18.6511L28.5202 20.1219C28.6834
                 20.291 28.9682 20.2907 29.1315 20.1219L31.8733 17.2906C31.9549 17.2063 32 17.0943 32 16.975C32 16.8558 31.9549 16.7438
                 31.8733 16.6595ZM18.2311 19.4303L19.6893 20.9356L23.8762 16.6124L22.4178 15.1071L18.2311 19.4303ZM17.6898
                 19.3647C17.6607 19.335 17.6226 19.3196 17.5845 19.3196C17.5485 19.3196 17.5122 19.3331 17.4834 19.3606L8.6604
                 27.8084C8.24547 28.2052 8.01106 28.7457 8.0004 29.3294C7.98946 29.9139 8.20413 30.463 8.60413 30.8757C9.00414 31.2893
                 9.54094 31.5137 10.1017 31.4994C10.6673 31.4883 11.1905 31.2463 11.5751 30.8179L19.7567 21.7074C19.8114 21.6468 19.8098
                 21.5532 19.7527 21.4948L17.6898 19.3647Z" />
        </svg>
    `,
    framing3: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M24.9125 22.2834L21.136 24.9921C20.947
                 25.1274 20.8343 25.3071 20.796 25.5298L20.3582 28.1421C20.3189 28.3751 20.3746 28.5888 20.5214 28.7775L22.6585
                 31.5109C23.0799 32.0499 23.8835 32.1606 24.4462 31.7569L29.4894 28.1391C30.0521 27.7354 30.1677 26.9656 29.7462
                 26.4266L26.6998 22.5291C26.2784 21.9887 25.4752 21.8784 24.9125 22.2834ZM26.4827 27.7437C26.6716 27.9861 26.6201
                 28.3298 26.3671 28.5108L24.4096 29.9147C24.1566 30.0957 23.7979 30.0463 23.6089 29.804L22.6519 28.5785C22.5854 28.4938
                 22.5607 28.3975 22.5785 28.2935L22.774 27.1227C22.7904 27.0223 22.8419 26.9416 22.9265 26.8816L24.3157 25.8855C24.5687
                 25.7045 24.9275 25.7538 25.1164 25.9962L26.4827 27.7437Z" />
            <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M20.0906 26.2943L20.2333
                 25.4412C20.2945 25.0778 20.4873 24.7731 20.7946 24.5517L24.5711 21.8431C24.7124 21.7414 24.8648 21.6621 25.0211
                 21.6061L14.9099 8.67082C14.3323 7.93106 13.2326 7.78004 12.4604 8.33344L10 10.098L11.3308 10.8547L11.3116
                 12.3379L12.6424 13.0947L12.6232 14.5779L13.954 15.3346L13.9348 16.8178L15.2656 17.5746L15.2464 19.0577L16.5772
                 19.8145L16.5581 21.2977L17.8888 22.0544L17.8697 23.5376L19.2004 24.2944L19.1813 25.7776L20.0892 26.2946L20.0906
                 26.2943Z" />
        </svg>
    `,
    utilities: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M27.8839 14.6192C27.6481 14.1988 27.1427 14.0586 26.7384 14.3039L20.8423
                 17.8076V12.9374L23.942 11.0804C23.9757 11.1855 24.1104 11.2556 24.2452 11.2556H24.2789V11.4658H24.2452C24.0767 11.4658
                 23.942 11.606 23.942 11.7461C23.942 11.8863 24.0767 12.0264 24.2452 12.0264H24.2789V12.2367H24.2452C24.0767 12.2367
                 23.942 12.3768 23.942 12.517C23.942 12.6571 24.0767 12.7973 24.2452 12.7973H24.2789V13.0075H24.2452C24.0767 13.0075
                 23.942 13.1476 23.942 13.2878C23.942 13.463 24.0767 13.5681 24.2452 13.5681H24.2789V13.6031C24.2789 13.7082 24.38
                 13.7783 24.481 13.7783C24.5821 13.7783 24.6832 13.7082 24.6832 13.6031V13.6382H24.7169C24.8854 13.6382 25.0201 13.498
                 25.0201 13.3579C25.0201 13.1827 24.8854 13.0776 24.7169 13.0776H24.6832V12.8673H24.7169C24.8854 12.8673 25.0201 12.7272
                 25.0201 12.587C25.0201 12.4469 24.8854 12.3067 24.7169 12.3067H24.6832V12.0965H24.7169C24.8854 12.0965 25.0201 11.9564
                 25.0201 11.8162C25.0201 11.6761 24.8854 11.5359 24.7169 11.5359H24.6832V11.3257H24.7169C24.8854 11.3257 25.0201 11.1855
                 25.0201 11.0454C25.0201 10.8702 24.8854 10.7651 24.7169 10.7651H24.6832V10.7301L25.1549 10.4498C25.5592 10.2045 25.694
                 9.67893 25.4581 9.25848C25.2223 8.83803 24.7169 8.69788 24.3126 8.94314L20.8423 10.9403V7.26135C20.8423 6.5606 20.3032
                 6 19.6294 6H19.5957C18.9219 6 18.3828 6.5606 18.3828 7.26135V12.3768L13.4974 15.32C13.0931 15.5652 12.9584 16.0908
                 13.1942 16.5112C13.3627 16.7915 13.6322 16.9317 13.9354 16.9317C14.0702 16.9317 14.2387 16.8966 14.3397 16.8266L14.6093
                 16.6514C14.643 16.7565 14.7777 16.8616 14.9125 16.8616H14.9462V17.0718H14.9125C14.744 17.0718 14.6093 17.212 14.6093
                 17.3521C14.6093 17.4923 14.744 17.6324 14.9125 17.6324H14.9462V17.8426H14.9125C14.744 17.8426 14.6093 17.9828 14.6093
                 18.1229C14.6093 18.2631 14.744 18.4032 14.9125 18.4032H14.9462V18.6135H14.9125C14.744 18.6135 14.6093 18.7536 14.6093
                 18.8938C14.6093 19.069 14.744 19.1741 14.9125 19.1741H14.9462V19.2091C14.9462 19.3142 15.0473 19.3843 15.1483
                 19.3843C15.2494 19.3843 15.3505 19.3142 15.3505 19.2091V19.2441H15.3842C15.5526 19.2441 15.6874 19.104 15.6874
                 18.9638C15.6874 18.7887 15.5526 18.6835 15.3842 18.6835H15.3505V18.4733H15.3842C15.5526 18.4733 15.6874 18.3332 15.6874
                 18.193C15.6874 18.0529 15.5526 17.9127 15.3842 17.9127H15.3505V17.7025H15.3842C15.5526 17.7025 15.6874 17.5623 15.6874
                 17.4222C15.6874 17.282 15.5526 17.1419 15.3842 17.1419H15.3505V16.9317H15.3842C15.5526 16.9317 15.6874 16.7915 15.6874
                 16.6514C15.6874 16.4762 15.5526 16.3711 15.3842 16.3711H15.3505V16.336L18.4165 14.5141V19.3843L12.4193 22.853C12.015
                 23.0983 11.8802 23.6238 12.1161 24.0443C12.2508 24.3596 12.554 24.4998 12.8573 24.4998C12.992 24.4998 13.1605 24.4647
                 13.2616 24.3946L13.5648 24.2195C13.6322 24.3246 13.7333 24.3596 13.8343 24.3596H13.868V24.5698H13.8343C13.6659 24.5698
                 13.5311 24.71 13.5311 24.8501C13.5311 24.9903 13.6659 25.1304 13.8343 25.1304H13.868V25.3407H13.8343C13.6659 25.3407
                 13.5311 25.4808 13.5311 25.621C13.5311 25.7611 13.6659 25.9012 13.8343 25.9012H13.868V26.1115H13.8343C13.6659 26.1115
                 13.5311 26.2516 13.5311 26.3918C13.5311 26.567 13.6659 26.6721 13.8343 26.6721H13.868V26.7071C13.868 26.8122 13.9691
                 26.8823 14.0702 26.8823C14.1713 26.8823 14.2723 26.8122 14.2723 26.7071V26.6721H14.306C14.4745 26.6721 14.6093 26.5319
                 14.6093 26.3918C14.6093 26.2166 14.4745 26.1115 14.306 26.1115H14.2723V25.9012H14.306C14.4745 25.9012 14.6093 25.7611
                 14.6093 25.621C14.6093 25.4808 14.4745 25.3407 14.306 25.3407H14.2723V25.1304H14.306C14.4745 25.1304 14.6093 24.9903
                 14.6093 24.8501C14.6093 24.71 14.4745 24.5698 14.306 24.5698H14.2723V24.3596H14.306C14.4745 24.3596 14.6093 24.2195
                 14.6093 24.0793C14.6093 23.9041 14.4745 23.799 14.306 23.799H14.2723L18.4165 21.3113C18.4165 21.3113 18.3828 33.391
                 18.4165 33.7414C18.4502 34.0918 19.4946 34.0567 20.0337 33.8115C20.8423 33.4261 20.876 33.1107 20.876 33.1107L20.876
                 19.8398L26.3004 16.5813C26.3678 16.6514 26.4689 16.7214 26.57 16.7214V16.9317H26.5363C26.3678 16.9317 26.233 17.0718
                 26.233 17.212C26.233 17.3521 26.3678 17.4923 26.5363 17.4923H26.57V17.7025H26.5363C26.3678 17.7025 26.233 17.8426
                 26.233 17.9828C26.233 18.1229 26.3678 18.2631 26.5363 18.2631H26.57V18.5434H26.5363C26.3678 18.5434 26.233 18.6835
                 26.233 18.8237C26.233 18.9989 26.3678 19.104 26.5363 19.104H26.57V19.139C26.57 19.2441 26.671 19.3142 26.7721
                 19.3142C26.8732 19.3142 26.9743 19.2441 26.9743 19.139V19.104H27.008C27.1764 19.104 27.3112 18.9638 27.3112
                 18.8237C27.3112 18.6485 27.1764 18.5434 27.008 18.5434H26.9743V18.3332H27.008C27.1764 18.3332 27.3112 18.193 27.3112
                 18.0529C27.3112 17.9127 27.1764 17.7726 27.008 17.7726H26.9743V17.5623H27.008C27.1764 17.5623 27.3112 17.4222 27.3112
                 17.282C27.3112 17.1419 27.1764 17.0017 27.008 17.0017H26.9743V16.7915H27.008C27.1764 16.7915 27.3112 16.6514 27.3112
                 16.5112C27.3112 16.336 27.1764 16.2309 27.008 16.2309H26.9743L27.5807 15.8806C27.985 15.5652 28.1198 15.0046 27.8839
                 14.6192Z" />
        </svg>
    `,
    windows2: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M28.5 32H11.5V8H28.4988V32H28.5ZM12.65
                 30.8521H27.35V9.14676H12.65V30.8521ZM20.3056 30.2421V20.3044H26.7389V30.2421H20.3056ZM13.2611
                 20.3044H19.6944V30.2421H13.2611V20.3044ZM19.6944 9.75677V19.6944H13.2611V9.75677H19.6944ZM26.7389
                 19.6944H20.3056V9.75677H26.7389V19.6944Z" />
        </svg>
    `,
}

export default categoryIcons
