import memoizeResult from '../../memoize-result.js'
import { _field } from '../../selector-primitives.js'

const sortByName = (a, b) => a.name.localeCompare(b.name)

const organizationLookupTable = _field('organizations')
const organizationsAsArray = memoizeResult(state => Object.values(state.organizations).sort(sortByName))

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

const OrganizationSelectors = {
    organizationLookupTable,
    organizationsAsArray,
}

export default OrganizationSelectors
