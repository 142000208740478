import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'
import { Collaboration, Comment, Participant, Upload } from '@range.io/basic-types'
import { mergeRight } from '@range.io/functional'
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 } from 'uuid'
import { useKeyMaps } from '../components-reusable/hooks/index.js'
import { URL_SEARCH_ORDER, useImageUrl } from '../components-reusable/hooks/useImageUrl.js'
import { useNavigateToCollaboration } from '../components-reusable/hooks/useNavigateToCollaboration.js'
import {
    Box,
    Button,
    Flex,
    FlexColumn,
    FlexRow,
    Icon,
    LoadingDotsAnimation,
    TextAreaExternallyChangeable,
} from '../components-reusable/index.js'
import { MediaIconFullscreen } from '../components-reusable/MediaIcon.js'
import {
    CommentAddedCommand,
    CommentChangedCommand,
    CommentRemovedCommand,
    UploadAddedCommand,
    UploadChangedCommand,
    UploadRemovedCommand,
} from '../firebase/commands/index.js'
import { useCommandHistory } from '../firebase/commands/UndoRedo.js'
import { downloadImage, getImageHeightToWidth } from '../helpers.js'
import { styled } from '../range-theme/index.js'
import { ReduxActions, ReduxSelectors } from '../redux/index.js'
import { uploadFileThunk } from '../redux/redux-actions.js'
import * as Segment from '../segment/segment.js'
import CommentBox from './CommentBox.js'
import CommentFeedItem from './CommentFeedItem.js'
import Identifier from './Identifier.js'
import MediaDetailsViewLocationTile from './MediaDetailsViewLocationTile.js'
import MediaDetailsViewTopBar from './MediaDetailsViewTopBar.js'
import PhotoAnnotationEditor from './PhotoAnnotationEditor.js'
import Tags from './Tags.js'

const StyledMediaDetailsViewContainer = styled(Flex, {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 20,
    backgroundColor: '$neutral03',
})

const StyledMediaDetailsViewSidebar = styled('div', {
    width: '450px',
    minWidth: '450px',
    backgroundColor: '$neutral09',
    borderLeft: '1px solid $neutral07',
    display: 'flex',
    flexDirection: 'column',
})

const StyledSidebarContent = styled(ScrollAreaPrimitive.Root, {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    variants: {
        shouldShrink: {
            true: { height: 'calc(100% - 64px - 120px)' },
            false: { height: 'calc(100% - 64px - 71px)' },
        },
    },
})

const StyledSidebarContentViewport = styled(ScrollAreaPrimitive.Viewport, {
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    flexGrow: '5',
})

const StyledSidebarContentScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
    width: '4px',
    display: 'flex',
    padding: 2,
    background: 'none',
    // ensures no selection
    userSelect: 'none',
    // disable browser handling of all panning and zooming gestures on touch devices
    touchAction: 'none',
    transition: 'background 160ms ease-out',
})

const StyledSidebarContentScrollbarThumb = styled(ScrollAreaPrimitive.Thumb, {
    flex: 1,
    background: '#99999950',
    borderRadius: '4px',
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        minWidth: 44,
        minHeight: 44,
    },
})

const StyledImg = styled('img', {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    backgroundColor: '$snapshotBackground',
    variants: {
        isLoading: {
            true: { display: 'none' },
            false: { display: 'visible' },
        },
    },
})

const StyledNavButton = styled('div', {
    width: '52px',
    height: '52px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: '100px',
    cursor: 'pointer',
    transitionDuration: '0.2s',

    variants: {
        disabled: {
            true: {
                pointerEvents: 'none',
                opacity: 0.5,
            },
        },
    },

    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0)',
        outline: '2px solid $primary04',
    },
})

const StyledNavButtonContainer = styled('div', {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [`& ${StyledNavButton}`]: {
        visibility: 'hidden',
    },

    '&:hover': {
        [`& ${StyledNavButton}`]: {
            visibility: 'visible',
        },
    },
})

/*
 * "Smart" wrapper for CommentFeedItem
 */
const CommentWrapper = ({ commentShape, onDelete }) => {
    const handleCommentEdit = (comment, newText) =>
        runCommand(CommentChangedCommand.Outbound(comment.id, { text: newText }))

    const isEditingAllowed = useSelector(ReduxSelectors.isUpdateAllowed('comment', commentShape))
    const { runCommand } = useCommandHistory()

    return (
        <CommentFeedItem
            createdAt={commentShape.createdAt}
            onDelete={() => onDelete(commentShape)}
            onSave={newText => handleCommentEdit(commentShape, newText)}
            participantShape={commentShape.createdBy}
            text={commentShape.text}
            isEditingAllowed={isEditingAllowed}
        />
    )
}

/*
 * (Possibly) show the previous and next buttons, either of which may be disabled if we at the start or end of the list
 */
const PreviousNextButtons = ({ isNavigationAllowed, handleUploadNavigationClicked }) => {
    const onNextClick = () => handleUploadNavigationClicked(1)
    const onPrevClick = () => handleUploadNavigationClicked(-1)

    const showNavigation = isNavigationAllowed.prev || isNavigationAllowed.next

    if (!showNavigation) return null // no buttons needed; there's nowhere to go

    return (
        <StyledNavButtonContainer>
            <StyledNavButton css={{ marginLeft: '16px' }} onClick={onPrevClick} disabled={!isNavigationAllowed.prev}>
                <Icon name="chevronLeft" iconSize="16px" css={{ color: 'white' }} />
            </StyledNavButton>
            <StyledNavButton css={{ marginRight: '16px' }} onClick={onNextClick} disabled={!isNavigationAllowed.next}>
                <Icon name="chevronRight" iconSize="16px" css={{ color: 'white' }} />
            </StyledNavButton>
        </StyledNavButtonContainer>
    )
}

const ImageWithRef = forwardRef(({ src, onLoad, isLoading }, peerRef) => (
    <StyledImg id="photo-annotation-image" ref={peerRef} src={src} onLoad={onLoad} isLoading={isLoading} />
))

/*
 * Container for the fullscreen image, handles loading and shows proper indicator
 */
const MediaDetailsImageContainer = ({
    isEditing,
    setIsEditing,
    imageUrl,
    currentUpload,
    isNavigationAllowed,
    handleUploadNavigationClicked,
}) => {
    const [isLoading, setIsLoading] = useState(true)

    const _setIsEditing = v => {
        // ran-3730: 'first the image without the annotations is being loaded, then the annotated image'
        if (v === NEWLY_SAVED) {
            setIsLoading(true) // cover up the outdated ('main') image we would initially show otherwise
            v = NOT_EDITING // there's no other use for NEWLY_SAVED, so the state should now be NOT_EDITING
        }

        setIsEditing(v)
    }

    const imageRef = useRef()
    useEffect(() => setIsLoading(true), [imageUrl])

    return (
        <Flex align="center" css={{ width: '100%', backgroundColor: '$snapshotBackground', position: 'relative' }}>
            {isLoading && <LoadingDotsAnimation />}
            <ImageWithRef ref={imageRef} src={imageUrl} onLoad={() => setIsLoading(false)} isLoading={isLoading} />
            <PreviousNextButtons
                isNavigationAllowed={isNavigationAllowed}
                handleUploadNavigationClicked={handleUploadNavigationClicked}
            />
            {/* Wait until image is loaded to show annotation editor - so it doesn't break when the upload is not yet uploaded or downloaded */}
            {!isLoading && (
                <PhotoAnnotationEditor
                    isEditing={isEditing}
                    setIsEditing={_setIsEditing}
                    imageRef={imageRef}
                    disabled={isLoading}
                    upload={currentUpload}
                />
            )}
        </Flex>
    )
}

const DownloadButton = ({ url, name, fileType }) => {
    const onClick = () => downloadImage(url, name + '.' + fileType)

    const css = { position: 'absolute', bottom: 40, br: 14, p: 16, bg: '#1D1E29', border: '#333333' }
    return (
        <Box css={css}>
            <Button variant="primary" size="xl" onClick={onClick}>
                <Icon name="download" iconSize="16px" />
                Download
            </Button>
        </Box>
    )
}

/*
 * Show an image or a "preview available" depending on the type of the upload
 */
const ContentViewer = ({
    isEditing,
    setIsEditing,
    imageUrl,
    currentUpload,
    isNavigationAllowed,
    handleUploadNavigationClicked,
}) => {
    const params = useSelector(ReduxSelectors.paramsForTrackEvent)
    const selectedProject = useSelector(ReduxSelectors.selectedProject)
    Segment.sendTrack(
        'media viewed',
        currentUpload.id,
        mergeRight(params, { mediaType: currentUpload.fileType, projectName: selectedProject.name })
    )

    // show image
    if (Upload.isImage(currentUpload))
        return (
            <MediaDetailsImageContainer
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                currentUpload={currentUpload}
                imageUrl={imageUrl}
                isNavigationAllowed={isNavigationAllowed}
                handleUploadNavigationClicked={handleUploadNavigationClicked}
            />
        )

    const { fileType, fileSize, description, name } = currentUpload

    // show "no preview available"
    return (
        <Flex align="center" css={{ width: '100%', backgroundColor: '$snapshotBackground', position: 'relative' }}>
            <MediaIconFullscreen fileType={fileType} fileSize={fileSize} description={description} name={name} />
            <PreviousNextButtons
                handleUploadNavigationClicked={handleUploadNavigationClicked}
                isNavigationAllowed={isNavigationAllowed}
            />
            <DownloadButton url={imageUrl} name={name} fileType={fileType} />
        </Flex>
    )
}

/*
 * Compute whether the previous/next buttons should be allowed
 *
 * In the Media View the top-left upload has the LOWEST index
 * In the CollaborationWindow the top-most upload has the HIGHEST index
 *
 * So for the Media View, there is no PREV if we're at 0 but for the Collaboration Window there is no NEXT if we're at 0
 * Likewise when we click, the direction delta has to fit. For the Media View going RIGHT is an INCREASE in the index
 * while for the Collaboration Window going RIGHT is a DECREASE in the index
 *
 * See handleUploadNavigationClicked too
 */
const computePrevAndNext = (currentIndex, allIndexes) => {
    const navigatedFrom = window.history.state?.usr?.navigatedFrom

    const isIndexBelowMax = currentIndex >= 0 && currentIndex < allIndexes.length - 1
    const isIndexAbove0 = currentIndex > 0

    return navigatedFrom !== 'collaborationWindow'
        ? { prev: isIndexAbove0, next: isIndexBelowMax } // Media View: "normal" direction
        : { prev: isIndexBelowMax, next: isIndexAbove0 } // Collaboration: "reverse" direction
}

const NOT_EDITING = PhotoAnnotationEditor.NOT_EDITING
const EDITING = PhotoAnnotationEditor.EDITING
const NEWLY_SAVED = PhotoAnnotationEditor.NEWLY_SAVED

/*
 * Display a full size image for an upload, together with a sidebar containing information about
 * author, date of creation, description, tags and a section for comments related to this upload.
 * Allows the user to add, modify and delete those upload fields.
 */
const MediaDetailsView = () => {
    const styledMediaDetailsViewSidebar = (currentUpload, project) => {
        const textAreaCss = {
            border: 'none',
            width: '100%',
            background: 'none',
            br: '0px',
            marginBottom: '20px',
            fw: 500,

            '&:hover': { border: 'none' },
            '&:active': { border: 'none' },
            '&:focus': { border: 'none' },
            '&::placeholder': { border: 'none', color: '$neutral05' },
        }

        const topFlexCss = {
            padding: '24px',
            backgroundColor: '$neutral10',
            borderBottom: '1px solid $neutral07',
        }

        const bottomFlexCss = {
            padding: '24px',
            gap: '16px',
        }

        const { collaborationIdentifier } = currentUpload

        const identifierComponent = collaborationIdentifier && (
            <Identifier
                projectIdentifier={selectedProject?.identifier}
                collaborationIdentifier={collaborationIdentifier}
                variant="small"
            />
        )

        const navigateToUpload = (uploadId, isNewUpload = false) => {
            // If we navigate to freshly added upload we need to add it to the uploadsIdsArray,
            // so arrow navigation between uploads works correctly
            const uploadIds = isNewUpload ? [uploadId].concat(uploadIdsInContext) : uploadIdsInContext

            navigate(`../${uploadId}`, {
                state: {
                    safeToNavigateBack: true,
                    uploadIds,
                    navigatedFrom: 'mediaDetailsView',
                    navigatedFromUrl: window.location.pathname,
                },
            })
        }

        const handleUploadNewFile = e => {
            const file = e.target.files[0]
            addUpload(file)
        }

        const addUpload = async file => {
            try {
                const collaboration = ReduxSelectors.collaborationForUpload(getState(), currentUpload)
                const id = v4()
                const imageHeightToWidth = Upload.isImageFile(file) ? await getImageHeightToWidth(file) : 1
                const upload = Upload.uploadForCollaboration({
                    id,
                    collaboration,
                    userId,
                    name: Upload.dropExtension(file.name),
                    imageHeightToWidth,
                    fileType: Upload.guessFileType(file),
                    fileSize: file.size,
                    correlationId: currentUpload.correlationId,
                })

                runCommand(UploadAddedCommand.Outbound(upload))
                navigateToUpload(id, true) // navigate to the upload page
                dispatch(uploadFileThunk({ id, file, projectId: project.id }))
            } catch (e) {
                console.error(e)
                alert(`Error while uploading file: ${file.name}`)
            }
        }

        const onUploadDelete = () => {
            closeDetailsView()
            runCommand(UploadRemovedCommand.Outbound(currentUpload.id))
        }

        const callDeleteUploadModal = () => {
            const documentType = Upload.isImage(currentUpload) ? 'photo' : 'document'

            const title = `Are you sure you want to delete this ${documentType}?`
            const description = `You cannot undo this action.`
            dispatch(
                ReduxActions.globalModalDataSet({
                    type: 'destructive',
                    title,
                    description,
                    cancelButton: {
                        label: 'Cancel',
                    },
                    submitButton: {
                        label: 'Delete',
                        onClick: onUploadDelete,
                    },
                })
            )
        }

        const onDeleteComment = commentShape =>
            runCommand(CommentRemovedCommand.Outbound(commentShape.isNote, commentShape.id))

        const callDeleteCommentModal = commentShape => {
            const title = `Are you sure you want to delete this comment?`
            const description = `You cannot undo this action.`
            dispatch(
                ReduxActions.globalModalDataSet({
                    type: 'destructive',
                    title,
                    description,
                    cancelButton: {
                        label: 'Cancel',
                    },
                    submitButton: {
                        label: 'Delete',
                        onClick: () => onDeleteComment(commentShape),
                    },
                })
            )
        }

        return (
            <StyledMediaDetailsViewSidebar>
                <MediaDetailsViewTopBar
                    upload={currentUpload}
                    allowDelete={allowUploadDelete}
                    uploadsLimitReached={uploadsLimitReached}
                    onCloseClick={closeDetailsView}
                    onDeleteClick={callDeleteUploadModal}
                    onDownloadClick={onDownloadClick}
                    onUploadClicked={navigateToUpload}
                    onUploadNewFile={handleUploadNewFile}
                />
                <StyledSidebarContent shouldShrink={isCommentBoxFocused}>
                    <StyledSidebarContentViewport>
                        <FlexColumn css={topFlexCss}>
                            <FlexRow>
                                <MediaDetailsViewLocationTile
                                    {...currentUpload}
                                    onClick={handleLocationSnapshotClicked}
                                    identifierComponent={identifierComponent}
                                />
                            </FlexRow>
                            <TextAreaExternallyChangeable
                                placeholder={`Add a description...\n(Any text added here will appear in the PDF photo reports)`}
                                externalValue={currentUpload.description ?? null}
                                onBlur={handleDescriptionChange}
                                maxLineCount={20}
                                css={textAreaCss}
                            />
                            <Tags tags={currentUpload?.tagIds ?? []} onTagsChanged={handleTagsChange} />
                        </FlexColumn>
                        <FlexColumn css={bottomFlexCss}>
                            {commentShapes.map(c => (
                                <CommentWrapper
                                    commentShape={c}
                                    key={`comment-feed-item-${c.id}`}
                                    onDelete={callDeleteCommentModal}
                                />
                            ))}
                        </FlexColumn>
                        <StyledSidebarContentScrollbar orientation="vertical">
                            <StyledSidebarContentScrollbarThumb />
                        </StyledSidebarContentScrollbar>
                    </StyledSidebarContentViewport>
                </StyledSidebarContent>
                <CommentBox
                    onCommentSubmit={handleCommentAdded}
                    onFocusChange={isFocused => setIsCommentBoxFocused(isFocused)}
                    userAvatarUrl={participant.avatarUrl}
                    userName={Participant.fullName(participant)}
                    css={{ backgroundColor: '$neutral10' }}
                />
            </StyledMediaDetailsViewSidebar>
        )
    }
    // "upload" here is an enhanced version of an Upload object with extra fields to simplify presentation
    const enhanceUpload = upload => {
        const participantShape = ReduxSelectors.selectedProjectParticipantShapeWithId(getState(), upload.createdBy)
        const parentCollaboration = allCollaborations[upload.parentId]
        const geometry = ReduxSelectors.geometryForCollaboration(getState(), parentCollaboration)
        const canvas = allCanvases[geometry.canvasId]
        const isTask = !!Collaboration.isTask(parentCollaboration)
        const mapPinType = isTask ? 'task' : 'photo'
        const isArchived = !!geometry?.archivedDate
        const statusName = ReduxSelectors.statusNameForCollaboration(getState(), parentCollaboration)
        const isCompleted = !!statusName?.isCompleted
        const collaboration = ReduxSelectors.collaborationForUpload(getState(), upload)
        const correlatedUploads = ReduxSelectors.correlatedUploads(getState(), upload)
        const version = correlatedUploads.length > 1 ? correlatedUploads.indexOf(upload) : null // only add versioning if there is more than one correlated upload
        const category = ReduxSelectors.categoryForCollaboration(getState(), parentCollaboration)

        return mergeRight(upload, {
            canvasId: canvas.id,
            collaborationName: collaboration?.name,
            collaborationTagNames: ReduxSelectors.textsFromTagNameIds(getState(), parentCollaboration.tags),
            collaborationUploadCount: ReduxSelectors.uploadsForCollaboration(getState(), parentCollaboration).length,
            collaborationIdentifier: collaboration?.identifier,
            isArchived,
            isCompleted,
            isTask,
            locationSnapshotUrl,
            locationLabel: canvas.name,
            mapPinType,
            name: Upload.isImage(upload) ? undefined : upload.name, // names for images are useless here
            statusNameColor: statusName?.color,
            tagNames: ReduxSelectors.textsFromTagNameIds(getState(), upload.tagIds),
            participantShape,
            version,
            correlatedUploads,
            category,
        })
    }

    const handleCommentAdded = newCommentText => {
        const comment = Comment.commentForUpload(currentUpload, userId, newCommentText)
        return runCommand(CommentAddedCommand.Outbound(comment))
    }

    const handleDescriptionChange = description => {
        if (description !== currentUpload.description)
            runCommand(UploadChangedCommand.Outbound(currentUpload.id, { description }))
    }

    const handleTagsChange = tags => {
        const tagIds = tags.map(tag => tag.id)
        runCommand(UploadChangedCommand.Outbound(currentUpload.id, { tagIds }))
    }

    const closeDetailsView = () => {
        const navigatedFrom = window.history.state?.usr?.navigatedFrom
        if (navigatedFrom === 'collaborationWindow') {
            const navigatedFromUrl = window.history.state?.usr?.navigatedFromUrl
            navigate(navigatedFromUrl)
        } else if (navigatedFrom === 'mediaView') {
            // when the user came here from MediaView and closes the window we also want to restore the scroll state
            const navigatedFromUrl = window.history.state?.usr?.navigatedFromUrl
            const restoreScrollState = window.history.state?.usr?.scrollState
            navigate(navigatedFromUrl, { state: { restoreScrollState } })
        } else navigate('..')
    }

    const handleUploadNavigationClicked = dirDelta => {
        const navigatedFrom = window.history.state?.usr?.navigatedFrom
        const navigatedFromUrl = window.history.state?.usr?.navigatedFromUrl
        const scrollState = window.history.state?.usr?.scrollState

        // see comment for computePrevAndNext; we need to change the direction of the index
        if (navigatedFrom === 'collaborationWindow') dirDelta = -dirDelta

        const uploadIndexToNavigate = currentUploadIndexInContext + dirDelta
        navigate(`../${uploadIdsInContext[uploadIndexToNavigate]}`, {
            state: {
                safeToNavigateBack: false,
                uploadIds: uploadIdsInContext,
                navigatedFrom,
                navigatedFromUrl,
                scrollState,
            },
        })
    }

    const handleLocationSnapshotClicked = () => navigateToCollaboration(currentUpload.parentId)

    const onDownloadClick = () => downloadImage(url, Upload.fileName(currentUpload))

    // main
    const { runCommand } = useCommandHistory()
    const { pushKeyMap, popKeyMap } = useKeyMaps()
    const { dispatch, getState } = useStore()
    const { uploadId } = useParams()
    const navigate = useNavigate()
    const { navigateToCollaboration } = useNavigateToCollaboration()
    const [currentUpload, setCurrentUpload] = useState()
    const [isCommentBoxFocused, setIsCommentBoxFocused] = useState(false)
    const [isEditing, setIsEditing] = useState(NOT_EDITING)
    const { url } = useImageUrl(
        isEditing === NOT_EDITING ? URL_SEARCH_ORDER.ANNOTATED : URL_SEARCH_ORDER.MAIN, // hide the "live" annotations?
        currentUpload?.id
    )
    const { url: locationSnapshotUrl } = useImageUrl(URL_SEARCH_ORDER.LOCATION_THUMBNAIL_1, currentUpload?.parentId)

    const allUploads = useSelector(ReduxSelectors.uploadLookupTable)
    const allCollaborations = useSelector(ReduxSelectors.collaborationsAsObject)
    const allCanvases = useSelector(ReduxSelectors.canvasLookupTable)
    const userId = useSelector(ReduxSelectors.selectedUserId)
    const selectedProject = useSelector(ReduxSelectors.selectedProject)
    const commentShapes = useSelector(() => ReduxSelectors.commentShapesForUpload(getState(), currentUpload))
    const allowUploadDelete = useSelector(ReduxSelectors.isUpdateAllowed('upload', currentUpload))

    const uploadIdsInContext = window.history?.state?.usr?.uploadIds ?? []
    const currentUploadIndexInContext = uploadIdsInContext.findIndex(id => id === uploadId)
    const isNavigationAllowed = computePrevAndNext(currentUploadIndexInContext, uploadIdsInContext)
    const participant = ReduxSelectors.selectedProjectParticipantWithId(getState(), userId)
    const organization = useSelector(ReduxSelectors.selectedOrganization)

    const uploadsLimitReached = useMemo(() => {
        if (!organization.limitsAndCounts) return false
        const { uploadStorageBytesCount, uploadStorageBytesLimit } = organization.limitsAndCounts
        return uploadStorageBytesCount >= uploadStorageBytesLimit
    }, [organization])

    useEffect(() => {
        const uploadToDisplay = allUploads[uploadId]
        if (uploadToDisplay) {
            setCurrentUpload(enhanceUpload(uploadToDisplay))
        }
    }, [allUploads, uploadId, locationSnapshotUrl])

    useEffect(() => {
        pushKeyMap('MediaDetailsView', { Escape: () => closeDetailsView() })
        if (window.history.state?.usr?.startWithAnnotations) setIsEditing(EDITING)
        return () => popKeyMap('MediaDetailsView')
    }, [])

    if (!selectedProject) return null // probably had access to the project revoked

    return (
        <StyledMediaDetailsViewContainer data-cy="media-details-view">
            {currentUpload && (
                <>
                    <ContentViewer
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        imageUrl={url}
                        currentUpload={currentUpload}
                        isNavigationAllowed={isNavigationAllowed}
                        handleUploadNavigationClicked={handleUploadNavigationClicked}
                    />

                    {isEditing === NOT_EDITING && styledMediaDetailsViewSidebar(currentUpload, selectedProject)}
                </>
            )}
        </StyledMediaDetailsViewContainer>
    )
}

export default MediaDetailsView
