/*
 * FilterGroupTextInput is a reusable input component for filter interfaces. It provides a styled text input with support for:
 *
 * - Search/filter text input
 * - Clear button functionality
 * - Custom placeholder text
 * - Disabled state
 * - Focus/blur handling
 * - Dynamic width based on content
 *
 * This component is used across various filter groups to provide a consistent text search to limit options.
 */

import React, { useLayoutEffect, useRef } from 'react'
import { Button, Icon, TextInput } from '../../../components-reusable/index.js'
import { RangeUITheme, styled } from '../../../range-theme/index.js'

const baseInputStyle = {
    fontFamily: RangeUITheme.fonts.default,
    fontSize: '14px',
    lineHeight: '20px',
}

// Create a hidden span to measure text width
const MeasureSpan = styled('span', {
    ...baseInputStyle,
    position: 'absolute',
    visibility: 'hidden',
    whiteSpace: 'pre',
    height: 0,
})

const StyledInputClearButton = styled(Button, {
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '5px',
    margin: 'auto',
    border: 'none',
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    background: '$primary04',
    transitionDuration: '0.4s',

    '&:hover': {
        cursor: 'pointer',
        $$hoverBackgroundColor: 'var(--colors-primary06)',
        $$hoverColor: 'white',
    },
})

const StyledCanvasInputIcon = styled(Icon, {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    bottom: 0,
    left: 10,
    margin: 'auto',
    color: '$neutral05',
    transitionDuration: '0.4s',
    size: '16px',

    '&:active': { color: '$neutral05' },
})

const StyleTextInputWrapper = styled('div', {
    position: 'relative',
    padding: 0,
    zIndex: 0,
    height: 30,
    mt: 1,
    width: 'fit-content',
    minWidth: 40,
    gap: '8px',
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
        [`& ${StyledCanvasInputIcon}`]: {
            color: 'var(--colors-neutral04)',
        },
    },

    input: {
        ...baseInputStyle,
        transition: 'all 0.4s ease-in-out, width 0s linear 0s',
        boxSizing: 'content-box',
        paddingLeft: '32px',
        paddingRight: '10px',
        borderRadius: '1000px',
        fw: 500,
        boxShadow: '0px 0px 0px #5D81FF50',
        cursor: 'pointer',
        border: '1px solid var(--colors-neutral07)',
        background: 'var(--colors-neutral09)',
        height: '30px',
        color: 'var(--colors-neutral04)',
        width: '32px',

        '&::placeholder': {
            transitionDuration: '0.4s',
            color: 'var(--colors-neutral05)',
        },

        '&:hover': {
            border: '1px solid var(--colors-primary03)',
            boxShadow: '6px 6px 2px #8B8F9D20',
            backgroundColor: 'var(--colors-primary02)',
            color: 'var(--colors-neutral04)',

            '&::placeholder': {
                color: 'var(--colors-neutral04)',
            },
        },

        '&:active': {
            backgroundColor: 'var(--colors-primary03)',
            color: 'var(--colors-neutral04)',
            boxShadow: 'none',
        },
    },
})

const FilterGroupTextInput = ({
    inputRef,
    inputWrapperRef,
    setShowList,
    phraseToFilter,
    setPhraseToFilter,
    placeholder = 'Add',
    iconName,
    IconComponent = StyledCanvasInputIcon,
    dataCy,
}) => {
    const measureRef = useRef()

    const clearInput = () => {
        setPhraseToFilter('')
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }

    // Use the hidden MeasureSpan compoennt to determine the width of the text the user has typed,
    // and then change the width and padding of the (visible) text input that is actually displaying the text
    //
    // Note: useLayoutEffect runs synchronously, immediately after React has performed all DOM mutations
    // but before the browser has painted those changes to the screen
    useLayoutEffect(() => {
        if (measureRef.current && inputRef.current) {
            const textWidth = measureRef.current.offsetWidth
            const padding = phraseToFilter ? 24 : 8
            inputRef.current.style.width = `${textWidth + padding}px`
        }
    }, [phraseToFilter, placeholder])

    return (
        <StyleTextInputWrapper ref={inputWrapperRef}>
            <IconComponent name={iconName} iconSize="16" />
            <MeasureSpan ref={measureRef}>{phraseToFilter || placeholder}</MeasureSpan>
            <TextInput
                ref={inputRef}
                value={phraseToFilter}
                onChange={phrase => {
                    setPhraseToFilter(phrase)
                    setShowList(true)
                }}
                onFocus={() => setShowList(true)}
                onClick={() => setShowList(true)}
                placeholder={placeholder}
                data-cy={dataCy}
            />
            {phraseToFilter && (
                <StyledInputClearButton onClick={clearInput}>
                    <Icon name="close" iconSize="12" css={{ color: 'white' }} />
                </StyledInputClearButton>
            )}
        </StyleTextInputWrapper>
    )
}

export default FilterGroupTextInput
