import { filterValues } from '@range.io/functional'
import memoizeResult from '../../memoize-result.js'
import { _field } from '../../selector-primitives.js'

const uploadLookupTable = _field('uploads')
const uploadsAsArray = memoizeResult(state => Object.values(state.uploads))

/*
 * Return all uploads for the given Collaboration
 * @sig uploadsForCollaboration :: (State, Collaboration) -> [Upload]
 */
const uploadsForCollaboration = (state, collaboration) =>
    collaboration ? filterValues(upload => upload.parentId === collaboration.id, uploadsAsArray(state)) : []

// return correlated uploads for the upload
const correlatedUploads = (state, upload) => {
    const uploadsLookup = state.uploads
    return Object.values(uploadsLookup)
        .filter(el => el.correlationId === upload.correlationId)
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
}

// ---------------------------------------------------------------------------------------------------------------------
// API
// ---------------------------------------------------------------------------------------------------------------------

const UploadSelectors = {
    correlatedUploads,
    uploadLookupTable,
    uploadsAsArray,
    uploadsForCollaboration,
}

export default UploadSelectors
