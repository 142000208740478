/*
 * FilterGroupForStatus is a filter group component for filtering content by status.
 * It provides a multi-select interface for choosing task/pin statuses, with support for:
 *
 * - Selecting multiple statuses
 * - Disabled state when task pins are not shown
 * - Custom styling through CSS props
 *
 * The component integrates with the FilterSettings system and uses FilterGroupMultiSelect for its UI implementation.
 */
import { FilterSettings } from '@range.io/basic-types'
import React from 'react'
import { FlexColumn } from '../../components-reusable/index.js'
import { styled } from '../../range-theme/index.js'
import { Row } from './filter-primitives/FilterGroupShared.js'

// each status can be toggled on/off
const statusRow = ({ statusShape, disabled = false, showStatuses, filter, filterSettings, setFilterSettings }) => {
    const setChecked = b => setFilterSettings(filter.setStatus(filterSettings, statusShape, b))
    const checked = showStatuses.includes(statusShape) && !disabled
    const props = { title: statusShape.name, checked, disabled, setChecked }
    return <Row key={statusShape.id} {...props} />
}

const StyledStatusGroup = styled(FlexColumn, {
    w: 'auto',
    boxSizing: 'border-box',
    ml: 16,
    mr: 16,
    gap: 12,
    pl: 0,
    pr: 0,
    pt: 16,
    pb: 16,
    borderTop: '1px solid $neutral07',
})

/*
 * FilterGroupForStatus component for filtering by status
 * @sig FilterGroupForStatus :: ({[StatusShape], Boolean, FilterSettings, (FilterSettings -> ())}, CSS) -> ReactElement
 */
const FilterGroupForStatus = ({
    allStatusShapes,
    disabled = false,
    filterSettings,
    setFilterSettings,
    css = {},
    'data-cy': dataCy,
}) => {
    const { showStatuses } = filterSettings

    return (
        <StyledStatusGroup style={css} data-cy={dataCy}>
            {allStatusShapes.map(statusShape =>
                statusRow({
                    statusShape,
                    disabled,
                    showStatuses,
                    filter: FilterSettings,
                    filterSettings,
                    setFilterSettings,
                })
            )}
        </StyledStatusGroup>
    )
}

export default FilterGroupForStatus
