import { memoizeReduxState } from '@range.io/functional'
import filterCollaborations from './redux-filter-common.js'
import ReduxSelectors from './redux-selectors.js'

/*
 * Filter the TaskList that meet the taskListFilterSettings. This is ALL the CollaborationShapes
 * that meet the filter criteria, not just the ones on the selected canvas.
 * @sig _filteredTaskList :: State -> [CollaborationShape]
 * memoized as filteredTaskList below
 */
const _filteredTaskList = state => {
    const filterByCanvas = collaborationShape => {
        if (selectedCanvasShapes.length === 0) return true // the user hasn't yet selected any canvases to filter by: all pass
        return selectedCanvasShapes.some(canvas => canvas.id === collaborationShape.canvasId) // has ANY required canvas
    }

    const { selectedUserId, taskListFilterSettings } = state
    const { shouldFilterByCanvas, selectedCanvasShapes } = taskListFilterSettings

    const allShapes = ReduxSelectors.allShapes(state)
    const { collaborations: allCollaborations } = allShapes

    // Filter to only have tasks
    let collaborationShapes = Object.values(allCollaborations).filter(c => !!c.isTask)

    // Apply canvas filter specific to task list view
    if (shouldFilterByCanvas) collaborationShapes = collaborationShapes.filter(filterByCanvas)

    // Apply common filters
    collaborationShapes = filterCollaborations(collaborationShapes, taskListFilterSettings, selectedUserId)

    return collaborationShapes
}

/*
 * Memoize the _filteredTaskList function, recomputing only if any of the collections or filters has changed
 */
const filteredTaskList = memoizeReduxState(
    [
        'taskListFilterSettings',

        'canvases',
        'categories',
        'collaborations',
        'geometries',
        'selectedCanvas',
        'uploads', // we need this so when upload image URLs get updated this also gets refreshed
        'statusNames',
    ],
    _filteredTaskList
)
export { filteredTaskList }
